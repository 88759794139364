import React from 'react';
import PropTypes from 'prop-types';
import { Button, PaymentIcon, RadioButton } from 'anf-core-react';
import Tmnt from '../../../Tmnt/Tmnt';
import PaymentFields from '../PaymentFields/PaymentFields';
import {
  dispatchPaymentTypeChangedEvent,
  handleClickPayOverTimeLearnMore,
} from '../../../../tools/paymentTypeChangedEvent';

export default function PaymentOption({
  isLoggedIn,
  isSelected,
  payment,
  changePaymentOption,
  savedPaymentDetails,
  tmntData,
  onChange,
  onClear,
  paymentConfig,
  setSelectedPayment,
}) {
  const paymentId = (payment?.id || '').replace(/\s/g, '-');
  if (paymentId === 'applepay' && !window.ApplePaySession) {
    return null;
  }

  let description = '';
  let hasLearnMore = false;
  if ((payment?.type === 'Klarna' || payment?.type === 'paypal') && payment?.message) {
    description = payment?.message;
    hasLearnMore = true;
  } else if (payment?.type === 'credit card') {
    description = payment?.description;
  }

  const handleChangePaymentOption = () => {
    if (!payment?.disabled) {
      changePaymentOption(payment?.type);
      dispatchPaymentTypeChangedEvent(payment);
    }
  };

  return (
    <div className="payment-option">
      <RadioButton
        key={paymentId}
        id={paymentId}
        name="payment-radio"
        value={payment?.id}
        label={<span>{payment?.name}</span>}
        valueDescription={(
          <span className="payment-icon-wrap">
            <PaymentIcon iconName={payment?.icon} labelText={payment?.name} />
          </span>
        )}
        isChecked={isSelected}
        isDisabled={payment?.disabled}
        onChange={handleChangePaymentOption}
        labelDescription={(
          <>
            {description && (
              <Tmnt tmnt={description} isHtml />
            )}
            {hasLearnMore && (
              <Button
                onClick={() => handleClickPayOverTimeLearnMore(payment?.type)}
                classList="payment-option-learn-more ds-override learn-more-button"
                variant="borderless"
                isDisabled={payment?.disabled}
              >
                <Tmnt tmnt={tmntData?.payOverTimeLearnMore} />
              </Button>
            )}
          </>
        )}
        classList={paymentId}
      >
        {isSelected && !payment?.disabled && (
          <PaymentFields
            isLoggedIn={isLoggedIn}
            selectedPayment={payment}
            paymentConfig={paymentConfig}
            tmntData={tmntData}
            savedPaymentDetails={savedPaymentDetails}
            setSelectedPayment={setSelectedPayment}
            onChange={onChange}
            onClear={onClear}
            isRadioButtonView
          />
        )}
      </RadioButton>
    </div>
  );
}

PaymentOption.defaultProps = {
  isLoggedIn: false,
  isSelected: false,
  payment: {},
  savedPaymentDetails: null,
  paymentConfig: {},
  tmntData: {},
  setSelectedPayment: () => {},
  onChange: () => {},
  onClear: () => {},
};

PaymentOption.propTypes = {
  isLoggedIn: PropTypes.bool,
  isSelected: PropTypes.bool,
  payment: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    code: PropTypes.string,
    description: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    message: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    icon: PropTypes.string,
    disabled: PropTypes.bool,
    savable: PropTypes.bool,
    default: PropTypes.bool,
  }),
  changePaymentOption: PropTypes.func.isRequired,
  setSelectedPayment: PropTypes.func,
  savedPaymentDetails: PropTypes.shape({
    iconCode: PropTypes.string,
    maskedCardNumber: PropTypes.string,
    cardExpiryMonth: PropTypes.string,
    cardExpiryYear: PropTypes.string,
    cardType: PropTypes.string,
    requiresCvv: PropTypes.bool,
    savedPaymentId: PropTypes.string,
    default: PropTypes.bool,
  }),
  paymentConfig: PropTypes.instanceOf(Object),
  tmntData: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  onClear: PropTypes.func,
};
