import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Charity from './Charity';
import OrderContactInfoWrapper from '../OrderContactInfo/OrderContactInfoWrapper';
import MarketingPreferencesWrapper from '../MarketingPreferences/MarketingPreferencesWrapper';
import SubmitButtonWrapper from '../SubmitButton/SubmitButtonWrapper';
import SwitchTestContext from '../../../context/SwitchTestContext';
import CheckOutRightRail from '../CheckoutRightRail';
import { useIsL } from '../../../hooks/useBreakPoint';
import OrderSubmitLegalTermsWrapper from '../OrderSubmitLegalTerms/OrderSubmitLegalTermsWrapper';

function CharityPortalComponents({
  hasCharity,
  hasOrderContactInfo,
  hasMFESubmitButton,
  hasOrderSubmitLegal,
}) {
  const isLBreakPoint = useIsL();
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) { return null; }
  return (
    <div className="checkout-mfe-web-service">
      {hasOrderContactInfo && <OrderContactInfoWrapper />}
      {hasOrderContactInfo && <MarketingPreferencesWrapper />}
      {hasCharity && <Charity />}
      {isLBreakPoint && (
        <div className="checkout-right-rail-mobile">
          <CheckOutRightRail parentClass="checkout-right-rail order-summary-wrapper" showPayOverTime={false} />
        </div>
      )}
      {hasOrderSubmitLegal && <OrderSubmitLegalTermsWrapper />}
      {hasMFESubmitButton && <SubmitButtonWrapper />}
    </div>
  );
}

function CharityPortal({
  id, hasCharity, hasOrderSubmitLegal,
}) {
  const {
    digitalData,
  } = useContext(SwitchTestContext);
  const hasOrderContactInfo = digitalData && digitalData['chk-order-contact-info-mfe-enabled'];
  const hasMFESubmitButton = digitalData && digitalData['chk-use-mfe-submit-button'];

  if (typeof document !== 'undefined') {
    return ReactDOM.createPortal(
      CharityPortalComponents({
        hasCharity, hasOrderContactInfo, hasMFESubmitButton, hasOrderSubmitLegal,
      }),
      document.getElementById(id),
    );
  }
  return null;
}

CharityPortal.defaultProps = {
  id: '',
  hasCharity: false,
  hasOrderSubmitLegal: false,
};

CharityPortal.propTypes = {
  id: PropTypes.string,
  hasCharity: PropTypes.bool,
  hasOrderSubmitLegal: PropTypes.bool,
};

CharityPortalComponents.defaultProps = {
  hasCharity: false,
  hasOrderContactInfo: false,
  hasMFESubmitButton: false,
  hasOrderSubmitLegal: false,
};

CharityPortalComponents.propTypes = {
  hasCharity: PropTypes.bool,
  hasOrderContactInfo: PropTypes.bool,
  hasMFESubmitButton: PropTypes.bool,
  hasOrderSubmitLegal: PropTypes.bool,
};

export default CharityPortal;
