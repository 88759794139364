import { Button } from 'anf-core-react';
import React, { useContext } from 'react';
import OrderConfirmationContext from '../../../context/OrderConfirmationContext';
import Tmnt from '../../Tmnt/Tmnt';

function SignIn() {
  const { orderConfirmationData } = useContext(OrderConfirmationContext);
  const { orderContact, textFor } = orderConfirmationData;
  const { earnPointsExplained, userName, userSignIn } = textFor;

  const openSignInModal = () => {
    document.dispatchEvent(new CustomEvent(
      'authentication-modal:open',
      { detail: { isSignIn: true } },
    ));
  };

  return (
    <>
      <p className="ocn-points-reward__description">
        <Tmnt tmnt={earnPointsExplained} />
      </p>
      {orderContact?.email && (
        <p className="ocn-points-reward__email">
          <Tmnt tmnt={userName} />
          {`: ${orderContact?.email}`}
        </p>
      )}
      <Button
        classList="ocn-points-reward__action"
        variant="secondary"
        isFullWidth
        onClick={openSignInModal}
      >
        <Tmnt tmnt={userSignIn} />
      </Button>
    </>
  );
}

export default SignIn;
