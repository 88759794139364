import $window from '../../../tools/window';

const submitBagMutationCompleted = (
  submitBagMutationObj,
  setBag,
  bag,
  updateBagCount,
) => {
  // SOLDOUT_ITEM, SFS_SOLDOUT_ITEM repudiation loads when we load the bag for the first time,
  // resolver doesn't return errorMessage then. If inventoryRepudiation data comes back
  // from PUT cart data, then only we set errorMessage. Hence checking this scenario to check
  // if this is an inventoryRepudiation scenario or not
  // GC_NON_USD_REPUDIATE repudiation is when the gift card currency is not in USD on WD site
  const BAG_REPUDIATION_TYPES = ['SOLDOUT_ITEM', 'SFS_SOLDOUT_ITEM', 'GC_NON_USD_REPUDIATE', 'SKU_RESTRICT'];
  const submitBagRepudiation = submitBagMutationObj?.submitBag?.repudiationData;

  if ((BAG_REPUDIATION_TYPES.includes(submitBagRepudiation?.repudiationType))
      && submitBagRepudiation?.errorMessage !== '') {
    const updateSaveForLater = new CustomEvent('shoppingBag:submitBagMutation:done', { detail: 'success' });
    window.dispatchEvent(updateSaveForLater);
    setBag((previousState) => {
      const newState = {
        ...previousState,
        orderTotals: submitBagMutationObj?.submitBag?.orderTotals,
        bagItems: submitBagMutationObj?.submitBag?.bagItems,
        repudiationData: submitBagRepudiation,
        promoInfo: submitBagMutationObj?.submitBag?.promoInfo,
        rewardsAndPromotions: submitBagMutationObj?.submitBag?.rewardsAndPromotions,
        freeShippingProgressInfo: submitBagMutationObj?.submitBag?.freeShippingProgressInfo,
      };

      // merge the bag response to digitalData `cart`
      $window.digitalData?.merge('cart', {
        origin: 'bag',
        data: newState,
        action: 'bag_repudiation',
      });

      return newState;
    });

    updateBagCount(submitBagMutationObj?.submitBag?.bagItems);
  } else if (submitBagMutationObj?.submitBag?.success === false) {
    // if PUT call fails, we will be getting responseInfo with success as false
    // setting NetworkResponse to show the error message in the UI or for debugging purposes
    setBag((previousState) => ({
      ...previousState,
      statusCode: submitBagMutationObj?.submitBag?.statusCode,
      success: submitBagMutationObj?.submitBag?.success,
      statusMessages: submitBagMutationObj?.submitBag?.statusMessages,
    }));
  } else {
    // This takes in every other conditions outside of these two scenarios above.
    // It takes us to checkoutUrl as part of normal repudiation flow.
    window.location.href = bag?.checkoutButton?.checkoutUrl;
  }
};

export default submitBagMutationCompleted;
