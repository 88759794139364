import React from 'react';
import { ErrorMessage, Icon } from 'anf-core-react';

export const ERROR_MESSAGE = 'Looks like we are having a problem, and are working to resolve the issue. Please try again in a few minutes.';

export const SHOPPING_BAG_ERROR_MESSAGE = (
  <div className="shopping-bag-error-message scope-1892">
    <ErrorMessage>
      <Icon icon="exclamation" />
      {ERROR_MESSAGE}
    </ErrorMessage>
  </div>
);

export const LOADING_MESSAGE = <p>Loading...</p>;
