import React, {
  useState, useRef, forwardRef, useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button, ButtonGroup, ErrorMessage, FieldGroup, InputField, TooltipWidget,
} from 'anf-core-react';
import useScript from '../../../Helpers/useScript';
import Tmnt from '../../../Tmnt/Tmnt';
import GiftCardBalance from './GiftCardBalance';

const GC_NUMBER_MAX_LENGTH = 16;
const GC_PIN_MAX_LENGTH = 16;
const EGC_NUMBER_LENGTH = 16;
const GC_NUMBER_LENGTH = 12;

const GiftCardForm = forwardRef(({
  applyGiftCard,
  checkGiftCardBalance,
  error,
  tmntData,
  gRecaptchaSiteKey,
  isCaptchaEnabled,
  hasGlobalReCaptcha,
  numberOfGiftCardsApplied,
  giftCardBalance,
}, ref) => {
  const [showRedeemGiftCardForm, setShowRedeemGiftCardForm] = useState(false);
  const [giftCardNumber, setGiftCardNumber] = useState('');
  const [giftCardPin, setGiftCardPin] = useState('');
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isProcessingCheckBalance, setIsProcessingCheckBalance] = useState(false);
  const [isProcessingApplyBalance, setIsProcessingApplyBalance] = useState(false);
  const actionData = useRef({
    giftCardNumber: '',
    giftCardPin: '',
    action: '',
  });
  const setProcessing = () => {
    if (actionData.current.action === 'check-balance') {
      setIsProcessingCheckBalance(true);
    } else if (actionData.current.action === 'apply') {
      setIsProcessingApplyBalance(true);
    }
  };
  const clearProcessing = (clearInput = false) => {
    if (actionData.current.action === 'check-balance') {
      setIsProcessingCheckBalance(false);
    } else if (actionData.current.action === 'apply') {
      setIsProcessingApplyBalance(false);
    }
    if (clearInput) {
      setGiftCardNumber('');
      setGiftCardPin('');
      actionData.current.giftCardNumber = '';
      actionData.current.giftCardPin = '';
      setShowRedeemGiftCardForm(false);
    }
    actionData.current.action = '';
  };
  useImperativeHandle(ref, () => ({
    setProcessing,
    clearProcessing,
  }));
  const [recaptchaScript] = useScript({
    id: 'recaptcha-script',
    src: (hasGlobalReCaptcha
      ? 'https://www.recaptcha.net/recaptcha/api.js?hl=en'
      : 'https://www.google.com/recaptcha/api.js?hl=en'),
  });
  const showGiftCardPinInput = giftCardNumber.length < EGC_NUMBER_LENGTH;
  const canApplyGiftCard = (giftCardNumber.length === EGC_NUMBER_LENGTH)
    || (giftCardNumber.length === GC_NUMBER_LENGTH && giftCardPin.length > 0);

  const executeCaptcha = () => {
    if (recaptchaScript.loaded && window.grecaptcha) {
      try {
        window.grecaptcha.reset();
      } catch (e) {
        window.grecaptcha.render('giftcard-grecaptcha', {
          sitekey: gRecaptchaSiteKey,
          size: 'invisible',
          callback: (token) => {
            if (actionData.current.action === 'check-balance') {
              checkGiftCardBalance(
                actionData.current.giftCardNumber,
                actionData.current.giftCardPin,
                token,
              );
            } else if (actionData.current.action === 'apply') {
              applyGiftCard(
                actionData.current.giftCardNumber,
                actionData.current.giftCardPin,
                token,
              );
            }
          },
        });
      }
      window.grecaptcha.execute();
    }
  };

  const setInput = (inputFieldType, inputValue) => {
    const inputVal = (inputValue || '').replace(/[^0-9]+/g, '');
    if (inputFieldType === 'gift-card-number' && inputVal.length <= GC_NUMBER_MAX_LENGTH) {
      setGiftCardNumber(inputVal);
      actionData.current.giftCardNumber = inputVal;
      if (inputVal.length === EGC_NUMBER_LENGTH && giftCardPin) {
        setGiftCardPin('');
        actionData.current.giftCardPin = '';
      }
    } else if (inputFieldType === 'gift-card-pin' && inputVal.length <= GC_PIN_MAX_LENGTH) {
      setGiftCardPin(inputVal);
      actionData.current.giftCardPin = inputVal;
    }
  };

  const handleApplyGiftCard = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (canApplyGiftCard) {
      actionData.current.action = 'apply';
      if (isCaptchaEnabled) {
        executeCaptcha();
      } else {
        applyGiftCard(giftCardNumber, giftCardPin);
      }
      setProcessing();
    }
  };

  const handleCheckGiftCardBalance = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (canApplyGiftCard) {
      actionData.current.action = 'check-balance';
      if (isCaptchaEnabled) {
        executeCaptcha();
      } else {
        checkGiftCardBalance(giftCardNumber, giftCardPin);
      }
      setProcessing();
    }
  };

  return (
    <div className="gift-card-form">
      {!showRedeemGiftCardForm && (
        <Button variant="tertiary-dark" onClick={() => setShowRedeemGiftCardForm(true)} isFullWidth>
          <Tmnt
            tmnt={(
              numberOfGiftCardsApplied > 0
                ? tmntData?.giftCardRedeemAnother : tmntData?.giftCardRedeem
            )}
          />
        </Button>
      )}
      {showRedeemGiftCardForm && (
        <div className="gift-card-form-container">
          <h3
            className="gift-card-reedeem-title"
          >
            <Tmnt tmnt={tmntData?.giftCardRedeem} />
          </h3>
          <p
            className="gift-card-apply-up-to-num"
            data-property="CHK_APPLYUPTONUMGIFTCARDSPERORDER"
          >
            <Tmnt tmnt={tmntData?.giftCardApplyMaxMessage} />
          </p>
          <div className="gift-card-form-wrapper">
            <FieldGroup legend="gift-card-form" isInvalid={!!error}>
              <InputField
                name="gift-card-number"
                id="gift-card-number"
                type="text"
                label={tmntData?.giftCardNumber?.value}
                value={giftCardNumber}
                onChange={(event) => {
                  setInput('gift-card-number', event.target.value);
                }}
                autoComplete="false"
                maxLength={GC_NUMBER_MAX_LENGTH}
              >
                <TooltipWidget
                  buttonLabelText="Gift Card Account Number"
                  contentID="gift-card-number-tooltip"
                  contentPosition="top-right"
                  onKeyUp={(event) => {
                    event.stopPropagation();
                    if (event.key === 'Escape') {
                      setIsTooltipOpen(false);
                    } else if (['Space', 'Return'].includes(event.key)) {
                      setIsTooltipOpen((isOpen) => !isOpen);
                    }
                  }}
                  onClick={(event) => {
                    event.preventDefault();
                    setIsTooltipOpen((isOpen) => !isOpen);
                  }}
                  isOpen={isTooltipOpen}
                >
                  <Tmnt tmnt={tmntData?.giftCardTooltipContent} isHtml />
                </TooltipWidget>
              </InputField>
              {showGiftCardPinInput && (
                <InputField
                  name="gift-card-pin"
                  id="gift-card-pin"
                  type="text"
                  label={tmntData?.giftCardPin?.value}
                  value={giftCardPin}
                  onChange={(event) => {
                    setInput('gift-card-pin', event.target.value);
                  }}
                  autoComplete="false"
                  maxLength={GC_PIN_MAX_LENGTH}
                />
              )}
              {error && (
                <ErrorMessage id="gift-card-number-invalid">
                  {error}
                </ErrorMessage>
              )}
            </FieldGroup>
          </div>
          {giftCardBalance && (
            <GiftCardBalance
              giftCardBalance={giftCardBalance}
              tmntData={tmntData}
            />
          )}
          <div className="gift-card-action-container">
            <ButtonGroup variant="grid">
              <Button
                variant="secondary"
                onClick={handleApplyGiftCard}
                isProcessing={isProcessingApplyBalance}
                isDisabled={!canApplyGiftCard || isProcessingApplyBalance}
              >
                {tmntData?.giftCardApply?.value}
              </Button>
              <Button
                variant="tertiary-dark"
                onClick={handleCheckGiftCardBalance}
                isProcessing={isProcessingCheckBalance}
                isDisabled={!canApplyGiftCard || isProcessingCheckBalance}
              >
                {tmntData?.giftCardCheckBalance?.value}
              </Button>
            </ButtonGroup>
          </div>
        </div>
      )}
      {isCaptchaEnabled && (
        <div id="giftcard-grecaptcha" />
      )}
    </div>
  );
});

export default GiftCardForm;

GiftCardForm.defaultProps = {
  error: '',
  tmntData: {},
  gRecaptchaSiteKey: '',
  isCaptchaEnabled: false,
  hasGlobalReCaptcha: false,
  numberOfGiftCardsApplied: 0,
  giftCardBalance: '',
};

GiftCardForm.propTypes = {
  applyGiftCard: PropTypes.func.isRequired,
  checkGiftCardBalance: PropTypes.func.isRequired,
  error: PropTypes.string,
  tmntData: PropTypes.instanceOf(Object),
  gRecaptchaSiteKey: PropTypes.string,
  isCaptchaEnabled: PropTypes.bool,
  hasGlobalReCaptcha: PropTypes.bool,
  numberOfGiftCardsApplied: PropTypes.number,
  giftCardBalance: PropTypes.string,
};
