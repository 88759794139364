import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonGroup } from 'anf-core-react';
import CheckoutButton from '../CheckoutButton/CheckoutButton';

export default function BagCta({
  checkoutURLProps,
  viewBagProps,
  showCheckoutBtn,
  tmntData,
}) {
  return (
    <ButtonGroup variant="grid">
      <Button
        variant={viewBagProps.variant}
        href={viewBagProps.bagUrl}
        classList="viewBag-button"
        isFullWidth={!showCheckoutBtn}
      >
        {tmntData.viewBag.value}
      </Button>
      {showCheckoutBtn && <CheckoutButton {...checkoutURLProps} tmntData={tmntData} />}
    </ButtonGroup>
  );
}

BagCta.defaultProps = {
  checkoutURLProps: {
    checkoutUrl: '',
    brand: '',
    variant: '',
    tmntData: {},
  },
  viewBagProps: {
    bagUrl: '',
    brand: '',
    variant: '',
    label: '',
  },
  showCheckoutBtn: true,
  tmntData: {},
};

BagCta.propTypes = {
  checkoutURLProps: PropTypes.shape({
    checkoutUrl: PropTypes.string,
    brand: PropTypes.string,
    variant: PropTypes.string,
    tmntData: PropTypes.instanceOf(Object),
  }),
  viewBagProps: PropTypes.shape({
    bagUrl: PropTypes.string,
    brand: PropTypes.string,
    variant: PropTypes.string,
  }),
  showCheckoutBtn: PropTypes.bool,
  tmntData: PropTypes.instanceOf(Object),
};
