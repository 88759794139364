import React, {
  useCallback,
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { useLazyQuery } from '@apollo/client';
import CheckoutPageContext from '../../../../context/CheckoutPageContext';
import useScript from '../../../Helpers/useScript';
import useLog from '../../../useLog/useLog';
import prepareAuthorizationRequest from '../../../../tools/klarna';
import removeTypeNameFromObject from '../../../../tools/gql';
import KLARNA_SESSION_QUERY from '../../../../gql/klarna.gql';
import $window from '../../../../tools/window';

export default function Klarna() {
  const retryDelay = 500;
  const maxRetries = 3;
  const [klarnaLoaded, setKlarnaLoaded] = useState(false);
  const logger = useLog('checkout.klarna');
  const retryCount = useRef(0);
  const sessionRequestDone = useRef(false);
  const { checkoutPageState, updateCheckoutPageState } = useContext(CheckoutPageContext);
  const orderId = checkoutPageState?.orderTotals?.orderId;

  const [klarnaScript] = useScript({
    id: 'klarna-script',
    src: 'https://x.klarnacdn.net/kp/lib/v1/api.js',
  });

  let refetchOnErrror = () => {};

  const updateStateOrRefetch = (res, refetchQuery, mounted) => {
    const klarnaClientToken = res?.klarnaSession?.clientToken;
    const klarnaSessionId = res?.klarnaSession?.paymentOptions;
    const klarnaPaymentOptions = res?.klarnaSession?.paymentOptions;
    const success = res?.klarnaSession?.success;
    if (success && klarnaClientToken && klarnaSessionId && klarnaPaymentOptions) {
      updateCheckoutPageState({ ...res });
    } else {
      logger.error(`ERR: updateStateOrRefetch: orderId: ${orderId} err: ${JSON.stringify(res)}`);
      refetchOnErrror(refetchQuery, mounted);
    }
  };

  const refetchAndSetState = (refetchQuery, mounted) => {
    refetchQuery().then((res) => {
      updateStateOrRefetch(res?.data, refetchQuery, mounted);
    }).catch(() => {
      refetchOnErrror(refetchQuery, mounted);
    });
  };

  refetchOnErrror = (refetchQuery, mounted) => {
    retryCount.current += 1;
    if (retryCount.current < maxRetries) {
      setTimeout(() => refetchAndSetState(refetchQuery, mounted), retryDelay);
    }
  };

  const [getKlarnaSession, { refetch }] = useLazyQuery(KLARNA_SESSION_QUERY, {
    fetchPolicy: 'no-cache',
    context: { batch: true },
    ssr: false,
    onCompleted: (res) => {
      logger.debug('KLARNA_SESSION_QUERY RESULT', res);
      updateStateOrRefetch(res, refetch, true);
    },
    onError: (err) => {
      logger.error(`ERR: KLARNA_SESSION_QUERY: orderId: ${orderId} err: ${JSON.stringify(err)}`);
      refetchOnErrror(refetch, true);
    },
  });

  const isKlarnaSelected = checkoutPageState?.selectedPaymentType === 'klarna';
  const klarnaReady = checkoutPageState?.klarnaReady;
  const clientToken = checkoutPageState?.klarnaSession?.clientToken;
  const paymentOptions = checkoutPageState?.klarnaSession?.paymentOptions?.[0];
  const klarnaOrder = checkoutPageState?.klarnaState?.orderInfo;
  const sessionRequestBody = useMemo(
    () => (checkoutPageState?.klarnaState?.sessionRequestBody
    && removeTypeNameFromObject(checkoutPageState?.klarnaState?.sessionRequestBody)),
    [checkoutPageState?.klarnaState?.sessionRequestBody],
  );
  const shippingSpeed = useMemo(
    () => (checkoutPageState?.shippingSpeed || []),
    [checkoutPageState?.shippingSpeed],
  );

  useEffect(() => {
    if (!klarnaReady && !sessionRequestDone.current && sessionRequestBody) {
      getKlarnaSession({ variables: { sessionRequestBody } });
      sessionRequestDone.current = true;
    }
  }, [
    klarnaReady, sessionRequestBody, sessionRequestDone, getKlarnaSession,
  ]);

  useEffect(
    () => {
      if (!klarnaReady && klarnaScript.loaded && clientToken) {
        if ($window?.Klarna) {
          try {
            const klarnaPayments = $window.Klarna.Payments;
            klarnaPayments.init({ client_token: clientToken });
            updateCheckoutPageState({ klarnaReady: true });
          } catch (err) {
            logger.error(`ERR: klarna init failed: orderId: ${orderId} err: ${JSON.stringify(err?.message)}`);
          }
        } else {
          logger.debug(`ERR: window.Klarna is undefined: orderId: ${orderId}}`);
        }
      }
    },
    // eslint-disable-next-line
    [
      $window.Klarna,
      klarnaReady,
      klarnaScript.loaded,
      clientToken,
      updateCheckoutPageState,
      orderId,
      logger,
    ],
  );

  useEffect(() => {
    if (!isKlarnaSelected) {
      setKlarnaLoaded(false);
    }
  }, [isKlarnaSelected, paymentOptions, klarnaOrder]);

  useEffect(() => {
    if (isKlarnaSelected && klarnaReady && $window?.Klarna && !klarnaLoaded) {
      const klarnaPayments = $window.Klarna.Payments;
      try {
        klarnaPayments.load(paymentOptions, klarnaOrder, (response) => {
          if (!response?.error) {
            setKlarnaLoaded(true);
          } else {
            logger.error(`ERR: klarna load failed: orderId: ${orderId} response:  ${JSON.stringify(response)}`);
          }
        });
      } catch (err) {
        logger.error(`ERR: klarna load failed: orderId: ${orderId} err: ${JSON.stringify(err?.message)}`);
      }
    }
  }, [
    isKlarnaSelected,
    klarnaReady,
    paymentOptions,
    klarnaOrder,
    klarnaLoaded,
    orderId,
    logger,
  ]);

  // setting klarnaLoaded to false when payment method changes
  // due to possibility of having multiple klarna options
  useEffect(() => {
    const handlePaymentMethodChangeEvent = () => {
      setKlarnaLoaded(false);
    };

    window.addEventListener('paymentSection:paymentUpdated', handlePaymentMethodChangeEvent);

    return () => {
      window.removeEventListener('paymentSection:paymentUpdated', handlePaymentMethodChangeEvent);
    };
  }, []);

  const authorizeKlarnaPayment = useCallback((formData) => {
    const authorizationRequestBody = prepareAuthorizationRequest(
      formData,
      shippingSpeed,
      klarnaOrder,
    );
    if ($window?.Klarna && klarnaLoaded) {
      const klarnaPayments = $window.Klarna.Payments;
      klarnaPayments.authorize(paymentOptions, authorizationRequestBody, (response) => {
        let event;
        if (response?.approved && response?.authorization_token) {
          event = new Event('mfe:klarnaPayment:authorize:success');
          const formDataObj = { ...formData };
          formDataObj.payment.klarnaPayment.authorizationToken = response.authorization_token;
          event.detail = { formData: formDataObj };
        } else {
          event = new Event('mfe:klarnaPayment:authorize:fail');
          event.detail = { error: response };
        }
        setKlarnaLoaded(false);
        window.dispatchEvent(event);
      });
    }
  }, [paymentOptions, klarnaOrder, klarnaLoaded, shippingSpeed]);

  useEffect(() => {
    const handleKlarnaPaymentAuthorizeEvent = (event) => {
      const { formData } = event.detail;
      authorizeKlarnaPayment(formData);
    };

    window.addEventListener('crs:klarnaPayment:authorize', handleKlarnaPaymentAuthorizeEvent);

    return () => {
      window.removeEventListener('crs:klarnaPayment:authorize', handleKlarnaPaymentAuthorizeEvent);
    };
  }, [authorizeKlarnaPayment]);

  useEffect(() => {
    const handleOrderSubmitValidationFail = () => {
      setKlarnaLoaded(false);
    };

    window.addEventListener('cartModel:validationFailed', handleOrderSubmitValidationFail);
    return () => {
      window.removeEventListener('cartModel:validationFailed', handleOrderSubmitValidationFail);
    };
  }, []);

  return <div data-testid="mfe-anf-klarna-iframe" data-loaded={klarnaLoaded} data-ready={klarnaReady} />;
}
