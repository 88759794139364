import React, { useMemo, useContext } from 'react';
import OrderSubmitLegalTerms from './OrderSubmitLegalTerms';
import CheckoutPageContext from '../../../context/CheckoutPageContext';
import Loader from '../../Common/Loader/Loader';

export default function OrderSubmitLegalTermsWrapper() {
  const {
    checkoutPageState, loading,
  } = useContext(CheckoutPageContext);

  const shippingCountry = useMemo(() => {
    const selectedShippping = checkoutPageState?.shippingSpeed?.find((d) => d.isSelected);
    return selectedShippping ? selectedShippping?.country : 'US';
  }, [checkoutPageState.shippingSpeed]);

  if (loading || !checkoutPageState?.orderSubmitLegalTerms) {
    return (
      <div className="submit-legal-terms-wrapper" data-testid="submit-legal-terms-loader">
        <Loader classList="loader-submit-legal-terms" />
      </div>
    );
  }

  return (
    <div className="submit-legal-terms-wrapper order-summary-wrapper" data-testid="submit-legal-terms-wrapper">
      <OrderSubmitLegalTerms
        hasOnlyEGiftCard={checkoutPageState?.bagItems?.hasOnlyEGiftCard}
        orderSubmitLegalTermsData={checkoutPageState?.orderSubmitLegalTerms}
        shippingCountry={shippingCountry}
      />
    </div>
  );
}
