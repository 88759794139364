const DEFAULT_ICON = 'default-icon';
const DEFAULT_MAX_LENGTH = 19;
const DEFAULT_CARD_TYPES = [
  {
    type: 'visa', maxlength: 19, code: '001', regex: /^4\d{3}/,
  },
  {
    type: 'mastercard', maxlength: 19, code: '002', regex: /^5[1-5]\d{2}|^2\d{1}/,
  },
  {
    type: 'amex', maxlength: 17, code: '003', regex: /^3[47]\d{2}/,
  },
  {
    type: 'discover', maxlength: 19, code: '004', regex: /^6(011|2\d{2}|4[4-9]\d{1}|5\d{2})|^8\d{3}|^30[0-5]\d{1}|^3095|^3[689]\d{2}/,
  },
  {
    type: 'jcb', maxlength: 19, code: '007', regex: /^30[89]\d{1}|^3[01][09][^345]|^3[135]\d{2}/,
  },
];

const formatCardNumber = (cardNumber, cardTypesList) => {
  const formattedNumber = cardNumber.replace(/\D+/ig, ''); // Leave only digits
  const cardTypes = cardTypesList || DEFAULT_CARD_TYPES; // allow to pass configurable list

  // Return the empty string
  if (!formattedNumber.length) {
    return { formattedNumber, maxlength: DEFAULT_MAX_LENGTH };
  }

  const matchingCardType = cardTypes?.find((cardType) => {
    // Convert regular expression string to RegExp
    const cardTypeRegex = new RegExp(cardType?.regex);
    return formattedNumber?.match(cardTypeRegex);
  });

  if (!matchingCardType) {
    // If the card number doesn't match any of the regex, just format it with 4 digits per block
    return { formattedNumber: formattedNumber?.match(/.{1,4}/g).join(' ')?.substr(0, 19), maxlength: DEFAULT_MAX_LENGTH };
  }

  const { type, maxlength, code } = matchingCardType;

  let formattedNumberWithSpaces;
  switch (type) {
    case 'amex':
      if (formattedNumber.length > 4 && formattedNumber.length <= 10) {
        formattedNumberWithSpaces = formattedNumber.replace(/(\d{1,4})/, '$1 ');
      } else if (formattedNumber.length > 10 && formattedNumber.length < 15) {
        formattedNumberWithSpaces = formattedNumber.replace(/(\d{1,4})(\d{1,6})/, '$1 $2 ');
      } else {
        formattedNumberWithSpaces = formattedNumber.replace(/\b(\d{4})(\d{6})(\d{5})\b/, '$1 $2 $3');
      }
      break;
    default:
      formattedNumberWithSpaces = formattedNumber.match(/.{1,4}/g).join(' ');
  }

  return {
    formattedNumber: formattedNumberWithSpaces.substr(0, maxlength), type, maxlength, code,
  };
};

export {
  formatCardNumber, DEFAULT_ICON, DEFAULT_MAX_LENGTH, DEFAULT_CARD_TYPES,
};
