import {
  gql,
} from '@apollo/client';

export const MINIBAG_QUERY = gql`
query FetchMiniBagEssentials {
  viewBagButton {
    bagUrl
  }
  miniBagIcon {
    localStorageKey
  }
}
`;

export const MINIBAG_LOCAL_STORAGE_QUERY = gql`
query FetchMiniBagItemCount {
  bagItems {
    items {
      item {
        productContent {
          orderItemId
        }
      }
    }
  }
  textFor {
    shoppingBag: pair (pairKey: "shoppingBag") {...values}
    itemsInBagTMNT: pair (pairKey: "itemsInBagTMNT") {...values}
  }
}
fragment values on TextPair {
  key
  value
}
`;
