import React from 'react';
import { PaymentBlock } from 'anf-core-react';
import PropTypes from 'prop-types';

export default function PaymentInfo({
  iconCode, paymentLabel, payPalPaymentEmail,
}) {
  if (iconCode === 'gift-card') return null;
  return (
    <div className="non-card-payments" data-testid="non-card-payments">
      <PaymentBlock paymentIcon={(
        <span className="payment-icon" role="img" data-icon-name={iconCode} aria-label={paymentLabel} />
  )}
      >
        <span>{paymentLabel}</span>
        {iconCode === 'paypal' && <p className="payment-email">{payPalPaymentEmail}</p>}

      </PaymentBlock>
    </div>
  );
}

PaymentInfo.defaultProps = {
  iconCode: '',
  paymentLabel: '',
  payPalPaymentEmail: '',
};

PaymentInfo.propTypes = {
  iconCode: PropTypes.string,
  paymentLabel: PropTypes.string,
  payPalPaymentEmail: PropTypes.string,
};
