import React from 'react';
import PropTypes from 'prop-types';
import LoggedOutView from './loggedOutView/LoggedOutView';
import LoggedInView from './loggedInView/LoggedInView';

export default function UserState({
  userData, tmntData, loyaltyEstimatedPoints, isEmptyBag,
}) {
  return (
    <div className="loyalty-section__wrapper">
      <div data-module-loading="false">
        {userData?.hasBagSignIn && (
          <div className="loyalty-member-welcome">
            {userData?.isLoggedIn ? (

              <LoggedInView
                iconImageUrl={userData.iconImageUrl}
                loggedInData={userData.loggedInData}
                tmntData={tmntData}
                loyaltyInfo={userData.loyaltyInfo}
              />
            ) : (
              <LoggedOutView
                iconImageUrl={userData.iconImageUrl}
                hasSignIn={userData.hasSignIn}
                tmntData={tmntData}
                estimatedPoints={loyaltyEstimatedPoints}
                displayIconImage={userData.displayIconImage}
                isEmptyBag={isEmptyBag}
              />
            )}
          </div>
        )}
        {userData?.statusCode === '408' && (
          <div className="loyalty-member-error">
            {userData?.statusMessages?.map((err) => (
              err?.message && <p key={err}>{err.message}</p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

UserState.defaultProps = {
  tmntData: {},
  loyaltyEstimatedPoints: '',
  isEmptyBag: false,
  userData: {
    statusCode: '',
    statusMessages: [],
    hasBagSignIn: false,
    isLoggedIn: false,
    isRemembered: false,
    loggedInData: {
      userName: '',
      registrationDate: '',
    },
    loyaltyInfo: {
      loyaltyTierInfo: {
        loyaltyTier: '',
        actionNeededForNextTier: '',
        formattedActionNeededForNextTier: '',
        spendByDateForNextTier: '',
        formattedLoyaltyTierExpirationDate: '',
      },
    },
    iconImageUrl: '',
    displayIconImage: false,
  },

};

UserState.propTypes = {
  tmntData: PropTypes.instanceOf(Object),
  loyaltyEstimatedPoints: PropTypes.string,
  isEmptyBag: PropTypes.bool,
  userData: PropTypes.shape({
    statusCode: PropTypes.string,
    statusMessages: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
    hasBagSignIn: PropTypes.bool,
    isLoggedIn: PropTypes.bool,
    isRemembered: PropTypes.bool,
    brand: PropTypes.string,
    hasSignIn: PropTypes.bool,
    loggedInData: PropTypes.shape({
      userName: PropTypes.string,
      registrationDate: PropTypes.string,
    }),
    loyaltyInfo: PropTypes.shape({
      loyaltyTierInfo: PropTypes.shape({
        loyaltyTier: PropTypes.string,
        actionNeededForNextTier: PropTypes.string,
        formattedActionNeededForNextTier: PropTypes.string,
        spendByDateForNextTier: PropTypes.string,
        formattedLoyaltyTierExpirationDate: PropTypes.string,
      }),
    }),
    iconImageUrl: PropTypes.string,
    displayIconImage: PropTypes.bool,
  }),

};
