import React from 'react';
import PropTypes from 'prop-types';
import ShippingSpeedEstimatedDeliveryDate from './ShippingSpeedEstimatedDeliveryDate';
import ShippingSpeedOption from './ShippingSpeedOption';
import shippingSpeedShape from './shippingSpeedShape';

export default function HomeDeliveryShippingSpeedOption({
  shippingSpeed, onChangeShippingSpeed, textFor, ...props
}) {
  const {
    inStockItemsOnlyTMNTValue,
    estDeliveryTMNTValue,
  } = textFor;

  return (
    <ShippingSpeedOption
      shippingSpeed={shippingSpeed}
      onChangeShippingSpeed={onChangeShippingSpeed}
      {...props}
    >
      <ShippingSpeedEstimatedDeliveryDate
        estimatedDeliveryDateDisplay={`${estDeliveryTMNTValue.value}: ${shippingSpeed.estimatedDeliveryDateDisplay} ${inStockItemsOnlyTMNTValue.value}`}
      />
    </ShippingSpeedOption>
  );
}

HomeDeliveryShippingSpeedOption.defaultProps = {
  shippingSpeed: undefined,
  onChangeShippingSpeed: undefined,
  textFor: {
    inStockItemsOnlyTMNTValue: {
      key: 'CHK_ESTIMATED_DELIVERY_INSTOCK',
      value: 'in-stock items only',
    },
    estDeliveryTMNTValue: {
      key: 'CHK_EST_DELIVERY',
      value: 'Est. Delivery',
    },
  },
};

HomeDeliveryShippingSpeedOption.propTypes = {
  shippingSpeed: shippingSpeedShape,
  onChangeShippingSpeed: PropTypes.func,
  textFor: PropTypes.shape({
    inStockItemsOnlyTMNTValue: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    estDeliveryTMNTValue: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
};
