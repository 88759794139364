import React from 'react';
import PropTypes from 'prop-types';

export default function ShippingSpeedEstimatedDeliveryDate({ estimatedDeliveryDateDisplay }) {
  return (
    <div className="shipping-option-edd">
      {estimatedDeliveryDateDisplay}
    </div>
  );
}

ShippingSpeedEstimatedDeliveryDate.defaultProps = {
  estimatedDeliveryDateDisplay: undefined,
};

ShippingSpeedEstimatedDeliveryDate.propTypes = {
  estimatedDeliveryDateDisplay: PropTypes.string,
};
