import React, {
  useCallback,
  useContext,
  useRef,
  useState,
  useEffect,
} from 'react';
import {
  useLazyQuery, useMutation,
} from '@apollo/client';
import GiftCardForm from './GiftCardForm';
import GiftCardsApplied from './GiftCardsApplied';
import useLog from '../../../useLog/useLog';
import {
  GIFT_CARD_CHECK_BALANCE,
  GIFT_CARD_APPLY,
  GIFT_CARD_REMOVE,
} from '../../../../gql/giftCard.gql';
import CheckoutPageContext from '../../../../context/CheckoutPageContext';
import trackAction from '../../../../tools/analytics';

const GC_CURRENCY_REPUDIATION_TYPES = [
  'GLB_REPUDIATION_CURRENCY_GIFTCARD_RESTRICT',
  'MESSAGE_CURRENCY_GIFTCARD_REPUDIATE',
];

export default function GiftCard() {
  const [giftCardBalance, setGiftCardBalance] = useState('');
  const [error, setError] = useState('');
  const logger = useLog('checkout')('giftcard');
  const {
    checkoutPageState,
    setCheckoutPageState,
  } = useContext(CheckoutPageContext);
  const giftCardFormRef = useRef(null);
  const country = checkoutPageState?.shippingAddress?.country;
  const giftCardsApplied = checkoutPageState?.giftCards?.appliedGiftCards;
  const showGiftCardsApplied = !!giftCardsApplied?.length;
  const isMaxNumOfGiftCardApplied = (
    giftCardsApplied?.length === checkoutPageState?.config?.numberOfGiftCardsAllowed
  );
  const isGCAllowed = checkoutPageState?.giftCards?.isAllowedGiftCards;

  const isGcCurrencyRepudiation = useCallback((statusMessages) => {
    const errorKey = statusMessages[0]?.key || '';
    return GC_CURRENCY_REPUDIATION_TYPES.includes(errorKey);
  }, []);

  const handleGcCurrencyRepudiation = useCallback((statusMessages) => {
    const errorKey = statusMessages[0]?.key || '';
    const errorMessage = statusMessages[0]?.message;
    const params = statusMessages[0]?.params;
    const errorDetails = {
      errorKey,
      errorCode: errorKey,
      errorMessage,
      params,
    };
    const giftCardErrorEvent = new CustomEvent('mfe:giftCard:repudiation', { detail: errorDetails });
    window.dispatchEvent(giftCardErrorEvent);
  }, []);

  const [checkGCBalance] = useLazyQuery(GIFT_CARD_CHECK_BALANCE, {
    fetchPolicy: 'no-cache',
    context: { batch: true },
    ssr: false,
    onCompleted: (resp) => {
      const { success, statusMessages, amountFmt } = resp?.giftCardBalance;
      if (success) {
        setGiftCardBalance(amountFmt || '');
      } else if (isGcCurrencyRepudiation(statusMessages)) {
        handleGcCurrencyRepudiation(statusMessages);
      } else {
        setError(statusMessages[0]?.message || '');
      }
      giftCardFormRef.current.clearProcessing();
    },
    onError: (err) => {
      logger.error(`ERR: GIFT_CARD_CHECK_BALANCE: ${JSON.stringify(err)}`);
      giftCardFormRef.current.clearProcessing();
      setError(checkoutPageState?.textFor?.mfeErrorMessage?.value);
    },
  });

  const [applyGCBalance] = useMutation(GIFT_CARD_APPLY, {
    fetchPolicy: 'no-cache',
    context: { batch: true },
    ssr: false,
    onCompleted: (resp) => {
      const {
        cartInfo,
        orderTotals,
        charity,
        klarnaState,
        giftCards,
        paymentOptions,
        success, statusMessages,
      } = resp?.applyGiftCard;
      if (success) {
        setCheckoutPageState((previousState) => {
          const newState = {
            ...previousState,
            orderTotals,
            charity,
            klarnaState,
            giftCards,
            paymentOptions,
          };
          return newState;
        });
        giftCardFormRef.current.clearProcessing(true);
        const giftCardApplyDoneEvent = new CustomEvent(
          'mfe:giftCard:apply:done',
          { detail: cartInfo },
        );
        window.dispatchEvent(giftCardApplyDoneEvent);
        trackAction('universal_click', {
          event_name: 'click',
          data_text: 'submit',
          data_action: 'gift card applied successful',
        });
      } else if (isGcCurrencyRepudiation(statusMessages)) {
        giftCardFormRef.current.clearProcessing();
        handleGcCurrencyRepudiation(statusMessages);
      } else {
        setError(statusMessages[0]?.message || '');
        giftCardFormRef.current.clearProcessing();
        const errorCode = statusMessages[0]?.code;
        if (errorCode === '404') {
          trackAction('universal_click', {
            event_name: 'click',
            data_text: 'invalid submit',
            data_action: 'invalid gift card number',
          });
        } else if (errorCode === '204') {
          trackAction('universal_click', {
            event_name: 'click',
            data_text: 'invalid submit',
            data_action: 'insufficient funds',
          });
        }
      }
    },
    onError: (err) => {
      logger.error(`ERR: GIFT_CARD_APPLY: ${JSON.stringify(err)}`);
      giftCardFormRef.current.clearProcessing();
      setError(checkoutPageState?.textFor?.mfeErrorMessage?.value);
    },
  });

  const [removeGCBalance, { loading: removeGCLoading }] = useMutation(GIFT_CARD_REMOVE, {
    fetchPolicy: 'no-cache',
    context: { batch: true },
    ssr: false,
    onCompleted: (resp) => {
      const {
        cartInfo,
        orderTotals,
        charity,
        klarnaState,
        giftCards,
        paymentOptions,
        success,
      } = resp?.removeGiftCard;
      if (success) {
        setCheckoutPageState((previousState) => {
          const newState = {
            ...previousState,
            orderTotals,
            charity,
            klarnaState,
            giftCards,
            paymentOptions,
          };
          return newState;
        });
        const giftCardApplyDoneEvent = new CustomEvent(
          'mfe:giftCard:remove:done',
          { detail: cartInfo },
        );
        window.dispatchEvent(giftCardApplyDoneEvent);
      }
    },
    onError: (err) => {
      logger.error(`ERR: GIFT_CARD_REMOVE: ${JSON.stringify(err)}`);
    },
  });

  const applyGiftCard = (cardNumber, pin, captchaToken = null) => {
    setGiftCardBalance('');
    setError('');
    applyGCBalance({
      variables: {
        cardNumber,
        pin,
        country,
        captchaToken,
      },
    });
  };

  const removeGiftCard = useCallback((giftCardId) => {
    removeGCBalance({
      variables: {
        giftCardId: encodeURI(giftCardId),
      },
    });
  }, [removeGCBalance]);

  const checkGiftCardBalance = (cardNumber, pin, captchaToken = null) => {
    setGiftCardBalance('');
    setError('');
    checkGCBalance({
      variables: {
        cardNumber,
        pin,
        country,
        captchaToken,
      },
    });
  };

  useEffect(() => {
    function removeGiftCards() {
      const giftCardIds = giftCardsApplied?.map((giftCard) => giftCard?.id);
      if (giftCardIds && giftCardIds.length > 0) {
        const giftCardIdsStr = giftCardIds.join(',');
        removeGiftCard(giftCardIdsStr);
      }
    }
    window.addEventListener('crs:giftCard:remove', removeGiftCards);

    return () => {
      window.removeEventListener('crs:giftCard:remove', removeGiftCards);
    };
  }, [giftCardsApplied, removeGiftCard]);

  return (
    <div className="gift-card-section">
      {showGiftCardsApplied && (
        <GiftCardsApplied
          giftCardsApplied={giftCardsApplied}
          isMaxNumOfGiftCardApplied={isMaxNumOfGiftCardApplied}
          removeGiftCard={removeGiftCard}
          isRemoving={removeGCLoading}
          tmntData={checkoutPageState?.textFor}
        />
      )}
      {isGCAllowed && !isMaxNumOfGiftCardApplied && (
        <GiftCardForm
          isMaxNumOfGiftCardApplied={isMaxNumOfGiftCardApplied}
          applyGiftCard={applyGiftCard}
          checkGiftCardBalance={checkGiftCardBalance}
          error={error}
          tmntData={checkoutPageState?.textFor}
          isCaptchaEnabled={checkoutPageState?.switches?.hasGiftCardCheckoutRecaptcha}
          gRecaptchaSiteKey={checkoutPageState?.switches?.invisibleRecaptchaSiteKey}
          hasGlobalReCaptcha={checkoutPageState?.switches?.hasGlobalReCaptcha}
          ref={giftCardFormRef}
          numberOfGiftCardsApplied={giftCardsApplied?.length || 0}
          giftCardBalance={giftCardBalance}
        />
      )}
    </div>
  );
}
