import React, { useState, useContext, useCallback } from 'react';
import {
  Accordion, Button,
} from 'anf-core-react';
import {
  useMutation,
} from '@apollo/client';
import useLog from '../../useLog/useLog';
import GiftReceipt from '../GiftReceipt/GiftReceipt';
import GiftBoxModal from '../GiftBoxModal/GiftBoxModal';
import BagContext from '../../../context/BagContext';
import trackAction from '../../../tools/analytics';
import decodeHTMLEntities from '../../../tools/decodeHtml';
import { GIFT_RECEIPT_DELETE_MUTATION } from '../../../gql/giftReceipt.gql';
import $window from '../../../tools/window';

export default function GiftBoxAndReceipt() {
  const [openModal, setOpenModal] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const {
    bag, setBag,
  } = useContext(BagContext);
  const logger = useLog('shoppingBag.giftreceipt');
  const tmntData = bag?.textFor;
  const priceFormatted = bag?.giftBox?.priceFormatted ? decodeHTMLEntities(bag?.giftBox?.priceFormatted) : '';
  const addGiftBoxTMNTValue = tmntData?.addGiftBoxTMNTValue?.value;
  const addGiftBoxesTMNTValue = tmntData?.addGiftBoxesTMNTValue?.value;
  const giftReceipText = tmntData?.giftReceipText?.value;
  const addOrEditGiftBoxText = tmntData?.addOrEditGiftBoxText?.value;
  const hasAddedGiftBox = bag?.bagItems?.items
      && bag.bagItems.items.some((bagItem) => bagItem?.giftBox?.isWrapped);

  const handleCloseModal = useCallback(() => {
    setOpenModal(false);
  }, []);

  const handleOpenModal = useCallback(() => {
    setOpenModal(true);
    trackAction('checkout.bag.gwpModalOpen', { name: 'add gift box clicked' });
  }, []);

  const [deleteGiftReceiptMutation] = useMutation(GIFT_RECEIPT_DELETE_MUTATION, {
    onCompleted: (deleteGiftReceiptObj) => {
      logger.debug('GIFT_RECEIPT_DELETE_MUTATION RESULT', deleteGiftReceiptObj.deleteGiftReceipt);
      if (deleteGiftReceiptObj?.deleteGiftReceipt?.success) {
        setBag((previousState) => {
          const newState = ({
            ...previousState,
            orderTotals: deleteGiftReceiptObj?.deleteGiftReceipt?.orderTotals,
          });

          // merge the bag response to digitalData `cart`
          $window.digitalData?.merge('cart', {
            origin: 'bag',
            data: newState,
            action: 'gift_receipt_delete',
          });

          return newState;
        });
      } else {
        // handle Error
        setBag((previousState) => ({
          ...previousState,
          statusCode: deleteGiftReceiptObj?.deleteGiftReceipt?.statusCode,
          success: deleteGiftReceiptObj?.deleteGiftReceipt?.success,
          statusMessages: deleteGiftReceiptObj?.deleteGiftReceipt?.statusMessages,
        }));
      }
    },
    onError: (err) => {
      logger.error('ERR: GIFT_RECEIPT_DELETE_MUTATION', err);
    },
  });

  return (
    <div className="shopping-bag-gift-box" data-testid="gift-box">
      <Accordion
        headingLevel={2}
        title={bag?.switches?.hasGiftReceipt && !bag?.switches?.hasGiftBox
          ? giftReceipText : addGiftBoxesTMNTValue}
        id="giftbox"
        variant="boxed"
        onClick={() => { setExpanded(!expanded); }}
        isExpanded={expanded}
      >
        {bag?.bagItems?.items ? (
          <div className="gift-box-line-items">
            {bag?.bagItems?.items?.map((bagItem) => (
              <div key={bagItem?.item?.productContent?.orderItemId}>
                {
        bagItem?.giftBox?.isWrapped
          && (
          <div className="gift-box-line-item">
            <span>{bagItem?.item?.productContent?.name}</span>
            <span>{priceFormatted}</span>
          </div>
          )
      }
              </div>
            ))}
          </div>
        ) : ''}

        <form data-testid="giftbox">
          {bag?.switches?.hasGiftBox
          && (
          <Button
            brand="anf"
            fullWidth
            variant="tertiary-light"
            classList="gift-box-button"
            onClick={handleOpenModal}
          >
            {hasAddedGiftBox ? addOrEditGiftBoxText : addGiftBoxTMNTValue}
          </Button>
          )}
          {bag?.switches?.hasGiftReceipt
          && (<GiftReceipt deleteGiftReceiptMutation={deleteGiftReceiptMutation} />)}
        </form>
      </Accordion>
      <GiftBoxModal
        openModal={openModal}
        closeModal={handleCloseModal}
      />
    </div>
  );
}
