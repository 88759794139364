import React, { useState } from 'react';
import { Modal, Button } from 'anf-core-react';
import { gql, useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import Loader from '../Loader/Loader';
import Tmnt from '../../Tmnt/Tmnt';

const TMNT_QUERY = gql`
  query($tmntPairKey: String!) {
    textFor {
      tmnt: pair (pairKey: $tmntPairKey) {
        key
        value
      }
    }
  }
`;

export default function ModalButtonWithTMNTQuery({
  tmntPairKey,
  modalHeadingTmnt,
  buttonLabelTmnt,
}) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [getLegalTerms, { loading, data }] = useLazyQuery(TMNT_QUERY, {
    variables: {
      tmntPairKey,
    },
    context: { batch: true },
    ssr: false,
  });

  const openModal = () => {
    setIsOpenModal(true);
    getLegalTerms();
  };

  return (
    <>
      <Button
        brand="anf"
        variant="borderless"
        onClick={openModal}
        classList="show-tmnt-modal-btn"
      >
        <Tmnt tmnt={buttonLabelTmnt} />
      </Button>
      <Modal
        id="tmnt-modal"
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        heading={(
          <h2>
            <Tmnt tmnt={modalHeadingTmnt} />
          </h2>
        )}
        closeButtonLabel="Close"
      >
        <section
          className="tmnt-modal-content scope-1892"
          data-testid="tmnt-modal-content"
        >
          {loading && <Loader />}
          {!loading && data?.textFor?.tmnt
            && <Tmnt tmnt={data?.textFor?.tmnt} isHtml />}
        </section>
      </Modal>
    </>
  );
}

ModalButtonWithTMNTQuery.defaultProps = {
  tmntPairKey: '',
  modalHeadingTmnt: {},
  buttonLabelTmnt: {},
};

ModalButtonWithTMNTQuery.propTypes = {
  tmntPairKey: PropTypes.string,
  modalHeadingTmnt: PropTypes.instanceOf(Object),
  buttonLabelTmnt: PropTypes.instanceOf(Object),
};
