import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from 'anf-core-react/components/ErrorMessage';

export default function TileInputGroup({
  legendId,
  legend,
  tileSize,
  tileLayout,
  isInvalid,
  id,
  classes,
  errorMessageText,
  children,
}) {
  return (
    <fieldset className="tile-group">
      <legend id={legendId}>
        {legend}
      </legend>

      <ul id={id} className={`tiles ${classes}`} data-tile-size={tileSize} data-tile-layout={tileLayout}>
        {children}
      </ul>
      {isInvalid && (
        <ErrorMessage id={`${id}-error-message`}>
          {errorMessageText}
        </ErrorMessage>
      )}
    </fieldset>
  );
}

TileInputGroup.defaultProps = {
  legendId: undefined,
  legend: undefined,
  tileLayout: 'spaced',
  tileSize: 'medium',
  isInvalid: false,
  classes: '',
};

TileInputGroup.propTypes = {
  legendId: PropTypes.string,
  legend: PropTypes.string,
  tileSize: PropTypes.string,
  tileLayout: PropTypes.string,
  children: PropTypes.node.isRequired,
  isInvalid: PropTypes.bool,
  id: PropTypes.string.isRequired,
  errorMessageText: PropTypes.string.isRequired,
  classes: PropTypes.string,
};
