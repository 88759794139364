import assert from 'assert';
import StorageAdapter from './StorageAdapter';
import $window from '../../../../../tools/window';

/**
 * ported in part from: crs-app/StaticWeb/WebContent/core/js/services/storage.js
 */

export default class Store {
  #localStorage = null;

  set localStorage(storage) {
    // NOTE: it is 3x faster to check type than instanceof.
    // If we can short circuit faster, the better.
    assert(
      typeof storage === 'object'
      && storage !== null
      && storage instanceof StorageAdapter,
      'must be a StorageAdapter',
    );
    this.#localStorage = storage;
  }

  get localStorage() {
    if (!this.#localStorage) {
      this.localStorage = new StorageAdapter($window?.localStorage);
    }
    return this.#localStorage;
  }
}
