import { useCallback } from 'react';
import useLog from '../../useLog/useLog';

export default function useGeolocator() {
  const logger = useLog('geolocator');

  const findMe = useCallback(async () => {
    logger.debug('findMe');

    return new Promise((resolve, reject) => {
      const handleResponse = ({ detail }) => {
        logger('findMe').log('handleResponse', detail).destroy();
        if (detail?.error) {
          reject(detail?.error);
        } else {
          resolve(detail?.geolocation);
        }
      };

      window.addEventListener('geolocator:findMeResponse', handleResponse, { once: true });

      logger.debug('dispatching geolocator:findMe');
      window.dispatchEvent(new CustomEvent('geolocator:findMe'));
    });
  }, [logger]);

  const getCurrentGeolocation = useCallback(async () => {
    logger.debug('getCurrentGeolocation');

    return new Promise((resolve) => {
      const handleResponse = ({ detail }) => {
        // NOTE: no error is propagated from phoenix
        resolve(detail?.geolocation);
      };

      window.addEventListener(
        'geolocator:currentGeolocationResponse',
        handleResponse,
        { once: true },
      );

      logger.debug('dispatching geolocator:currentGeolocation');
      window.dispatchEvent(new CustomEvent('geolocator:currentGeolocation'));
    });
  }, [logger]);

  const getAddressGeolocation = useCallback(async (postalCode) => {
    logger.debug('getAddressGeolocation', postalCode);

    return new Promise((resolve, reject) => {
      const handleResponse = (res) => {
        if (res.detail.error) {
          reject(res.detail.error);
          return;
        }
        const upperCasedPostalCode = postalCode.toUpperCase();
        if (
          res.detail.address?.postalCode
          && ((postalCode === res.detail.address?.postalCode)
          || (!!upperCasedPostalCode.match(res.detail.address?.postalCode) && res.detail.address?.countryAbbr === 'GB'))
        ) {
          resolve({
            ...res.detail.address,
            postalCode: upperCasedPostalCode,
          });
        } else {
          reject(new Error('zipcode-error'));
        }
      };

      window.addEventListener('geolocator:getAddressGeolocationResponse', handleResponse, { once: true });
      window.dispatchEvent(new CustomEvent('geolocator:getAddressGeolocation', {
        detail: {
          postalCode,
        },
      }));
    });
  }, [logger]);

  const geolocateValue = useCallback(async (postalCode) => {
    logger.debug('geolocateVlaue', postalCode);

    return new Promise((resolve, reject) => {
      const handleResponse = (res) => {
        if (res.detail.error) {
          reject(res.detail.error);
          return;
        }
        resolve(res.detail.address);
      };

      window.addEventListener('geolocator:getAddressGeolocationResponse', handleResponse, { once: true });
      window.dispatchEvent(new CustomEvent('geolocator:getAddressGeolocation', {
        detail: {
          postalCode,
        },
      }));
    });
  }, [logger]);

  return {
    findMe, getCurrentGeolocation, getAddressGeolocation, geolocateValue,
  };
}
