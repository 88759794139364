/*

Display OiS CTAs:
Send to Register/ Send to Customer / Start New Session
As a store associate who is looking up online inventory
for customers, I want to be able to choose to send their
order to the register, their email, or start a new session,
so that the customer can have a more personalized way of
shopping in store.

*/

import React, { useState, useContext } from 'react';
import { Button } from 'anf-core-react';
import PropTypes from 'prop-types';
import {
  useMutation,
} from '@apollo/client';
import OrderInStoreModal from './OrderInStoreModal';
import { OIS_START_NEW_SESSION_MUTATION } from '../../../gql/orderInStore.gql';
import useLog from '../../useLog/useLog';
import SwitchTestContext from '../../../context/SwitchTestContext';

export default function OrderInStoreCTAs({
  oisData,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [modalType, setModalType] = useState('sendToRegister');
  const logger = useLog('shoppingBag.orderInStoreCTAs');
  const {
    digitalData,
  } = useContext(SwitchTestContext);

  const isStartNewSessionWithFetchEnabled = digitalData ? digitalData['chk-ois-start-session-with-fetch'] : false;

  const startNewSessionWithFetch = () => {
    // temporary solution until we resolve cookie issues with OiS
    fetch(oisData.startNewSessionURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'data-type': 'json',
      },
    }).then(() => {
      // redirect the page after starting a new session
      window.location.href = oisData?.redirectOiSUrl;
    });
  };

  const [startNewSession] = useMutation(OIS_START_NEW_SESSION_MUTATION, {
    onCompleted: (response) => {
      logger.debug('THE OIS_START_NEW_SESSION_MUTATION RESULT', response);
      // redirect the page after starting a new session
      window.location.href = oisData?.redirectOiSUrl;
    },
    onError: (err) => {
      // log ther error message
      logger.error(`ERR: OIS_START_NEW_SESSION_MUTATION: ${JSON.stringify(err)}`);
    },
  });

  const restartOiSSession = () => (
    isStartNewSessionWithFetchEnabled ? startNewSessionWithFetch() : startNewSession()
  );

  return (
    <div className="order-in-store-ctas" data-testid="ois-ctas">
      <Button
        isFullWidth
        type="submit"
        variant="secondary"
        classList="send-to-register-button"
        onClick={() => {
          setOpenModal(true);
          setModalType('sendToRegister');
        }}
      >
        {oisData?.sendToRegisterLabel?.value}
      </Button>
      <Button
        isFullWidth
        type="submit"
        variant="secondary"
        classList="send-to-customer-button"
        onClick={() => {
          setOpenModal(true);
          setModalType('sendToCustomer');
        }}
      >
        {oisData?.sendToCustomerLabel?.value}
      </Button>
      <span className="horizontal-or-separator" data-property={oisData?.orLabel?.key}>{oisData?.orLabel?.value}</span>
      <Button
        isFullWidth
        type="submit"
        variant="tertiary-light"
        classList="start-new-session-button"
        onClick={() => restartOiSSession()}
      >
        {oisData?.startNewSessionLabel?.value}
      </Button>
      <OrderInStoreModal
        key={modalType}
        openModal={openModal}
        modalType={modalType}
        closeModal={() => setOpenModal(false)}
        oisData={oisData}
        startNewSession={restartOiSSession}
      />
    </div>
  );
}

OrderInStoreCTAs.defaultProps = {
  oisData: {
    sendToRegisterLabel: {
      key: 'CHK_SNDCUSTOMERINSTD',
      value: 'Send to Register',
    },
    sendToCustomerLabel: {
      key: 'CHK_SNDREGISTERINSTD',
      value: 'Send to Customer',
    },
    startNewSessionLabel: {
      key: 'CHK_RESTARTSESSION',
      value: 'Start New Session',
    },
    orLabel: {
      key: 'GLB_OR',
      value: 'Or',
    },
  },
};

OrderInStoreCTAs.propTypes = {
  oisData: PropTypes.instanceOf(Object),
};
