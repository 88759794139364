export default async function handleShippingMethodChange(
  shippingMethod,
  updateShippingSpeedMutation,
) {
  try {
    const { data } = await updateShippingSpeedMutation({
      variables: {
        shipModeId: shippingMethod?.shipModeId,
        shippingAddress: shippingMethod?.shippingAddress,
      },
    });

    // if mutation is succesfull return the response
    if (data?.updateShippingSpeed?.responseInfo?.success) {
      return data.updateShippingSpeed;
    }

    // if mutation is not succesfull return the response
    return data;
  } catch (error) {
    // if there is an error return the error
    return {
      error,
    };
  }
}
