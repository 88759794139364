export default class Transport {
  enabled = false;

  name = 'transport';

  constructor(name) {
    if (name) {
      this.name = name;
    }
  }

  debug() {
    return this;
  }

  info() {
    return this;
  }

  log() {
    return this;
  }

  warn() {
    return this;
  }

  error() {
    return this;
  }

  setEnabled(enabled) {
    this.enabled = enabled;
    return this;
  }
}
