import React from 'react';
import { PaymentBlock, PaymentIcon } from 'anf-core-react';
import PropTypes from 'prop-types';

export default function CartPaymentDetails({ cartPayment }) {
  const paymentDetails = cartPayment?.paypalPayment;

  return (
    <div className="cart-payment-details">
      <PaymentBlock
        paymentIcon={(
          <PaymentIcon
            iconName={paymentDetails?.paymentCode}
            labelText={paymentDetails?.paymentType}
          />
        )}
      >
        <p className="payment-name" data-testid="payment-name">
          <span>
            {paymentDetails?.paymentName}
          </span>
        </p>
        <p className="payment-email" data-testid="payment-email">
          <span>
            {paymentDetails?.paymentEmail}
          </span>
        </p>
      </PaymentBlock>
    </div>
  );
}

CartPaymentDetails.propTypes = {
  cartPayment: PropTypes.shape({
    paypalPayment: PropTypes.shape({
      paymentCode: PropTypes.string,
      paymentType: PropTypes.string,
      paymentName: PropTypes.string,
      paymentEmail: PropTypes.string,
    }),
  }).isRequired,
};
