import { useMemo } from 'react';

export default function usePromise() {
  const [resolve, reject, promise] = useMemo(() => {
    let $resolve;
    let $reject;
    const $promise = new Promise((_resolve, _reject) => {
      $resolve = _resolve;
      $reject = _reject;
    });
    return [$resolve, $reject, $promise];
  }, []);

  return { resolve, reject, promise };
}
