import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import CheckoutRightRail from './CheckoutRightRail';

export default function CheckoutRightRailPortal({ id }) {
  if (typeof document !== 'undefined' && !!document.getElementById(id)) {
    return ReactDOM.createPortal(
      <CheckoutRightRail />,
      document.getElementById(id),
    );
  }
  return null;
}

CheckoutRightRailPortal.defaultProps = {
  id: '',
};

CheckoutRightRailPortal.propTypes = {
  id: PropTypes.string,
};
