import assert from 'assert';
import Manager from '../Manager/Manager';

/**
 * The controls that can be accessed from the console
 * @public
 * @module clientSideLogger.controls
 */

export default class Controls {
  /**
   * @type {Manager}
   */

  #manager = new Manager();

  /**
   * @param {Manager} manager the log manager
   */

  set manager(manager) {
    // NOTE: it is 3x faster to check type than instanceof.
    // If we can short circuit faster, the better.
    assert(
      typeof manager === 'object'
      && manager !== null
      && manager instanceof Manager,
      'must be a Manager',
    );
    this.#manager = manager;
  }

  /**
   * initiate the Controls with an optional Manager otherwise it will create its own manager
   * @param {Manager} [manager] the manager to control
   */
  constructor(manager) {
    if (manager) {
      this.manager = manager;
    }
  }

  /**
   * Get the log setting
   * @returns {String} the current log setting
   */

  getLogSetting() {
    return this.#manager.getLogSetting();
  }

  /**
   * Clear the log setting
   * @returns {Controls} this
   */

  clearLogSetting() {
    this.#manager.clearLogSetting();
    return this;
  }

  /**
   * Enable logger(s)
   * @param {string} [value] the name or fragment of a name for logger(s)
   * @returns {Controls} this
   */

  enableLogger(value) {
    this.#manager.enableLogger(value);
    return this;
  }

  /**
   * Enable all loggers
   * @returns {Controls} this
   */

  enableAllLoggers() {
    this.#manager.enableAllLoggers();
    return this;
  }

  /**
   * Disable logger(s)
   * @param {string} [value] the name or fragment of a name for logger(s)
   * @returns {Controls} this
   */

  disableLogger(value) {
    this.#manager.disableLogger(value);
    return this;
  }

  /**
   * Disable all loggers
   * @returns {Controls} this
   */

  disableAllLoggers() {
    this.#manager.disableAllLoggers();
    return this;
  }

  /**
   * Get all the loggers
   * @returns {Function[]} the loggers
   */

  getLoggers() {
    return this.#manager.getLoggers();
  }

  /**
   * Check if we have a logger
   * @param {String} name - The name of the logger
   * @returns {Boolean} if we have the logger or not
   */

  hasLogger(name) {
    return this.#manager.hasLogger(name);
  }

  /**
   * Get a logger
   * @param {String} name - The name of the logger
   * @returns {Function} the logger
   */

  getLogger(name) {
    return this.#manager.getLogger(name);
  }

  /**
   * Check if the logger is enabled
   * @param {String} name - The name of the logger
   * @returns {Boolean} if the logger is enabled or not
   */

  isLoggerEnabled(name) {
    return this.#manager.isLoggerEnabled(name);
  }

  /**
   * enable a transport by name
   * @param {String} transportName - the transport name
   * @returns {Controls} this
   */

  enableTransport(transportName) {
    this.#manager.transporter.enableTransport(transportName);
    return this;
  }

  /**
   * disable a transport by name
   * @param {String} transportName - the transport name
   * @returns {Controls} this
   */

  disableTransport(transportName) {
    this.#manager.transporter.disableTransport(transportName);
    return this;
  }

  /**
   * enable all transports
   * @returns {Controls} this
   */

  enableAllTransports() {
    this.#manager.transporter.enableAllTransports();
    return this;
  }

  /**
   * disable all transports
   * @returns {Controls} this
   */

  disableAllTransports() {
    this.#manager.transporter.disableAllTransports();
    return this;
  }

  /**
   * get the transports
   * @return {Transport[]} the transports
   */
  getTransports() {
    return this.#manager.transporter.getTransports();
  }
}
