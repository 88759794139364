import React, { useContext, useState, useEffect } from 'react';
import SelectedStore from '../../../Common/SelectedStore/SelectedStore';
import OrderConfirmationContext from '../../../../context/OrderConfirmationContext';
import PudosPickUpInstructions from './PudosPickUpInstructions/PudosPickUpInstructions';
import useLog from '../../../useLog/useLog';
import { getStaticGoogleMapImage } from '../../../../tools/googleMapHelper';

export default function PudosPickUpInfo() {
  const [pudosData, setPudosData] = useState({});
  const { orderConfirmationData } = useContext(OrderConfirmationContext);
  const { textFor, shipMethod } = orderConfirmationData;
  const logger = useLog('PudosPickUpInfo.root');

  const reformatPudosData = (data) => {
    const staticMapImageUrl = getStaticGoogleMapImage(
      data?.geoPosition?.latitude,
      data?.geoPosition?.longitude,
      data?.markerImage,
    );
    return {
      storeName: data?.name,
      pickupAddress: data?.address,
      pudosProviderType: data?.providerType,
      displayOperatingHours: data?.canDisplayOperatingHours,
      storeOperatingHours: data?.operatingHours,
      staticMapImageUrl,
    };
  };
  useEffect(() => {
    let sessionPudosData;
    try {
      sessionPudosData = sessionStorage.getItem('pudosCheckoutData');
      if (sessionPudosData) {
        const parsedPudosData = JSON.parse(sessionPudosData);
        setPudosData(reformatPudosData(parsedPudosData?.selectedStore));
      }
    } catch (error) {
      logger.error('Error parsing pudos data from session storage', error);
    }
  }, [logger]);
  return (
    <div data-testid="pudos-pickup-info" className="pudos-pickup-details">
      { pudosData && (
      <SelectedStore
        storeName={pudosData?.storeName}
        address={pudosData?.pickupAddress}
        providerType={pudosData?.pudosProviderType}
        tmntData={textFor}
        displayOperatingHours={pudosData?.displayOperatingHours}
        storeOperatingHours={pudosData?.storeOperatingHours}
        estimatedPickupDate={shipMethod?.estimatedDeliveryDateDisplay}
        staticMapImageUrl={pudosData?.staticMapImageUrl}
      />
      ) }
      {textFor && <PudosPickUpInstructions tmntData={textFor} /> }
    </div>
  );
}
