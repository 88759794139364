// NOTE: used for both UK or Canada
export const postalCodeRegEx = {
  UK_OR_CA: /^[A-Za-z0-9]{5,7}$/i,
  US: /^[\d]{5}(?:-[\d]{4})?$/,
};

export function determinePostalCodeValidity(value) {
  const postalCodeValidityDeterminiation = {
    input: value,
    matched: null,
    valid: false,
    value: null,
  };
  if (typeof value === 'string' && value.length >= 5) {
    if (value.match(postalCodeRegEx.US)) {
      postalCodeValidityDeterminiation.valid = true;
      postalCodeValidityDeterminiation.value = value.split('-').shift();
    } else if (value.replace(/[^\w]+/g, '').match(postalCodeRegEx.UK_OR_CA)) {
      postalCodeValidityDeterminiation.valid = true;
      postalCodeValidityDeterminiation.value = value;
    }
  }
  return postalCodeValidityDeterminiation;
}
