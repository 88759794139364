import { createBreakpoint } from 'react-use';

export const BREAKPOINT = {
  XXS: 360,
  XS: 540,
  S: 700,
  M: 825,
  L: 1025,
  XL: 1200,
  XXL: 1500,
};

export const BREAKPOINTSIZE = {
  XXS: 'XXS',
  XS: 'XS',
  S: 'S',
  M: 'M',
  L: 'L',
  XL: 'XL',
  XXL: 'XXL',
};

const useBreakpoint = createBreakpoint(BREAKPOINT);

export const useIsXS = () => {
  const currentBreakPoint = useBreakpoint();
  return BREAKPOINT[currentBreakPoint] <= BREAKPOINT.XS;
};

export const useIsS = () => {
  const currentBreakPoint = useBreakpoint();
  return BREAKPOINT[currentBreakPoint] <= BREAKPOINT.S;
};

export const useIsM = () => {
  const currentBreakPoint = useBreakpoint();
  return BREAKPOINT[currentBreakPoint] <= BREAKPOINT.M;
};

export const useIsL = () => {
  const currentBreakPoint = useBreakpoint();
  return BREAKPOINT[currentBreakPoint] < BREAKPOINT.L;
};

export default useBreakpoint;
