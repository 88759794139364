import React from 'react';
import PropTypes from 'prop-types';
import GiftCardApplied from './GiftCardApplied';
import Tmnt from '../../../Tmnt/Tmnt';

export default function GiftCardsApplied({
  giftCardsApplied,
  isMaxNumOfGiftCardApplied,
  removeGiftCard,
  isRemoving,
  tmntData,
}) {
  return (
    <div className="gift-cards-applied-container">
      <h3
        className="gift-cards-applied-title"
      >
        <Tmnt tmnt={tmntData?.giftCardApplied} />
      </h3>
      {isMaxNumOfGiftCardApplied && (
        <p className="gift-cards-max-num-message">
          <Tmnt tmnt={tmntData?.giftCardMaxNumAppliedMessage} />
        </p>
      )}
      <div className="gift-cards-applied">
        {giftCardsApplied?.map((giftCardApplied) => (
          <GiftCardApplied
            key={giftCardApplied?.maskedNumber}
            giftCard={giftCardApplied}
            removeGiftCard={removeGiftCard}
            isRemoving={isRemoving}
            tmntData={tmntData}
          />
        ))}
      </div>
    </div>
  );
}

GiftCardsApplied.defaultProps = {
  giftCardsApplied: [],
  isMaxNumOfGiftCardApplied: false,
  isRemoving: false,
  tmntData: {},
};

GiftCardsApplied.propTypes = {
  giftCardsApplied: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      maskedNumber: PropTypes.string,
      amountFmt: PropTypes.string,
    }),
  ),
  isMaxNumOfGiftCardApplied: PropTypes.bool,
  removeGiftCard: PropTypes.func.isRequired,
  isRemoving: PropTypes.bool,
  tmntData: PropTypes.instanceOf(Object),
};
