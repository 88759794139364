import { useEffect } from 'react';

function HandlePXPPayment() {
  useEffect(() => {
    // This function handles the PXP event
    // and dispatches a new event based on the transaction method
    function handlePXPEvent(event, eventType) {
      const { transactionMethod = '' } = event.detail;
      const lowerCaseTransactionMethod = transactionMethod.toLowerCase();
      let eventName;

      if (lowerCaseTransactionMethod === 'googlepay') {
        eventName = `googlePay:${eventType}`;
      } else if (lowerCaseTransactionMethod === 'applepay') {
        eventName = `applePay:${eventType}`;
      }

      if (!eventName) {
        return;
      }

      const newEvent = new CustomEvent(eventName, {
        detail: event.detail,
      });
      window.dispatchEvent(newEvent);
    }

    // This function handles successful Google and Apple Pay payments
    function handlePaymentResult(event) {
      handlePXPEvent(event, 'handlePaymentResult');
    }

    // This function handles errors for Google and Apple Pay payments
    function handlePaymentError(event) {
      handlePXPEvent(event, 'handlePaymentError');
    }

    window.handlePaymentResult = handlePaymentResult;
    window.handlePaymentError = handlePaymentError;

    return () => {
      // Clean up the event handlers when the component is unmounted
      window.handlePaymentResult = null;
      window.handlePaymentError = null;
    };
  }, []);

  return null;
}

export default HandlePXPPayment;
