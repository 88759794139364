import inheritStorage from './inheritStorage';

export default class StorageMock {
  #things = new Map();

  setItem(key, value) {
    this.#things.set(key, value);
  }

  getItem(key) {
    return this.#things.get(key);
  }

  removeItem(key) {
    this.#things.delete(key);
  }

  clear() {
    this.#things.clear();
  }
}

inheritStorage(StorageMock);
