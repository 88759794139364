import assert from 'assert';
import StorageMock from './StorageMock';
import inheritStorage from './inheritStorage';

const isSupportedByBrowserKey = 'isSupportedByBrowserKey';

export default class StorageAdapter {
  #storage = new StorageMock();

  set storage(storage) {
    // NOTE: it is 3x faster to check type than instanceof.
    // If we can short circuit faster, the better.
    assert(typeof storage === 'object' && storage !== null, 'must be an object and not null');
    assert(storage instanceof Storage, 'must be an instance of Storage');
    assert(storage !== this, 'can not be this');
    this.#storage = storage;
  }

  constructor(storage) {
    if (storage) {
      this.storage = storage;
    }
  }

  setItem(key, value) {
    this.#storage.setItem(key, value);
  }

  getItem(key) {
    return this.#storage.getItem(key);
  }

  removeItem(key) {
    return this.#storage.removeItem(key);
  }

  #unsafeSet(key, value) {
    if (typeof value === 'undefined') return;
    const valueToSet = typeof value === 'string'
      ? value
      : JSON.stringify(value);
    this.setItem(key, valueToSet);
  }

  set(key, value) {
    try {
      this.#unsafeSet(key, value);
    } catch (e) {
      // keeping Empty. Safety net for Private Mode on Safari, Phone
    }
  }

  #unsafeGet(key) {
    return this.getItem(key);
  }

  get(key) {
    let value;
    try {
      value = this.#unsafeGet(key);
    } catch (e) {
      // keeping Empty. Safety net for Private Mode on Safari, Phone
    }
    return value;
  }

  getBoolean(key) {
    return this.get(key) === 'true';
  }

  #unsafeGetJSON(key) {
    return JSON.parse(this.#unsafeGet(key));
  }

  getJSON(key, defaultValue = {}) {
    try {
      return this.#unsafeGetJSON(key);
    } catch (e) {
      return defaultValue;
    }
  }

  exists(key) {
    const value = this.get(key);
    return typeof value !== 'undefined' && value !== null;
  }

  #unsafeRemove(key) {
    this.removeItem(key);
  }

  remove(key) {
    try {
      this.#unsafeRemove(key);
    } catch (e) {
      // keeping Empty. Safety net for Private Mode on Safari, Phone
    }
  }

  #unsafeClear() {
    this.#storage.clear();
  }

  clear() {
    try {
      this.#unsafeClear();
    } catch (e) {
      // keeping Empty. Safety net for Private Mode on Safari, Phone
    }
  }

  isSupportedByBrowser() {
    try {
      this.#unsafeSet(isSupportedByBrowserKey, 'test');
      const value = (this.#unsafeGet(isSupportedByBrowserKey) === 'test');
      this.#unsafeRemove(isSupportedByBrowserKey);
      return value;
    } catch (e) {
      return false;
    }
  }
}

inheritStorage(StorageAdapter);
