import React, { useState, useEffect } from 'react';
import {
  gql,
  useMutation,
} from '@apollo/client';
import {
  Toaster, Switch, Button, Checkbox,
  // InputField,
} from 'anf-core-react';
import DEBUG_KEYS from '../../../hooks/useDebug/debugKeys';

export const SAVE_DEBUG_FEATURE_SWITCHES = gql`
  mutation SaveDebugFeatureSwitches($debugFeatureSwitch: [SaveDebugFeatureSwitchInput!]!) {
    saveDebugFeatureSwitches(debugFeatureSwitch: $debugFeatureSwitch) {
      success
      message
    }
  }
`;

function DebugToaster() {
  const [isToasterOpen, setIsToasterOpen] = useState(false);
  const [flags, setFlags] = useState({});
  const [preExistingSwitchStates, setPreExistingSwitchStates] = useState({});
  const [switchStates, setSwitchStates] = useState({});
  const [serverSwitchStates, setServerSwitchStates] = useState({});
  const [showOnlyOverridden, setShowOnlyOverridden] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [readOnly, setReadOnly] = useState(false);

  const [saveDebugFeatureSwitches] = useMutation(SAVE_DEBUG_FEATURE_SWITCHES);

  const debugFeatureFlags = () => {
    setIsToasterOpen(true);
    const exportedData = window?.digitalData?.export();
    if (exportedData?.flag) {
      // setting all flags in the state
      setFlags(exportedData.flag);
      const existingSwitchStates = {};
      Object.keys(exportedData.flag).forEach((key) => {
        const value = sessionStorage.getItem(key);
        if (value !== null) {
          existingSwitchStates[key] = JSON.parse(value);
          setShowOnlyOverridden(true);
        }
      });
      setPreExistingSwitchStates(existingSwitchStates);
      setSwitchStates(existingSwitchStates);
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('debugFeatureFlags') === 'true') {
      // url param `debugFeatureFlags` just provides the read-only view
      setReadOnly(true);
      // open the modal
      debugFeatureFlags();
    }

    window.debugFeatureFlags = debugFeatureFlags;
  }, []);

  const closeToaster = () => {
    setIsToasterOpen(false);
  };

  const handleSwitchState = (key, newVal) => {
    setSwitchStates((prevState) =>
      // If the new value matches the original value in flags, remove the entry
      // if (newVal === flags[key]) {
      //   const { [key]: _, ...rest } = prevState;
      //   return rest;
      // }
      // eslint-disable-next-line implicit-arrow-linebreak
      ({
        ...prevState,
        [key]: newVal,
      }));
  };

  const handleServerSwitchState = (key, newVal) => {
    setServerSwitchStates((prevState) => ({
      ...prevState,
      [key]: newVal,
    }));
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const resetOverrides = () => {
    // Reset the switch states to the original values
    setSwitchStates({});
    // delete all the overridden session storage values
    Object.keys(preExistingSwitchStates).forEach((key) => {
      sessionStorage.removeItem(key);
    });
    setPreExistingSwitchStates({});
    // make sure serverSwitchStates are also reset
    setServerSwitchStates({});
    // make saveDebugFeatureSwitches call to reset the server switch states
    saveDebugFeatureSwitches({ variables: { debugFeatureSwitch: [] } });
    // since all the overrides are now deleted, show all feature swtiches
    setShowOnlyOverridden(false);
    setIsToasterOpen(false);
  };

  const saveSwitchStatesInSession = async () => {
    // Any updates to the switch states will enable debug mode
    if (Object.keys(switchStates).length) {
      sessionStorage.setItem(DEBUG_KEYS.DEBUG_MODE, true);
    }

    const serverSwitchStateArr = [];
    // Save only the states that have changed
    Object.entries(switchStates).forEach(([key, value]) => {
      sessionStorage.setItem(key, value);

      if (serverSwitchStates[key]) {
        serverSwitchStateArr.push({ key, value });
      }
    });

    // if serverSwitchStateArr is not empty, make a graphql request
    if (serverSwitchStateArr.length) {
      try {
        await saveDebugFeatureSwitches({ variables: { debugFeatureSwitch: serverSwitchStateArr } });
      } catch (error) {
        console.error('Failed to save switch states on the server:', error);
      }
    }

    setIsToasterOpen(false);
  };

  const createToggleSwitch = () => (
    <table style={{ width: '100%', borderCollapse: 'collapse' }}>
      <thead>
        <tr style={{ background: '#F6F6F6' }}>
          <th style={{
            border: '1px solid #CCCCCC', padding: '8px', textAlign: 'left', width: '50%', fontWeight: 'bold',
          }}
          >
            FEATURE FLAG KEY
          </th>
          <th style={{
            border: '1px solid #CCCCCC', padding: '8px', textAlign: readOnly ? 'center' : 'left', fontWeight: 'bold',
          }}
          >
            FLAG VALUE
          </th>
          {readOnly ? null
            : (
              <th style={{
                border: '1px solid #CCCCCC', padding: '8px', textAlign: 'left', fontWeight: 'bold',
              }}
              >
                SAVE TO SERVER
              </th>
            )}
        </tr>
      </thead>
      <tbody>
        {/* iterate over preExistingSwitchStates if showOnlyOverridden is true */}
        {Object.keys(showOnlyOverridden ? preExistingSwitchStates : flags)
          // filter out non-boolean flags
          .filter((key) => typeof flags[key] === 'boolean')
          // filter out flags that don't match the search query
          .filter((key) => key.includes(searchQuery))
          // map the remaining flags to table rows
          .map((key) => {
            const isOverridden = typeof preExistingSwitchStates[key] === 'boolean';
            return (
              <tr key={key} style={{ background: '#F6F6F6', borderBottom: '1px solid #CCCCCC' }}>
                <td style={{ padding: '8px', width: '50%', color: isOverridden && '#257717' }}>
                  {`${key}:`}
                  {isOverridden && <span style={{ color: flags[key] ? '#257717' : '#CC0000' }}>{flags[key] ? ' ON*' : ' OFF*'}</span>}
                </td>
                {readOnly ? <td style={{ padding: '8px', textAlign: 'center', color: flags[key] ? '#257717' : '#CC0000' }}>{flags[key] ? 'ON' : 'OFF'}</td> : (
                  <>
                    <td style={{ padding: '8px' }}>
                      <Switch
                        id={key}
                        isChecked={typeof switchStates[key] === 'boolean' ? switchStates[key] : flags[key]}
                        onChange={(event) => handleSwitchState(key, event.target.checked)}
                      >
                        {' '}
                      </Switch>
                    </td>
                    <td style={{ padding: '8px', textAlign: 'center' }}>
                      {/* display switch to update on server, if switchState has been toggled */}
                      {typeof switchStates[key] === 'boolean' ? (
                        <Switch
                          id={`${key}-server`}
                          isChecked={typeof serverSwitchStates[key] === 'boolean' ? serverSwitchStates[key] : false}
                          onChange={(event) => handleServerSwitchState(key, event.target.checked)}
                        >
                          {' '}
                        </Switch>
                      ) : 'N/A'}
                    </td>
                  </>
                )}
              </tr>
            );
          })}
      </tbody>
    </table>
  );

  // Conditional rendering based on isToasterOpen
  if (!isToasterOpen) {
    return null;
  }

  const isPreExistingSwitchStates = Object.keys(preExistingSwitchStates).length > 0;
  return (
    <Toaster
      direction="from-top"
      isOpen={isToasterOpen}
      onClose={closeToaster}
      heading={<h1>Debug Mode: Feature Flags</h1>}
      footer={readOnly ? null : (
        <div className="scope-1892">
          <Button variant="secondary" isFullWidth onClick={saveSwitchStatesInSession}>SAVE</Button>
          <Button variant="tertiary-light" isFullWidth onClick={resetOverrides}>RESET OVERRIDES</Button>
        </div>
      )}
    >
      <section className="scope-1892 debug-modal-section">
        <div style={{ marginBottom: '1rem' }}>
          {isPreExistingSwitchStates && (
            <div style={{ marginBottom: '1rem' }}>
              <Checkbox
                description="Show Overridden Values?"
                id="checkbox-id"
                name="checkbox"
                value="Show Overridden Values?"
                isChecked={showOnlyOverridden}
                onChange={() => setShowOnlyOverridden(!showOnlyOverridden)}
              />
            </div>
          )}
          <div className="input-field js-input-field ">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search Feature Flags"
              required=""
              aria-required="true"
              className="js-input-handler"
              id="searchQuery"
            />
            <label htmlFor="searchQuery" className="float-label">Search Feature Flag</label>
          </div>
        </div>
        {createToggleSwitch()}
      </section>
    </Toaster>
  );
}

export default DebugToaster;
