let StorageRef;
if (typeof Storage === 'undefined') {
  // eslint-disable-next-line
  StorageRef = () => {}; { /* TODO document why this function 'Storage' is empty */ }
  StorageRef.prototype = {
    setItem() {},
    getItem() {},
    removeItem() {},
    clear() {},
  };
} else {
  StorageRef = Storage;
}

const inheritStorage = (Thing) => Object.setPrototypeOf(Thing.prototype, StorageRef.prototype);

export default inheritStorage;
