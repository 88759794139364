import {
  gql,
} from '@apollo/client';

export const PROMOTION_UPDATE_MUTATION = gql`
mutation ApplyPromo($promotionCode: String!) {
  applyPromo(promotionCode: $promotionCode) {
    orderTotals {
      totalItemsInBag
      cartTotalAmount
      cartPayableAmount
      subTotalFmt
      promoAmount
      promoAmountFmt
      shippingHandlingChargeFmt
      totalGiftBoxes
      subTotal
      orderId
      totalGiftBoxesAmount
      isGiftReceiptChecked
      displayFreeShippingText
      displayGiftReceiptText
      estimatedTaxAmountFmt
      charityRoundUpFmt
      includesConsumptionTax
      includesGst
      includesVat
      grandTotal
      grandTotalFmt
      currency
      charityTotalFmt
      subTotalUSD
      grandTotalUSD
      cartTotalAmountUSD
      totalAdjustmentUSD
      shippingChargeUSD
    }
    charity {
      name
      id
      brand
      sku
      option {
        id
        name
        checked
      }
    }
      shippingSpeed {
      amount
      amountDisplay
      addressType
      country
      description
      estimatedDeliveryDate
      estimatedDeliveryDateDisplay
      externalId
      isSelected
      isValid
      isEnabled
      shipModeId
      shipModeType
      state
      availability {
        cartID
        courierEligible
        storeNo
        shipDate
        orderByTime
        timeZone
        reason
        hasOption
        postalCode
        isValid
        shouldBeSelected
        eligibilityConfirmed
        ineligibilityConfirmed
        usingExpressLanePayment
        displayChangeLink
        sddAvailabilityMessage
        geolocation {
          country
          countryAbbr
          latlng
          postalCode
          postalTown
          state
          stateAbbr
        }
      }
    }
    bagItems {
      hasGiftCard
      hasOnlyGiftCard
      items {
        item {
          headers {
            badgeStatusMessage {
              key
              value
            }
            headerMessage
            estimatedShipDate
            shouldDisplayAsbadge
            attached
            fullWidth
            textAlign
            variant
          }
          footer {
            bogoMessage
          }
          image {
            altText
            imageSrc
            imageId
            classList
          }
          imageOverlay {
            productUrl
            zoomIn
          }
          secondaryContent {
            buttonGroup
          }
          productContent {
            gender
            name
            size
            brand
            shortSku
            longSku
            orderItemId
            productId
            collectionId
            freeGift
            seq
            faceOut
            faceoutImage
            color
            productPrice {
              description
              originalPrice
              discountPrice
              discountText
              variant
              priceFlag
              original
              discount
              originalUSD
              discountUSD
            }
            promotions {
              shortDesc
              promotionType
            }
            deliveryDate
            toEmail
            editRemoveButtonVariant
            editRemoveButton
            hasEdit
            hasRemove
            isFinalSale
            itemShortDesc
            quantity
          }
        }
        giftBox {
          isWrapped
          giftBoxMessage
        }
      }
    }
    promoInfo {
      promoCompId
      promotionCode
      promotionKey
      longDesc
      isShippingPromo
      isRewardPromo
      isPromoBadging
      isImplicitPromo
      amount
      amountFmt
      orderAdjustmentId
      shortDesc
      promotionType
    }
    statusCode
    success
    statusMessages {
      code
      key
      message
    }
    rewardsAndPromotions {
      couponInfo {
        couponCode
        associatedPromoKey
        associatedPromoName
        formattedValue
        couponExpiryDate
        couponApplied
        offerHeaderKey
        offerHeader
        promoType
        exclusionsApplyTmntKey
        offerType
        isPromotion
        associatedPoints
        tier
      }
      legalTermsTmntKey
      totalCoupons
    }
    repudiationData {
      errorMessage
      repudiationType
    }
    freeShippingProgressInfo {
      isVisible
      amountToFreeShipping
      amountToFreeShippingFmt
      preLabel
      postLabel
      progressValue
      maxProgress
      minProgress
    }
    klarnaState {
      isEnabled
      orderInfo {
        locale
        merchant_reference1
        merchant_reference2
        order_amount
        order_lines {
          image_url
          product_url
          type
          reference
          quantity
          unit_price
          total_amount
          name
        }
        order_tax_amount
        purchase_country
        purchase_currency
      }
      sessionRequestBody {
        orderId
        purchaseCountry
        currency
        locale
        orderTotal
        tax
        shippingDetails {
          type
          name
          quantity
          unitPrice
          totalAmount
        }
        items {
          type
          shortSku
          orderItemId
          quantity
          price
          offerPrice
          contractPrice
          listPrice
          name
          productUrl
          imageUrl
          adjustments {
            shippingPromo
            type
            name
            quantity
            unitPrice
            totalAmount
          }
        }
        adjustments {
          shippingPromo
          type
          name
          quantity
          unitPrice
          totalAmount
        }
        attributes {
          options {
            color_details
            color_button
            color_button_text
            color_checkbox
            color_checkbox_checkmark
            color_header
            color_link
            color_text
            color_text_secondary
          }
        }
      }
    }
    giftCards {
      isAllowedGiftCards
      appliedGiftCards {
        id
        maskedNumber
        amountFmt
      }
    }
    paymentOptions {
      id
      name
      type
      code
      description {
        key
        value
      }
      message {
        key
        value
      }
      images {
        altText
        imageSrc
      }
      icon
      disabled
      savable
      default
    }
  }
}
  `;
export const PROMOTION_DELETE_MUTATION = gql`
mutation RemovePromo($promotionCode: String!) {
  removePromo(promotionCode: $promotionCode) {
    orderTotals {
      totalItemsInBag
      cartTotalAmount
      cartPayableAmount
      subTotalFmt
      promoAmount
      promoAmountFmt
      subTotal
      orderId
      shippingHandlingChargeFmt
      totalGiftBoxes
      totalGiftBoxesAmount
      isGiftReceiptChecked
      displayFreeShippingText
      displayGiftReceiptText
      estimatedTaxAmountFmt
      charityRoundUpFmt
      includesConsumptionTax
      includesGst
      includesVat
      grandTotal
      grandTotalFmt
      currency
      charityTotalFmt
      subTotalUSD
      grandTotalUSD
      cartTotalAmountUSD
      totalAdjustmentUSD
      shippingChargeUSD
    }
        shippingSpeed {
      amount
      amountDisplay
      addressType
      country
      description
      estimatedDeliveryDate
      estimatedDeliveryDateDisplay
      externalId
      isSelected
      isValid
      isEnabled
      shipModeId
      shipModeType
      state
      availability {
        cartID
        courierEligible
        storeNo
        shipDate
        orderByTime
        timeZone
        reason
        hasOption
        postalCode
        isValid
        shouldBeSelected
        eligibilityConfirmed
        ineligibilityConfirmed
        usingExpressLanePayment
        displayChangeLink
        sddAvailabilityMessage
        geolocation {
          country
          countryAbbr
          latlng
          postalCode
          postalTown
          state
          stateAbbr
        }
      }
    }
    charity {
      name
      id
      brand
      sku
      option {
        id
        name
        checked
      }
    }
    bagItems {
      hasGiftCard
      hasOnlyGiftCard
      items {
        item {
          headers {
            badgeStatusMessage {
              key
              value
            }
            headerMessage
            estimatedShipDate
            shouldDisplayAsbadge
            attached
            fullWidth
            textAlign
            variant
          }
          footer {
            bogoMessage
          }
          image {
            altText
            imageSrc
            imageId
            classList
          }
          imageOverlay {
            productUrl
            zoomIn
          }
          secondaryContent {
            buttonGroup
          }
          productContent {
            gender
            name
            size
            brand
            shortSku
            longSku
            orderItemId
            productId
            collectionId
            freeGift
            seq
            faceOut
            faceoutImage
            color
            productPrice {
              description
              originalPrice
              discountPrice
              discountText
              variant
              priceFlag
              original
              discount
              originalUSD
              discountUSD
            }
            promotions {
              shortDesc
              promotionType
            }
            deliveryDate
            toEmail
            editRemoveButtonVariant
            editRemoveButton
            hasEdit
            hasRemove
            isFinalSale
            itemShortDesc
            quantity
          }
        }
        giftBox {
          isWrapped
          giftBoxMessage
        }
      }
    }
    statusCode
    success
    statusMessages {
      code
      key
      message
    }
    promoInfo {
      promoCompId
      promotionCode
      promotionKey
      longDesc
      isShippingPromo
      isRewardPromo
      isPromoBadging
      isImplicitPromo
      amount
      amountFmt
      orderAdjustmentId
      shortDesc
      promotionType
    }
    rewardsAndPromotions {
      couponInfo {
        couponCode
        associatedPromoKey
        associatedPromoName
        formattedValue
        couponExpiryDate
        couponApplied
        offerHeaderKey
        offerHeader
        promoType
        exclusionsApplyTmntKey
        offerType
        isPromotion
        associatedPoints
        tier
      }
      legalTermsTmntKey
      totalCoupons
    }
    repudiationData {
      errorMessage
      repudiationType
    }
    freeShippingProgressInfo {
      isVisible
      amountToFreeShipping
      amountToFreeShippingFmt
      preLabel
      postLabel
      progressValue
      maxProgress
      minProgress
    }
    klarnaState {
      isEnabled
      orderInfo {
        locale
        merchant_reference1
        merchant_reference2
        order_amount
        order_lines {
          image_url
          product_url
          type
          reference
          quantity
          unit_price
          total_amount
          name
        }
        order_tax_amount
        purchase_country
        purchase_currency
      }
      sessionRequestBody {
        orderId
        purchaseCountry
        currency
        locale
        orderTotal
        tax
        shippingDetails {
          type
          name
          quantity
          unitPrice
          totalAmount
        }
        items {
          type
          shortSku
          orderItemId
          quantity
          price
          offerPrice
          contractPrice
          listPrice
          name
          productUrl
          imageUrl
          adjustments {
            shippingPromo
            type
            name
            quantity
            unitPrice
            totalAmount
          }
        }
        adjustments {
          shippingPromo
          type
          name
          quantity
          unitPrice
          totalAmount
        }
        attributes {
          options {
            color_details
            color_button
            color_button_text
            color_checkbox
            color_checkbox_checkmark
            color_header
            color_link
            color_text
            color_text_secondary
          }
        }
      }
    }
    giftCards {
      isAllowedGiftCards
      appliedGiftCards {
        id
        maskedNumber
        amountFmt
      }
    }
    paymentOptions {
      id
      name
      type
      code
      description {
        key
        value
      }
      message {
        key
        value
      }
      images {
        altText
        imageSrc
      }
      icon
      disabled
      savable
      default
    }
  }
}
`;
