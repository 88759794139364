import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { RecommendationSliders } from '@xp-utilities/web';
import trackAction from '../../tools/analytics';
import ShoppingBagRecommendationsContextProvider from '../../context/Recommendations/ShoppingBagRecommendationsContextProvider';
import handleOnProductClick from './helpers';

const ShoppingBagRecommendationsPropTypes = {
  id: PropTypes.string.isRequired,
  placements: PropTypes.arrayOf(PropTypes.string),
  slidesToShow: PropTypes.number,
  bagItems: PropTypes.arrayOf(PropTypes.shape({})),
  hasItemsInBag: PropTypes.bool.isRequired,
};

const ShoppingBagRecommendationsDefaultProps = {
  placements: [],
  slidesToShow: undefined,
  bagItems: [],
};

const debounce = (func, delay) => {
  const timeoutIds = {};
  return (...args) => {
    const context = this;

    // Create a unique key for the current set of arguments
    const key = JSON.stringify(args[0]);

    // Clear previous timeout for the same arguments
    if (timeoutIds[key]) {
      clearTimeout(timeoutIds[key]);
    }

    // Set new timeout for the current arguments
    timeoutIds[key] = setTimeout(() => {
      func.apply(context, ['universal_scroll', {
        data_text: 'user scrolled to',
        data_action: args[0],
        event_type: 'scroll',
      }]);
      delete timeoutIds[key]; // Remove the timeout reference after execution
    }, delay);
  };
};
export default function ShoppingBagRecommendations({
  id,
  placements,
  slidesToShow,
  bagItems,
  hasItemsInBag,
}) {
  const sliderRefs = useRef(null);
  const [sliders, setSliders] = useState([]);

  useEffect(() => {
    // If sliders are already set, return
    if (sliders.length) return;
    async function getSliders() {
      // Get all the sliders on the page
      const checkoutSliders = await document.querySelectorAll('.scope-1892.recommendation-slider');
      setSliders(checkoutSliders);
    }

    getSliders();
  }, [sliders]);

  useEffect(() => {
    // Onces sliders are set, observe them
    if (sliders.length) {
      const debouncedTrackAction = debounce(trackAction, 300);
      const options = {
        root: null, // Use the viewport as the root
        rootMargin: '0px',
        threshold: 0.25, // Trigger when 25% of the element is visible
      };
      let observers;

      // Callback function when a slider intersects with the viewport
      const handleIntersection = (entry, index) => {
        if (entry.isIntersecting && window.scrollY > 0) {
        // Access intersection data from the entry object
          const { target } = entry;

          // Access and print the data attributes of the element
          const dataAttributes = target.dataset;

          debouncedTrackAction(dataAttributes.sliderId);
          // Unobserve the element to prevent multiple triggers
          observers[index].unobserve(entry.target);
        }
      };

      // Store the slider elements in a ref
      sliderRefs.current = Array.from(sliders);

      observers = sliderRefs.current.map((sliderRef, index) => {
        const observer = new IntersectionObserver((entries) => {
          handleIntersection(entries[0], index);
        }, options);

        // Observe the slider element
        if (sliderRef) {
          observer.observe(sliderRef);
        }
        return observer;
      });

      // Clean up the observers when the component unmounts
      return () => {
        observers.forEach((observer) => {
          observer.disconnect();
        });
      };
    }
    return () => {};
  }, [sliders]);

  const renderContainer = () => (
    <div
      id={id}
      data-testid={id}
      ref={sliderRefs}
    >
      <RecommendationSliders
        placements={placements}
        slidesToShow={slidesToShow}
        onProductClick={handleOnProductClick}
      />
    </div>
  );

  return (
    <ShoppingBagRecommendationsContextProvider
      id={id}
      bagItems={bagItems}
      hasItemsInBag={hasItemsInBag}
    >
      { renderContainer() }
    </ShoppingBagRecommendationsContextProvider>
  );
}

ShoppingBagRecommendations.propTypes = ShoppingBagRecommendationsPropTypes;
ShoppingBagRecommendations.defaultProps = ShoppingBagRecommendationsDefaultProps;
