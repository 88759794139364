import React from 'react';
import PropTypes from 'prop-types';
import { RadioButtonGroup } from 'anf-core-react';
import PaymentOption from './PaymentOption';

export default function PaymentOptions({
  isLoggedIn,
  payments,
  selectedPayment,
  setSelectedPayment,
  savedPaymentDetails,
  paymentConfig,
  tmntData,
  onChange,
  onClear,
}) {
  return (
    <RadioButtonGroup
      id="payment-options"
      legend=""
    >
      {
        payments?.map((payment) => (
          <PaymentOption
            key={payment?.id}
            isLoggedIn={isLoggedIn}
            isSelected={selectedPayment === payment?.type?.toLowerCase()}
            payment={payment}
            changePaymentOption={setSelectedPayment}
            savedPaymentDetails={savedPaymentDetails}
            paymentConfig={paymentConfig}
            tmntData={tmntData}
            onChange={onChange}
            onClear={onClear}
          />
        ))
      }
    </RadioButtonGroup>
  );
}

PaymentOptions.defaultProps = {
  isLoggedIn: false,
  payments: [],
  selectedPayment: '',
  savedPaymentDetails: null,
  paymentConfig: {},
  tmntData: {},
  onChange: () => {},
  onClear: () => {},
};

PaymentOptions.propTypes = {
  isLoggedIn: PropTypes.bool,
  payments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    code: PropTypes.string,
    description: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    message: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    icon: PropTypes.string,
    disabled: PropTypes.bool,
    savable: PropTypes.bool,
    default: PropTypes.bool,
  })),
  selectedPayment: PropTypes.string,
  setSelectedPayment: PropTypes.func.isRequired,
  savedPaymentDetails: PropTypes.shape({
    iconCode: PropTypes.string,
    maskedCardNumber: PropTypes.string,
    cardExpiryMonth: PropTypes.string,
    cardExpiryYear: PropTypes.string,
    cardType: PropTypes.string,
    requiresCvv: PropTypes.bool,
    savedPaymentId: PropTypes.string,
    default: PropTypes.bool,
  }),
  paymentConfig: PropTypes.instanceOf(Object),
  tmntData: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  onClear: PropTypes.func,
};
