import assert from 'assert';
import Transporter from '../Transporter/Transporter';
import StorageAdapter from '../Utils/Store/StorageAdapter';
import Store from '../Utils/Store/Store';

let logDeprecationWarned = false;

/**
 * assert the passed value is a logger
 * @param {Function} logger the logger to assert
 */

function assertLogger(logger) {
  assert(typeof logger === 'function', 'must be a function');
  assert(typeof logger.name === 'string', 'must be a string');
}

/**
 * the factory responsible for making and managing loggers
 * @private
 */
export default class Manager {
  /**
   * the current local storage instance
   * @private
   * @type {Store}
   */

  #store = new Store();

  get #localStorage() {
    return this.#store.localStorage;
  }

  set localStorage(localStorage) {
    assert(
      typeof localStorage === 'object' && localStorage !== null,
      'must be an object and not null',
    );
    assert(localStorage instanceof Storage, 'must be a Storage');
    this.#store.localStorage = new StorageAdapter(localStorage);
  }

  /**
   * the current transporter
   * @public
   * @type {Transporter}
   */

  #transporter = null;

  get transporter() {
    if (!this.#transporter) {
      this.transporter = new Transporter();
    }
    return this.#transporter;
  }

  set transporter(transporter) {
    // NOTE: it is 3x faster to check type than instanceof.
    // If we can short circuit faster, the better.
    assert(
      typeof transporter === 'object'
      && transporter !== null
      && transporter instanceof Transporter,
      'must be a Transporter',
    );
    this.#transporter = transporter;
  }

  /**
   * the local storage key
   * @private
   * @type {String}
   */

  #storageKey = 'clientSideLogger';

  /**
   * a map of the loggers names and the reference object to the logger
   * @private
   * @type {Map}
   */

  #loggerNames = new Map();

  /**
   * a map of all of the loggers. Using a weak map to help with GC
   * @private
   * @type {WeakMap}
   */

  #loggers = new WeakMap();

  /**
   * initializes and returns the log setting from local storage
   * @return {String} The setting string e.g. !*
   */

  getLogSetting() {
    if (!this.#localStorage.get(this.#storageKey)) {
      this.#localStorage.set(this.#storageKey, '!*');
    }
    return this.#localStorage.get(this.#storageKey);
  }

  /**
   * Set the log setting into local storage
   * @param {String} value - the log setting to save
   */

  #setLogSetting(value) {
    this.#localStorage.set(this.#storageKey, value);
    this.#updateLoggersState();
  }

  /**
   * Append a log setting
   * @param {String} value the log setting to append
   */

  #updateLogSetting(value) {
    this.#setLogSetting(`${this.getLogSetting()};${value}`);
  }

  /**
   * Get the logger names
   * @returns {String[]} An array of the logger name
   */

  getLoggerNames() {
    return [...this.#loggerNames.keys()].sort();
  }

  /**
   * Get the loggers
   * @returns {Function[]} An array of the logger functions
   */

  getLoggers() {
    // grab the name references to produce a list of the loggers
    return Array.from(this.#loggerNames.values())
      .map((nameRef) => this.#loggers.get(nameRef))
      .filter((logger) => !!logger);
  }

  /**
   * Check if we have the logger
   * @param {String} name - The name of the logger
   * @returns {Boolean} if we hae the logger or not
   */

  hasLogger(name) {
    return !!this.getLogger(name);
  }

  /**
   * Get a logger by the name
   * @param {String} name - The name of the logger
   * @returns {Function} the logger
   */

  getLogger(name) {
    const loggerRef = this.#loggerNames.get(name);
    let logger = null;
    if (loggerRef) {
      logger = this.#loggers.get(loggerRef);
    }
    if (!logger) {
      // since we do not have a logger, delete the nameRef object
      this.#loggerNames.delete(name);
    }
    return logger;
  }

  /**
   * Check if the logger is enabled or not
   * @param {String} name - The name of the logger
   * @returns {Boolean} if the logger is enabled or not
   */

  isLoggerEnabled(name) {
    if (this.hasLogger(name)) {
      return this.getLogger(name).enabled;
    }
    return false;
  }

  /**
   * Add the logger and trigger an update to loggers' state
   * @param {Function} logger - The logger
   */

  #addLogger(logger) {
    assertLogger(logger);
    // here we create an object that holds the logger's name
    // this object with name prop is our reference object to
    // the logger in the weak map.
    const nameRef = { name: logger.name };
    this.#loggerNames.set(logger.name, nameRef);
    this.#loggers.set(nameRef, logger);
    this.#updateLoggersState();
  }

  /**
   * update the state of all the loggers based on the log setting
   */

  #updateLoggersState() {
    const logSettings = this.getLogSetting().split(';');

    const failedSettings = logSettings.filter((logSetting) => {
      let currentLogSetting = logSetting;
      if (!logSetting.length) {
        return true;
      }

      const firstChar = currentLogSetting.charAt(0);
      let enabled = true;

      if (firstChar === '!') {
        currentLogSetting = currentLogSetting.slice(1);
        enabled = false;
      }

      const names = this.getLoggerNames();
      names.forEach((name) => {
        if (currentLogSetting === '*' || name.match(currentLogSetting)) {
          const logger = this.getLogger(name);
          logger.enabled = enabled;
        }
      });

      return false;
    });

    if (failedSettings.length) {
      this.clearLogSetting();
    }
  }

  /**
   * Remove a logger
   * @param {Function} logger - The logger
   */

  #removeLogger(logger) {
    assertLogger(logger);
    if (!this.hasLogger(logger.name)) {
      return;
    }
    const loggerRef = this.#loggerNames.get(logger.name);
    this.#loggerNames.delete(logger.name);
    this.#loggers.delete(loggerRef);
  }

  /**
   * Clear the log setting
   * @returns {Manager} this
   */

  clearLogSetting() {
    this.#setLogSetting('');
    return this;
  }

  /**
   * Enable logger(s)
   * @param {string} value the name or fragment of a name for logger(s)
   * @returns {Manager} this
   */

  enableLogger(value = '*') {
    assert(typeof value === 'string', 'must be a string');
    this.#updateLogSetting(value);
    return this;
  }

  /**
   * Enable all loggers
   * @returns {Manager} this
   */

  enableAllLoggers() {
    this.#setLogSetting('*');
    return this;
  }

  /**
   * Disable logger(s)
   * @param {string} value the name or fragment of a name for logger(s)
   * @returns {Manager} this
   */

  disableLogger(value = '*') {
    this.#updateLogSetting(`!${value}`);
    return this;
  }

  /**
   * Disable all loggers
   * @returns {Manager} this
   */

  disableAllLoggers() {
    this.#setLogSetting('!*');
    return this;
  }

  /**
   * Create or get a logger
   * @param {String} name - The name of the logger
   * @returns {Function} the logger
   */

  initLogger(name) {
    const manager = this;
    if (manager.hasLogger(name)) {
      return manager.getLogger(name);
    }

    /**
     * Initialize a logger based on the parent logger's name
     * @param {String} _name - The name of the logger that is appended to the name of the parent
     * @returns {Function} the logger
     */
    function logger(_name) {
      return manager.initLogger(`${name}.${_name}`);
    }

    /**
     * the transport
     */
    logger.transporter = manager.transporter;

    /**
     * the name of the logger
     */
    Object.defineProperty(logger, 'name', {
      get: function get() {
        return name;
      },
    });

    /**
     * the state of the logger
     */
    logger.enabled = false;

    /**
     * log a log message and data to the transports
     * @deprecated
     */

    logger.log = function log(...args) {
      if (!logDeprecationWarned) {
        // eslint-disable-next-line no-console
        console.trace('please update to use "info(...)" or "debug(...)" rather than "log(...)"');
        logDeprecationWarned = true;
      }
      if (this.enabled) {
        this.transporter.log(this.name, ...args);
      }
      return this;
    };

    /**
     * @param  {...any} args
     * @returns {logger} the current logger
     */

    logger.debug = function debug(...args) {
      if (this.enabled) {
        this.transporter.debug(this.name, ...args);
      }
    };

    /**
     * @param  {...any} args
     * @returns {logger} the current logger
     */

    logger.info = function info(...args) {
      if (this.enabled) {
        this.transporter.info(this.name, ...args);
      }
    };

    /**
     * log a warn message and data to the transports
     */

    logger.warn = function warn(...args) {
      // NOTE: if we are enabled or not we want to record warnings
      this.transporter.warn(this.name, ...args);
      return this;
    };

    /**
     * log an error message and data to the transports
     */

    logger.error = function error(...args) {
      // NOTE: if we are enabled or not we want to record errors
      this.transporter.error(this.name, ...args);
      return this;
    };

    /**
     * destroy / remove this logger
     */

    logger.destroy = function destroy() {
      this.enabled = false;
      manager.#removeLogger(this);
      return this;
    };

    /**
     * enable the logger
     */

    logger.enable = function enable() {
      if (!manager.isLoggerEnabled(this.name)) {
        manager.enableLogger(this.name);
      }
      return this;
    };

    /**
     * disable the logger
     */

    logger.disable = function disable() {
      if (manager.isLoggerEnabled(this.name)) {
        manager.disableLogger(this.name);
      }
      return this;
    };

    manager.#addLogger(logger);

    return logger;
  }
}
