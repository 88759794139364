import React, {
  useContext,
} from 'react';
import { Link, Icon } from 'anf-core-react';
import OrderConfirmationContext from '../../../context/OrderConfirmationContext';
import Tmnt from '../../Tmnt/Tmnt';

export default function SmsOptIn() {
  const { orderConfirmationData } = useContext(OrderConfirmationContext);
  const { smsOptIn, textFor: tmntData } = orderConfirmationData;
  const title = smsOptIn.isSelected ? tmntData.smsOptInSigned : tmntData.getTextUpdateTitle;
  if (!smsOptIn.isEnabled) {
    return null;
  }

  return (
    <section className="order-review-survey convey-text-updates upper smsOptIn" data-testid="sms-optin" data-aui="convey-text-updates">
      <div className="icon-heading" data-variant="medium" data-layout="vertical">
        <Icon
          icon={smsOptIn.smsIcon}
          size="m"
        />
        <h2 className="icon-heading-text" data-property={title.key} data-testid="smsOptIn-title">
          <Tmnt tmnt={title} />
        </h2>
        {smsOptIn.isSelected ? (
          <div className="text-details" data-property={tmntData.smsOptInDetail.key} data-testid="smsOptIn-text">
            <Tmnt tmnt={{ key: tmntData.smsOptInDetail?.key, value: tmntData.smsOptInDetail?.value?.split('{0}')[0] }} />
            <Link
              href={smsOptIn.privacyUrl}
              target="_blank"
            >
              <Tmnt tmnt={tmntData.privacyNotice} />
            </Link>

          &nbsp;
            <Tmnt tmnt={tmntData.smsOptInAnd} />
          &nbsp;
            <Link
              href={smsOptIn.textTermUrl}
              target="_blank"
            >
              <Tmnt tmnt={tmntData.textTerms} />
            </Link>
            <Tmnt tmnt={{ key: tmntData.smsOptInDetail?.key, value: tmntData.smsOptInDetail?.value?.split('{1}')[1] }} />
          </div>
        ) : (
          <div className="text-details" data-testid="smsOptIn-text" data-property={tmntData.getTextUpdateMessage.key}>
            <Tmnt tmnt={tmntData.getTextUpdateMessage} />
          </div>
        )}
      </div>
    </section>
  );
}
