export default function handleOnProductClick(event, product = {}) {
  const {
    brand,
    collection,
    faceout,
    id,
    imageId,
    name,
    productUrl,
  } = product;

  const eventDetails = {
    productId: id,
    collectionId: collection,
    imageId,
    productName: name,
    productBrand: brand,
    returnFocus: event.target,
    faceout,
  };

  const isOrderConfirmationPage = window.digitalData.get('page.pageKind') === 'order confirmation';

  window.digitalData.subscribe('algonomy.onClick', (data) => {
    const { strategyName } = data;
    const { testId } = data.mvtTreatments[0];
    const analyticsParam = `recommendations-${strategyName}-${testId}`;

    if (isOrderConfirmationPage) {
      const url = new URL(productUrl);

      url.search = new URLSearchParams({
        prodvm: analyticsParam,
        pagefm: analyticsParam,
      }).toString();

      window.location.href = url.href;
    } else {
      eventDetails.origin = analyticsParam;
    }
  });

  if (!isOrderConfirmationPage) {
    window.dispatchEvent(new CustomEvent('quickview:openModal', { detail: eventDetails }));
  }
}

export const getEmptyCartPlacements = (isMobile) => {
  const mobilePlacements = [
    'cart_page.m_empty2',
    'cart_page.m_empty3',
    'cart_page.m_empty4',
    'cart_page.m_empty5',
    'cart_page.m_empty6',
  ];

  const desktopPlacements = [
    'cart_page.empty1',
    'cart_page.empty2',
    'cart_page.empty3',
    'cart_page.empty4',
    'cart_page.empty5',
    'cart_page.empty6',
  ];

  return isMobile ? mobilePlacements : desktopPlacements;
};

export const getCartPlacements = (isMobile) => {
  const mobilePlacements = [
    'cart_page.m_rr1',
    'cart_page.m_rr2',
    'cart_page.m_rr3',
    'cart_page.m_rr4',
    'cart_page.m_rr5',
    'cart_page.m_rr6',
  ];

  const desktopPlacements = [
    'cart_page.rr1',
    'cart_page.rr2',
    'cart_page.rr3',
    'cart_page.rr4',
    'cart_page.rr5',
    'cart_page.rr6',
  ];

  return isMobile ? mobilePlacements : desktopPlacements;
};
