function isValidCardNumber(cardNumber) {
  // Remove all non-digit characters from the input
  const digitsOnly = cardNumber.replace(/\D/g, '');

  // Check that the input contains only digits and is between 13 and 19 characters long
  if (!/^\d{13,19}$/.test(digitsOnly)) {
    return false;
  }

  let sum = 0;
  let isEven = false;
  for (let i = digitsOnly.length - 1; i >= 0; i -= 1) {
    let digit = parseInt(digitsOnly.charAt(i), 10);

    if (isEven) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }

    sum += digit;
    isEven = !isEven;
  }

  return sum % 10 === 0;
}

function isValidExpiry(expiry) {
  // If the expiry is blank or not in the expected format, return false
  if (!expiry || !/^\d{2}\s*\/\s*\d{2}$/.test(expiry)) {
    return false;
  }

  const [expiryMonth, expiryYear] = expiry.split('/').map((str, index) => {
    const parsedInt = parseInt(str.trim(), 10);
    if (index === 1 && parsedInt < 100) {
      // Convert YY to YYYY
      const currentYear = new Date().getFullYear();
      const century = Math.floor(currentYear / 100) * 100;
      return century + parsedInt;
    }
    return parsedInt;
  });
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;

  // If the expiry is in the past, return false
  if (expiryYear < currentYear || (expiryYear === currentYear && expiryMonth < currentMonth)) {
    return false;
  }

  // If the expiry is more than 10 years in the future, return false
  if (expiryYear > currentYear + 10) {
    return false;
  }

  return true;
}

export {
  isValidCardNumber,
  isValidExpiry,
};
