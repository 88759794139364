import React, { useEffect, useState } from 'react';
import { ErrorMessage, Icon } from 'anf-core-react';
import { ERROR_MESSAGE } from '../../Common/Messages/Messages';
import $window from '../../../tools/window';

export default function MfeErrorMessage() {
  const [errorMessage, setErrorMessage] = useState(ERROR_MESSAGE);

  useEffect(() => {
    const windowDigitalData = $window?.digitalData?.export();
    const basePath = windowDigitalData?.page?.url?.domain?.full;
    const tmntURL = `https://${basePath}/api/ecomm/util/tmnt/text-MFE_ERROR_MESSAGE`;
    const currentUrl = window.location.href;

    // Function for extracting parameter value from URL
    function getParameterByName(param, link) {
      let url;
      if (!link) { url = window.location.href; } else { url = link; }
      const name = param.replace(/[[\]]/g, '\\$&');
      const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
      const results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
    const langId = getParameterByName('langId', currentUrl) || -1;
    // sets to default English if no langId is found

    const fetchTMNTData = async () => {
      try {
        const tmntResponse = await fetch(tmntURL);
        if (!tmntResponse.ok) {
          setErrorMessage(ERROR_MESSAGE);
        }
        const jsonData = await tmntResponse.json();
        if (jsonData?.prop && jsonData?.prop[langId] && jsonData?.prop[langId]?.val[0]) {
          setErrorMessage(jsonData.prop[langId].val[0]);
        } else {
          setErrorMessage(ERROR_MESSAGE);
        }
      } catch (error) {
        setErrorMessage(ERROR_MESSAGE);
      }
    };
    fetchTMNTData();
  }, []);

  return (
    <div className="mfe-error-message error-component scope-1892" data-property="MFE_ERROR_MESSAGE">
      <ErrorMessage>
        <Icon icon="exclamation" />
        <p>{errorMessage}</p>
      </ErrorMessage>
    </div>
  );
}
