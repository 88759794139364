import React from 'react';
import PropTypes from 'prop-types';

export default function ApmFields({ payment }) {
  return (
    <div className="apm-fields-wrap" data-testid="apm-fields">
      <input
        name="payment.apmPayment.paymentCode"
        value={payment?.code}
        type="hidden"
        data-testid="apm-payment-code"
      />
    </div>
  );
}

ApmFields.defaultProps = {
};

ApmFields.propTypes = {
  payment: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    code: PropTypes.string,
    description: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    message: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    icon: PropTypes.string,
    disabled: PropTypes.bool,
    savable: PropTypes.bool,
    default: PropTypes.bool,
  }).isRequired,
};
