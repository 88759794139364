import React, { useContext } from 'react';
import SubmitButton from './SubmitButton';
import CheckoutPageContext from '../../../context/CheckoutPageContext';
import Loader from '../../Common/Loader/Loader';

export default function SubmitButtonWrapper() {
  const {
    checkoutPageState, loading,
  } = useContext(CheckoutPageContext);

  const { klarnaSession = {}, orderTotals = {} } = checkoutPageState;
  const paymentInfo = {
    paymentType: 'klarna',
    authorizationToken: klarnaSession?.sessionId ?? '',
    orderId: orderTotals?.orderId ?? '',
  };

  if (loading || !checkoutPageState.submitButton) {
    return (
      <div className="order-submit-wrapper" data-testid="order-submit-loader">
        <Loader classList="loader-order-contact" />
      </div>
    );
  }

  return (
    <div className="order-submit-wrapper" data-testid="order-submit-wrapper">
      <SubmitButton
        submitButtonData={checkoutPageState.submitButton}
        paymentInfo={paymentInfo}
        isKlarnaReady={checkoutPageState?.klarnaReady}
      />
    </div>
  );
}
