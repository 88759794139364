import assert from 'assert';
import Transport from './Transport';
import $window from '../../../../../tools/window';
/**
 * A transport to the console
 * @private
 * @module clientSideLogger.ConsoleTransport
 */

export default class ConsoleTransport extends Transport {
  /**
   * name of the transport
   */
  name = 'console';

  /**
   * if it is enabled or not
   */

  enabled = true;

  /**
   * the current console
   */
  #console = $window?.console;

  /**
   * debug any arugments to the console
   * @param {...mixed}
   */

  debug(...args) {
    if (this.enabled && this.#console) {
      this.#console.info(...args);
    }
    return this;
  }

  /**
   * info any arugments to the console
   * @param {...mixed}
   */

  info(...args) {
    if (this.enabled && this.#console) {
      this.#console.info(...args);
    }
    return this;
  }

  /**
   * log any arugments to the console
   * @param {...mixed}
   */

  log(...args) {
    if (this.enabled && this.#console) {
      this.#console.log(...args);
    }
    return this;
  }

  /**
   * warn any arugments to the console
   * @param {...mixed}
   */

  warn(...args) {
    if (this.enabled && this.#console) {
      this.#console.warn(...args);
    }
    return this;
  }

  /**
   * error any arugments to the console
   * @param {...mixed}
   */

  error(...args) {
    if (this.enabled && this.#console) {
      this.#console.error(...args);
    }
    return this;
  }

  /**
   * Set the console instance
   * @param {Object} console - The console instance
   * @returns {Object} the controls module
   */

  setConsole(console) {
    assert(typeof console === 'object' && console !== null, 'must be an object and not null');
    assert(typeof console.log === 'function', 'must be a function');
    assert(typeof console.warn === 'function', 'must be a function');
    assert(typeof console.error === 'function', 'must be a function');
    this.#console = console;
    return this;
  }

  /**
   * Unset the console instance
   * @returns {Object} the controls module
   */

  unsetConsole() {
    this.#console = undefined;
    return this;
  }
}
