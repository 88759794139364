import React, {
  useEffect,
  useCallback,
  useRef,
  useState,
  useMemo,
  useContext,
} from 'react';
import { useLazyQuery } from '@apollo/client';
import useLog from '../../useLog/useLog';
import SameDayDeliveryModal from './SameDayDeliveryModal';
import SameDayDeliveryUnavailableMessage from './SameDayDeliveryUnavailableMessage';
import { FIND_COURIER_AVAILABILITY, SAME_DAY_DELIVERY_QUERY } from '../../../gql/sameDayDelivery.gql';
import useGeolocator from './useGeolocator';
import CheckoutPageContext from '../../../context/CheckoutPageContext';

// NOTE: these values will come from the BFF at some point
export const STANDARD_SHIPPING_EXTERNAL_ID_US = '1';
export const STANDARD_SHIPPING_EXTERNAL_ID_UK = '11';

export default function SameDayDelivery() {
  const {
    checkoutPageState,
  } = useContext(CheckoutPageContext);
  const logger = useLog('checkout.sameDayDelivery');
  const listeningForOpenOnInitEvents = useRef(false);
  const [openModalOnNextEffect, setOpenModalOnNextEffect] = useState(false);
  const [state, setState] = useState({
    geolocation: null,
    availability: null,
    addressChanged: false,
  });
  const geolocator = useGeolocator();

  // use a lazy query so we're not fetching TMNT values until we're ready to be initialized
  const [getSameDayDeliveryData, { loading, error, data }] = useLazyQuery(SAME_DAY_DELIVERY_QUERY, {
    fetchPolicy: 'no-cache',
    context: { batch: true },
    ssr: false,
    onCompleted: (res) => {
      logger.debug('onCompleted', res);
      // tell components we've initialized successfully
      window.dispatchEvent(new CustomEvent('sameDayDelivery:initSuccess', { detail: { data: res } }));
    },
    onError: (err) => {
      logger.error('onError', err);
      // tell components we've failed to initialize successfully
      window.dispatchEvent(new CustomEvent('sameDayDelivery:initFailure', { detail: { error: err } }));
    },
  });

  const [findCourierAvailability] = useLazyQuery(FIND_COURIER_AVAILABILITY, {
    fetchPolicy: 'no-cache',
    context: { batch: true },
    ssr: false,
  });

  const initialized = useMemo(() => !!data && !loading, [data, loading]);

  // set state so an effect can open up the modal
  const handleOpenOnInitSuccess = useCallback((event) => {
    logger.debug('handleOpenOnInitSuccess', event);
    setOpenModalOnNextEffect(true);
    // clean up listeners to prevent weird issues
    window.dispatchEvent(new CustomEvent('sameDayDelivery:cleanUpOpenOnInit'));
  }, [logger]);

  // remove on init success handler since we failed
  const handleOpenOnInitFailure = useCallback((event) => {
    logger.debug('handleOpenOnInitFailure', event);
    // clean listeners to prevent weird issues
    window.dispatchEvent(new CustomEvent('sameDayDelivery:cleanUpOpenOnInit'));
  }, [logger]);

  // when we receive an init event with detail openOnInit set to true, we will
  // setup some listeners for when this service has initialized and the modal
  // has mounted before opening up the modal
  const listenForInitEventsToOpenModal = useCallback(() => {
    logger.debug('listenForInitEventsToOpenModal');
    if (!listeningForOpenOnInitEvents.current) {
      listeningForOpenOnInitEvents.current = true;
      window.addEventListener('sameDayDelivery:initSuccess', handleOpenOnInitSuccess);
      window.addEventListener('sameDayDelivery:initFailure', handleOpenOnInitFailure);
    }
  }, [logger, listeningForOpenOnInitEvents, handleOpenOnInitSuccess, handleOpenOnInitFailure]);

  // handle when we are supposed to init and if we should open the modal after init
  const handleSameDayDeliveryInit = useCallback(async (event) => {
    logger.debug('handleSameDayDeliveryInit', event);
    logger.debug('initialized?', initialized);
    if (!initialized) {
      if (event?.detail?.openOnInit) {
        listenForInitEventsToOpenModal();
      }
      await getSameDayDeliveryData();
    }
  }, [logger, getSameDayDeliveryData, listenForInitEventsToOpenModal, initialized]);

  // clean up listeners we attached when requested to open the modal after init
  const handleCleanUpOpenOnInit = useCallback((event) => {
    logger.debug('handleCleanUpOpenOnInit', event);
    window.removeEventListener('sameDayDelivery:initSuccess', handleOpenOnInitSuccess);
    window.removeEventListener('sameDayDelivery:initFailure', handleOpenOnInitFailure);
    listeningForOpenOnInitEvents.current = false;
  }, [logger, handleOpenOnInitSuccess, handleOpenOnInitFailure, listeningForOpenOnInitEvents]);

  // listen for open events and check if we're initialized or not
  const handleOpen = useCallback((event) => {
    logger.debug('handleOpen', event);
    if (!initialized) {
      window.dispatchEvent(
        new CustomEvent('sameDayDelivery:init', { detail: { openOnInit: true } }),
      );
    } else {
      window.dispatchEvent(new CustomEvent('sameDayDelivery:openModal'));
    }
  }, [logger, initialized]);

  const dispatchAvailability = useCallback((detail) => {
    logger.debug('dispatchAvailability', detail);
    // TODO: get rid of this
    window.dispatchEvent(
      new CustomEvent('sameDayDelivery:availability', {
        detail: { ...detail, fromMFE: true },
      }),
    );
  }, [logger]);

  const searchAvailability = useCallback(async (args) => {
    const {
      geolocation,
      addressChanged = false,
      shippingAddress,
      fromModal,
    } = args;
    const { postalCode } = geolocation;

    logger.debug('searchAvailability', geolocation);
    try {
      // NOTE: this logic exists in shipping speed as well,
      // it can be factored out at some point in time
      const availableShippingMethods = checkoutPageState?.shippingSpeed?.map((item) => {
        // get availability from the item or the current state
        let { availability = null } = item.shipModeType === 'SameDayDelivery' ? state : item;

        if (availability) {
          availability = {
            cartID: availability.cartID,
            courierEligible: availability.courierEligible,
            storeNo: availability.storeNo,
            shipDate: availability.shipDate,
            orderByTime: availability.orderByTime,
            timeZone: availability.timeZone,
            reason: availability.reason,
            hasOption: availability.hasOption,
            postalCode: availability.postalCode,
            isValid: availability.isValid,
            isSelected: availability.isSelected,
            isEnabled: availability.isEnabled,
            isDisabled: availability.isDisabled,
            shouldBeSelected: availability.shouldBeSelected,
            eligibilityConfirmed: availability.eligibilityConfirmed,
            ineligibilityConfirmed: availability.ineligibilityConfirmed,
            usingExpressLanePayment: availability.usingExpressLanePayment,
            displayChangeLink: availability.displayChangeLink,
            sddAvailabilityMessage: availability.sddAvailabilityMessage,
            geolocation: availability.geolocation && {
              country: availability.geolocation.country,
              countryAbbr: availability.geolocation.countryAbbr,
              latlng: availability.geolocation.latlng,
              postalCode: availability.geolocation.postalCode,
              postalTown: availability.geolocation.postalTown,
              state: availability.geolocation.state,
              stateAbbr: availability.geolocation.stateAbbr,
            },
          };
        }

        return ({
          amount: item.amount,
          amountFmt: item.amountFmt,
          description: item.description,
          estimatedDeliveryDate: item.estimatedDeliveryDate,
          estimatedDeliveryDateFmt: item.estimatedDeliveryDateFmt,
          externalId: item.externalId,
          isSelected: item.isSelected,
          isEnabled: item.isEnabled,
          shipModeId: item.shipModeId,
          shipModeType: item.shipModeType,
          availability,
        });
      });

      const skus = checkoutPageState?.bagItems?.items
        ?.map((item) => item?.item?.productContent?.shortSku);

      const { cartState, cartPayment, orderTotals } = checkoutPageState;

      const response = await findCourierAvailability({
        variables: {
          geolocation,
          addressChanged,
          availableShippingMethods,
          skus,
          cartState: {
            isShippingAddressComplete: cartState?.isShippingAddressComplete,
            isBillingAddressComplete: cartState?.isBillingAddressComplete,
            isShippingMethodSet: cartState?.isShippingMethodSet,
            isPaymentComplete: cartState?.isPaymentComplete,
          },
          cartPayment: {
            paypalPayment: cartPayment?.paypalPayment,
          },
          shippingAddress,
          orderId: orderTotals?.orderId,
        },
      });
      if (response?.error) {
        throw response.error;
      }

      const { courierAvailability } = response?.data?.sameDayDelivery;
      if (courierAvailability?.ineligibilityConfirmed && fromModal) {
        throw new Error('sdd-unavailable');
      }

      // NOTE: used for phoenix SDD module so other phoenix modules can use SDD
      window.dispatchEvent(new CustomEvent('sameDayDelivery:setAvailability', {
        detail: {
          geolocation,
          availability: courierAvailability,
          fromMFE: true,
          postalCode,
        },
      }));
      // NOTE: SDD Modal is awaiting searchComplete events
      window.dispatchEvent(new CustomEvent('sameDayDelivery:searchComplete'));
    } catch (e) {
      logger.error(e);
      window.dispatchEvent(new CustomEvent('sameDayDelivery:searchComplete', {
        detail: {
          error: e,
        },
      }));
    } finally {
      // NOTE: clearing the address changed here may hide the element
      setState((currentState) => ({
        ...currentState,
        addressChanged: false,
      }));
    }
  }, [logger, findCourierAvailability, state, checkoutPageState]);

  const handleFindMe = useCallback(async (event) => {
    logger.debug('handleFindMe', event);
    window.dispatchEvent(new CustomEvent('sameDayDelivery:searchingFindMe'));
    try {
      const geolocation = await geolocator.findMe();
      // TODO may need to pass in shippingAddress here
      await searchAvailability({ geolocation, fromModal: !!event.detail?.fromModal });
    } catch (e) {
      logger.error(e);
      window.dispatchEvent(
        new CustomEvent('sameDayDelivery:searchComplete', { detail: { error: e } }),
      );
    }
  }, [logger, geolocator, searchAvailability]);

  const handleSetAvailability = useCallback((event) => {
    logger.debug('handleSetAvailability', event);
    if (!(event.detail.postalCode && event.detail.availability)) {
      return;
    }
    // NOTE: we dispatch the availability first before updating the state
    // so things don't get out of sync, we may want to see if we can just
    // use a ref rather than a state variable here, since the state isn't
    // used to render anything
    dispatchAvailability(event.detail);
    setState((currentState) => ({
      ...currentState,
      ...event?.detail,
    }));

    const { availability } = event.detail;
    const usingSDD = checkoutPageState.shipMethod?.shipModeType === 'SameDayDelivery';

    if (
      availability?.sddAvailabilityMessage || (availability?.ineligibilityConfirmed && usingSDD)
    ) {
      const shippingSpeed = checkoutPageState?.shippingSpeed?.find((candidate) => (
        candidate.externalId === STANDARD_SHIPPING_EXTERNAL_ID_US
        || candidate.externalId === STANDARD_SHIPPING_EXTERNAL_ID_UK
      ));
      window.dispatchEvent(new CustomEvent('shippingSpeed:change', {
        detail: {
          shipModeId: shippingSpeed?.shipModeId,
          fromPhoenix: false,
        },
      }));
    }
  }, [
    logger,
    dispatchAvailability,
    checkoutPageState?.shippingSpeed,
    checkoutPageState?.shipMethod?.shipModeType,
  ]);

  const handleCheckAvailability = useCallback((event) => {
    logger.debug('handleCheckAvailability', event);
    window.dispatchEvent(new CustomEvent('sameDayDelivery:open'));
  }, [logger]);

  const handleChangePostalCode = useCallback((event) => {
    logger.debug('handleChangePostalCode', event);
    window.dispatchEvent(new CustomEvent('sameDayDelivery:open'));
  }, [logger]);

  const handleShippingAddressChanged = useCallback(async (event) => {
    try {
      logger.debug('handleShippingAddressChanged', event);
      // send searchAvailability when current postal code and address postal code are not equal;
      setState((currentState) => ({
        ...currentState,
        addressChanged: true,
      }));
      const homeDelivery = event?.detail?.addresses?.homeDelivery;
      if (checkoutPageState?.shippingAddress?.postalCode !== homeDelivery.postalCode) {
        const geolocation = {
          country: null,
          countryAbbr: homeDelivery?.country,
          latlng: null,
          postalCode: homeDelivery?.postalCode,
          postalTown: homeDelivery?.city,
          state: null,
          // TODO: double check this line for UK
          stateAbbr: homeDelivery?.state || homeDelivery?.province,
        };
        const shippingAddress = {
          addressType: homeDelivery?.addressType,
          country: homeDelivery?.country,
          state: homeDelivery?.state,
          province: homeDelivery?.province,
          addressId: homeDelivery?.addressId,
          firstName: homeDelivery?.firstName,
          lastName: homeDelivery?.lastName,
          city: homeDelivery?.city,
          postalCode: homeDelivery?.postalCode,
        };
        await searchAvailability({
          geolocation, addressChanged: true, shippingAddress, fromModal: false,
        });
      }
    } catch (e) {
      logger.error(e.toString());
    }
  }, [logger, searchAvailability, checkoutPageState]);

  const handleAvailabilityRequest = useCallback(async (event) => {
    logger.debug('handleAvailabilityRequest', event);
    if (state.geolocation && state?.geolocation?.postalCode && !state.availability) {
      logger.debug('we have a postal code and no availability');
      await searchAvailability(state);
    } else {
      dispatchAvailability(state);
    }
  }, [logger, state, searchAvailability, dispatchAvailability]);

  const handleSearch = useCallback(async (event) => {
    logger.debug('handleSearch', event);
    try {
      const geolocation = await geolocator.getAddressGeolocation(event?.detail?.postalCode);
      window.dispatchEvent(new CustomEvent('sameDayDelivery:searchingValue'));
      // NOTE: may need to pass shippingSpeed
      await searchAvailability({ geolocation, fromModal: !!event.detail?.fromModal });
    } catch (e) {
      logger.error(e.toString());
      window.dispatchEvent(
        new CustomEvent('sameDayDelivery:searchComplete', { detail: { error: e } }),
      );
    }
  }, [logger, searchAvailability, geolocator]);

  // listen for a other component to tell us we can init
  useEffect(() => {
    window.addEventListener('sameDayDelivery:init', handleSameDayDeliveryInit);
    window.addEventListener('sameDayDelivery:cleanUpOpenOnInit', handleCleanUpOpenOnInit);
    window.addEventListener('sameDayDelivery:open', handleOpen);
    window.addEventListener('sameDayDelivery:findMe', handleFindMe);
    window.addEventListener('sameDayDelivery:setAvailability', handleSetAvailability);
    window.addEventListener('sameDayDelivery:checkAvailability', handleCheckAvailability);
    window.addEventListener('sameDayDelivery:changePostalCode', handleChangePostalCode);
    window.addEventListener('sameDayDelivery:availabilityRequest', handleAvailabilityRequest);
    window.addEventListener('sameDayDelivery:search', handleSearch);
    window.addEventListener('sameDayDelivery:shippingAddressChanged', handleShippingAddressChanged);

    logger.debug('mounted');

    return () => {
      window.removeEventListener('sameDayDelivery:init', handleSameDayDeliveryInit);
      window.removeEventListener('sameDayDelivery:cleanUpOpenOnInit', handleCleanUpOpenOnInit);
      window.removeEventListener('sameDayDelivery:open', handleOpen);
      window.removeEventListener('sameDayDelivery:findMe', handleFindMe);
      window.removeEventListener('sameDayDelivery:setAvailability', handleSetAvailability);
      window.removeEventListener('sameDayDelivery:checkAvailability', handleCheckAvailability);
      window.removeEventListener('sameDayDelivery:changePostalCode', handleChangePostalCode);
      window.removeEventListener('sameDayDelivery:availabilityRequest', handleAvailabilityRequest);
      window.removeEventListener('sameDayDelivery:search', handleSearch);
      window.removeEventListener('sameDayDelivery:shippingAddressChanged', handleShippingAddressChanged);
    };
  }, [
    logger,
    handleSameDayDeliveryInit,
    handleCleanUpOpenOnInit,
    handleOpen,
    handleFindMe,
    handleSetAvailability,
    handleCheckAvailability,
    handleChangePostalCode,
    handleAvailabilityRequest,
    handleSearch,
    handleShippingAddressChanged,
  ]);

  // let components know we are currently loading / intializing
  useEffect(() => {
    logger.debug('effect: loading changed to', loading);
    if (loading) {
      window.dispatchEvent(new CustomEvent('sameDayDelivery:loading'));
    }
  }, [logger, loading]);

  // trigger the modal to open when openModalOnNextEffect is true
  useEffect(() => {
    logger.debug('open modal on next effect', openModalOnNextEffect);
    if (openModalOnNextEffect) {
      setOpenModalOnNextEffect(false);
      window.dispatchEvent(new CustomEvent('sameDayDelivery:openModal'));
    }
  }, [logger, openModalOnNextEffect]);

  // Logic from the BFF to determine when this message should be displayed.

  const unavailableMessage = useMemo(() => (state?.availability?.sddAvailabilityMessage ? (
    <SameDayDeliveryUnavailableMessage
      isDisplayed={state?.availability?.sddAvailabilityMessage}
      textFor={checkoutPageState.textFor}
    />
  ) : null), [checkoutPageState, state]);

  // nothing to render yet
  if (!data || error || loading) {
    return unavailableMessage;
  }

  const { textFor } = data;

  // since we're initialized we can render the modal and await for open modal events
  return (
    <>
      <SameDayDeliveryModal textFor={textFor} />
      {unavailableMessage}
    </>
  );
}

SameDayDelivery.defaultValues = {};

SameDayDelivery.propTypes = {};
