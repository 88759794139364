import Transport from './Transport';
import $window from '../../../../../tools/window';

/**
 * A transport to Quantum Metrics API
 * @private
 * @module clientSideLogger.QuantumMetricTransport
 */

export default class QuantumMetricTransport extends Transport {
  /**
   * name of the transport
   */

  name = 'quantum';

  /**
   * if it is enabled or not
   */

  enabled = true;

  /**
   * the Quantum Metrics API
   */
  #QuantumMetricAPI = $window?.QuantumMetricAPI;

  get quantumMetricAPI() {
    if (!this.#QuantumMetricAPI) {
      this.#QuantumMetricAPI = $window?.QuantumMetricAPI;
    }
    return this.#QuantumMetricAPI;
  }

  /**
   * warn to Quantum Metrics
   * @override
   * @param {...any} args thigns to warn
   * @returns {QuantumMetricTransport}
   */

  warn(...args) {
    if (this.enabled) {
      this.#send(args);
    }
    return this;
  }

  /**
   * error to Quantum Metrics
   * @override
   * @param {...any} args things to error
   * @returns {QuantumMetricTransport}
   */

  error(...args) {
    if (this.enabled) {
      this.#send(args);
    }
    return this;
  }

  /**
   * send whatever arguments.
   *
   * e.g. "checkout.deliveryOptions.sameDayDelivery || .same-day-delivery || some message ||
   * { \"some\": \"data\" }"
   *
   * @param {any[]} data - a list of any data
   */

  #send(data) {
    // NOTE: this has been inferred from looking at examples
    if (this.quantumMetricAPI) {
      this.quantumMetricAPI.sendEvent(400, 0, this.constructor.formatDataIntoMessage(data));
    }
  }

  /**
   * convert the arguments into a message.
   * Extracts name and message from Error instances.
   * Converts other objects into JSON
   * Takes functions and return's there names
   * All other data is passed.
   * All data is joined by " || "
   * @param {any[]} args - any data
   * @return {String} the message
   */

  static formatDataIntoMessage(args) {
    const message = [];
    args.forEach((arg) => {
      const type = typeof arg;
      if (type === 'object' && arg !== null) {
        if (arg instanceof Error) {
          message.push(arg.name, arg.message);
        } else {
          message.push(JSON.stringify(arg));
        }
      } else if (type !== 'function') {
        message.push(arg);
      }
    });
    return message.join(' || ');
  }

  /**
   * Set the Qunatum Metrics API instance
   * @param {Object} QuantumMetricAPI - the quantum metrics API
   * @returns {Object} the controls module
   */

  setQuantumMetricAPI(QuantumMetricAPI) {
    this.#QuantumMetricAPI = QuantumMetricAPI;
    return this;
  }

  /**
   * Unset the Quantum Metrics API
   * @returns {Object} the controls module
   */

  unsetQuantumMetricAPI() {
    this.#QuantumMetricAPI = undefined;
    return this;
  }
}
