function ANFImage(imageName) {
  let myEncode = true;
  let myImageName = '';
  let myIsSizePresetEnabled = true;
  let myIsAIMPolicies = true;
  let myWidth = 800;
  let myPolicy = '';
  let myExtensionType = '';

  Object.defineProperties(this, {
    /**
     * @memberOf ANFImage
     * @property {Object[]} args - list of query string object's name, value pairs
     * @property {String} args[].name - the name of the query string let
     * @property {String} args[].value - the value of the query string let
     */
    args: {
      enumerable: true,
      value: [],
      writable: false,
    },
    /**
     * @memberOf ANFImage
     * @property {Boolean} encode - flag to encode the arguments in the query string
     */
    encode: {
      enumerable: true,
      get: function getterEncode() {
        return myEncode;
      },
      set: function setterEncode(encode) {
        if (typeof (encode) !== 'boolean') {
          throw new Error('Supplied encode is not valid');
        }
        myEncode = encode;
      },
    },
    /**
     * @memberor ANFImage
     * @property {String} imageName - the name of the image
     */
    imageName: {
      enumerable: true,
      get: function getterImageName() {
        return myImageName;
      },
      set: function setterImageName(newMyImageName) {
        myImageName = newMyImageName;
      },
    },
    /**
     * @memberOf ANFImage
     * @property {String[]} presets - list presets to be applied to the image
     */
    presets: {
      enumerable: true,
      value: [],
      writable: false,
    },
    /**
     * @memberor ANFImage
     * @property {Boolean} isSizePresetEnabled - the flag if we get the size specific url
     */
    isSizePresetEnabled: {
      enumerable: true,
      get: function getterisSizePresetEnabled() {
        return myIsSizePresetEnabled;
      },
      set: function setterisSizePresetEnabled(newMyIsSizePresetEnabled) {
        if (typeof (newMyIsSizePresetEnabled) !== 'boolean') {
          throw new TypeError('Supplied isSizePresetEnabled is invalid');
        }
        myIsSizePresetEnabled = newMyIsSizePresetEnabled;
      },
    },
    policy: {
      enumerable: true,
      get: function getterPolicy() {
        return myPolicy;
      },
      set: function setterPolicy(newPolicy) {
        if (!newPolicy) {
          throw new TypeError('Supplied policy is invalid');
        }
        myPolicy = newPolicy;
      },
    },
    extensionType: {
      enumerable: true,
      get: function getterExtensionType() {
        return myExtensionType;
      },
      set: function setterExtensionType(newExtensionType) {
        if (Object.values(ANFImage.extensionTypes).indexOf(newExtensionType) === -1) {
          throw new TypeError('Supplied extensionType is invalid');
        }
        myExtensionType = newExtensionType;
      },
    },
    /**
     * @memberOf ANFImage
     * @property {Number} width - the width of the container that the image will occupy
     */
    width: {
      enumerable: true,
      get: function getterWidth() {
        return myWidth;
      },
      set: function setterWidth(width) {
        if (typeof (width) !== 'number') {
          throw new Error('Supplied width is not valid');
        }
        myWidth = width;
      },
    },
    hasAIMPolicies: {
      enumerable: true,
      get: function getterAIMPolicy() {
        return myIsAIMPolicies;
      },
      set: function setterAIMPolicy(hasAIMPolicies) {
        if (typeof (hasAIMPolicies) !== 'boolean') {
          throw new Error('Supplied hasAIMPolicies is not valid');
        }
        myIsAIMPolicies = hasAIMPolicies;
      },
    },
  });

  this.imageName = imageName || '';

  return this;
}

Object.defineProperties(ANFImage, {
  sizePolicies: {
    value: Object.freeze({
      EXTRA_LARGE: 'product-extra-large',
      LARGE: 'product-large',
      MEDIUM: 'product-medium',
      SMALL: 'product-small',
    }),
  },
  /**
   * @description Lookup map of the acceptable image size presets
   * @static
   * @memberOf ANFImage
   * @readonly
   * @type {Readonly<{SMALL: string, MEDIUM: string, LARGE: string, EXTRA_LARGE: string}>}
   */
  sizePresets: {
    value: Object.freeze({
      EXTRA_LARGE: 'product-extra-large-anf',
      LARGE: 'product-large-anf',
      MEDIUM: 'product-medium-anf',
      SMALL: 'product-small-anf',
    }),
  },
  /**
   * @description look up map fo the size range for acceptable image size presets
   * @static
   * @memberOf ANFImage
   * @readonly
   */
  sizePresetsRange: {
    value: Object.freeze({
      EXTRA_LARGE: Object.freeze({
        min: 801,
        max: null,
      }),
      LARGE: Object.freeze({
        min: 401,
        max: 800,
      }),
      MEDIUM: Object.freeze({
        min: 201,
        max: 400,
      }),
      SMALL: Object.freeze({
        min: 0,
        max: 200,
      }),
    }),
  },

  extensionTypes: {
    value: Object.freeze({
      PNG: 'png',
      JPEG: 'jpg',
      GIF: 'gif',
    }),
  },
});

/**
 * @private
 * @param {ANFImage} anfImage - the instance of ANFImage
 * @param {String} sizePreset - the size preset to use
 * @param {String} sizePolicy - the size policy to use
 * @returns {string} the constructed url
 */
function buildUrl(anfImage, sizePreset, sizePolicy) {
  const search = [];
  let paramifedArgs;
  const presets = Array.from(anfImage.presets);
  let presetString;
  const { extensionType } = anfImage;
  const url = [
    'https://img.abercrombie.com/is/image/anf/',
    anfImage.imageName,
  ];
  const args = Array.from(anfImage.args);
  const hasAIMPolicies = anfImage?.hasAIMPolicies;
  // TODO: remove this when we eliminate need for switch around policies vs presets
  if (hasAIMPolicies) {
    if (sizePolicy) {
      args.push({
        name: 'policy',
        value: sizePolicy,
      });
    }
    if (extensionType) {
      url.push(`.${extensionType}`);
    }
  } else {
    if (sizePreset) {
      presets.push(sizePreset);
    }

    if (extensionType) {
      presets.push(extensionType.toUpperCase());
    }

    if (presets.length) {
      presetString = presets
        .map((preset) => `$${preset}$`)
        .join('');

      search.push(presetString);
    }
  }

  if (args.length) {
    paramifedArgs = args.map((arg) => Object.values(arg).join('=')).join('&');

    if (!anfImage.encode) {
      paramifedArgs = decodeURIComponent(paramifedArgs);
    }

    search.push(paramifedArgs);
  }

  if (search.length) {
    url.push('?');
    url.push(search.join('&'));
  }
  return url.join('');
}

/**
 * Get the function to build the url by a specific size
 * @param {String} size - the size amount of space available for the image
 * @param {String} policy - the size policy for the image
 * @returns {function(): string} function to build the url based off the size
 */
function getUrlBySizeFN(size, policy) {
  return function getUrlBySize() {
    return buildUrl(this, size, policy);
  };
}

Object.defineProperties(ANFImage.prototype, {
  /**
   * @memberOf ANFImage
   * @readonly
   * @property {string} extraLargeUrl - the extra large url for the image
   */
  extraLargeUrl: {
    get: getUrlBySizeFN(ANFImage.sizePresets.EXTRA_LARGE, ANFImage.sizePolicies.EXTRA_LARGE),
  },
  /**
   * @memberOf ANFImage
   * @readonly
   * @property {string} largeUrl - the large url for the image
   */
  largeUrl: {
    get: getUrlBySizeFN(ANFImage.sizePresets.LARGE, ANFImage.sizePolicies.LARGE),
  },
  /**
   * @memberOf ANFImage
   * @readonly
   * @property {string} mediumUrl - the medium url for the image
   */
  mediumUrl: {
    get: getUrlBySizeFN(ANFImage.sizePresets.MEDIUM, ANFImage.sizePolicies.MEDIUM),
  },
  /**
   * @memberOf ANFImage
   * @readonly
   * @property {string} smallUrl - the small url for the image
   */
  smallUrl: {
    get: getUrlBySizeFN(ANFImage.sizePresets.SMALL, ANFImage.sizePolicies.SMALL),
  },
  /**
   * @memberOf ANFImage
   * @readonly
   * @property {string} url - the url for the image
   */
  url: {
    get: function computedPropertyUrl() {
      return this.getUrl();
    },
  },
  presetName: {
    get: function computedPropertyPresetName() {
      return this.getPresetName();
    },
  },
});

/**
 * Set the encode flag
 * @memberOf ANFImage
 * @param {Boolean} encode - value to set encode to
 * @returns {ANFImage} ANFImage instance
 */
ANFImage.prototype.setEncode = function setEncode(encode) {
  this.encode = encode;
  return this;
};

/**
 * Set the isSizePresetEnabled flag
 * @memberOf ANFImage
 * @param {Boolean} isSizePresetEnabled - value to set isSizePresetEnabled to
 * @returns {ANFImage} ANFImage instance
 */
ANFImage.prototype.setSizePresetEnabled = function setSizePresetEnabled(isSizePresetEnabled) {
  this.isSizePresetEnabled = isSizePresetEnabled;
  return this;
};

/**
 * Set the width used to determine which preset to use when isSizePresetEnabled is true
 * @memberOf ANFImage
 * @param {Number} width - the new width
 * @returns {ANFImage} ANFImage instance
 */
ANFImage.prototype.setWidth = function setWidth(width) {
  this.width = width;
  return this;
};

/**
 * Set the hasAIMPolicy based on StoreAttribute
 * @memberOf ANFImage
 * @param {Number} width - the new hasAIMPolicies
 * @returns {ANFImage} ANFImage instance
 */
ANFImage.prototype.setAIMPolicies = function setAIMPolicies(isAIMPolicy) {
  this.hasAIMPolicies = isAIMPolicy;
  return this;
};

/**
 * Set the name of the image
 * @memberOf ANFImage
 * @param {String} imageName - the name of the image
 * @returns {ANFImage} ANFImage instance
 */
ANFImage.prototype.setImageName = function setImageName(imageName) {
  this.imageName = imageName;
  return this;
};

/**
 * adds a new preset to the list of presets
 * @memberOf ANFImage
 * @param {String} preset - name of the preset to add
 * @returns {ANFImage} ANFImage instance
 */
ANFImage.prototype.addPreset = function addPreset(preset) {
  const myPreset = typeof preset === 'string' ? preset.trim() : preset;

  if (myPreset && this.presets.indexOf(myPreset) === -1) {
    this.presets.push(preset);
  }
  return this;
};

ANFImage.prototype.setExtensionType = function setExtensionType(extensionType) {
  this.extensionType = extensionType;
  return this;
};

/**
 * removes a preset from the list of presets
 * @memberOf ANFImage
 * @param {String} preset - name of the preset to be removed
 * @returns {ANFImage} ANFImage instance
 */
ANFImage.prototype.removePreset = function removePreset(preset) {
  const newPresets = Array.from(this.presets);
  const self = this;

  // Need to celar out the presets this way b/c presets is not a
  // writeable propety, there for doing `this.presets = ...`
  // will throw an error
  while (this.presets.length > 0) {
    this.presets.pop();
  }

  newPresets
    .filter((p) => p !== preset)
    .forEach((p) => { self.addPreset(p); });

  return this;
};

/**
 * Add an argument to the list of arguments to use when building the image's query string
 * @memberOf ANFImage
 * @param {String} name - the name of the query string parameter
 * @param {String} value - the value of the query string parameter
 * @returns {ANFImage} ANFImage instance
 */
ANFImage.prototype.addArg = function addArg(name, value) {
  this.args.push({ name, value });

  return this;
};

/**
 * Remove all instances of the argument with the supplied name from the list
 * of arguments to use when building the image's query string
 * @memberOf ANFImage
 * @param {String} name - name of the query string parameter(s) to be removed
 * @returns {ANFImage} ANFImage instance
 */
ANFImage.prototype.removeArg = function removeArg(name) {
  const newArgs = Array.from(this.args);
  const self = this;

  // Need to celar out the Args this way b/c Args is not a
  // writeable propety, there for doing `this.Args = ...`
  // will throw an error
  while (this.args.length > 0) {
    this.args.pop();
  }

  newArgs
    .filter((arg) => arg.name !== name)
    .forEach((arg) => { self.addArg(arg.name, arg.value); });

  return this;
};

/**
 * Clone the current instance of ANFImage
 * @memberOf ANFImage
 * @returns {ANFImage} Clone of ANFImage instance
 */
ANFImage.prototype.clone = function clone() {
  const newImage = new ANFImage(this.imageName);

  this.args.forEach((arg) => {
    newImage.addArg(arg.name, arg.value);
  });

  this.presets.forEach((preset) => { newImage.addPreset(preset); });

  newImage
    .setEncode(this.encode)
    .setSizePresetEnabled(this.isSizePresetEnabled)
    .setWidth(this.width);

  return newImage;
};

/**
 * Retrieves the image's url based off the ANFImage instance
 * @memberOf ANFImage
 * @returns {string} url
 */
ANFImage.prototype.getUrl = function getUrl() {
  let url;

  if (this.isSizePresetEnabled) {
    if (this.width >= ANFImage.sizePresetsRange.SMALL.min
      && this.width <= ANFImage.sizePresetsRange.SMALL.max) {
      url = this.getSmallUrl();
    } else if (this.width >= ANFImage.sizePresetsRange.MEDIUM.min
      && this.width <= ANFImage.sizePresetsRange.MEDIUM.max) {
      url = this.getMediumUrl();
    } else if (this.width >= ANFImage.sizePresetsRange.LARGE.min
      && this.width <= ANFImage.sizePresetsRange.LARGE.max) {
      url = this.getLargeUrl();
    } else {
      url = this.getExtraLargeUrl();
    }
  } else {
    url = buildUrl(this, null, this.policy);
  }

  return url;
};

ANFImage.prototype.getPresetName = function getPresetName() {
  let presetName;

  if (this.isSizePresetEnabled) {
    if (this.width >= ANFImage.sizePresetsRange.SMALL.min
      && this.width <= ANFImage.sizePresetsRange.SMALL.max) {
      presetName = ANFImage.sizePresets.SMALL;
    } else if (this.width >= ANFImage.sizePresetsRange.MEDIUM.min
      && this.width <= ANFImage.sizePresetsRange.MEDIUM.max) {
      presetName = ANFImage.sizePresets.MEDIUM;
    } else if (this.width >= ANFImage.sizePresetsRange.LARGE.min
      && this.width <= ANFImage.sizePresetsRange.LARGE.max) {
      presetName = ANFImage.sizePresets.LARGE;
    } else {
      presetName = ANFImage.sizePresets.EXTRA_LARGE;
    }
  }

  return presetName;
};

/**
 * Retrieves the image's small url based off the ANFImage instance
 * @memberOf ANFImage
 * @returns {string} url
 */
ANFImage.prototype.getSmallUrl = getUrlBySizeFN(
  ANFImage.sizePresets.SMALL,
  ANFImage.sizePolicies.SMALL,
);

/**
 * Retrieves the image's medium url based off the ANFImage instance
 * @memberOf ANFImage
 * @returns {string} url
 */
ANFImage.prototype.getMediumUrl = getUrlBySizeFN(
  ANFImage.sizePresets.MEDIUM,
  ANFImage.sizePolicies.MEDIUM,
);

/**
 * Retrieves the image's large url based off the ANFImage instance
 * @memberOf ANFImage
 * @returns {string} url
 */
ANFImage.prototype.getLargeUrl = getUrlBySizeFN(
  ANFImage.sizePresets.LARGE,
  ANFImage.sizePolicies.LARGE,
);

/**
 * Retrieves the image's extra large url based off the ANFImage instance
 * @memberOf ANFImage
 * @returns {string} url
 */
ANFImage.prototype.getExtraLargeUrl = getUrlBySizeFN(
  ANFImage.sizePresets.EXTRA_LARGE,
  ANFImage.sizePolicies.EXTRA_LARGE,
);

/**
 * Get a new instance of AMFImage. Takes same parameters as the constructor
 * @static
 * @memberOf ANFImage
 * @returns {ANFImage} ANFImage instance
 */
ANFImage.instance = function instance(...args) {
  const ANFImageInstance = Object.create(ANFImage.prototype);
  ANFImage.apply(ANFImageInstance, args);
  return ANFImageInstance;
};

module.exports = ANFImage;
