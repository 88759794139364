import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  ErrorMessage, Button, ProductImage,
} from 'anf-core-react';
import decodeHTMLEntities from '../../../../tools/decodeHtml';
import ANFImage from '../../../../tools/ANFimage';
import { useIsXS } from '../../../../hooks/useBreakPoint';

export default function SoldOutRepudiation({
  tmntData,
  checkoutUrl,
}) {
  const isXS = useIsXS();
  const [anfObj, setAnfObj] = useState(null);
  const [aimPolicies, setAimPolicies] = useState(null);
  let imageSize = 'l';
  if (isXS) {
    imageSize = 'm';
  }
  useEffect(() => {
    const ANFobj = window?.ANF?.models?.cart?.inventoryRepudiation;
    const AIMpolicies = window?.ANF?.params?.storeAttributes?.hasAIMPolicies;
    setAnfObj(ANFobj);
    setAimPolicies(AIMpolicies);
  }, []);
  const productList = anfObj?.soldOutRepudiatedItems;
  const errorMessage = tmntData?.soldOutTMNTValue?.value || 'The following item just sold out and was removed from your bag.';
  const continueToCheckoutTMNTValue = tmntData?.continueToCheckoutTMNTValue?.value;
  const itemList = productList ? productList?.map((product) => {
    const imageId = product?.imageId;
    // eslint-disable-next-line
    const imageSrc = ANFImage?.instance()?.setAIMPolicies(aimPolicies)?.setImageName(imageId + '_prod1')?.url;
    const originalPrice = product?.listPriceFmt ? decodeHTMLEntities(product?.listPriceFmt) : '';
    const discountPrice = product?.offerPriceFmt ? decodeHTMLEntities(product?.offerPriceFmt) : '';
    return (
      <div className="product-template" key={product.productId}>
        <ProductImage
          alt={product?.productName}
          data-itemid={product.productId}
          size={imageSize}
          src={imageSrc}
          classList="js-pc-edit-button"
        />
        <div className="product-content">
          <div className="product-description">
            <div className="product-name">
              <span className="screen-reader-text">{product?.productName}</span>
              <span aria-hidden="true">{product?.productName}</span>
            </div>
            <div className="product-detail">
              <p data-property="BAG_SKU" className="product-sku">
                {tmntData?.skuText?.value}
                {': '}
                {product?.sku}
              </p>
              <p className="product-size-color">
                {product?.size}
                {(product?.size && product?.colorName) ? ', ' : ''}
                {product?.colorName}
              </p>
            </div>
            <span className="product-price">
              <span className="screen-reader-text">
                Was
                {originalPrice}
                , now
                {discountPrice}
              </span>
              <span className="product-price-text-wrapper" aria-hidden="true">
                <span
                  className={(product?.listPrice > product?.offerPrice) ? 'product-price-text with-discount' : 'product-price-text'}
                >
                  {originalPrice}
                </span>
                {(product?.listPrice > product?.offerPrice) && (
                  <span className="product-price-text discount">
                    {discountPrice}
                  </span>
                )}
              </span>
            </span>
          </div>
        </div>

      </div>
    );
  }) : <div data-display="false" />;
  return (
    <div>
      {anfObj && (
        <div className="sold-out-inventory-repudiation-section">
          <ErrorMessage id="error-message-story-id">
            {errorMessage}
          </ErrorMessage>
          <div className="sold-out-wrap">
            {itemList}
          </div>
          <div className="actions actions--sold-out">
            <ul>
              <li>
                <Button
                  isFullWidth
                  href={checkoutUrl}
                  classList="continue-to-checkout"
                  variant="primary"
                >
                  <span data-state="initial" data-hidden="false">
                    {continueToCheckoutTMNTValue}
                  </span>
                </Button>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

SoldOutRepudiation.defaultProps = {
  tmntData: {},
  checkoutUrl: '',
};

SoldOutRepudiation.propTypes = {
  tmntData: PropTypes.instanceOf(Object),
  checkoutUrl: PropTypes.string,
};
