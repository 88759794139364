import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { RecommendationsContext, useUserConsent } from '@xp-utilities/web';

const propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    bagItems: PropTypes.shape({
      items: PropTypes.shape([]),
    }),
  }).isRequired,
};
export default function MiniBagRecommendationsContextProvider({ id, data, children }) {
  const { getUserConsent } = useUserConsent();
  const items = data?.bagItems?.items;
  const miniBagItems = items?.map((item) => ({ kic: item.item.image?.imageId }));
  const ctx = useMemo(
    () => ({
      id,
      variables: {
        hasUserConsent: getUserConsent(),
        products: miniBagItems || [],
      },
    }),
    [id, getUserConsent, miniBagItems],
  );

  return (
    <RecommendationsContext.Provider value={ctx}>
      { children }
    </RecommendationsContext.Provider>
  );
}

MiniBagRecommendationsContextProvider.propTypes = propTypes;
