import { gql } from '@apollo/client';

const ESPOTS_QUERY = gql`
  query FetchMarketingSpots($espotId: String!, $source: String) {
    eSpots {
      eSpot: get(espotId: $espotId, source: $source) {
        name
        content
        source
      }
    }
  }
`;

export default ESPOTS_QUERY;
