import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  StoreCard,
  Button,
  InputField,
  Icon,
  SingleEntryForm,
  Disclosure,
  HorizontalSeparator,
  ErrorMessage,
  Toggle,
  ToggleBlock,
} from 'anf-core-react';
import GoogleMapReact from 'google-map-react';
import Tmnt from '../../Tmnt/Tmnt';
import MapMarker from '../MapMarker/MapMarker';
import InfoWindow from '../InfoWindow/InfoWindow';
import { useIsS } from '../../../hooks/useBreakPoint';
import {
  formatHours,
  formatDayOfWeek,
  getPudosLocationImage,
} from '../../../tools/pickupHelper';

const textProp = PropTypes.shape({
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

const remoteLocationProp = PropTypes.shape({
  address: PropTypes.shape({
    city: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
  }).isRequired,
  distance: PropTypes.string.isRequired,
  geoPosition: PropTypes.shape({
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
  }).isRequired,
  id: PropTypes.string.isRequired,
  mapAndHoursUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  operatingHours: PropTypes.arrayOf(
    PropTypes.shape({
      dayOfWeek: PropTypes.string.isRequired,
      closeHours: PropTypes.string.isRequired,
      openHours: PropTypes.string.isRequired,
      sortSeq: PropTypes.number.isRequired,
    }),
  ).isRequired,
  providerType: PropTypes.string.isRequired,
  sortingId: PropTypes.number.isRequired,
});

const defaultProps = {
  defaultSearchValue: undefined,
  defaultMapCenter: undefined,
  pudosResults: undefined,
  selectedPudosId: undefined,
  selectedStore: undefined,
  text: undefined,
  brand: undefined,
};

const propTypes = {
  defaultSearchValue: PropTypes.string,
  defaultMapCenter: PropTypes.shape({
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
  }),
  isErrorState: PropTypes.bool.isRequired,
  isGeolocationBlocked: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isInputSearching: PropTypes.bool.isRequired,
  isNearMeSearching: PropTypes.bool.isRequired,
  areStoresFound: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onInputSearch: PropTypes.func.isRequired,
  onNearMeSearch: PropTypes.func.isRequired,
  onSetSelectedStore: PropTypes.func.isRequired,
  pudosResults: PropTypes.shape({
    pudosLocations: PropTypes.arrayOf(remoteLocationProp),
  }),
  selectedPudosId: PropTypes.string,
  selectedStore: remoteLocationProp,
  text: PropTypes.shape({
    cityStateZip: textProp,
    findingStoresNear: textProp,
    list: textProp,
    loadMore: textProp,
    locationsNear: textProp,
    locationServicesBlocked: textProp,
    map: textProp,
    mapAndHours: textProp,
    nearMe: textProp,
    noStoresFound: textProp,
    or: textProp,
    pickupUnavailable: textProp,
    pleaseTryDifferentLocation: textProp,
    pudosLegal: textProp,
    searching: textProp,
    selectPickupLocation: textProp,
    selectStore: textProp,
    selectedStore: textProp,
    storeHours: textProp,
    you: textProp,
    closeButton: textProp,
  }),
  brand: PropTypes.string,
};

const resultsViewTypes = {
  list: 'LIST',
  map: 'MAP',
};

export default function PudosModal({
  defaultSearchValue,
  defaultMapCenter,
  isErrorState,
  isGeolocationBlocked,
  isOpen,
  isInputSearching,
  isNearMeSearching,
  areStoresFound,
  onClose,
  onInputSearch,
  onNearMeSearch,
  onSetSelectedStore,
  pudosResults,
  selectedPudosId,
  selectedStore,
  text,
  brand,
}) {
  const [searchInputValue, setSearchInputValue] = useState(defaultSearchValue);
  const [searchValueUI, setSearchValueUI] = useState(defaultSearchValue);
  const [storeLimit] = useState(10);
  const [showAllStores, setShowAllStores] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(isOpen);
  const [isInfoWindowOpen, setIsInfoWindowOpen] = useState(false);
  const [storeList, setStoreList] = useState(undefined);
  const [isListDisclosureExpanded, setIsListDisclosureExpanded] = useState(false);
  const [isMapDisclosureExpanded, setIsMapDisclosureExpanded] = useState(false);
  const [selectedListDisclosure, setSelectedListDisclosure] = useState(undefined);
  const [selectedMapDisclosure, setSelectedMapDisclosure] = useState(undefined);
  const [resultsView, setResultsView] = useState(resultsViewTypes.list);
  const [infoWindowLocation, setInfoWindowLocation] = useState(null);
  const isMobileView = useIsS();
  const mapApiKey = 'AIzaSyBf_zev-wYZfFPXOW7bygNJwYHAthecVwc';

  const inputSearch = useCallback(async (e) => {
    e.preventDefault();
    setIsInfoWindowOpen(false);
    setSearchValueUI(searchInputValue);
    setStoreList(undefined);
    setShowAllStores(false);
    await onInputSearch(searchInputValue);
  }, [onInputSearch, searchInputValue]);

  const nearMeSearch = useCallback(async (e) => {
    e.preventDefault();
    setIsInfoWindowOpen(false);
    setSearchValueUI(undefined);
    setStoreList(undefined);
    setShowAllStores(false);
    await onNearMeSearch();
  }, [onNearMeSearch]);

  const handleInputChange = useCallback((inputValue) => {
    setSearchInputValue(inputValue);
  }, []);

  const handleSetSelectedStore = useCallback((e, location) => {
    e.preventDefault();
    onSetSelectedStore(location);
  }, [onSetSelectedStore]);

  const handleMarkerClick = (e, location) => {
    e.preventDefault();
    if (e.target?.className?.includes('checkout-map-marker')) {
      setInfoWindowLocation(location);
      setIsInfoWindowOpen(true);
    }
  };

  const handleInfoWindowClose = useCallback(() => {
    setIsInfoWindowOpen(false);
  }, [setIsInfoWindowOpen]);

  const loadMore = useCallback(() => {
    setStoreList(pudosResults?.pudosLocations);
    setShowAllStores(true);
  }, [pudosResults]);

  const sortStoreList = useCallback((physicalStores) => {
    if (physicalStores && (selectedStore?.id || selectedPudosId)) {
      // Find the index of the store with the matching id
      let storeIndex;
      if (selectedPudosId) {
        storeIndex = physicalStores.findIndex(
          (location) => location.id === selectedPudosId,
        );
      } else {
        storeIndex = physicalStores.findIndex(
          (location) => location.id === selectedStore?.id,
        );
      }

      if (storeIndex >= 0) {
        return [
          physicalStores[storeIndex],
          ...physicalStores.filter((_, index) => index !== storeIndex),
        ];
      }
    }
    return physicalStores;
  }, [selectedStore, selectedPudosId]);

  const matchStoreListLengthToStoreLimit = useCallback((pudosLocations, limit) => {
    if (!pudosLocations) {
      return pudosLocations;
    }

    return sortStoreList(pudosLocations).slice(0, limit);
  }, [sortStoreList]);

  const toggleListDisclosure = useCallback((storeId) => {
    if (isListDisclosureExpanded) {
      setIsListDisclosureExpanded(false);
      setSelectedListDisclosure(undefined);
    } else {
      setIsListDisclosureExpanded(true);
      setSelectedListDisclosure(storeId);
    }
  }, [isListDisclosureExpanded]);

  const toggleMapDisclosure = useCallback((storeId) => {
    if (isMapDisclosureExpanded) {
      setIsMapDisclosureExpanded(false);
      setSelectedMapDisclosure(undefined);
    } else {
      setIsMapDisclosureExpanded(true);
      setSelectedMapDisclosure(storeId);
    }
  }, [isMapDisclosureExpanded]);

  function createStoreCardChildNode(location) {
    const isSelected = (location.id === selectedStore?.id) || (location.id === selectedPudosId);
    const buttonValue = isSelected ? text?.selectedStore.value : text?.selectStore.value;

    return (
      <>
        <div className="first-row store-name">
          <Icon
            icon="location-anf"
            labelText="location"
            size="s"
          />
          <p className="pudos-location-name">{location.name}</p>
        </div>

        <p className="secondary-row">{location.address.street}</p>
        <p className="secondary-row">{`${location.address.city}, ${location.address.country}`}</p>

        <div className="secondary-row pudos-disclosure-wrapper">
          <Disclosure
            id="pudos-list-store-hours-disclosure"
            label={text.storeHours.value}
            onClick={() => toggleListDisclosure(location.id)}
            isExpanded={isListDisclosureExpanded && (selectedListDisclosure === location.id)}
          >
            <table className="store-hours" role="presentation">
              <tbody>
                {location.operatingHours.map((hoursObj, i) => {
                  const key = `${location.name}-hours-${i}`;
                  return (
                    <tr key={key}>
                      <td className="day-of-week">{`${formatDayOfWeek(hoursObj.dayOfWeek)} `}</td>
                      <td>{`${formatHours(hoursObj.openHours)} - ${formatHours(hoursObj.closeHours)}`}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Disclosure>
        </div>
        <Button
          classList="select-store-button"
          variant={isSelected ? 'secondary' : 'tertiary-dark'}
          labelText={buttonValue}
          onClick={(e) => handleSetSelectedStore(e, location)}
          isDisabled={isSelected}
          isFullWidth
        >
          <div>{buttonValue}</div>
        </Button>
      </>
    );
  }

  function createStoreCards(pudosLocations) {
    return sortStoreList(pudosLocations).map((location, i) => {
      const key = `store-${i}`;
      return (
        <li key={key}>
          <div className="pudos-store-card-wrapper">
            <StoreCard
              brand={brand}
              storeName={location.name}
              storeMap={(
                <a href={location.mapAndHoursUrl} target="_blank" rel="noreferrer">
                  {text?.mapAndHours.value}
                </a>
              )}
              firstLogo={<img src={getPudosLocationImage(location)} alt={location.providerType} />}
            >
              {createStoreCardChildNode(location)}
            </StoreCard>
          </div>
        </li>
      );
    });
  }

  const infoWindowUI = useMemo(() => {
    if (!infoWindowLocation) {
      return null;
    }
    const isSelected = (infoWindowLocation.id === selectedStore?.id)
      || (infoWindowLocation.id === selectedPudosId);
    const buttonValue = isSelected ? text?.selectedStore.value : text?.selectStore.value;

    return (
      <InfoWindow
        location={infoWindowLocation}
        isSelected={isSelected}
        isDisclosureExpanded={isMapDisclosureExpanded}
        selectedDisclosure={selectedMapDisclosure}
        buttonValue={buttonValue}
        brand={brand}
        text={text}
        onInfoWindowClose={handleInfoWindowClose}
        onSetSelectedStore={handleSetSelectedStore}
        onToggleMapDisclosure={toggleMapDisclosure}
      />
    );
  }, [
    text,
    selectedStore,
    selectedPudosId,
    brand,
    infoWindowLocation,
    isMapDisclosureExpanded,
    selectedMapDisclosure,
    handleSetSelectedStore,
    toggleMapDisclosure,
    handleInfoWindowClose,
  ]);

  const searchInput = useMemo(() => {
    if (text) {
      return (
        <div className="pudos-search-input">
          <SingleEntryForm>
            <InputField
              autoComplete="off"
              id="pudos-modal-pickup-point-search"
              label={text?.cityStateZip ? text.cityStateZip.value : ''}
              isInvalid={false}
              onChange={(e) => handleInputChange(e.target.value)}
              isRequired
              value={searchInputValue}
            >
              {/* change disabled state to look at single value */}
              <Button
                classList="pudos-search-button"
                variant="secondary"
                onClick={(e) => inputSearch(e)}
                isDisabled={isInputSearching || isNearMeSearching}
                isProcessing={isInputSearching}
              >
                {!isInputSearching && (
                  <Icon
                    icon="search"
                    labelText="search"
                  />
                )}
              </Button>
            </InputField>
          </SingleEntryForm>
        </div>
      );
    }
    return (<div />);
  }, [text, searchInputValue, isInputSearching, isNearMeSearching, handleInputChange, inputSearch]);

  const nearMe = useMemo(() => {
    if (text) {
      return (
        <div className="nearme-wrapper">
          <Button
            classList="nearme-button"
            variant="secondary"
            isFullWidth
            onClick={(e) => nearMeSearch(e)}
            isDisabled={isInputSearching || isNearMeSearching || isGeolocationBlocked}
            isProcessing={isNearMeSearching}
          >
            {!isNearMeSearching && (
              <Icon
                icon="location-anf"
                labelText="location"
              />
            )}
            <div
              className="nearme-button-text"
              data-testid="nearme-button-text"
            >
              {text?.nearMe.value}
            </div>
          </Button>
        </div>
      );
    }
    return (<div />);
  }, [text, isGeolocationBlocked, isInputSearching, isNearMeSearching, nearMeSearch]);

  const orSeparator = useMemo(() => {
    if (text) {
      return (
        <div className="horizontal-separator-wrapper">
          <HorizontalSeparator>
            <span>{text?.or.value}</span>
          </HorizontalSeparator>
        </div>
      );
    }
    return (<div />);
  }, [text]);

  const toggleBlock = useMemo(() => {
    if (text) {
      return (
        <Toggle
          border="round"
          classList="toggle-block"
          labelText="Pudos Results Option"
        >
          <ToggleBlock
            id="pudos-list"
            name="list"
            value="list"
            isChecked={resultsView === resultsViewTypes.list}
            onChange={() => setResultsView(resultsViewTypes.list)}
          >
            {text?.list?.value}
          </ToggleBlock>
          <ToggleBlock
            id="pudos-map"
            isChecked={resultsView === resultsViewTypes.map}
            name="map"
            value="map"
            onChange={() => setResultsView(resultsViewTypes.map)}
          >
            {text?.map?.value}
          </ToggleBlock>
        </Toggle>
      );
    }
    return (<div />);
  }, [text, resultsView, setResultsView]);

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (pudosResults?.pudosLocations) {
      setStoreList(matchStoreListLengthToStoreLimit(pudosResults.pudosLocations, storeLimit));
    }
  }, [pudosResults, storeLimit, matchStoreListLengthToStoreLimit]);

  useEffect(() => {
    if (selectedPudosId && pudosResults?.pudosLocations) {
      setStoreList(matchStoreListLengthToStoreLimit(pudosResults.pudosLocations, storeLimit));
    }
  }, [selectedPudosId, pudosResults, storeLimit, matchStoreListLengthToStoreLimit]);

  useEffect(() => {
    if (defaultSearchValue) {
      setSearchInputValue(defaultSearchValue);
      setSearchValueUI(defaultSearchValue);
    }
  }, [defaultSearchValue]);

  useEffect(() => {
    if (selectedStore) {
      setInfoWindowLocation(selectedStore);
      setIsInfoWindowOpen(true);
    }
    if (selectedPudosId && pudosResults?.pudosLocations) {
      const { pudosLocations } = pudosResults;
      const storeIndex = pudosLocations.findIndex(
        (location) => location.id === selectedPudosId,
      );
      setInfoWindowLocation(pudosLocations[storeIndex]);
      setIsInfoWindowOpen(true);
    }
  }, [selectedStore, selectedPudosId, pudosResults]);

  useEffect(() => {
    if (!isOpen) {
      setShowAllStores(false);
    }
  }, [showAllStores, isOpen]);

  if (!text) return null;

  return (
    <Modal
      id="pudos-modal"
      isOpen={isModalOpen}
      closeButtonLabel={text?.closeButton.value}
      heading={(
        <h2 className="h2" data-property={text?.selectPickupLocation.key} data-testid="pudos-modal-header">
          <Tmnt tmnt={text?.selectPickupLocation} />
        </h2>
      )}
      onClose={onClose}
    >
      {isMobileView ? (
        <div className="pudos-search-wrapper" data-testid="storecard-container">
          {searchInput}
          {orSeparator}
          {nearMe}
        </div>
      ) : (
        <div className="vertical-separator pudos-search-wrapper" data-testid="storecard-container">
          {nearMe}
          {searchInput}
        </div>
      )}

      <hr className="pudos-modal-hr" />

      <div>
        {(isInputSearching || isNearMeSearching) && (
          <div className="pudos-view-controls">
            <div className="locations-near-text">
              {`${text?.findingStoresNear.value} ${searchValueUI || text?.you.value}`}
            </div>
            <div className="pudos-toggle">{toggleBlock}</div>
          </div>
        )}

        {(pudosResults?.pudosLocations?.length > 0
          && storeList?.length > 0
          && !isInputSearching
          && !isNearMeSearching
        ) && (
          <>
            <div className="pudos-view-controls">
              <div className="locations-near-text">
                {`${pudosResults.pudosLocations.length} ${text?.locationsNear.value} ${searchValueUI || text?.you.value}`}
              </div>
              <div className="pudos-toggle">{toggleBlock}</div>
            </div>

            {(resultsView === resultsViewTypes.list) && (
              <ul className="pudos-stores-list" data-testid="pudos-list">
                {createStoreCards(storeList)}
              </ul>
            )}

            <div className="pudos-map-container" data-testid="pudos-map">
              {(resultsView === resultsViewTypes.map && defaultMapCenter?.latitude) && (
                <div style={{
                  position: 'relative',
                  maxHeight: '470px',
                  height: '100vh',
                  width: '100%',
                }}
                >
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: mapApiKey }}
                    defaultCenter={{
                      lat: defaultMapCenter?.latitude,
                      lng: defaultMapCenter?.longitude,
                    }}
                    defaultZoom={11}
                    yesIWantToUseGoogleMapApiInternals
                    options={{
                      zoomControl: true, // zoom UI allowed inside map
                      mapTypeControl: false, // allow other map - ex: Satellite
                      scaleControl: false, // UI element that shows you: [____] = 1mi
                      streetViewControl: false, // allow street view
                      rotateControl: false, // allows 45 deg imagery
                      fullscreenControl: false, // allows map to go full screen
                      clickableIcons: false, // built in onClick dialog pop-ups
                    }}
                  >
                    {storeList.map((location) => (
                      <MapMarker
                        key={location.id}
                        imgSrc={getPudosLocationImage(location)}
                        label={`${location.id}-map-marker`}
                        lat={location.geoPosition.latitude}
                        lng={location.geoPosition.longitude}
                        onMarkerClick={(e) => handleMarkerClick(e, location)}
                      />
                    ))}
                  </GoogleMapReact>
                </div>
              )}
              {(isInfoWindowOpen && infoWindowUI) && infoWindowUI}
            </div>
          </>
        )}

        {(!areStoresFound && !isErrorState && !isInputSearching && !isNearMeSearching) && (
          <p className="no-stores" data-testid="no-stores-found">
            {`${text?.noStoresFound.value} ${searchValueUI}. ${text?.pleaseTryDifferentLocation.value}`}
          </p>
        )}

        {(isGeolocationBlocked) && (
          <div className="pudos-error" data-testid="geolocation-blocked">
            <ErrorMessage id="pudos-modal-error-message">
              <Tmnt tmnt={text?.locationServicesBlocked} />
            </ErrorMessage>
          </div>
        )}

        {(isErrorState && text?.noStoresFound) && (
          <div className="pudos-error">
            <ErrorMessage id="pudos-modal-error-message">
              <Tmnt tmnt={text?.noStoresFound} />
            </ErrorMessage>
          </div>
        )}
      </div>

      <hr className="pudos-modal-hr" />
      {(!showAllStores
        && storeList?.length > 0
        && pudosResults?.pudosLocations.length > storeLimit
        && resultsView === resultsViewTypes.list
      ) && (
      <>
        <Button
          classList="link-button load-more-button"
          labelText={text?.loadMore.value}
          onClick={() => loadMore()}
          isDisabled={false}
          isFullWidth
        >
          <div>{text?.loadMore.value}</div>
        </Button>
        <hr className="pudos-modal-hr" />
      </>
      )}

      <p className="pudos-legal" data-property={text?.pudosLegal.key}>
        <small>{text?.pudosLegal.value}</small>
      </p>

    </Modal>
  );
}
PudosModal.defaultProps = defaultProps;
PudosModal.propTypes = propTypes;
