import {
  gql,
} from '@apollo/client';

// MUTATION: order submit mutation
const ORDER_SUBMIT_MUTATION = gql`
  mutation SubmitOrder($orderSubmitInput: OrderSubmitInput!, $paymentInfo: PaymentInfoInput!) {
    orderSubmit(
      orderSubmitInput: $orderSubmitInput
      paymentInfo: $paymentInfo
    ) {
      responseCode
      orderId
      paymentURL
      paymentStatusURL
      statusCode
      success
      statusMessages {
        code
        key
        message
      }
    }
  }
`;

export default ORDER_SUBMIT_MUTATION;
