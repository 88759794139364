import React, { useContext } from 'react';
import {
  InputField,
} from 'anf-core-react';
import PropTypes, { number } from 'prop-types';
import BagContext from '../../../../context/BagContext';

export default function GiftBoxMessages({
  bagItem,
  bagItemIndex,
}) {
  const {
    bag, setBag,
  } = useContext(BagContext);
  const tmntData = bag?.textFor;

  const addGiftBoxMessage = (index, value) => {
    const { items } = bag.bagItems;
    items[bagItemIndex].giftBox.giftBoxMessage[index] = value;
    setBag((previousState) => ({
      ...previousState,
      bagItems: {
        ...previousState.bagItems,
        items,
      },
    }));
  };

  return (
    <div id="gift-messages" className="gift-messages" aria-hidden="false">
      <h3 data-prop="CHK_GIFT_MESSAGE_TITLE">{tmntData?.chkGiftMessageTitleTMNTValue?.value}</h3>
      <p data-prop="CHK_GIFT_MESSAGE_LEGEND">{tmntData?.chkGiftMessageLegendTMNTValue?.value}</p>
      <fieldset className="field-group js-field-group giftbox">
        <legend className="screen-reader-text">Gift Box Message, Line 1 - 5</legend>
        {[...Array(5).keys()].map((giftMessageLineId, index) => (
          <InputField
            id={`${bagItem.item.productContent.name}-message-line-${giftMessageLineId}`}
            key={`${bagItem.item.productContent.name}-message-line-${giftMessageLineId}`}
            type="text"
            defaultValue={bagItem.giftBox.giftBoxMessage[index]}
            value={bagItem.giftBox.giftBoxMessage[index]}
            onChange={(event) => addGiftBoxMessage(index, event.target.value)}
            maxLength={40}
            name={`Line ${index + 1}`}
            label={`Line ${index + 1}`}
            autoComplete=""
          />
        ))}
      </fieldset>
    </div>
  );
}

GiftBoxMessages.defaultProps = {
  bagItem: {},
  bagItemIndex: null,
};

GiftBoxMessages.propTypes = {
  bagItem: PropTypes.instanceOf(Object),
  bagItemIndex: number,
};
