import React, { useContext } from 'react';
import OrderConfirmationContext from '../../../context/OrderConfirmationContext';
import Footer from '../../Common/Footer/Footer';

export default function OrderConfirmationFooter() {
  const { orderConfirmationData } = useContext(OrderConfirmationContext);
  const { textFor, footer } = orderConfirmationData;
  return (
    <Footer
      tmntData={textFor}
      orderId={footer?.orderId}
      phoneNumberByCountry={footer?.phoneNumberByCountry}
      phoneNumberByLanguage={footer?.phoneNumberByLanguage}
    />
  );
}
