import React from 'react';
import PropTypes from 'prop-types';
import { RecommendationSliders } from '@xp-utilities/web';
import OrderConfirmationRecommendationsContextProvider from '../../context/Recommendations/OrderConfirmationRecommendationsContextProvider';
import handleOnProductClick from './helpers';

const propTypes = { orderData: PropTypes.shape({}).isRequired };

export default function OrderConfirmationRecommendations({ orderData }) {
  const recommendationsProps = {
    id: 'order-confirmation-1',
    placements: [
      'purchase_complete_page.rr1',
      'purchase_complete_page.rr2',
      'purchase_complete_page.rr3',
      'purchase_complete_page.rr4',
      'purchase_complete_page.rr5',
      'purchase_complete_page.rr6',
    ],
    slidesToShow: 3,
  };

  return (
    <OrderConfirmationRecommendationsContextProvider
      id={recommendationsProps.id}
      data={orderData}
    >
      <div
        id={recommendationsProps.id}
        data-testid={recommendationsProps.id}
      >
        <RecommendationSliders
          placements={recommendationsProps.placements}
          slidesToShow={recommendationsProps.slidesToShow}
          onProductClick={handleOnProductClick}
        />
      </div>
    </OrderConfirmationRecommendationsContextProvider>
  );
}

OrderConfirmationRecommendations.propTypes = propTypes;
