import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ErrorMessage, Button,
} from 'anf-core-react';
import useLog from '../../useLog/useLog';
import BagList from '../BagList/BagList';

export default function Repudiation({
  productList,
  errorMessage,
  errorDesc,
  tmntData,
  hasCheckoutButton,
  itemsToRemove,
  continueInCurrency,
  checkoutUrl,
  onRemoveItems,
  showRemoveItemsButton,
}) {
  const [processing, setProcessing] = useState(false);
  const logger = useLog('shoppingBagRepudiation.root');
  const removeItemsTMNTValue = productList?.length > 1
    ? tmntData?.removeItemsTMNTValue?.value : tmntData?.removeItemTMNTValue?.value;

  const continueToCheckoutTMNTValue = tmntData?.continueToCheckoutTMNTValue?.value;
  const removeItemsHandler = useCallback(async () => {
    setProcessing(true);
    try {
      await onRemoveItems({
        variables: {
          orderItemId: itemsToRemove,
        },
      });
    } catch (err) {
      logger.error(`ERR: REMOVE_REPUDIATED_MUTATION: ${JSON.stringify(err)}`);
    }
  }, [onRemoveItems, itemsToRemove, logger]);

  return (
    <div className="repudiation-section">
      <div className="bag-repudiation-message">
        <ErrorMessage id="error-message-story-id">
          {errorMessage}
        </ErrorMessage>
        <div className="bag-repudiation-error-desc">
          <p>
            {errorDesc}
          </p>
        </div>
      </div>
      <div className="bag-repudiation-error">
        <div className="repudiation-error">
          <div className="repudiation-itemlist-wrap">
            <BagList
              bagItems={productList}
              tmntData={tmntData}
              showShopSimilarItems={false}
            />
          </div>
          <div className="error-button-container" data-error-type="error" data-item-to-remove={itemsToRemove} data-testid="error-container">
            {showRemoveItemsButton && (
            <Button
              isFullWidth
              variant="secondary"
              classList="remove-items"
              onClick={removeItemsHandler}
              isProcessing={processing}
            >
              <span data-state="initial" data-hidden="false">
                {removeItemsTMNTValue}
              </span>
            </Button>
            )}
            {hasCheckoutButton ? (
              <Button
                isFullWidth
                variant="secondary"
                classList="continue-in-currency"
              >
                <span data-state="initial" data-hidden="false">
                  {continueInCurrency}
                </span>
              </Button>
            ) : ''}

            {checkoutUrl ? (
              <Button
                isFullWidth
                href={checkoutUrl}
                classList="continue-to-checkout"
                variant="primary"
              >
                <span data-state="initial" data-hidden="false">
                  {continueToCheckoutTMNTValue}
                </span>
              </Button>
            ) : ''}
          </div>
        </div>
      </div>
    </div>
  );
}

Repudiation.defaultProps = {
  productList: {},
  tmntData: {},
  errorDesc: '',
  checkoutUrl: '',
  continueInCurrency: '',
  onRemoveItems: () => {},
};

Repudiation.propTypes = {
  productList: PropTypes.instanceOf(Object),
  errorMessage: PropTypes.string.isRequired,
  errorDesc: PropTypes.string,
  hasCheckoutButton: PropTypes.bool.isRequired,
  tmntData: PropTypes.instanceOf(Object),
  itemsToRemove: PropTypes.string.isRequired,
  checkoutUrl: PropTypes.string,
  continueInCurrency: PropTypes.string,
  onRemoveItems: PropTypes.func,
  showRemoveItemsButton: PropTypes.bool.isRequired,

};
