import React, { useEffect, useState } from 'react';
import { Button, PaymentBlock } from 'anf-core-react';
import PropTypes from 'prop-types';
import MaskedGiftCard from '../../../Common/GiftCards/MaskedGiftCard';
import decodeHTMLEntities from '../../../../tools/decodeHtml';
import Tmnt from '../../../Tmnt/Tmnt';

export default function GiftCardApplied({
  giftCard,
  removeGiftCard,
  isRemoving,
  tmntData,
}) {
  const [isProcessingRemoveGiftCard, setIsProcessingRemoveGiftCard] = useState(false);

  const removeGiftCardHandler = () => {
    setIsProcessingRemoveGiftCard(true);
    removeGiftCard(giftCard?.id);
  };

  useEffect(() => {
    if (isProcessingRemoveGiftCard) {
      setIsProcessingRemoveGiftCard(isRemoving);
    }
  }, [isRemoving, isProcessingRemoveGiftCard]);

  return (
    <PaymentBlock
      paymentIcon={(
        <span className="payment-icon" role="img" data-icon-name="gift-card" aria-label="Gift Card" />
      )}
    >
      <div className="gift-card-wrapper">
        <div className="gift-card-details">
          <div className="gift-card-number">
            <MaskedGiftCard giftcard={giftCard} />
          </div>
          <div className="gift-card-amount-used">
            <p>
              {`${tmntData?.giftCardAmountUsed?.value || ''} ${decodeHTMLEntities(giftCard?.amountFmt)}`}
            </p>
          </div>
        </div>
        <div className="gift-card-action">
          <Button
            isDisabled={isProcessingRemoveGiftCard}
            isProcessing={isProcessingRemoveGiftCard}
            variant="borderless"
            classList="ds-override"
            labelText="Remove"
            onClick={removeGiftCardHandler}
          >
            <Tmnt tmnt={tmntData?.giftCardRemove} />
          </Button>
        </div>
      </div>
    </PaymentBlock>
  );
}

GiftCardApplied.defaultProps = {
  giftCard: {
    id: '',
    maskedNumber: '',
    amountFmt: '',
  },
  isRemoving: false,
  tmntData: {},
};

GiftCardApplied.propTypes = {
  giftCard: PropTypes.shape({
    id: PropTypes.string,
    maskedNumber: PropTypes.string,
    amountFmt: PropTypes.string,
  }),
  removeGiftCard: PropTypes.func.isRequired,
  isRemoving: PropTypes.bool,
  tmntData: PropTypes.instanceOf(Object),
};
