import React from 'react';
import PropTypes from 'prop-types';

export default function Tmnt({ tmnt, isHtml, attributes }) {
  return (
    isHtml ? (
      // eslint-disable-next-line
      <span data-property={tmnt?.key} dangerouslySetInnerHTML={{ __html: tmnt?.value }} {...attributes} />
    ) : (
      <span data-property={tmnt?.key} {...attributes}>{tmnt?.value}</span>
    )
  );
}

Tmnt.defaultProps = {
  isHtml: false,
  attributes: {},
};

Tmnt.propTypes = {
  tmnt: PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  isHtml: PropTypes.bool,
  attributes: PropTypes.instanceOf(Object),
};
