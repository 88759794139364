import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  HorizontalSeparator,
  ErrorMessage,
  Icon,
  InputField,
  SingleEntryForm,
  VerticalSeparator,
} from 'anf-core-react';
import useLog from '../../useLog/useLog';
import { determinePostalCodeValidity } from './Utils';

import window from '../../../tools/window';

if (typeof window.GeolocationPositionError === 'undefined') {
  window.GeolocationPositionError = class MockGeolocationPositionError extends Error { };
}

export default function SameDayDeliveryModal({ textFor }) {
  const logger = useLog('checkout.sameDayDeliveryModal');
  const [modalOpened, setModalOpened] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [invalidPostalCode, setInvalidPostalCode] = useState(false);
  const [invalidCountryPostalCode, setCountryInvalidPostalCode] = useState(false);
  const [searchingValue, setSearchingValue] = useState(false);
  const [searchingFindMe, setSearchingFindMe] = useState(false);
  const [otherError, setOtherError] = useState(false);
  const [geolocationBlocked, setGeolocationBlocked] = useState(false);
  const [showMessage, setShowMessage] = useState(true);
  const [unavailable, setUnavailable] = useState(false);
  const handleOpenModal = useCallback((event) => {
    event.preventDefault();
    setSearchValue('');
    setModalOpened(true);
  }, []);

  const handleModalOpen = useCallback(() => {
    window.dispatchEvent(new CustomEvent('sameDayDelivery:openedModal'));
  }, []);

  const closeModal = useCallback(() => {
    logger.debug('close modal');
    setModalOpened(false);
    window.dispatchEvent(new CustomEvent('sameDayDelivery:closedModal'));
  }, [logger]);

  const clearErrors = useCallback(() => {
    logger.debug('clear errors');
    setOtherError(false);
    setInvalidPostalCode(false);
    setCountryInvalidPostalCode(false);
    setGeolocationBlocked(false);
    setShowMessage(true);
    setUnavailable(false);
  }, [logger]);

  const handleModalClose = useCallback((event) => {
    logger.debug('handle modal close', event);
    setSearchValue('');
    clearErrors();
    closeModal();
  }, [logger, clearErrors, closeModal]);

  const handleClickFindMe = useCallback((event) => {
    event.preventDefault();
    logger.debug('find me');
    clearErrors();
    window.dispatchEvent(
      new CustomEvent(
        'sameDayDelivery:findMe',
        { detail: { fromModal: true } },
      ),
    );
  }, [logger, clearErrors]);

  const handleSearchValueChange = useCallback((event) => {
    logger.debug('handle search value', event?.target?.value);
    setSearchValue(event?.target?.value);
  }, [logger]);

  const handleSearchValueBlur = useCallback((event) => {
    logger.debug('search value blur', event);
    setSearchValue(String(event?.target?.value).trim());
  }, [logger]);

  const handleClickSearch = useCallback((event) => {
    logger.debug('click search', event);
    clearErrors();
    const postalCodeValidityDeterminiation = determinePostalCodeValidity(searchValue);
    if (postalCodeValidityDeterminiation.valid) {
      window.dispatchEvent(
        new CustomEvent(
          'sameDayDelivery:search',
          { detail: { postalCode: searchValue, fromModal: true } },
        ),
      );
    } else {
      setInvalidPostalCode(true);
    }
  }, [logger, searchValue, clearErrors]);

  const handleSearchingFindMe = useCallback((event) => {
    logger.debug('searching find me', event);
    setSearchingFindMe(true);
  }, [logger]);

  const handleSearchingValue = useCallback((event) => {
    logger.debug('searching value', event);
    setSearchingValue(true);
  }, [logger]);

  const handleSearchComplete = useCallback((event) => {
    logger.debug('search complete', event);
    setSearchingFindMe(false);
    setSearchingValue(false);
    if (event?.detail?.error) {
      logger.error(event.detail.error);
      const { error } = event.detail;
      if (error instanceof window.GeolocationPositionError || error.message === 'geoposition-error') {
        setGeolocationBlocked(true);
        setShowMessage(false);
      } else if (error.message === 'zipcode-error') {
        setInvalidPostalCode(true);
      } else if (error.message === 'zipcode-country-error') {
        setCountryInvalidPostalCode(true);
        setShowMessage(false);
      } else if (error.message === 'sdd-unavailable') {
        setUnavailable(true);
        setShowMessage(false);
      } else {
        setOtherError(true);
        setShowMessage(false);
      }
    } else {
      closeModal();
    }
  }, [logger, closeModal]);

  const handleGeolocationBlocked = useCallback((event) => {
    logger.debug('geolocation blocked', event);
    setGeolocationBlocked(true);
  }, [logger]);

  useEffect(() => {
    window.addEventListener('sameDayDelivery:openModal', handleOpenModal);
    window.addEventListener('sameDayDelivery:searchingFindMe', handleSearchingFindMe);
    window.addEventListener('sameDayDelivery:searchingValue', handleSearchingValue);
    window.addEventListener('sameDayDelivery:searchComplete', handleSearchComplete);
    window.addEventListener('sameDayDelivery:geolocationBlocked', handleGeolocationBlocked);

    return () => {
      window.removeEventListener('sameDayDelivery:openModal', handleOpenModal);
      window.removeEventListener('sameDayDelivery:searchingFindMe', handleSearchingFindMe);
      window.removeEventListener('sameDayDelivery:searchingValue', handleSearchingValue);
      window.removeEventListener('sameDayDelivery:searchComplete', handleSearchComplete);
      window.removeEventListener('sameDayDelivery:geolocationBlocked', handleGeolocationBlocked);
    };
  }, [
    handleOpenModal,
    handleSearchingFindMe,
    handleSearchingValue,
    handleSearchComplete,
    handleGeolocationBlocked,
  ]);

  const {
    sddEnterYourLocationTMNTValue,
    sddEnterZipInstructionsTMNTValue,
    sddLocationServicesBlockedTMNTValue,
    sddInvalidCountryZipTMNTValue,
    sddOtherErrorTMNTValue,
    sddFindMeTMNTValue,
    sddSearchingTMNTValue,
    sddSubmitTMNTValue,
    countryZipInvalidTMNTValue,
    closeButtonTMNTValue,
    sddPostalCodeTMNTValue,
    orTextTMNTValue,
    sddUnavailableTMNTValue,
  } = textFor;

  return (
    <Modal
      id="mfe-sdd-modal"
      isOpen={modalOpened}
      onOpen={handleModalOpen}
      onClose={handleModalClose}
      heading={<h2>{sddEnterYourLocationTMNTValue?.value}</h2>}
      closeButtonLabel={closeButtonTMNTValue?.value}
    >
      <div className="sdd-search-horizontal-wrapper">
        <VerticalSeparator className="vertical-separator">
          <div className="findme-wrapper">
            <Button
              variant="secondary"
              onClick={handleClickFindMe}
              isDisabled={geolocationBlocked}
              isProcessing={searchingFindMe}
            >
              {!searchingFindMe && (
                <Icon
                  icon="location"
                  size="s"
                />
              )}
              {!searchingFindMe && <span>{sddFindMeTMNTValue?.value}</span>}
              {searchingFindMe && <span>{sddSearchingTMNTValue?.value}</span>}
            </Button>
          </div>
          <div className="search-form-wrapper">
            <SingleEntryForm>
              <InputField
                id="searchValueHorizontal"
                label={sddPostalCodeTMNTValue?.value}
                value={searchValue}
                onBlur={handleSearchValueBlur}
                onChange={handleSearchValueChange}
                autoComplete=""
              >
                <Button
                  variant="secondary"
                  onClick={handleClickSearch}
                  isProcessing={searchingValue}
                >
                  {!searchingValue && <span>{sddSubmitTMNTValue?.value}</span>}
                  {searchingValue && <span>{sddSearchingTMNTValue?.value}</span>}
                </Button>
              </InputField>
            </SingleEntryForm>
          </div>
        </VerticalSeparator>
      </div>
      <div className="sdd-search-vertical-wrapper">
        <div className="search-form-wrapper">
          <SingleEntryForm>
            <InputField
              id="searchValueVertical"
              label={sddPostalCodeTMNTValue?.value}
              value={searchValue}
              onBlur={handleSearchValueBlur}
              onChange={handleSearchValueChange}
              autoComplete=""
            >
              <Button
                variant="secondary"
                onClick={handleClickSearch}
                isProcessing={searchingValue}
              >
                {!searchingValue && <span>{sddSubmitTMNTValue?.value}</span>}
                {searchingValue && <span>{sddSearchingTMNTValue?.value}</span>}
              </Button>
            </InputField>
          </SingleEntryForm>
        </div>
        <HorizontalSeparator className="horizontal-or-separator">{orTextTMNTValue?.value}</HorizontalSeparator>
        <div className="findme-wrapper">
          <Button
            variant="secondary"
            onClick={handleClickFindMe}
            isFullWidth
            isDisabled={geolocationBlocked}
            isProcessing={searchingFindMe}
          >
            {!searchingFindMe && (
              <Icon
                icon="location"
                size="s"
              />
            )}
            {!searchingFindMe && <span>{sddFindMeTMNTValue?.value}</span>}
            {searchingFindMe && <span>{sddSearchingTMNTValue?.value}</span>}
          </Button>
        </div>
      </div>

      <div className="same-day-delivery-bottom">
        {invalidPostalCode && (
          <ErrorMessage>
            {countryZipInvalidTMNTValue?.value}
          </ErrorMessage>
        )}
        {geolocationBlocked && (
          <div
            data-testid="geoposition-error"
          >
            <ErrorMessage
              className="geoposition-error"
            >
              {sddLocationServicesBlockedTMNTValue?.value}
            </ErrorMessage>
          </div>
        )}
        {invalidCountryPostalCode && (
          <div
            data-testid="zipcode-country-error"
          >
            <ErrorMessage
              className="zipcode-country-error"
            >
              {sddInvalidCountryZipTMNTValue?.value}
            </ErrorMessage>
          </div>
        )}
        {unavailable && (
          <div
            data-testid="sdd-unavailable"
          >
            <ErrorMessage
              className="sdd-unavailable"
            >
              {sddUnavailableTMNTValue?.value}
            </ErrorMessage>
          </div>
        )}
        {otherError && (
          <div
            data-testid="other-error"
          >
            <ErrorMessage
              className="other-error"
            >
              {sddOtherErrorTMNTValue?.value}
            </ErrorMessage>
          </div>
        )}
        {showMessage && (
          <p className="message">{sddEnterZipInstructionsTMNTValue?.value}</p>
        )}
      </div>
    </Modal>
  );
}

SameDayDeliveryModal.defaultProps = {
  textFor: {
    sddEnterYourLocationTMNTValue: {
      key: '',
      value: '',
    },
    sddEnterZipInstructionsTMNTValue: {
      key: '',
      value: '',
    },
    sddLocationServicesBlockedTMNTValue: {
      key: '',
      value: '',
    },
    sddInvalidCountryZipTMNTValue: {
      key: '',
      value: '',
    },
    sddOtherErrorTMNTValue: {
      key: '',
      value: '',
    },
    sddFindMeTMNTValue: {
      key: '',
      value: '',
    },
    sddSearchingTMNTValue: {
      key: '',
      value: '',
    },
    sddSubmitTMNTValue: {
      key: '',
      value: '',
    },
    countryZipInvalidTMNTValue: {
      key: '',
      value: '',
    },
    closeButtonTMNTValue: {
      key: '',
      value: '',
    },
    sddPostalCodeTMNTValue: {
      key: '',
      value: '',
    },
    orTextTMNTValue: {
      key: '',
      value: '',
    },
  },
};

SameDayDeliveryModal.propTypes = {
  textFor: PropTypes.shape({
    sddEnterYourLocationTMNTValue: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
    sddEnterZipInstructionsTMNTValue: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
    sddLocationServicesBlockedTMNTValue: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
    sddInvalidCountryZipTMNTValue: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
    sddOtherErrorTMNTValue: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
    sddFindMeTMNTValue: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
    sddSearchingTMNTValue: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
    sddSubmitTMNTValue: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
    countryZipInvalidTMNTValue: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
    closeButtonTMNTValue: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
    sddPostalCodeTMNTValue: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
    sddUnavailableTMNTValue: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
    orTextTMNTValue: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }).isRequired,
  }),
};
