import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import ORDER_SUBMIT_MUTATION from '../../../gql/orderSubmit.gql';
import prepareFormData from '../../CheckoutPage/SubmitButton/prepareFormData';

export default function ApplePayExpress({ page, orderConfirmationURL }) {
  const triggerApplePayError = (errorMessage) => {
    const newEvent = new Event('mfe:applepay:error');
    newEvent.detail = errorMessage;
    window.dispatchEvent(newEvent);
  };

  const [orderSubmit] = useMutation(ORDER_SUBMIT_MUTATION, {
    onCompleted: (data) => {
      if (data?.orderSubmit?.success) {
        // trigger an event for CRS to listen to and submit the form
        // this evenet will pass the data that was returned from CART
        // currently required for proper redirect to order confirmation page
        if (page === 'bag') {
          window.location.href = orderConfirmationURL;
        } else {
          const event = new CustomEvent('mfe:submit:successful', {
            detail: data?.orderSubmit,
          });
          window.dispatchEvent(event);
        }
      } else {
        triggerApplePayError(
          data?.orderSubmit?.statusMessages?.[0]?.message ?? 'Something went wrong. Please try again.',
        );
      }
    },
    onError: (error) => {
      triggerApplePayError(error?.message);
    },
  });

  useEffect(() => {
    function handleApplePaySubmitEvent(event) {
      const { success, errorMessage, formData } = event.detail;
      const submitData = prepareFormData(formData);
      const currentPaymentInfo = {
        paymentType: 'applepay',
      };

      if (success) {
        orderSubmit({
          variables: {
            orderSubmitInput: submitData,
            paymentInfo: currentPaymentInfo,
          },
        });
      } else {
        // trigger an event for Page Header to display the error message
        triggerApplePayError(errorMessage);
      }
    }
    window.addEventListener('mfe:checkout:submit', handleApplePaySubmitEvent);
    return () => {
      window.removeEventListener('mfe:checkout:submit', handleApplePaySubmitEvent);
    };
  }, [orderSubmit]);

  return null;
}

ApplePayExpress.propTypes = {
  page: PropTypes.string.isRequired,
  orderConfirmationURL: PropTypes.string.isRequired,
};
