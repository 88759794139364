import { useEffect, useContext } from 'react';
import {
  useMutation,
} from '@apollo/client';
import {
  EMAIL_MARKETING_SUBSCRIBE,
} from '../../../gql/orderConfirmationPage.gql';
import useLog from '../../useLog/useLog';
import SwitchTestContext from '../../../context/SwitchTestContext';

function MarketingSubscription() {
  const {
    digitalData,
  } = useContext(SwitchTestContext);
  const logger = useLog('OCP.marketingSubscription');
  const marketingSubscribe = digitalData && digitalData['chk-use-mfe-marketing-subscribe'];
  const [subscribeEmail] = useMutation(EMAIL_MARKETING_SUBSCRIBE, {
    fetchPolicy: 'no-cache',
    context: { batch: true },
    ssr: false,
    onError: (err) => {
      logger.error(`ERR: MarketingSubscription: ${JSON.stringify(err)}`);
    },
  });

  useEffect(() => {
    if (marketingSubscribe) {
      subscribeEmail();
    }
  }, [marketingSubscribe, subscribeEmail]);

  return null;
}

export default MarketingSubscription;
