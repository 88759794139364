import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { Icon } from 'anf-core-react';

export default function SameDayDeliveryUnavailableMessage({ isDisplayed, textFor }) {
  if (!isDisplayed) {
    return null;
  }

  const {
    sameDayDeliveryNotAvailableInLocationTMNTValue = {},
  } = (textFor || {});
  const els = document.getElementsByClassName('sdd-loc-unavailable');
  const el = els.length ? els[0] : document.createElement('div');

  return createPortal(
    (
      <div className="same-day-delivery-available">
        <span className="font-icon">
          <Icon icon="exclamation" />
        </span>
        <span>{sameDayDeliveryNotAvailableInLocationTMNTValue.value}</span>
      </div>
    ),
    el,
  );
}

SameDayDeliveryUnavailableMessage.defaultProps = {
  isDisplayed: false,
  textFor: {},
};

SameDayDeliveryUnavailableMessage.propTypes = {
  isDisplayed: PropTypes.bool,
  textFor: PropTypes.shape({}),
};
