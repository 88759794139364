import React, { useContext } from 'react';
import { Icon } from 'anf-core-react';
import CheckoutPageContext from '../../../context/CheckoutPageContext';
import Tmnt from '../../Tmnt/Tmnt';

export default function PaymentHeader() {
  const { checkoutPageState } = useContext(CheckoutPageContext);
  const tmntData = checkoutPageState?.textFor;
  return (
    <div className="payment-manager-header-section">
      {/* Secure Payment heading */}
      <div className="heading-secure">
        <h2>
          <Tmnt tmnt={tmntData?.securePayment} />
        </h2>
        <Icon icon="lock-anf" />
      </div>
    </div>
  );
}
