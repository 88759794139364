import React from 'react';
import SwitchTestProvider from '../SwitchTestProvider/SwitchTestProvider';
import CheckoutPage from './CheckoutPage';
import { CHECKOUT_SWITCHES } from '../../tools/constants';

export default function CheckoutWrapper() {
  return (
    <SwitchTestProvider keys={CHECKOUT_SWITCHES}>
      <CheckoutPage />
    </SwitchTestProvider>
  );
}
