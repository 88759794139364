// Format Expiration Date in MM / YY

const formatExpiry = (userInput) => {
  // Remove any non-numeric characters from input
  let input = userInput.replace(/\D/g, '');

  // Limit input to 4 characters
  if (input.length > 4) {
    input = input.slice(0, 4);
  }

  // Format input as MM / YY
  if (input.length === 1) {
    if (parseInt(input, 10) > 1) {
      input = `0${input} / `;
    }
  } else if (input.length === 2) {
    const month = parseInt(input.slice(0, 2), 10);
    if (month > 12) {
      input = `01 / ${input.slice(1)}`;
    } else {
      input += ' / ';
    }
  } else if (input.length > 2) {
    const month = parseInt(input.slice(0, 2), 10);
    const year = input.slice(2);
    if (month > 12) {
      input = `12 / ${year}`;
    } else if (year.length === 2) {
      input = `${input.slice(0, 2)} / ${year}`;
    } else {
      input = `${input.slice(0, 2)} / ${input.slice(2)}`;
    }
  }

  return input;
};

export default formatExpiry;
