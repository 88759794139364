import React from 'react';
import PropTypes from 'prop-types';
import { PaymentBlock } from 'anf-core-react';
import Tmnt from '../../Tmnt/Tmnt';
import MaskedGiftCard from '../../Common/GiftCards/MaskedGiftCard';

export default function CardDetails({
  iconCode,
  cardNumber,
  expiryMonth,
  expiryYear,
  expTmnt,
  expiresTmnt,
}) {
  return (
    <div className="card-details">
      <PaymentBlock
        paymentIcon={(
          <span className="payment-icon" role="img" data-icon-name={iconCode} aria-label="Card" />
        )}
      >
        <MaskedGiftCard giftcard={{
          maskedNumber: cardNumber,
        }}
        />
        <p>
          <span className="screen-reader-text">
            {`${expiresTmnt?.value} ${expiryMonth} ${expiryYear}`}
          </span>
          <Tmnt tmnt={expTmnt} />
          <span>{`${expiryMonth}/${expiryYear}`}</span>
        </p>
      </PaymentBlock>
    </div>
  );
}

CardDetails.defaultProps = {
  expTmnt: {
    key: 'REFUNDMETHOD_EXP',
    value: 'Exp:',
  },
  expiresTmnt: {
    key: 'CHK_EXPIRATION_DATE',
    value: 'Expires:',
  },
};

CardDetails.propTypes = {
  iconCode: PropTypes.string.isRequired,
  cardNumber: PropTypes.string.isRequired,
  expiryMonth: PropTypes.string.isRequired,
  expiryYear: PropTypes.string.isRequired,
  expTmnt: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  expiresTmnt: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
};
