import {
  gql, useLazyQuery,
} from '@apollo/client';
import {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import useLog from '../../useLog/useLog';
import $window from '../../../tools/window';
import CheckoutPageContext from '../../../context/CheckoutPageContext';

const GET_PREFERRED_STORE = gql`
  query Query($storeNumber: String, $bagItems: [BagItemInput], $shippingAddress: ShippingAddressInput) {
    popins {
      preferredStore(storeNumber: $storeNumber, bagItems: $bagItems, shippingAddress: $shippingAddress) {
        name
        storeNumber
        storeBrand
        storeBrandLogo
        eligibleForPickup
        estimatedPickupDateFmt
        storeMessage
        mapAndHoursUrl
        addressLine
        city
        state
        province
        postalCode
        country
        countryName
        latitude
        longitude
        physicalStoreAttribute {...physicalStoreAttribute}
        inventoryAvailability {...inventoryAvailability}
      }
    }
  }

  fragment physicalStoreAttribute on PhysicalStoreAttribute {
    displayable
    name
    value
  }

  fragment inventoryAvailability on InventoryAvailability {
    availabilityDateTime
    availabilityDateTimeFmt
    availableQuantity
    inventoryStatus
    sku
    storeNumber
    unitOfMeasure
  }
`;

const localStorageKey = 'preferredStore';

function parseJSON(value) {
  try {
    return JSON.parse(value);
  } catch (e) {
    return undefined;
  }
}

function parseJSONObject(value) {
  const object = parseJSON(value);
  if (typeof object === 'object' && object !== null) {
    return object;
  }
  return {};
}

export default function usePreferredStore() {
  const logger = useLog('checkout.preferredStore');
  const [storeNumber, setStoreNumber] = useState(null);
  const [store, setStore] = useState(null);
  const searched = useRef(null);
  const { checkoutPageState } = useContext(CheckoutPageContext);

  const [getPreferredStore] = useLazyQuery(GET_PREFERRED_STORE, {
    fetchPolicy: 'network-only',
    context: { batch: true },
    ssr: false,
  });

  const bagItems = useMemo(
    () => (checkoutPageState?.bagItems?.items || []).map((product) => {
      const { shortSku, brand } = product.item.productContent;
      return {
        shortSku,
        brand,
      };
    }),
    [checkoutPageState?.bagItems],
  );
  const country = useMemo(
    () => checkoutPageState?.shippingAddress?.country,
    [checkoutPageState?.shippingAddress],
  );

  useEffect(() => {
    // TODO: we may need to check the legacy cookie as well
    const localStorageValue = $window?.localStorage?.getItem(localStorageKey);
    const item = parseJSONObject(localStorageValue);
    setStoreNumber(item.storeNumber);
  }, []);

  useEffect(() => {
    if (!storeNumber) {
      return;
    }
    if (store && store?.storeNumber === storeNumber) {
      return;
    }
    if (searched.current === storeNumber) {
      return;
    }
    searched.current = storeNumber;
    getPreferredStore({
      variables: {
        storeNumber,
        bagItems,
        shippingAddress: { country },
      },
    }).then((response) => {
      if (response?.data?.popins?.preferredStore) {
        setStore(response.data.popins.preferredStore);
      }
    }).catch((error) => {
      logger.error('Error getting preferred store', error);
    });
  }, [storeNumber, store, logger, getPreferredStore, bagItems, country]);

  return store;
}
