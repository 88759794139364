import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import {
  ApolloClient, ApolloLink, ApolloProvider, HttpLink,
} from '@apollo/client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { InMemoryCache } from '@apollo/client/cache';
import { onError } from '@apollo/client/link/error';
import { sha256 } from 'crypto-hash';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { setContext } from '@apollo/client/link/context';

const simpleCacheMerge = {
  merge: (existing = {}, incoming = {}) => ({ ...existing, ...incoming }),
};

export function buildURI(baseURI, params) {
  const keys = ['storeId', 'catalogId', 'langId', 'brand', 'store', 'country', 'page', 'orderId', 'email', 'filter'];
  const urlSearchParams = new URLSearchParams();
  if (typeof params === 'object' && params !== null) {
    keys.forEach((key) => {
      if (params[key] !== undefined && params[key] !== null) {
        urlSearchParams.set(key, params[key]);
      }
    });
  }
  const queryString = urlSearchParams.toString();
  return baseURI + (queryString ? `${queryString}` : '');
}

export default function hydrate({ frontend, component }) {
  const Component = component;

  const cacheId = `${PACKAGE.name}-${frontend}-config`;

  const el = document.getElementById(`${PACKAGE.name}-${frontend}`);

  // To support a single file js type delivery, need to handle component endpoints
  // existing in js, but not in dom
  if (!el) return;

  const config = window[`APOLLO_STATE__${cacheId}`];

  // Goal uri example: 'http://localhost:51902?storeId=10051&catalogId=10901&langId=-1&brand=anf&store=a-us',

  const persistedQueriesLink = createPersistedQueryLink({ sha256 });

  const baseURI = '/api/bff/checkout?';
  const uri = buildURI(baseURI, config);

  const httpLink = new HttpLink({
    uri,
    credentials: 'same-origin',
    useGETForQueries: true,
  });

  const batchHttpLink = new BatchHttpLink({
    uri,
    credentials: 'same-origin',
    batchMax: 50,
    batchInterval: 10,
  });

  // Custom link to add operationName to headers, this is leveraged by our CDN
  const addOperationNameLink = setContext((operation, { headers }) => ({
    headers: {
      ...headers,
      'x-operation-name': operation.operationName || 'unknown-operation',
    },
  }));

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      // eslint-disable-next-line no-console
      graphQLErrors.forEach(({ message, locations, path }) => console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ));
    }
    // eslint-disable-next-line no-console
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  // construct apollo client with cached results computed on server
  const client = new ApolloClient({
    link: ApolloLink.from([
      addOperationNameLink,
      errorLink,
    ]).split(
      (operation) => operation?.query?.definitions?.[0]?.operation === 'mutation' || operation.getContext()?.batch,
      batchHttpLink,
      persistedQueriesLink.concat(httpLink),
    ),
    cache: new InMemoryCache(
      {
        typePolicies: {
          Query: {
            fields: {
              textFor: simpleCacheMerge,
              config: simpleCacheMerge,
            },
          },
        },
      },
    ).restore(config.CACHE),
  });

  hydrateRoot(
    el,
    <ApolloProvider client={client}>
      <Component />
    </ApolloProvider>,
  );
}
