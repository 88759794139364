import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
} from 'anf-core-react';

export default function VatModal({
  openModal,
  closeModal,
  vatDetails,
}) {
  const heading = (
    <h1 className="modal-label" data-property="GLB_VATINCLUDED">
      {vatDetails?.vatIncludedText}
    </h1>
  );
  return (
    <Modal
      id="vat-modal"
      isOpen={openModal}
      onClose={closeModal}
      heading={heading}
      closeButtonLabel="Close"
    >
      <div className="legal-modal">
        <div className="interruption">
          <div id="vatDutyDetails" className="">
            <h4 data-property="GLB_DUTYVATDETAILS">{vatDetails?.dutyVatDetailsText}</h4>
            <ul>
              {
                vatDetails?.vatDetailsText?.map((vatText) => (vatText?.value
                  ? (
                    <li data-property={vatText?.key} key={vatText?.key}>
                      <p>{vatText?.value}</p>
                    </li>
                  ) : null
                ))
              }
            </ul>
          </div>
        </div>
      </div>
    </Modal>
  );
}

VatModal.defaultProps = {
  openModal: false,
  closeModal: () => {},
  vatDetails: {},
};

VatModal.propTypes = {
  openModal: PropTypes.bool,
  closeModal: PropTypes.func,
  vatDetails: PropTypes.instanceOf(Object),
};
