import Controls from './Utils/Controls';
import Manager from './Manager/Manager';

/**
 * A replacement for console.(log|warn|error) calls that allows for the ability to turn on
 * logging and transporting warnings and errors to Quantum Metrics and other transports.
 * @public
 */

export default class Logger {
  /**
   * manages the logs
   * @type {Manager}
   */

  #manager = null;

  get manager() {
    if (!this.#manager) {
      this.#manager = new Manager();
    }
    return this.#manager;
  }

  /**
   * public interface managing loggers
   * @type {Controls}
   */

  #controls = null;

  get controls() {
    if (!this.#controls) {
      this.#controls = new Controls(this.manager);
    }
    return this.#controls;
  }

  /**
   * create a logger
   * @param {String} name the logger's name e.g. 'checkout.miniBag'
   * @returns {Function} the logger instance
   */

  logger(name) {
    return this.manager.initLogger(name);
  }
}
