/**
 * Checks the password contains numbers, letters, not whitespace and from 8 to 25 characters
 */
const PASSWORD_VALIDATION_REGEXP = /^(?=.*[0-9])(?=.*[a-zA-Z])(?!.* ).{8,25}$/;

export default function isValidPassword(password) {
  if (typeof password !== 'string') return false;

  return PASSWORD_VALIDATION_REGEXP.test(password);
}
