import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { RadioButtonGroup } from 'anf-core-react';
import shippingSpeedTypes from './shippingSpeedTypes';
import shippingSpeedShape from './shippingSpeedShape';
import useShippingSpeedFieldName from './ShippingSpeedHelper';

export default function ShippingSpeedOptions({
  shippingSpeedOptions, onChangeShippingSpeed, name, textFor, isUpdating, switches,
}) {
  const shippingSpeedFieldName = useShippingSpeedFieldName(name);

  const shippingSpeedOptionsUI = useMemo(() => {
    if (!Array.isArray(shippingSpeedOptions) || shippingSpeedOptions.length === 0) {
      return null;
    }
    return shippingSpeedOptions.map((shippingSpeed, index) => {
      const Component = shippingSpeedTypes[shippingSpeed.shipModeType];
      const key = `${shippingSpeed.shipModeId}-${index}`;
      if (Component) {
        return (
          <Component
            key={key}
            name={shippingSpeedFieldName}
            shippingSpeed={shippingSpeed}
            onChangeShippingSpeed={onChangeShippingSpeed}
            textFor={textFor}
            switches={switches}
          />
        );
      }
      return (
        <pre key={key}>{`shippingSpeed ${shippingSpeed.shipModeType} does not have a registered component.`}</pre>
      );
    });
  }, [shippingSpeedOptions, onChangeShippingSpeed, shippingSpeedFieldName, textFor, switches]);

  const initialCheckedValue = useMemo(() => {
    if (!Array.isArray(shippingSpeedOptions)) {
      return undefined;
    }
    const shipingSpeedOption = shippingSpeedOptions.find(
      (candidateShippingSpeedOption) => candidateShippingSpeedOption.isSelected,
    );
    if (!shipingSpeedOption) {
      return undefined;
    }
    return shipingSpeedOption.shipModeId;
  }, [shippingSpeedOptions]);

  const isHomeDelivery = (shippingOptions) => {
    if (!Array.isArray(shippingOptions)) {
      return true;
    }
    return shippingOptions[0]?.addressType === 'SB' || shippingOptions[0]?.addressType === 'S' || shippingOptions[0]?.addressType === 'B';
  };

  const {
    shippingSpeedLegendTMNTValue = {},
    shippingSpeedPickupLegendTMNTValue = {},
  } = (textFor || {});
  const legendTMNTvalue = isHomeDelivery(shippingSpeedOptions)
    ? shippingSpeedLegendTMNTValue : shippingSpeedPickupLegendTMNTValue;

  return (
    <div className={`shipping-speed${isUpdating ? ' updating' : ''}`} role="radiogroup">
      {shippingSpeedOptionsUI && (
        <RadioButtonGroup
          id="shipping-speed"
          initialCheckedValue={initialCheckedValue}
          key={initialCheckedValue}
          legend={<span data-property={legendTMNTvalue.key} className="h4">{legendTMNTvalue.value}</span>}
        >
          {shippingSpeedOptionsUI}
        </RadioButtonGroup>
      )}
    </div>
  );
}

ShippingSpeedOptions.defaultProps = {
  shippingSpeedOptions: undefined,
  onChangeShippingSpeed: undefined,
  name: undefined,
  textFor: {},
  switches: {},
  isUpdating: false,
};

ShippingSpeedOptions.propTypes = {
  shippingSpeedOptions: PropTypes.arrayOf(shippingSpeedShape),
  onChangeShippingSpeed: PropTypes.func,
  name: PropTypes.string,
  textFor: PropTypes.shape({}),
  switches: PropTypes.shape({}),
  isUpdating: PropTypes.bool,
};
