import HomeDeliveryShippingSpeedOption from './HomeDeliveryShippingSpeedOption';
import PickupShippingSpeedOption from './PickupShippingSpeedOption';
import SameDayDeliveryShippingSpeedOption from './SameDayDeliveryShippingSpeedOption';

const shippingSpeedTypes = Object.freeze({
  HomeDelivery: HomeDeliveryShippingSpeedOption,
  SameDayDelivery: SameDayDeliveryShippingSpeedOption,
  Pudos: PickupShippingSpeedOption,
  Popins: PickupShippingSpeedOption,
  DirectToDesk: HomeDeliveryShippingSpeedOption,
  GetItByChristmas: HomeDeliveryShippingSpeedOption,
});

export default shippingSpeedTypes;
