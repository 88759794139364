import {
  gql,
} from '@apollo/client';

// QUERY: gql query to fetch specific product data using productId
const PRODUCT_ITEM_DATA_QUERY = gql`
query FetchShopSimilarItemsUrl($productId: String!) {
  shopSimilarItemsUrl(productId: $productId)
}
`;

export default PRODUCT_ITEM_DATA_QUERY;
