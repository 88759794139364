import React from 'react';
import PropTypes from 'prop-types';
import ProgressBar from '../ProgressBar/ProgressBar';
import Tmnt from '../../Tmnt/Tmnt';
import decodeHTMLEntities from '../../../tools/decodeHtml';
import replacePlaceholders from '../../../tools/tmntHelpers';

export default function FreeShipping({
  isVisible,
  amountToFreeShipping,
  tmntData,
  progressValue,
  maxProgress,
  preLabel,
  postLabel,
  minProgress,
  amountToFreeShippingFmt,
}) {
  const freeShippingEarnedTMNTValue = tmntData?.freeShippingEarnedTMNTValue;
  const freeShippingAmountTMNTValue = {
    ...tmntData?.freeShippingAmountTMNTValue,
    value: replacePlaceholders(
      tmntData?.freeShippingAmountTMNTValue?.value,
      { 0: amountToFreeShippingFmt },
    ),
  };
  let freeShippingEl = (
    <div className="free-shipping-earned" data-testid="free-shipping-earned">
      <Tmnt
        tmnt={freeShippingEarnedTMNTValue}
        isHtml
      />
    </div>
  );
  if (amountToFreeShipping) {
    freeShippingEl = (
      <div className="free-shipping-amount" data-testid="free-shipping-amount">
        <Tmnt
          tmnt={freeShippingAmountTMNTValue}
          isHtml
        />
      </div>
    );
  }

  return (isVisible && (
    <div className="free-shipping-progress">
      <div className="free-shipping-text">
        {freeShippingEl}
      </div>
      <ProgressBar
        progressValue={progressValue}
        progressMinValue={minProgress}
        progressMaxValue={maxProgress}
        prelabel={decodeHTMLEntities(preLabel)}
        postlabel={decodeHTMLEntities(postLabel)}
      />
    </div>
  ));
}

FreeShipping.defaultProps = {
  isVisible: false,
  amountToFreeShipping: 0,
  amountToFreeShippingFmt: '',
  tmntData: {},
  progressValue: 0,
  maxProgress: 0,
  minProgress: 0,
  postLabel: '',
  preLabel: '',
};

FreeShipping.propTypes = {
  isVisible: PropTypes.bool,
  amountToFreeShipping: PropTypes.number,
  tmntData: PropTypes.instanceOf(Object),
  progressValue: PropTypes.number,
  maxProgress: PropTypes.number,
  minProgress: PropTypes.number,
  postLabel: PropTypes.string,
  preLabel: PropTypes.string,
  amountToFreeShippingFmt: PropTypes.string,
};
