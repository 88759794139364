/**
 * The purpose of this to resolve SSR issue with some code that relies on window being defined.
 */

let win;

if (typeof window !== 'undefined') {
  win = window;
} else {
  // NOTE: we may need to put some stuff in here in the future
  win = {};
}

const $window = win;

export default $window;
