import React from 'react';
import PropTypes from 'prop-types';
import { Recommendations } from '@xp-utilities/web';
import MiniBagRecommendationsContextProvider from '../../context/Recommendations/MiniBagRecommendationsContextProvider';

export const INSTANCE_DATA_ATTRIBUTE = 'data-recommendations-client';

export default function MiniBagInstrumentedRecommendations({ id, placements, miniBagData }) {
  return (
    <MiniBagRecommendationsContextProvider id={id} data={miniBagData}>
      <div
        id={id}
        data-testid={id}
      >
        <Recommendations placements={placements} />
      </div>
    </MiniBagRecommendationsContextProvider>
  );
}

MiniBagInstrumentedRecommendations.propTypes = {
  id: PropTypes.string.isRequired,
  placements: PropTypes.arrayOf(PropTypes.string),
  miniBagData: PropTypes.shape({}).isRequired,
};
MiniBagInstrumentedRecommendations.defaultProps = { placements: [] };
