import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import CheckoutPageContext from '../../../context/CheckoutPageContext';
import PaymentOptionsHeader from './PaymentOptionsHeader';
import PaymentOptions from './PaymentOptions/PaymentOptions';
import PaymentTiles from './PaymentTiles/PaymentTiles';
import { dispatchPaymentTypeChangedEvent } from '../../../tools/paymentTypeChangedEvent';

export default function PaymentOptionsContainer({ usePaymentTiles }) {
  const [isSavedPaymentModalOpen, setIsSavedPaymentModalOpen] = useState(false);
  const [selectedSavedPayment, setSelectedSavedPayment] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const { checkoutPageState, updateCheckoutPageState } = useContext(CheckoutPageContext);
  const paymentOptions = useMemo(
    () => checkoutPageState?.paymentOptions || [],
    [checkoutPageState?.paymentOptions],
  );
  const selectedPaymentType = checkoutPageState?.selectedPaymentType || '';
  const savedPayments = useMemo(
    () => checkoutPageState?.paymentConfig?.savedPayments || [],
    [checkoutPageState?.paymentConfig?.savedPayments],
  );
  const tmntData = checkoutPageState?.textFor;
  const isLoggedIn = checkoutPageState?.userData?.isLoggedIn;

  const onSavedPaymentModalCloseHandler = () => setIsSavedPaymentModalOpen(false);

  const getSavedPaymentById = (savedPaymentId) => {
    const savedPaymentFound = savedPayments.find(
      (savedPayment) => savedPayment?.savedPaymentId === savedPaymentId,
    );
    return savedPaymentFound;
  };

  const setSelectedPayment = useCallback((paymentType, savedPayment = null) => {
    const selectedType = paymentType?.toLowerCase() || '';
    if (selectedSavedPayment || savedPayment) {
      setSelectedSavedPayment(savedPayment);
    }
    updateCheckoutPageState({ selectedPaymentType: selectedType });
  }, [updateCheckoutPageState, selectedSavedPayment, setSelectedSavedPayment]);

  const onSelectHandler = (savedPaymentId) => {
    const savedPaymentFound = getSavedPaymentById(savedPaymentId);
    setSelectedPayment('credit card', savedPaymentFound);
    setIsSavedPaymentModalOpen(false);
  };

  const handleChangeSavedPayment = useCallback(() => {
    setIsSavedPaymentModalOpen(true);
  }, []);

  const handleClearSelectedSavedPayment = useCallback(() => {
    setSelectedSavedPayment(null);
  }, []);

  useEffect(() => {
    let seletedOption;
    if (selectedPaymentType) {
      seletedOption = paymentOptions?.find(
        (paymentOption) => paymentOption?.type?.toLowerCase() === selectedPaymentType,
      );
    }
    if (!isLoaded || !seletedOption) {
      const defaultSavedPayment = savedPayments?.find(
        (savedPayment) => savedPayment?.default,
      );
      if (defaultSavedPayment) {
        setSelectedPayment('credit card', defaultSavedPayment);
        seletedOption = paymentOptions?.find(
          (paymentOption) => paymentOption?.type?.toLowerCase() === 'credit card',
        );
      } else {
        seletedOption = paymentOptions?.find((paymentOption) => paymentOption?.default);
        setSelectedPayment(seletedOption?.type);
      }
      dispatchPaymentTypeChangedEvent(seletedOption);
      if (!isLoaded) {
        setIsLoaded(true);
      }
    }
  }, [
    paymentOptions,
    selectedPaymentType,
    savedPayments,
    setSelectedPayment,
    isLoaded,
    setIsLoaded,
  ]);

  return (
    <div className="payment-options" data-testid="payment-options">
      <PaymentOptionsHeader
        isLoggedIn={isLoggedIn}
        isModalOpen={isSavedPaymentModalOpen}
        savedPayments={savedPayments}
        tmntData={tmntData}
        onButtonClick={() => setIsSavedPaymentModalOpen(true)}
        onModalClose={onSavedPaymentModalCloseHandler}
        onSelect={onSelectHandler}
      />
      {
        !usePaymentTiles
          ? (
            <PaymentOptions
              isLoggedIn={isLoggedIn}
              payments={paymentOptions}
              selectedPayment={selectedPaymentType}
              setSelectedPayment={setSelectedPayment}
              savedPaymentDetails={selectedSavedPayment}
              tmntData={tmntData}
              onChange={handleChangeSavedPayment}
              onClear={handleClearSelectedSavedPayment}
              paymentConfig={checkoutPageState?.paymentConfig}
            />
          )
          : (
            <PaymentTiles
              isLoggedIn={isLoggedIn}
              payments={paymentOptions}
              selectedPayment={selectedPaymentType}
              setSelectedPayment={setSelectedPayment}
              savedPaymentDetails={selectedSavedPayment}
              tmntData={tmntData}
              onChange={handleChangeSavedPayment}
              onClear={handleClearSelectedSavedPayment}
              paymentConfig={checkoutPageState?.paymentConfig}
            />
          )
      }
    </div>
  );
}

PaymentOptionsContainer.defaultProps = {
  usePaymentTiles: false,
};

PaymentOptionsContainer.propTypes = {
  usePaymentTiles: PropTypes.bool,
};
