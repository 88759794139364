import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'anf-core-react';
import Tmnt from '../../../Tmnt/Tmnt';
import CardDetails from '../CardDetails';
import CVVField from '../../CreditCard/CVVField';

export default function SelectedSavedPayment({
  index,
  creditCardConfig,
  savedPaymentDetails,
  expTmnt,
  expiresTmnt,
  changeTmt,
  provideSecurityCodeTmnt,
  securityCodeTmnt,
  clearTmnt,
  onChange,
  onClear,
  hasValidationError,
}) {
  const [cvv, setCvv] = useState('');

  const handleClear = useCallback(() => {
    onClear();
  }, [onClear]);

  useEffect(() => {
    setCvv('');
  }, [savedPaymentDetails?.savedPaymentId]);

  return (
    <div className="selected-saved-payment" data-testid="selected-saved-payment">
      <div className="selected-saved-payment-card">
        <CardDetails
          iconCode={savedPaymentDetails?.iconCode}
          cardNumber={savedPaymentDetails?.maskedCardNumber}
          expiryMonth={savedPaymentDetails?.cardExpiryMonth}
          expiryYear={savedPaymentDetails?.cardExpiryYear}
          expTmnt={expTmnt}
          expiresTmnt={expiresTmnt}
        />
        <input type="hidden" name="creditCard[0].savedPaymentId" value={savedPaymentDetails?.savedPaymentId} />
        <input type="hidden" name="creditCard[0].paymentCode" value={savedPaymentDetails?.cardType} />
        <Button
          value="saved-payments"
          variant="borderless"
          classList="button ds-override"
          labelText="Change"
          onClick={() => onChange()}
        >
          <Tmnt tmnt={changeTmt} />
        </Button>
      </div>
      {savedPaymentDetails?.requiresCvv && (
        <div className="selected-saved-payment-card-security">
          <p>
            <Tmnt tmnt={provideSecurityCodeTmnt} />
          </p>
          <div className="credit-card-fields">
            <CVVField
              cvv={cvv}
              cardBrand={savedPaymentDetails?.cardType}
              label={securityCodeTmnt?.value || ''}
              errorMessage={creditCardConfig?.cvvErrorMessage?.value || ''}
              index={index}
              onChange={setCvv}
              tooltipMessage={creditCardConfig?.securityCodeMessage?.value || ''}
              tooltipImage={creditCardConfig?.securityCodeImage || ''}
              hasValidationError={hasValidationError?.cardCvv}
            />
          </div>
        </div>
      )}
      <div className="selected-saved-payment-card-action">
        <Button
          variant="tertiary-dark"
          labelText="Clear"
          onClick={handleClear}
        >
          <Tmnt tmnt={clearTmnt} />
        </Button>
      </div>
    </div>
  );
}

SelectedSavedPayment.defaultProps = {
  savedPaymentDetails: [],
  expTmnt: {
    key: 'REFUNDMETHOD_EXP',
    value: 'Exp:',
  },
  expiresTmnt: {
    key: 'CHK_EXPIRATION_DATE',
    value: 'Expires:',
  },
  changeTmt: {
    key: 'GLB_CHANGE',
    value: 'Change',
  },
  provideSecurityCodeTmnt: {
    key: 'CHK_ProvideCVV',
    value: 'Please confirm your security code',
  },
  securityCodeTmnt: {
    key: 'CHK_SECURITY_CODE',
    value: 'Security code',
  },
  clearTmnt: {
    key: 'GLB_CLEAR',
    value: 'Clear',
  },
  hasValidationError: {},
};

SelectedSavedPayment.propTypes = {
  index: PropTypes.number.isRequired,
  savedPaymentDetails: PropTypes.shape({
    iconCode: PropTypes.string,
    maskedCardNumber: PropTypes.string,
    cardExpiryMonth: PropTypes.string,
    cardExpiryYear: PropTypes.string,
    cardType: PropTypes.string,
    requiresCvv: PropTypes.bool,
    savedPaymentId: PropTypes.string,
    default: PropTypes.bool,
  }),
  creditCardConfig: PropTypes.shape({
    cardNumberLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    expiryLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    securityCodeLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    securityCodeMessage: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    creditCardMissingErrorMessage: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    creditCardErrorMessage: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    expiryErrorMessage: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    cvvErrorMessage: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    securityCodeImage: PropTypes.string,
    creditCardTypes: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      maxlength: PropTypes.number,
      code: PropTypes.string,
      regex: PropTypes.string,
    })),
  }).isRequired,
  expTmnt: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  expiresTmnt: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  changeTmt: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  provideSecurityCodeTmnt: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  securityCodeTmnt: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  clearTmnt: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  hasValidationError: PropTypes.shape({
    card: PropTypes.bool,
    cardNumber: PropTypes.bool,
    cardExpiry: PropTypes.bool,
    cardCvv: PropTypes.bool,
  }),
};
