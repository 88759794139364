import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  InputField, TooltipWidget, ErrorMessage,
} from 'anf-core-react';

export default function CVVField({
  cvv,
  cardBrand,
  label,
  errorMessage,
  index,
  onChange,
  tooltipMessage,
  tooltipImage,
  hasValidationError,
}) {
  const [maxLength, setMaxLength] = useState(3);
  const [isInvalid, setIsInvalid] = useState(false);
  const [isToolTipOpen, setIsToolTipOpen] = useState(false);

  useEffect(() => {
    setIsInvalid(!!hasValidationError);
  }, [hasValidationError]);

  useEffect(() => {
    if (cardBrand && String(cardBrand).match(/(amex)|(003)/)) {
      setMaxLength(4);
    } else {
      setMaxLength(3);
    }
  }, [cardBrand]);

  const handleChange = (event) => {
    const input = event.target.value;
    // Update state with formatted input
    onChange(input);
    return input;
  };

  const validateChange = (event) => {
    const input = event.target.value;
    setIsInvalid(!(input.length === maxLength));
  };

  return (
    <InputField
      label={label}
      name={`creditCard[${index}].cvv`}
      id={`cvv-field-${index}`}
      value={cvv}
      onChange={handleChange}
      onBlur={validateChange}
      type="tel"
      maxLength={maxLength}
      isInvalid={isInvalid}
      autoComplete=""
      classList="cvv-field"
      inputProps={{
        'data-validationrule': 'cvv',
        'data-testid': `cvv-field-${index}`,
      }}
    >
      <TooltipWidget
        buttonLabelText="Story example"
        contentID="story-tooltip"
        contentPosition="top-right"
        isOpen={isToolTipOpen}
        onFocus={() => setIsToolTipOpen(true)}
        onBlur={() => setIsToolTipOpen(false)}
        onClick={() => setIsToolTipOpen(!isToolTipOpen)}
        onKeyDown={() => setIsToolTipOpen(!isToolTipOpen)}
        onKeyUp={() => {}}
      >
        <img src={tooltipImage} alt={tooltipMessage} />
      </TooltipWidget>

      <ErrorMessage id="cvv-error-message">
        {errorMessage}
      </ErrorMessage>
    </InputField>
  );
}

CVVField.propTypes = {
  label: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  cvv: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  cardBrand: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  tooltipImage: PropTypes.string.isRequired,
  tooltipMessage: PropTypes.string.isRequired,
  hasValidationError: PropTypes.bool.isRequired,
};
