import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'anf-core-react';
import Tmnt from '../../../Tmnt/Tmnt';
import CreditCardFields from './CreditCardFields';
import KlarnaFields from './KlarnaFields';
import ApmFields from './ApmFields';
import {
  handleClickPayOverTimeLearnMore,
} from '../../../../tools/paymentTypeChangedEvent';

export default function PaymentFields({
  isLoggedIn,
  selectedPayment,
  paymentConfig,
  tmntData,
  savedPaymentDetails,
  setSelectedPayment,
  onChange,
  onClear,
  isRadioButtonView,
}) {
  return (
    <div
      className={`payment-option-fields-wrap ${isRadioButtonView ? 'radio-view' : 'tile-view'}`}
    >
      <div className="payment-type-description">
        {
          isRadioButtonView
            ? (
              <>
                {selectedPayment?.description
                && selectedPayment?.type !== 'credit card'
                && (
                  <>
                    {
                      selectedPayment?.type !== 'apm'
                        ? <Tmnt tmnt={selectedPayment?.description} isHtml />
                        : <h3><Tmnt tmnt={selectedPayment?.description} isHtml /></h3>
                    }
                    <br />
                  </>
                )}
                {selectedPayment?.type !== 'Klarna'
                && selectedPayment?.type !== 'paypal'
                && selectedPayment?.message
                && (
                  <Tmnt tmnt={selectedPayment?.message} isHtml />
                )}
              </>
            )
            : (
              <>
                {selectedPayment?.description && (
                  <>
                    {
                      selectedPayment?.type !== 'apm'
                        ? <Tmnt tmnt={selectedPayment?.description} isHtml />
                        : <h3><Tmnt tmnt={selectedPayment?.description} isHtml /></h3>
                    }
                    <br />
                  </>
                )}
                {selectedPayment?.message && (
                  <>
                    <Tmnt tmnt={selectedPayment?.message} isHtml />
                    {(selectedPayment?.type === 'Klarna' || selectedPayment?.type === 'paypal') && (
                      <Button
                        onClick={() => handleClickPayOverTimeLearnMore(selectedPayment?.type)}
                        classList="payment-option-learn-more ds-override learn-more-button"
                        variant="borderless"
                      >
                        <Tmnt tmnt={tmntData?.payOverTimeLearnMore} />
                      </Button>
                    )}
                  </>
                )}
              </>
            )
          }
        {selectedPayment?.images && (
          <div className="store-images">
            {selectedPayment?.images?.map((image) => (
              <img src={image?.imageSrc} alt={image?.altText} className="store-image" />
            ))}
          </div>
        )}
      </div>
      {selectedPayment?.type === 'credit card' && (
        <CreditCardFields
          isLoggedIn={isLoggedIn}
          creditCardConfig={paymentConfig?.creditCard}
          payment={selectedPayment}
          tmntData={tmntData}
          savedPaymentDetails={savedPaymentDetails}
          setSelectedPayment={setSelectedPayment}
          onChange={onChange}
          onClear={onClear}
        />
      )}
      {selectedPayment?.type === 'Klarna' && (
        <KlarnaFields payment={selectedPayment} />
      )}
      {selectedPayment?.type === 'apm' && (
        <ApmFields payment={selectedPayment} />
      )}
      {selectedPayment?.type !== 'credit card'
      && selectedPayment?.type !== 'Klarna'
      && selectedPayment?.type !== 'apm'
      && (
        <input
          name="payment.paymentCode"
          value={selectedPayment?.code}
          type="hidden"
          data-testid="payment-code"
        />
      )}
    </div>
  );
}

PaymentFields.defaultProps = {
  isLoggedIn: false,
  savedPaymentDetails: null,
  tmntData: {},
  isRadioButtonView: false,
  setSelectedPayment: () => {},
  onChange: () => {},
  onClear: () => {},
};

PaymentFields.propTypes = {
  isLoggedIn: PropTypes.bool,
  selectedPayment: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    code: PropTypes.string,
    description: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    message: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    images: PropTypes.arrayOf(PropTypes.shape({
      altText: PropTypes.string,
      imageSrc: PropTypes.string,
    })),
    icon: PropTypes.string,
    disabled: PropTypes.bool,
    savable: PropTypes.bool,
    default: PropTypes.bool,
  }).isRequired,
  paymentConfig: PropTypes.instanceOf(Object).isRequired,
  setSelectedPayment: PropTypes.func,
  savedPaymentDetails: PropTypes.shape({
    iconCode: PropTypes.string,
    maskedCardNumber: PropTypes.string,
    cardExpiryMonth: PropTypes.string,
    cardExpiryYear: PropTypes.string,
    cardType: PropTypes.string,
    requiresCvv: PropTypes.bool,
    savedPaymentId: PropTypes.string,
    default: PropTypes.bool,
  }),
  tmntData: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  isRadioButtonView: PropTypes.bool,
};
