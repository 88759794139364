// Matches the following:
// 1-234-567-8901
// 1-234-567-8901 x1234
// 1-234-567-8901 ext1234
// 1 (234) 567-8901
// 1.234.567.8901
// 1/234/567/8901
// 754-3010
// (541) 754-3010
// +1-541-754-3010
// 1-541-754-3010
// 001-541-754-3010
// 191 541 754 3010
// 636-48018
// (089) / 636-48018
// +49-89-636-48018
// 19-49-89-636-48018
// 1234567890

// using existing code from checkout validation in CRS
export default function isValidPhone(phone) {
  const unFormatedPhoneNumber = phone.replace(/[^0-9.]/g, '');
  return (unFormatedPhoneNumber.length >= 3 && unFormatedPhoneNumber.length <= 18);
}
