import { useCallback, useMemo } from 'react';
import useScript from '../components/Helpers/useScript';
import $window from '../tools/window';

export default function useGeocoder({ apiKey }) {
  const scriptUrl = useMemo(() => `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&loading=async`, [apiKey]);

  const [,, scriptPromise] = useScript({
    id: 'google-maps',
    src: scriptUrl,
    hasCacheBusting: false,
    attrs: {
      async: true,
      defer: true,
    },
  });

  const getGeocoder = useCallback(async () => {
    await scriptPromise;
    if (!$window.google) {
      throw new Error('google-maps-not-loaded');
    }
    return new $window.google.maps.Geocoder();
  }, [scriptPromise]);

  const getCurrentPosition = useCallback(async () => new Promise((resolve, reject) => {
    if (!$window.navigator?.geolocation) {
      reject(new Error('geoposition-error'));
      return;
    }

    function resolveOrRejectGeolocation(geolocation) {
      const type = typeof geolocation;
      if (type !== 'object' || (type === 'object' && geolocation === null)) {
        reject(new Error('geoposition-error'));
        return;
      } if (type === 'object' && Array.isArray(geolocation)) {
        if (geolocation.length === 0) {
          reject(new Error('zipcode-error'));
          return;
        }
        const geolocationObject = {
          coords: {
            latitude: geolocation[0],
            longitude: geolocation[1],
          },
        };
        resolveOrRejectGeolocation(geolocationObject);
      }

      const hasCoordinates = (
        geolocation.coords && geolocation.coords.latitude && geolocation.coords.longitude
      );
      if (hasCoordinates) {
        resolve(geolocation);
      } else {
        reject(new Error('invalid'));
      }
    }

    $window.navigator.geolocation.getCurrentPosition(resolveOrRejectGeolocation, reject);
  }), []);

  const getAddress = useCallback(async (geolocation) => {
    const geocoder = await getGeocoder();

    return new Promise((resolve) => {
      const latLng = new $window.google.maps.LatLng(
        geolocation.coords.latitude,
        geolocation.coords.longitude,
      );

      function processGeoFullAddess(results) {
        const address = {};

        const addressComponents = (
          results
            .map((result) => result.address_components)
            .reduce((acc, val) => acc.concat(val), [])
            .filter((component) => component?.types?.length > 0)
        );

        addressComponents.forEach((component) => {
          const [resultType] = component.types;

          if (resultType === 'country' && !(address.country && address.countryAbbr)) {
            address.country = component.long_name;
            address.countryAbbr = component.short_name;
          }
          if ((resultType === 'postal_code' || resultType === 'postal_code_prefix') && !(address.postalCode)) {
            address.postalCode = component.long_name;
          }
          if ((resultType === 'postal_town' || resultType === 'locality') && !(address.postalTown)) {
            address.postalTown = component.long_name;
          }
          if (resultType === 'administrative_area_level_1' && !(address.state && address.stateAbbr)) {
            address.state = component.long_name;
            address.stateAbbr = component.short_name;
          }
        });

        address.latlng = [latLng.lat(), latLng.lng()];

        resolve(address);
      }

      geocoder.geocode({ latLng }, processGeoFullAddess);
    });
  }, [getGeocoder]);

  const findMe = useCallback(async () => {
    const geolocation = await getCurrentPosition();
    return getAddress(geolocation);
  }, [getCurrentPosition, getAddress]);

  const geocodeAddress = useCallback(async (address, region) => {
    const geocoder = await getGeocoder();
    const geocodingResults = await new Promise((resolve) => {
      // TODO: if the geocoding fails, we should retry with a different region
      // by adding ', ' + region to the address or if that doesn't work, try
      // putting region + ', ' + address for AT country
      geocoder.geocode({ address, region }, resolve);
    });

    if (!geocodingResults || !geocodingResults.length) {
      throw new Error('no-results');
    }

    const latitude = geocodingResults[0]?.geometry?.location?.lat();
    const longitude = geocodingResults[0]?.geometry?.location?.lng();

    if ([latitude, longitude].filter((v) => typeof v === 'number').length !== 2) {
      throw new Error('invalid');
    }

    const geolocation = {
      coords: {
        latitude,
        longitude,
      },
    };

    return geolocation;
  }, [getGeocoder]);

  const getAddressGeolocation = useCallback(async (address, region) => {
    const geolocation = await geocodeAddress(address, region);
    return getAddress(geolocation);
  }, [geocodeAddress, getAddress]);

  const getCurrentGeolocation = useCallback(async () => {
    const cookie = $window?.document?.cookie;
    const geolocationCookieValue = cookie?.match(/geolocation=([^;]*)/i)?.[1];
    if (!geolocationCookieValue) {
      return null;
    }
    const [country, state, postalCode] = geolocationCookieValue?.split(',');
    const addressValue = [state, postalCode].filter((v) => !!v).join(', ');
    return getAddressGeolocation(addressValue, country);
  }, [getAddressGeolocation]);

  return {
    findMe,
    getAddressGeolocation,
    getCurrentGeolocation,
  };
}
