import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import PaymentManager from './PaymentManager';

export default function PaymentManagerPortal({ id }) {
  if (typeof document !== 'undefined' && !!document.getElementById(id)) {
    return ReactDOM.createPortal(
      <PaymentManager />,
      document.getElementById(id),
    );
  }
  return null;
}

PaymentManagerPortal.defaultProps = {
  id: '',
};

PaymentManagerPortal.propTypes = {
  id: PropTypes.string,
};
