import React, { useContext } from 'react';
import OrderContactInfo from './OrderContactInfo';
import CheckoutPageContext from '../../../context/CheckoutPageContext';
import Loader from '../../Common/Loader/Loader';

export default function OrderContactInfoWrapper() {
  const {
    checkoutPageState, loading,
  } = useContext(CheckoutPageContext);
  if (loading || !checkoutPageState.orderContact) {
    return (
      <div className="order-contact-wrapper" data-testid="order-contact-loader">
        <Loader classList="loader-order-contact" />
      </div>
    );
  }

  return (
    <div className="order-contact-wrapper" data-testid="order-contact-info">
      <OrderContactInfo
        orderContactData={checkoutPageState.orderContact}
        userData={checkoutPageState.userData}
        hasOnlyEGiftCard={checkoutPageState?.bagItems?.hasOnlyEGiftCard}
        isSmsOptInEnabled={checkoutPageState?.smsOptIn?.isEnabled}
      />
    </div>
  );
}
