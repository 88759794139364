import React, {
  useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import PayOverTime from '../Common/PayOverTime/PayOverTime';
import CheckoutPageContext from '../../context/CheckoutPageContext';
import OrderTotals from '../Common/OrderTotals/OrderTotals';
import Promotions from '../Common/Promotions/Promotions';
import MarketingSpot from '../Common/MarketingSpot/MarketingSpot';
import BagList from '../Common/BagList/BagList';
import { useIsL } from '../../hooks/useBreakPoint';
import Tmnt from '../Tmnt/Tmnt';

export default function CheckoutRightRail({
  parentClass,
  showPayOverTime,
}) {
  const [mounted, setMounted] = useState(false);
  const isLBreakPoint = useIsL();
  const {
    checkoutPageState,
    setCheckoutPageState,
    setShowDiscount,
    showDiscount,
    showNotification,
    setShowNotification,
  } = useContext(CheckoutPageContext);

  useEffect(() => {
    setMounted(true);
  }, []);

  const getSelectedShipInfo = (shippingSpeed) => {
    const selectedShipInfo = shippingSpeed?.filter((d) => d?.isSelected);
    return selectedShipInfo?.length ? selectedShipInfo[0] : {};
  };

  if (!mounted) { return null; }

  return (
    <div className={`checkout-mfe-web-service ${parentClass}`}>
      {!isLBreakPoint && checkoutPageState?.textFor
      && checkoutPageState?.switches?.showCheckoutBagList && (
        <>
          <h2 className="header-bag-text">
            <Tmnt tmnt={checkoutPageState?.textFor?.yourBag} />
          </h2>
          <div data-testid="baglist" className="checkout-baglist">
            <BagList
              variant="rightRail"
              bagItems={checkoutPageState?.bagItems?.items}
              tmntData={checkoutPageState?.textFor}
              shippingInfo={getSelectedShipInfo(checkoutPageState?.shippingSpeed)}
            />
          </div>
        </>
      )}
      {checkoutPageState?.textFor && checkoutPageState?.switches?.showCheckoutPromos && (
        <>
          <Promotions
            className="checkoutpage-promo"
            purchaseContext={checkoutPageState}
            setPurchaseContext={setCheckoutPageState}
            tmntData={checkoutPageState?.textFor}
            accordionVariant="bordered"
            showDiscount={showDiscount}
            setShowDiscount={setShowDiscount}
            showNotification={showNotification}
            setShowNotification={setShowNotification}
            page={checkoutPageState?.page}
          />
          <MarketingSpot
            espotId="checkout_memberpricing_belowpromotions"
            espotData={checkoutPageState?.eSpots?.checkoutMemberPricingBelowPromotions}
          />
        </>
      )}
      {checkoutPageState?.switches?.showOrderSummary && (
      <OrderTotals
        cartTotalAmount={checkoutPageState?.orderTotals?.cartTotalAmount}
        tmntData={checkoutPageState?.textFor}
        promoAmountFmt={checkoutPageState?.orderTotals?.promoAmountFmt}
        cartPayableAmount={checkoutPageState?.orderTotals?.grandTotalFmt}
        includesConsumptionTax={
          checkoutPageState?.orderTotals?.includesConsumptionTax
        }
        charityRoundUpFmt={checkoutPageState?.orderTotals?.charityTotalFmt}
        includesGst={checkoutPageState?.orderTotals?.includesGst}
        includesVat={checkoutPageState?.orderTotals?.includesVat}
        showDisclosure={!!checkoutPageState?.config?.showDisclosure}
        isVatIncluded={checkoutPageState?.config?.isVatIncluded}
        totalItemsInBag={checkoutPageState?.orderTotals?.totalItemsInBag}
        shippingHandlingChargeFmt={checkoutPageState?.orderTotals?.shippingHandlingChargeFmt}
        estimatedTaxAmountFmt={checkoutPageState?.orderTotals?.estimatedTaxAmountFmt}
        isCheckoutPage
        shippingModeType={checkoutPageState?.shipMethod?.shipModeType}
        totalGiftBoxesAmount={checkoutPageState?.orderTotals?.totalGiftBoxesAmount}
        totalGiftBoxes={checkoutPageState?.orderTotals?.totalGiftBoxes}
        giftCards={checkoutPageState?.giftCards?.appliedGiftCards}
        displayFreeShippingText={checkoutPageState?.orderTotals?.displayFreeShippingText}
        displayGiftReceiptText={checkoutPageState?.orderTotals?.displayGiftReceiptText}
        vatDetails={checkoutPageState?.vatDetails}
      />
      )}
      {showPayOverTime && checkoutPageState?.switches?.showPayOverTime && (
        <div data-testid="pay-overtime-check-rr" className="scope-1892">
          <PayOverTime
            page="checkout"
            inline
            purchaseAmount={checkoutPageState?.orderTotals?.subTotal}
            hasGiftCard={checkoutPageState?.bagItems?.hasGiftCard}
          />
        </div>
      )}
    </div>
  );
}

CheckoutRightRail.defaultProps = {
  parentClass: 'checkout-right-rail',
  showPayOverTime: true,
};

CheckoutRightRail.propTypes = {
  parentClass: PropTypes.string,
  showPayOverTime: PropTypes.bool,
};
