import React from 'react';
import PropTypes from 'prop-types';
import PageHeaderError from '../../Common/PageHeader/PageHeaderError';

export default function PageHeader({
  classes,
  tmntKey,
  value,
  children,
}) {
  return (
    <div className="page-header">
      <div className="page-header-wrapper">
        <h1 className={`${classes} h1`} data-property={tmntKey} data-testid="headline">
          {value}
        </h1>
        {children}
      </div>
      <PageHeaderError />
    </div>
  );
}

PageHeader.defaultProps = {
  classes: '',
  children: null,
};

PageHeader.propTypes = {
  classes: PropTypes.string,
  tmntKey: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
  children: PropTypes.node,
};
