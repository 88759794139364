import React, {
  useState, useMemo, useEffect, useRef, useContext, useCallback,
} from 'react';
import {
  useQuery,
  useMutation,
} from '@apollo/client';
import { CSSTransition } from 'react-transition-group';
import $window from '../../tools/window';
import CheckoutButton from '../Common/CheckoutButton/CheckoutButton';
import FreeShipping from '../Common/FreeShipping/FreeShipping';
import BagContext from '../../context/BagContext';
import Loader from '../Common/Loader/Loader';
import LeftRail from './LeftRail/LeftRail';
import RightRail from './RightRail/RightRail';
import {
  LOADING_MESSAGE as loadingMessage,
} from '../Common/Messages/Messages';
import MfeErrorMessage from './MfeErrorMessage/MfeErrorMessage';

import { SHOPPINGBAG_DATA_QUERY, SUBMIT_BAG_MUTATION } from '../../gql/shoppingBag.gql';
import useLog from '../useLog/useLog';
import MarketingSpot from '../Common/MarketingSpot/MarketingSpot';
import trackAction from '../../tools/analytics';
import submitBagMutationCompleted from './ShoppingBagHelpers/SubmitBagMutationHelper';
import SwitchTestContext from '../../context/SwitchTestContext';
import HandlePXPPayment from '../Common/PXP/HandlePXPPayment';

export default function ShoppingBag() {
  const shoppingBagCtaRef = useRef(null);
  const stickyCheckoutContainerRef = useRef(null);
  const bagRef = useRef(null);
  const [bag, setBag] = useState(null);
  const [savedForLater, setSavedForLater] = useState(null);
  const [showDiscount, setShowDiscount] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [isStickyVisible, setIsStickyVisible] = useState(true);
  const logger = useLog('shoppingBag.root');
  const hasCheckoutButtonCartPut = bag?.switches?.hasCheckoutButtonCartPut;
  const {
    digitalData,
  } = useContext(SwitchTestContext);

  const updateBagPageState = useCallback((newData) => {
    setBag((prev) => ({ ...prev, ...newData }));
  }, []);

  const [startTime, setStartTime] = useState(0);

  const updateBagCount = (bagItems) => {
    const updateItemCount = new CustomEvent(
      'miniBag:updateItemCount:done',
      {
        detail: bagItems,
        cancelable: false,
      },
    );
    window.dispatchEvent(updateItemCount);
  };

  const [submitBagMutation] = useMutation(SUBMIT_BAG_MUTATION, {

    onCompleted: (submitBagMutationObj) => {
      // Debug the result of the mutation
      logger.debug('SUBMIT_BAG_MUTATION RESULT', submitBagMutationObj);

      // PURX-1666: TODO clean up this switch once stable in prod
      const hasBagRepudiationsUpdate = digitalData && digitalData['chk-bag-repudiations-update'];

      if (hasBagRepudiationsUpdate) {
        // handle the response of the mutation
        submitBagMutationCompleted(
          submitBagMutationObj,
          setBag,
          bag,
          updateBagCount,
        );
      } else {
        // old flow
        const submitBagRepudiation = submitBagMutationObj?.submitBag?.repudiationData;
        if ((submitBagRepudiation?.repudiationType === 'SOLDOUT_ITEM' || submitBagRepudiation?.repudiationType === 'SFS_SOLDOUT_ITEM')
      && submitBagRepudiation?.errorMessage !== '') {
        // SOLDOUT_ITEM repudiation even loads when we load the bag for the first time,
        // resolver doesn't return errorMessage then. If inventoryRepudiation data comes back
        // from PUT cart data, then only we set errorMessage. Hence checking this scenario to check
        // if this is an inventoryRepudiation scenario or not
          const updateSaveForLater = new CustomEvent('shoppingBag:submitBagMutation:done', { detail: 'success' });
          window.dispatchEvent(updateSaveForLater);
          setBag((previousState) => {
            const newState = {
              ...previousState,
              orderTotals: submitBagMutationObj?.submitBag?.orderTotals,
              bagItems: submitBagMutationObj?.submitBag?.bagItems,
              repudiationData: submitBagRepudiation,
              promoInfo: submitBagMutationObj?.submitBag?.promoInfo,
              rewardsAndPromotions: submitBagMutationObj?.submitBag?.rewardsAndPromotions,
              freeShippingProgressInfo: submitBagMutationObj?.submitBag?.freeShippingProgressInfo,
            };

            // merge the bag response to digitalData `cart`
            $window.digitalData?.merge('cart', {
              origin: 'bag',
              data: newState,
              action: 'bag_repudiation',
            });

            return newState;
          });

          updateBagCount(submitBagMutationObj?.submitBag?.bagItems);
        } else if (submitBagMutationObj?.submitBag?.success !== null && submitBagRepudiation?.repudiationType !== '') {
          // if PUT call fails, then only we generate responseInfo from cart source. Hence checking
          // if we have responseInfo object or not from the submitBag operation. If so, then set
          // networkResponse with error information.
          setBag((previousState) => ({
            ...previousState,
            statusCode: submitBagMutationObj?.submitBag?.statusCode,
            success: submitBagMutationObj?.submitBag?.success,
            statusMessages: submitBagMutationObj?.submitBag?.statusMessages,
          }));
        } else {
        // This takes in every other conditions outside of these two scenarios above.
        // It takes us to checkoutUrl as part of normal repudiation flow.
          window.location.href = bag?.checkoutButton?.checkoutUrl;
        }
      }
    },
    onError: (error) => logger.error('ERR: SUBMIT_BAG_MUTATION', error),
  });

  const bagProviderValues = useMemo(() => ({
    bag,
    setBag,
    showDiscount,
    setShowDiscount,
    showNotification,
    setShowNotification,
    savedForLater,
    setSavedForLater,
  }), [bag, savedForLater, showDiscount, showNotification]);
  const {
    loading, error, data: queryData,
  } = useQuery(SHOPPINGBAG_DATA_QUERY, {
    fetchPolicy: 'no-cache',
    context: { batch: true },
    ssr: false,
    onCompleted: (res) => {
      const endTime = performance.now();
      const loadingTime = endTime - startTime;
      logger.debug(`Time taken to load bag: ${loadingTime} milliseconds`);
      logger.debug('SHOPPINGBAG_DATA_QUERY RESULT', res);
      if (Object.keys(res?.promoInfo).length !== 0) {
        setShowDiscount(true);
      }
      const resBag = { ...res };
      const cartBrands = Array.from(new Set(resBag?.bagItems?.items?.map((item) => item?.item?.productContent.brand))).join(',');
      const kicIds = Array.from(new Set(resBag?.bagItems?.items?.map((item) => item?.item?.productContent.kicId))).join(',');
      const totalItemsInBagAsNumber = parseInt(resBag?.orderTotals?.totalItemsInBag, 10);

      // Triggering MiniBag update item count
      const updateItemCount = new CustomEvent('miniBag:updateItemCount:done', { detail: resBag?.bagItems });
      window.dispatchEvent(updateItemCount);

      // Triggerring the event to update cart model in CRS:
      try {
        if (resBag?.cartInfo) {
          const cartLoaded = new CustomEvent('mfe:cart:get:done', { detail: resBag.cartInfo });
          window.dispatchEvent(cartLoaded);

          // only set it if it's not already set by CRS
          // eslint-disable-next-line no-undef
          if (ANF?.models && !ANF.models.cart) {
            // eslint-disable-next-line no-undef
            ANF.models.cart = resBag.cartInfo;
          }
        }
      } catch (e) {
        logger.error('Error while updating cart model in CRS', e);
      }

      // adding this trackAction to trigger data for Adobe Target and namagoo
      trackAction('bag_loaded', {
        ...resBag?.orderTotals,
        totalItems: totalItemsInBagAsNumber,
        cartBrands,
        kicIds,
      });
      setBag(resBag);

      // merge the bag state to digitalData `cart`
      $window.digitalData?.merge('cart', {
        origin: 'bag',
        data: resBag,
        action: 'load',
      });
    },
    onError: (err) => {
      logger.error(`ERR: SHOPPINGBAG_DATA_QUERY: ${JSON.stringify(err)}`);
    },
  });

  const hasItemsInBag = bag?.bagItems?.items && Object.keys(bag?.bagItems?.items).length !== 0;

  useEffect(() => {
    setStartTime(performance.now());
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const stickyContainer = stickyCheckoutContainerRef.current;
      const shoppingBagContent = shoppingBagCtaRef.current;
      if (stickyContainer && shoppingBagContent) {
        const containerTop = shoppingBagContent.getBoundingClientRect().top;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (containerTop - scrollTop <= 0) {
          setIsStickyVisible(false);
        } else {
          setIsStickyVisible(true);
        }
      }
    };
    handleScroll();

    // attaching window scroll event listener to handle stickiness
    window.addEventListener('scroll', handleScroll);
    return () => {
      // de-attaching window scroll event listener to handle stickiness
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const bagItemCount = bag?.bagItems?.items && Object.keys(bag?.bagItems?.items).length !== 0 ? bag?.bagItems?.items?.length : '0';
  const isEmptyBag = (bagItemCount === '0');

  if (error) return (<MfeErrorMessage />);
  return (
    <BagContext.Provider value={bagProviderValues}>
      <div className="shopping-bag scope-1892">
        <div className="shopping-bag-content" data-testid="shopping-bag-content">
          <section className="content-area" role="main">
            <CSSTransition
              nodeRef={bagRef}
              timeout={1000}
              classNames="fade"
              in={!loading}
            >
              <div ref={bagRef}>
                {loading ? <Loader variant="shimmer" contentRows={2} /> : null}
                {!loading && (!queryData || !bag) ? loadingMessage : null}
                {!loading && bag ? (
                  <>
                    {/* espot content */}
                    <MarketingSpot
                      espotId="bag_fix"
                      espotData={bag?.eSpots?.cartFix}
                    />
                    <div id="shoppingcart-content-spot">
                      <MarketingSpot
                        espotId="bag_top"
                        espotData={bag?.eSpots?.cartTop}
                      />
                    </div>
                    {/* espot content */}
                    <>
                      <div data-module-loading="false" />
                      <div className="bag-area" data-empty-bag={isEmptyBag}>
                        <LeftRail />
                        <RightRail
                          shoppingBagCtaRef={shoppingBagCtaRef}
                          submitBagMutation={submitBagMutation}
                          updateBagPageState={updateBagPageState}
                        />
                      </div>
                    </>
                    {/* espot content */}
                    <div id="cart-touts-bottom">
                      <MarketingSpot
                        espotId="bag_tout_bottom"
                        espotData={bag?.eSpots?.cartToutsBottom}
                      />
                    </div>
                    {/* espot content */}
                  </>
                ) : null}
              </div>
            </CSSTransition>
          </section>
        </div>
      </div>
      {!loading && hasItemsInBag && (
        <div data-testid="sticky-checkout-container" ref={stickyCheckoutContainerRef} className={`sticky-checkout-container scope-1892 ${isStickyVisible ? 'sticky-slide' : ''}`}>
          {/* Freeshipping progress bar block start */}
          {bag?.freeShippingProgressInfo
            ?.amountToFreeShippingFmt ? (
              <FreeShipping
                isVisible={bag?.freeShippingProgressInfo.isVisible}
                amountToFreeShipping={bag
                  ?.freeShippingProgressInfo.amountToFreeShipping}
                tmntData={bag?.textFor}
                amountToFreeShippingFmt={bag?.freeShippingProgressInfo
                  .amountToFreeShippingFmt}
                maxProgress={bag?.freeShippingProgressInfo.maxProgress}
                minProgress={bag?.freeShippingProgressInfo.minProgress}
                preLabel={bag?.freeShippingProgressInfo.preLabel}
                postLabel={bag?.freeShippingProgressInfo.postLabel}
                progressValue={bag?.freeShippingProgressInfo.progressValue}
              />
            ) : ''}
          <CheckoutButton
            checkoutUrl={bag?.checkoutButton.checkoutUrl}
            brand={bag?.checkoutButton.brand}
            variant={bag?.checkoutButton.variant}
            tmntData={bag?.textFor}
            isDisabled={!hasItemsInBag}
            submitBagMutation={submitBagMutation}
            hasCheckoutButtonCartPut={hasCheckoutButtonCartPut}
          />
        </div>
      )}
      <HandlePXPPayment />
    </BagContext.Provider>
  );
}
