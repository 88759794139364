import React from 'react';
import PropTypes from 'prop-types';
import {
  Icon, IconBlock,
} from 'anf-core-react';

export default function SocialProofingMessage({
  brand, message,
}) {
  const flameIconBrand = brand === 'anf' ? 'flame-anf' : 'flame-hco';

  return (
    message
      ? (
        <div className="social-proofing-message fadein">
          <div className=" social-proofing-icon content bump">
            <IconBlock icon={<Icon icon={flameIconBrand} size="s" className="social-proofing-icon" />}>
              <span className="h3">{message}</span>
            </IconBlock>
          </div>
        </div>
      )
      : null
  );
}

SocialProofingMessage.defaultProps = {
  brand: 'anf',
};

SocialProofingMessage.propTypes = {
  brand: PropTypes.string,
  message: PropTypes.string.isRequired,
};
