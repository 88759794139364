import { useMutation } from '@apollo/client';
import SHIPPING_SPEED_UPDATE_MUTATION from '../../gql/shippingSpeed.gql';
import useLog from '../useLog/useLog';
import $window from '../../tools/window';

// Custom hook definition
const useUpdateShippingSpeed = (updateStateFunction, fetchShippingSpeed) => {
  const logger = useLog('checkout.useUpdateShippingSpeed');
  const [updateShippingSpeedMutation,
    { loading, error, data },
  ] = useMutation(SHIPPING_SPEED_UPDATE_MUTATION, {
    onCompleted: ({ updateShippingSpeed }) => {
      if (updateShippingSpeed?.success) {
        logger.debug('updateShippingSpeedMutation RESULT', updateShippingSpeed.shippingSpeed);
        updateStateFunction(updateShippingSpeed); // Use the passed function to update state
        $window.digitalData?.merge('cart', {
          origin: 'checkout',
          data: updateShippingSpeed,
          action: 'shipping_speed_update',
        });
        // Trigger custom event with updated cart data for crs to capture
        const shippingSpeedDoneEvent = new CustomEvent('shippingSpeed:update:done', { detail: updateShippingSpeed.cartInfo });
        window.dispatchEvent(shippingSpeedDoneEvent);
      }
    },
    onError: (err, { variables }) => {
      logger.error(`ERR: updateShippingSpeedMutation: ${JSON.stringify(err)}`);
      if (variables.fromPhoenix) {
        window.dispatchEvent(
          new CustomEvent('shippingSpeed:retryUpdate', { detail: { variables } }),
        );
      } else {
        // Assuming fetchShippingSpeed is available in the scope or passed as a parameter
        fetchShippingSpeed();
      }
    },
  });

  return [updateShippingSpeedMutation, { loading, error, data }];
};

export default useUpdateShippingSpeed;
