import React from 'react';
import PropTypes from 'prop-types';
import ShippingSpeedEstimatedDeliveryDate from './ShippingSpeedEstimatedDeliveryDate';
import ShippingSpeedOption from './ShippingSpeedOption';
import shippingSpeedShape from './shippingSpeedShape';

function updateShippingSpeedText(shippingSpeed, textFor) {
  const {
    pickupInStoreBrandTMNTValue,
    pickupServiceProviderTMNTValue,
    pickupInStoreTMNTValue,
    pickupPointTMNTValue,
  } = textFor;

  const updatedShippingSpeed = { ...shippingSpeed };

  if (updatedShippingSpeed.shipModeType === 'Popins') {
    // Popins = Pickup in Store
    updatedShippingSpeed.description = pickupInStoreTMNTValue?.value;
    updatedShippingSpeed.subText = pickupInStoreBrandTMNTValue?.value;
  } else {
    // PUDOS = Pickup Point
    updatedShippingSpeed.description = pickupPointTMNTValue?.value;
    updatedShippingSpeed.subText = pickupServiceProviderTMNTValue?.value;
  }

  return updatedShippingSpeed;
}

export default function PickupShippingSpeedOption({
  shippingSpeed, onChangeShippingSpeed, textFor, ...props
}) {
  const updatedShippingSpeed = updateShippingSpeedText(shippingSpeed, textFor);

  return (
    <ShippingSpeedOption
      shippingSpeed={updatedShippingSpeed}
      onChangeShippingSpeed={onChangeShippingSpeed}
      {...props}
    >
      <ShippingSpeedEstimatedDeliveryDate
        estimatedDeliveryDateDisplay={`${updatedShippingSpeed.subText}`}
      />
    </ShippingSpeedOption>
  );
}

PickupShippingSpeedOption.defaultProps = {
  shippingSpeed: undefined,
  onChangeShippingSpeed: undefined,
  textFor: {
    pickupInStoreBrandTMNTValue: {
      key: 'GLB_POPINS_STORE_BRAND',
      value: 'A&F or abercrombie kids store',
    },
    pickupServiceProviderTMNTValue: {
      key: 'GLB_POPINS_STORE_BRAND',
      value: 'A&F or abercrombie kids store',
    },
    pickupInStoreTMNTValue: {
      key: 'GLB_POPINS_STORE_BRAND',
      value: 'A&F or abercrombie kids store',
    },
    pickupPointTMNTValue: {
      key: 'GLB_UPS_OR_ROYAL_MAIL',
      value: 'Fedex Service Provider',
    },
  },
};

PickupShippingSpeedOption.propTypes = {
  shippingSpeed: shippingSpeedShape,
  onChangeShippingSpeed: PropTypes.func,
  textFor: PropTypes.shape({
    pickupInStoreBrandTMNTValue: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    pickupServiceProviderTMNTValue: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    pickupInStoreTMNTValue: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    pickupPointTMNTValue: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
};
