import {
  gql,
} from '@apollo/client';

const ADDRESS_VERIFICATION_QUERY = gql`
query VerifyAddress(
  $enteredAddress: VerifyAddressInput,
  $acceptThreshold: String
  ) {
  verifyAddress(enteredAddress: $enteredAddress, acceptThreshold: $acceptThreshold) {
    addressSuggestions {
      address {
        displayAddress
        address1
        address2
        city
        state
        country
        postalCode
        province
      }
      summary {
        promptUser
        useEnteredAddress
        hasSuggestion
        score
        rating
        hasTags
      }
    }
    summary {
      promptUser
      useEnteredAddress
      hasSuggestion
      score
      rating
      hasTags
    }
    changedAddress
  }
  textFor {
    saveAndSubmitOrder: pair (pairKey: "saveAndSubmitOrder") {...values}
    confirmAddress: pair (pairKey: "confirmAddress") {...values}
    youEntered: pair (pairKey: "youEntered") {...values}
    weSuggest: pair (pairKey: "weSuggest") {...values}
    addressVerification: pair (pairKey: "addressVerification") {...values}
    verifyAddress: pair (pairKey: "verifyAddress") {...values}
    editAddress: pair (pairKey: "editAddress") {...values}
    useThisAddress: pair (pairKey: "useThisAddress") {...values}
    addressUnableVerify: pair (pairKey: "addressUnableVerify") {...values}
  }
}
fragment values on TextPair {
  key
  value
}
`;

export default ADDRESS_VERIFICATION_QUERY;
