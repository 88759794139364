export default function getOrderConfirmationProductInfo(cartItemDetails = []) {
  const sortedDetailsObj = cartItemDetails.reduce((acc, cartItem) => {
    const itemKicId = cartItem.item.image.imageId;
    const price = cartItem.item.productContent.productPrice.discount.toString();

    if (!acc[itemKicId]) {
      acc[itemKicId] = { quantity: 0, price };
    }
    acc[itemKicId].quantity += 1;
    return acc;
  }, {});

  const products = Object.entries(sortedDetailsObj).map(([kic, detail]) => ({
    kic,
    price: detail.price,
    quantity: detail.quantity,
  }));

  return products;
}
