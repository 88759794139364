import React from 'react';
import PropTypes from 'prop-types';
import {
  StoreCard,
  Button,
  Icon,
  IconButton,
  Disclosure,
} from 'anf-core-react';
import {
  formatHours,
  formatDayOfWeek,
  getPudosLocationImage,
} from '../../../tools/pickupHelper';

const remoteLocationProp = PropTypes.shape({
  address: PropTypes.shape({
    city: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    postalCode: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    street: PropTypes.string.isRequired,
  }).isRequired,
  distance: PropTypes.string.isRequired,
  geoPosition: PropTypes.shape({
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
  }).isRequired,
  id: PropTypes.string.isRequired,
  mapAndHoursUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
  operatingHours: PropTypes.arrayOf(
    PropTypes.shape({
      dayOfWeek: PropTypes.string.isRequired,
      closeHours: PropTypes.string.isRequired,
      openHours: PropTypes.string.isRequired,
      sortSeq: PropTypes.number.isRequired,
    }),
  ).isRequired,
  providerType: PropTypes.string.isRequired,
  sortingId: PropTypes.number.isRequired,
});

const textProp = PropTypes.shape({
  key: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

const defaultProps = {
  selectedDisclosure: undefined,
};

const propTypes = {
  location: remoteLocationProp.isRequired,
  isSelected: PropTypes.bool.isRequired,
  isDisclosureExpanded: PropTypes.bool.isRequired,
  selectedDisclosure: PropTypes.string,
  buttonValue: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  text: PropTypes.shape({
    cityStateZip: textProp,
    findingStoresNear: textProp,
    list: textProp,
    loadMore: textProp,
    locationsNear: textProp,
    locationServicesBlocked: textProp,
    map: textProp,
    mapAndHours: textProp,
    nearMe: textProp,
    noStoresFound: textProp,
    or: textProp,
    pickupUnavailable: textProp,
    pudosLegal: textProp,
    searching: textProp,
    selectPickupLocation: textProp,
    selectStore: textProp,
    selectedStore: textProp,
    storeHours: textProp,
    you: textProp,
  }).isRequired,
  onInfoWindowClose: PropTypes.func.isRequired,
  onSetSelectedStore: PropTypes.func.isRequired,
  onToggleMapDisclosure: PropTypes.func.isRequired,
};

export default function InfoWindow({
  location,
  isSelected,
  isDisclosureExpanded,
  selectedDisclosure,
  buttonValue,
  brand,
  text,
  onInfoWindowClose,
  onSetSelectedStore,
  onToggleMapDisclosure,
}) {
  return (
    <div className="info-window" data-testid="info-window">
      <StoreCard
        brand={brand}
        storeName={location.name}
        storeMap={(
          <a href={location.mapAndHoursUrl} target="_blank" rel="noreferrer">
            {text.mapAndHours.value}
          </a>
        )}
        firstLogo={(
          <img
            src={getPudosLocationImage(location)}
            alt={location.providerType}
          />
        )}
      >
        <div className="first-row store-name">
          <Icon
            icon="location-anf"
            labelText="location"
            size="s"
          />
          <p className="info-window-location-name" data-testid="info-window-location-name">
            {location.name}
          </p>
          <div className="info-window-close-icon">
            <IconButton
              icon="close-thin"
              labelText="close"
              theme="inverse"
              variant="square"
              onClick={onInfoWindowClose}
            />
          </div>
        </div>

        <p className="secondary-row">
          {location.address.street}
        </p>
        <p className="secondary-row">
          {`${location.address.city}, ${location.address.country}`}
        </p>

        <div className="secondary-row info-window-disclosure-wrapper">
          <Disclosure
            id="info-window-store-hours-disclosure"
            label={text.storeHours.value}
            onClick={() => onToggleMapDisclosure(location.id)}
            isExpanded={
              isDisclosureExpanded && (selectedDisclosure === location.id)
            }
          >
            <table className="info-window-store-hours" role="presentation" data-testid="info-window-store-hours">
              <tbody>
                {location.operatingHours.map((hoursObj, i) => {
                  const key = `${location.name}-hours-${i}`;
                  return (
                    <tr key={key}>
                      <td className="day-of-week">{`${formatDayOfWeek(hoursObj.dayOfWeek)} `}</td>
                      <td>{`${formatHours(hoursObj.openHours)} - ${formatHours(hoursObj.closeHours)}`}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Disclosure>
        </div>
        <Button
          classList="select-store-button"
          variant={isSelected ? 'secondary' : 'tertiary-dark'}
          labelText={buttonValue}
          onClick={(e) => onSetSelectedStore(e, location)}
          isDisabled={isSelected}
          isFullWidth
        >
          <div>{buttonValue}</div>
        </Button>
      </StoreCard>
    </div>
  );
}
InfoWindow.defaultProps = defaultProps;
InfoWindow.propTypes = propTypes;
