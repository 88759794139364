import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  FieldGroup,
  ErrorMessage,
} from 'anf-core-react';
import CardNumber from './CardNumber';
import ExpiryField from './ExpiryField';
import CVVField from './CVVField';

export default function CreditCard({
  index = 0,
  creditCardConfig,
  hasValidationError,
  onChangeCreditCard = () => {},
}) {
  const [creditCardNumber, setCreditCardNumber] = useState('');
  const [cardBrand, setCardBrand] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [cvv, setCVV] = useState('');

  const handleCreditCardNumberChange = (formatCardNumber) => {
    setCreditCardNumber(formatCardNumber?.formattedNumber || '');
    setCardBrand(formatCardNumber?.type || '');
    onChangeCreditCard(formatCardNumber);
  };

  return (
    <div className="credit-card-fields">
      <FieldGroup legend="Credit Card Fields" isInvalid={hasValidationError?.card}>
        <CardNumber
          cardNumber={creditCardNumber}
          cardBrand={cardBrand}
          label={creditCardConfig?.cardNumberLabel?.value || ''}
          errorMessage={
            creditCardNumber?.length > 0
              ? (creditCardConfig?.creditCardErrorMessage?.value || '')
              : (creditCardConfig?.creditCardMissingErrorMessage?.value || '')
          }
          index={index}
          onChange={handleCreditCardNumberChange}
          cardTypesList={creditCardConfig?.creditCardTypes}
          hasValidationError={hasValidationError?.cardNumber}
        />
        <ExpiryField
          expiryDate={expiryDate}
          label={creditCardConfig?.expiryLabel?.value || ''}
          errorMessage={creditCardConfig?.expiryErrorMessage?.value || ''}
          index={index}
          onChange={setExpiryDate}
          hasValidationError={hasValidationError?.cardExpiry}
        />
        <CVVField
          cvv={cvv}
          cardBrand={cardBrand}
          label={creditCardConfig?.securityCodeLabel?.value || ''}
          errorMessage={creditCardConfig?.cvvErrorMessage?.value || ''}
          index={index}
          onChange={setCVV}
          tooltipMessage={creditCardConfig?.securityCodeMessage?.value || ''}
          tooltipImage={creditCardConfig?.securityCodeImage || ''}
          hasValidationError={hasValidationError?.cardCvv}
        />
        {hasValidationError?.card && (
          <ErrorMessage id="cc-error-message">
            {creditCardConfig?.unableToProcessCardErrorMessage?.value || ''}
          </ErrorMessage>
        )}
      </FieldGroup>
    </div>
  );
}

CreditCard.defaultProps = {
  hasValidationError: {},
};

CreditCard.propTypes = {
  index: PropTypes.number.isRequired,
  creditCardConfig: PropTypes.shape({
    cardNumberLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    expiryLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    securityCodeLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    securityCodeMessage: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    creditCardMissingErrorMessage: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    creditCardErrorMessage: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    expiryErrorMessage: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    cvvErrorMessage: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    unableToProcessCardErrorMessage: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    securityCodeImage: PropTypes.string,
    creditCardTypes: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      maxlength: PropTypes.number,
      code: PropTypes.string,
      regex: PropTypes.string,
    })),
  }).isRequired,
  onChangeCreditCard: PropTypes.func.isRequired,
  hasValidationError: PropTypes.shape({
    card: PropTypes.bool,
    cardNumber: PropTypes.bool,
    cardExpiry: PropTypes.bool,
    cardCvv: PropTypes.bool,
  }),
};
