import { getSelectedShippingKey } from './address';

const prepareAuthorizationRequest = (formData, shippingSpeed, klarnaOrder) => {
  const request = { ...klarnaOrder };
  const selectedShippingKey = getSelectedShippingKey(shippingSpeed);
  const shippingAddress = formData?.addresses?.[selectedShippingKey];
  const billingAddress = (formData?.addresses?.billing
    || shippingAddress);
  const billingAddressSameAsShipping = formData?.billingAddressSameAsShipping;
  const isUS = (
    billingAddressSameAsShipping
      ? billingAddress?.country === 'US'
      : shippingAddress?.country === 'US'
  );

  request.billing_address = {
    given_name: billingAddress?.firstName,
    family_name: billingAddress?.lastName,
    email: billingAddress?.email || shippingAddress?.email,
    street_address: billingAddress?.address1,
    street_address2: billingAddress?.address2 ? billingAddress.address2 : '',
    postal_code: billingAddress?.postalCode,
    city: billingAddress?.city,
    region: isUS ? billingAddress?.state : '', // Per Klarna Documentation region is for US only
    phone: billingAddress?.phone || shippingAddress?.phone,
    country: billingAddress?.country,
  };

  if (!billingAddressSameAsShipping) {
    request.shipping_address = {
      given_name: shippingAddress?.firstName,
      family_name: shippingAddress?.lastName,
      email: shippingAddress?.email || billingAddress?.email,
      // NOTE shippingAddress.addresss2 is a read only variable, so I can't
      // test setting it up easily.
      street_address: shippingAddress?.address1,
      street_address2: shippingAddress?.address2 ? shippingAddress?.address2 : '',
      postal_code: shippingAddress?.postalCode,
      city: shippingAddress?.city,
      region: isUS ? shippingAddress?.state : '', // Per Klarna Documentation region is for US only
      phone: shippingAddress?.phone,
      country: shippingAddress?.country,
    };
  }
  // if the shipping type is popins then add the attachment
  if (selectedShippingKey === 'popins') {
    request.attachment = {
      content_type: 'application/vnd.klarna.internal.emd-v2+json',
      body: JSON.stringify({
        other_delivery_address: [{
          shipping_method: 'store pick-up',
          shipping_type: 'express',
          first_name: shippingAddress.firstName,
          last_name: shippingAddress.lastName,
          street_address: shippingAddress.address1,
          street_number: shippingAddress.address2 ? shippingAddress.address2 : '',
          postal_code: shippingAddress.postalCode,
          city: shippingAddress.city,
          country: shippingAddress.country,
        }],
      }),
    };
    delete request.shipping_address;
  }
  return request;
};

export default prepareAuthorizationRequest;
