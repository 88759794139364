import { useState, useEffect, useCallback } from 'react';
import DEBUG_KEYS from './debugKeys';

const useDebug = (key = DEBUG_KEYS.DEBUG_MODE, defaultValue = null) => {
  // Validate the key. Key should be part of debugKeys.js file
  if (!Object.values(DEBUG_KEYS).includes(key)) {
    throw new Error(`Invalid key: ${key}. Allowed keys are: ${Object.values(DEBUG_KEYS).join(', ')}`);
  }

  // Function to parse the value from sessionStorage
  const parseValue = useCallback((value) => {
    switch (value) {
      case 'true':
        return true;
      case 'false':
        return false;
      case null:
        return defaultValue;
      default:
        try {
          return JSON.parse(value);
        } catch (e) {
          return value;
        }
    }
  }, [defaultValue]);

  // Initialize state with the parsed value from sessionStorage
  const [debugValue, setDebugValue] = useState(() => {
    if (typeof window !== 'undefined') {
      const item = sessionStorage.getItem(key);
      return parseValue(item);
    }
    return defaultValue;
  });

  // Effect to handle changes in sessionStorage for the specified key
  useEffect(() => {
    if (typeof window === 'undefined') {
      return undefined; // Explicitly return undefined for SSR
    }

    const handleStorageChange = (event) => {
      if (event.key === key) {
        setDebugValue(parseValue(event.newValue));
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [key, parseValue]);

  // Function to set the value in both state and sessionStorage
  function updateDebugValue(value) {
    setDebugValue(value);
    if (typeof window !== 'undefined') {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  }

  return [debugValue, updateDebugValue];
};

export default useDebug;
