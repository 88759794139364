import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import {
  HorizontalSeparator,
  Modal, IconButton, Icon,
} from 'anf-core-react';
import PAY_OVER_TIME_QUERY from '../../../gql/payOverTime';
import useLog from '../../useLog/useLog';
import useScript from '../../Helpers/useScript';

function KlarnaLearnMoreModal({ headingText, learnMoreURL }) {
  const logger = useLog('KlarnaLearnMoreModal');
  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = (event) => {
    logger.debug('modalClose', event);
    setModalOpen(false);
  };

  useEffect(() => {
    const handleLearnMore = () => {
      setModalOpen(true);
    };

    window.addEventListener('payOverTime:klarna:learnMore', handleLearnMore);

    return () => {
      window.removeEventListener('payOverTime:klarna:learnMore', handleLearnMore);
    };
  }, []);

  return (
    <Modal
      id="payOverTimeLearnMore"
      isOpen={modalOpen}
      onClose={closeModal}
      heading={headingText}
      closeButtonLabel="Close"
    >
      {/* NOTE: CSS rules are not being applied so using inline */}
      <iframe
        data-testid="klarna-learn-more-info"
        style={{ width: '100%', height: 550, border: 'none' }}
        title={headingText}
        src={learnMoreURL}
      />
    </Modal>
  );
}

KlarnaLearnMoreModal.propTypes = {
  learnMoreURL: PropTypes.string.isRequired,
  headingText: PropTypes.string.isRequired,
};

function KlarnaOption({
  badgeURL, badgeAlt, learnMoreText, impressionURL,
}) {
  const openModal = (event) => {
    event.preventDefault();
    window.dispatchEvent(new CustomEvent('payOverTime:klarna:learnMore'));
  };

  return (
    <div data-testid="klarna-option" className="option klarna">
      <Icon
        icon={badgeURL}
        labelText={badgeAlt}
        size="xl"
      />
      <IconButton
        onClick={openModal}
        icon="question-outline-large"
        labelText={learnMoreText}
        size="s"
        theme="inverse"
      />
      <img
        className="impression"
        data-testid="klarna-pay-over-time-impression"
        aria-hidden="true"
        alt={badgeAlt}
        src={impressionURL}
      />
    </div>
  );
}

KlarnaOption.propTypes = {
  badgeURL: PropTypes.string.isRequired,
  badgeAlt: PropTypes.string.isRequired,
  learnMoreText: PropTypes.string.isRequired,
  impressionURL: PropTypes.string.isRequired,
};

function PayPalOption({
  clientSDK, offer, learnMoreText, badgeURL, badgeAlt, purchaseAmount,
}) {
  const logger = useLog('payOverTime.payPalOption');
  const [payPalScript] = useScript({
    id: 'paypal-messaging-script',
    src: clientSDK,
    attrs: {
      'data-namespace': 'paypal_sdk',
    },
  });

  const openLearnMore = useCallback((event) => {
    event.preventDefault();
    logger.debug('openLearnMore', event);
    const amount = purchaseAmount / 100;
    window.dispatchEvent(new CustomEvent('payOverTime:paypal:learnMore', {
      detail: {
        amount,
        offer,
      },
    }));
  }, [logger, offer, purchaseAmount]);

  if (payPalScript.loading || payPalScript.error) {
    return null;
  }

  return (
    <div data-testid="paypal-option" className="option paypal">
      <Icon
        icon={badgeURL}
        labelText={badgeAlt}
        size="xl"
      />
      <IconButton
        onClick={openLearnMore}
        icon="question-outline-large"
        labelText={learnMoreText}
        size="s"
        theme="inverse"
      />
    </div>
  );
}

PayPalOption.propTypes = {
  clientSDK: PropTypes.string.isRequired,
  badgeURL: PropTypes.string.isRequired,
  badgeAlt: PropTypes.string.isRequired,
  offer: PropTypes.string.isRequired,
  learnMoreText: PropTypes.string.isRequired,
  purchaseAmount: PropTypes.number.isRequired,
};

function PayOverTimeMessagePlacement({ textFor, payOverTime }) {
  const { klarna = {}, payPal = {} } = payOverTime;
  const useOr = !!(klarna?.enabled && payPal?.enabled);

  return (
    <div data-testid="payOverTime" className="message-placement">
      <div data-testid="installment-message" className="message">
        {payOverTime.installmentMessage}
      </div>
      <div className="options">
        {klarna.enabled && (
          <KlarnaOption
            badgeURL={klarna?.badgeURL}
            badgeAlt={klarna?.badgeAlt}
            learnMoreText={textFor?.payOverTimeMessagingLearnMore?.value}
            impressionURL={klarna?.impressionURL}
          />
        )}
        {useOr && (
          <div data-testid="or" className="or">
            {textFor?.glbOr?.value}
          </div>
        )}
        {payPal?.enabled && (
          <PayPalOption
            clientSDK={payPal.clientSDK}
            badgeURL={payPal.badgeURL}
            badgeAlt={payPal.badgeAlt}
            purchaseAmount={payOverTime?.purchaseAmount}
            learnMoreText={textFor?.payOverTimeMessagingLearnMore?.value}
            offer={payPal.offer}
          />
        )}
      </div>
    </div>
  );
}

PayOverTimeMessagePlacement.propTypes = {
  textFor: PropTypes.shape({
    payOverTimeMessagingHeading: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    payOverTimeMessagingLearnMore: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    glbOr: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  }).isRequired,
  payOverTime: PropTypes.shape({
    enabled: PropTypes.bool,
    purchaseAmount: PropTypes.number,
    installmentMessage: PropTypes.string,
    klarna: PropTypes.shape({
      badgeURL: PropTypes.string,
      badgeAlt: PropTypes.string,
      learnMoreURL: PropTypes.string,
      impressionURL: PropTypes.string,
    }),
    payPal: PropTypes.shape({
      clientSDK: PropTypes.string,
      badgeURL: PropTypes.string,
      badgeAlt: PropTypes.string,
      offer: PropTypes.string,
    }),
  }).isRequired,
};

function PayOverTimeLayout({ data, showOrSeparator }) {
  if (!data) {
    return null;
  }

  const { textFor, messagePlacement } = data;
  const { payOverTime } = messagePlacement;

  if (!payOverTime.enabled) {
    return null;
  }

  return (
    <div className="pay-over-time">
      {showOrSeparator && (
        <HorizontalSeparator>
          {textFor?.glbOr?.value}
        </HorizontalSeparator>
      )}
      <PayOverTimeMessagePlacement
        textFor={textFor}
        payOverTime={payOverTime}
      />
    </div>
  );
}

PayOverTimeLayout.defaultProps = {
  data: null,
  showOrSeparator: true,
};

PayOverTimeLayout.propTypes = {
  data: PropTypes.shape({
    messagePlacement: PropTypes.shape({
      payOverTime: PropTypes.shape({
        enabled: PropTypes.bool,
      }),
    }),
    textFor: PropTypes.shape({
      glbOr: PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
      }),
    }),
  }),
  showOrSeparator: PropTypes.bool,
};

export default function PayOverTime({
  page, purchaseAmount, showOrSeparator, inline, querySelector, hasGiftCard,
}) {
  const ready = useRef(false);
  const actions = useRef(null);
  const { data, refetch } = useQuery(PAY_OVER_TIME_QUERY, {
    fetchPolicy: 'no-cache',
    context: { batch: true },
    ssr: false,
    variables: {
      page,
      purchaseAmount,
      hasGiftCard,
    },
    onCompleted: () => {
      ready.current = true;
      window.dispatchEvent(new CustomEvent('payOverTime:ready'));
    },
  });

  useEffect(() => {
    const handleRefresh = (event) => {
      refetch({
        page: event?.detail?.page || page,
        purchaseAmount: event?.detail?.purchaseAmount,
        hasGiftCard: !!event?.detail?.hasGiftCard || hasGiftCard,
      });
    };

    const handleCheckReady = () => {
      if (ready.current) {
        window.dispatchEvent(new CustomEvent('payOverTime:ready'));
      }
    };

    window.addEventListener('payOverTime:refresh', handleRefresh);
    window.addEventListener('payOverTime:checkReady', handleCheckReady);

    return () => {
      window.removeEventListener('payOverTime:refresh', handleRefresh);
      window.removeEventListener('payOverTime:checkReady', handleCheckReady);
    };
  }, [page, refetch, hasGiftCard]);

  useEffect(() => {
    const handlePayPalLearnMore = (event) => {
      const { amount, offer } = event?.detail;
      if (actions.current) {
        actions.current.show({ amount, offer });
      } else {
        let element = document.querySelector('.paypal-messages-modal');
        if (!element) {
          element = document.createElement('div');
          element.className = 'paypal-messages-modal';
          document.body.appendChild(element);
        }
        window.paypal_sdk.MessagesModal({
          amount,
          offer,
          onReady: ({ show }) => {
            actions.current = { show };
            actions.current.show();
          },
        }).render(element);
      }
    };

    window.addEventListener('payOverTime:paypal:learnMore', handlePayPalLearnMore);

    return () => {
      window.removeEventListener('payOverTime:paypal:learnMore', handlePayPalLearnMore);
    };
  }, []);

  useEffect(() => {
    const handleLearnMore = (event) => {
      const target = String(event?.detail?.target).toLowerCase();
      const total = data?.messagePlacement?.payOverTime?.purchaseAmount;
      const offer = data?.messagePlacement?.payOverTime?.payPal?.offer;
      const amount = total / 100;
      window.dispatchEvent(new CustomEvent(`payOverTime:${target}:learnMore`, {
        detail: {
          amount,
          offer,
        },
      }));
    };

    window.addEventListener('payOverTime:learnMore', handleLearnMore);

    return () => {
      window.removeEventListener('payOverTime:learnMore', handleLearnMore);
    };
  }, [data]);

  const klarnaModal = useMemo(() => {
    const { textFor = {}, messagePlacement = {} } = data || {};
    const { payOverTime = {} } = messagePlacement;
    const { klarna = {} } = payOverTime;
    if (!klarna?.enabled) {
      return null;
    }
    return (
      <KlarnaLearnMoreModal
        learnMoreURL={klarna?.learnMoreURL}
        headingText={textFor?.payOverTimeMessagingHeading?.value}
      />
    );
  }, [data]);

  if (inline) {
    return (
      <>
        <PayOverTimeLayout
          showOrSeparator={showOrSeparator}
          data={data}
        />
        {klarnaModal}
      </>
    );
  }

  if (typeof document === 'undefined') {
    return null;
  }

  const portals = Array.from(document.querySelectorAll(querySelector)).map((element, index) => (
    ReactDOM.createPortal(
      <PayOverTimeLayout
        // eslint-disable-next-line react/no-array-index-key
        key={`pay-over-time-portal-${index}`}
        data={data}
        showOrSeparator={showOrSeparator}
      />,
      element,
    )
  ));

  return (
    <>
      {portals}
      {klarnaModal}
    </>
  );
}

PayOverTime.defaultProps = {
  purchaseAmount: undefined,
  showOrSeparator: true,
  inline: false,
  querySelector: '.pay-over-time-portal',
  hasGiftCard: false,
};

PayOverTime.propTypes = {
  page: PropTypes.string.isRequired,
  purchaseAmount: PropTypes.number,
  showOrSeparator: PropTypes.bool,
  inline: PropTypes.bool,
  querySelector: PropTypes.string,
  hasGiftCard: PropTypes.bool,
};
