import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { RecommendationsContext, useUserConsent } from '@xp-utilities/web';
import getOrderConfirmationProductInfo from './helpers';

const propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  data: PropTypes.shape({
    bagItems: PropTypes.shape({
      items: PropTypes.shape([]),
    }),
    footer: PropTypes.shape({
      orderId: PropTypes.string,
    }),
  }).isRequired,
};

export const generateRecommendationsCallEventName = (id) => `recommendations-${id}`;

export default function OrderConfirmationRecommendationsContextProvider({ id, data, children }) {
  const { getUserConsent } = useUserConsent();
  const [orderData, setOrderData] = useState(null);
  const [orderId, setOrderId] = useState(null);

  const ctx = useMemo(
    () => ({
      id,
      isEventDriven: true,
      variables: {
        hasUserConsent: getUserConsent(),
        hideSwatches: false,
        products: orderData || [],
        orderId: orderId || '',
      },
    }),
    [id, getUserConsent, orderData, orderId],
  );

  useEffect(() => {
    const items = data?.bagItems?.items;
    const orderNumber = data?.footer?.orderId;
    const productDetails = getOrderConfirmationProductInfo(items);

    setOrderData(productDetails);
    setOrderId(orderNumber);
  }, [data]);

  useEffect(() => {
    if (!orderData) return;
    window?.digitalData?.trigger(generateRecommendationsCallEventName(id));
  }, [orderData, id]);

  return (
    <RecommendationsContext.Provider value={ctx}>
      { children }
    </RecommendationsContext.Provider>
  );
}

OrderConfirmationRecommendationsContextProvider.propTypes = propTypes;
