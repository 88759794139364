import React from 'react';
import PropTypes from 'prop-types';
import { IconBlock, Icon } from 'anf-core-react';

export default function Footer({
  tmntData,
  orderId,
  phoneNumberByCountry,
  phoneNumberByLanguage,
  isEmptyBag,
}) {
  const chatHelpTMNTValue = tmntData?.chatHelpTMNTValue?.value;
  const customerServiceTMNTValue = tmntData?.customerServiceTMNTValue?.value;
  const orderNumberSymbolTMNTValue = tmntData?.orderNumberSymbolTMNTValue?.value;
  const phoneNumberOrTMNTValue = tmntData?.phoneNumberOrTMNTValue?.value;

  function footer() {
    return (
      <footer role="presentation" className="cust-service" data-module-state="readonly" data-module-loading="false" data-testid="footer">
        {/* need help section start */}
        <div className="chat-help">
          <h2 data-testid="chat-help" data-property="GLB_CHATHELP">{chatHelpTMNTValue}</h2>
          <div id="rnowMobileCondChatButton" />
        </div>
        <div className="icon-block cust-service-phone-number-block">
          <IconBlock icon={<Icon icon="phone-contact" />} size="l" aria-hidden="true">
            <p>
              {' '}
              <span>
                <span className="cust-service-phone-number-label" data-property="GLB_CUSTOMERSERVICE" data-testid="customer-service">{customerServiceTMNTValue}</span>
                <br className="cust-service-line-break" />
                <a href={`tel:${phoneNumberByCountry}`}>{phoneNumberByCountry}</a>
                <span className="or" data-property="GLB_OR" data-testid="or-text">{phoneNumberOrTMNTValue}</span>
                <a href={`tel:${phoneNumberByLanguage}`}>{phoneNumberByLanguage}</a>
              </span>
            </p>
          </IconBlock>
        </div>
        {!isEmptyBag && (
        <div className="icon-block order-number-block">
          <IconBlock icon={<Icon icon="content-anf" />} size="l" aria-hidden="true">
            <p>
              <span data-testid="order-number" data-property="GLB_ORDERNUMBERSYMBOL">{orderNumberSymbolTMNTValue}</span>
              <span>{orderId}</span>
            </p>
          </IconBlock>
        </div>
        )}

        {/* need help section end */}
      </footer>
    );
  }

  return (
    <div className="footer-content" data-testid="footer-info">
      {footer()}
    </div>
  );
}

Footer.defaultProps = {
  tmntData: {},
  orderId: '',
  phoneNumberByCountry: '',
  phoneNumberByLanguage: '',
  isEmptyBag: false,
};

Footer.propTypes = {
  tmntData: PropTypes.instanceOf(Object),
  orderId: PropTypes.string,
  phoneNumberByCountry: PropTypes.string,
  phoneNumberByLanguage: PropTypes.string,
  isEmptyBag: PropTypes.bool,
};
