import { Badge, Button } from 'anf-core-react';
import React, { useState, useMemo } from 'react';
import {
  useLazyQuery,
} from '@apollo/client';
import PropTypes from 'prop-types';
import PRODUCT_ITEM_DATA_QUERY from '../../../../gql/product.gql';
import useLog from '../../../useLog/useLog';

export default function ProductFooter({
  bogoMessage, productId, tmntData, productStatus, showShopSimilarItems,
}) {
  const logger = useLog('shoppingBag.productFooter');
  const [isDataLoading, setDataLoading] = useState(false);
  const [fetchDataError, setFetchDataError] = useState(false);
  const shopSimilarTMNTLabel = tmntData?.shopSimilarItemsText?.value;
  const shopSimilarTMNTErrorLabel = tmntData?.errSimilarItemsText?.value;
  const shopSimilarTMNTLoading = tmntData?.loadingSimilarItemsText?.value;
  const productSoldOutStatus = useMemo(() => (!productStatus ? false : productStatus?.toLowerCase() === 'sold out' && showShopSimilarItems), [productStatus, showShopSimilarItems]);

  const onShopSimilarItems = (url) => {
    window.location.href = url;
  };

  const fetchProductInfo = (loadShopSimilarItemsUrl) => {
    setDataLoading(true);
    loadShopSimilarItemsUrl().then((response) => {
      logger.debug(response);
      if (response?.data?.shopSimilarItemsUrl) {
        onShopSimilarItems(response.data.shopSimilarItemsUrl);
      }
      setDataLoading(false);
    });
  };

  const [loadShopSimilarItemsUrl] = useLazyQuery(PRODUCT_ITEM_DATA_QUERY, {
    variables: {
      productId,
    },
    onError: (error) => {
      logger.error(error);
      setFetchDataError(true);
      setDataLoading(false);
    },
  });

  return (
    <div className="product-footer">
      {bogoMessage && (
        <Badge data-testid="bogo-message">{bogoMessage}</Badge>
      )}
      {productSoldOutStatus && (
        <Button
          isProcessing={isDataLoading}
          variant="primary"
          onClick={() => fetchProductInfo(loadShopSimilarItemsUrl)}
          disabled={fetchDataError}
        >
          {isDataLoading && shopSimilarTMNTLoading}
          {!isDataLoading && !fetchDataError && shopSimilarTMNTLabel}
          {!isDataLoading && fetchDataError && shopSimilarTMNTErrorLabel}
        </Button>
      )}
    </div>
  );
}

ProductFooter.defaultProps = {
  bogoMessage: '',
  productId: null,
  tmntData: {},
  productStatus: '',
  showShopSimilarItems: false,
};

ProductFooter.propTypes = {
  bogoMessage: PropTypes.string,
  productId: PropTypes.string,
  tmntData: PropTypes.instanceOf(Object),
  productStatus: PropTypes.string,
  showShopSimilarItems: PropTypes.bool,
};
