import assert from 'assert';
import Transport from './Transport/Transport';

/**
 * The transport(s) used to render a log, warn, or error
 * @module clientSideLogger.transport
 */

export default class Transporter {
  /**
   * transports that have been configured
   */

  #transports = [];

  addTransports(...transports) {
    transports
      .filter((transport) => !!transport)
      .forEach((transport) => this.addTransport(transport));
    return this;
  }

  addTransport(transport) {
    // NOTE: it is 3x faster to check type than instanceof.
    // If we can short circuit faster, the better.
    assert(
      typeof transport === 'object'
      && transport !== null
      && transport instanceof Transport,
      'must be a Transport',
    );
    this.#transports.push(transport);
    return this;
  }

  /**
   * send debug on all enabled transports
   * @param  {...any} args things to log
   * @returns {Transporter} the current instance
   */

  debug(...args) {
    return this.#send('debug', ...args);
  }

  /**
   * send info on all enabled transports
   * @param  {...any} args things to log
   * @returns {Transporter} the current instance
   */

  info(...args) {
    return this.#send('info', ...args);
  }

  /**
   * send log on all enabled transports
   * @deprecated
   * @param  {...any} args things to log
   * @returns {Transporter} the current instance
   */

  log(...args) {
    return this.#send('log', ...args);
  }

  /**
   * send warn on all enabled transports
   * @param  {...any} args things to warn
   * @returns {Transporter} the current instance
   */

  warn(...args) {
    return this.#send('warn', ...args);
  }

  /**
   * send error on all enabled transports
   * @param  {...any} args things to warn
   * @returns {Transporter} the current instance
   */

  error(...args) {
    return this.#send('error', ...args);
  }

  /**
   * send error on all enabled transports
   * @private
   * @param {String} level
   * @param  {...any} args things to warn
   * @returns {Transporter} this
   */
  #send(level, ...args) {
    this.getEnabledTransports()
      .forEach((transport) => {
        transport[level](...args);
      });
    return this;
  }

  /**
   * @returns {Transport[]} all transports
   */
  getTransports() {
    return this.#transports.slice(0);
  }

  /**
   * get the enabled transports
   * @returns {Object[]} only the enabled transports
   */

  getEnabledTransports() {
    return this.getTransports().filter((transport) => transport.enabled);
  }

  /**
   * get the disabled transports
   * @returns {Object[]} only the disabled transports
   */

  getDisabledTransports() {
    return this.getTransports().filter((transport) => !transport.enabled);
  }

  /**
   * get transport by name
   * @param {String} transportName the name or partial name to match
   * @returns {Object} the transport
   */

  getTransport(transportName) {
    const name = transportName.toLowerCase();
    return this.#transports
      .find((transport) => transport.name.toLowerCase().match(name));
  }

  /**
   * has a transport by name
   * @returns {Boolean} if we have the transport or not
   */

  hasTransport(transportName) {
    return !!this.getTransport(transportName);
  }

  /**
   * enable a transport given the name
   * @param {String} transportName - name of the transport
   * @returns {Transporter} the transport instance (this)
   */

  enableTransport(transportName) {
    if (this.hasTransport(transportName)) {
      this.getTransport(transportName).enabled = true;
    }
    return this;
  }

  /**
   * disable a transport given the name
   * @param {String} transportName - name of the transport
   * @returns {Transporter} the transport instance (this)
   */

  disableTransport(transportName) {
    if (this.hasTransport(transportName)) {
      this.getTransport(transportName).enabled = false;
    }
    return this;
  }

  /**
   * enable all disabled transports given the name
   * @returns {Transporter} the transport instance (this)
   */

  enableAllTransports() {
    this.getDisabledTransports()
      .forEach((transport) => this.enableTransport(transport.name));
    return this;
  }

  /**
   * disable all enabled transports given the name
   * @returns {Transporter} the transport instance (this)
   */

  disableAllTransports() {
    this.getEnabledTransports()
      .forEach((transport) => this.disableTransport(transport.name));
    return this;
  }
}
