import React, { useState } from 'react';
import RecognizeCustomerForm from '../recognize-customer-form/recognize-customer-form';

export default function RecognizeCustomer() {
  const [showRecognizeCustomer, setShowRecognizeCustomer] = useState(true);

  const unmountRecognizeCustomer = () => {
    setShowRecognizeCustomer(false);
  };

  return (
    <section className="recognize-customer-section">
      {
        showRecognizeCustomer
          ? <RecognizeCustomerForm unmount={unmountRecognizeCustomer} />
          : null
      }
    </section>
  );
}
