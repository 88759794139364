import {
  gql,
} from '@apollo/client';

export const SAME_DAY_DELIVERY_QUERY = gql`
  query FetchSameDayDeliveryInfo {
    textFor {
      sddFindMeTMNTValue: pair (pairKey: "sddFindMe") {...values}
      sddSearchingTMNTValue: pair (pairKey: "sddSearching") {...values}
      sddSubmitTMNTValue: pair (pairKey: "sddSubmit") {...values}
      sddEnterYourLocationTMNTValue: pair (pairKey: "sddEnterYourLocation") {...values}
      countryZipInvalidTMNTValue: pair (pairKey: "countryZipInvalid") {...values}
      sddFindMeTMNTValue: pair (pairKey: "sddFindMe") {...values}
      sddEnterZipInstructionsTMNTValue: pair (pairKey: "sddEnterZipInstructions") {...values}
      sddLocationServicesBlockedTMNTValue: pair (pairKey: "sddLocationServicesBlocked") {...values}
      sddInvalidCountryZipTMNTValue: pair (pairKey: "sddInvalidCountryZip") {...values}
      sddOtherErrorTMNTValue: pair (pairKey: "sddOtherError") {...values}
      closeButtonTMNTValue: pair (pairKey: "closeButton") {...values}
      sddPostalCodeTMNTValue: pair (pairKey: "sddPostalCode") {...values}
      orTextTMNTValue: pair (pairKey: "orText") {...values}
      sddUnavailableTMNTValue: pair (pairKey: "sameDayDeliveryNotAvailableInLocationText") {...values}
    }
  }
  fragment values on TextPair {
    key
    value
  }
`;

export const FIND_COURIER_AVAILABILITY = gql`
  query FindCourierAvailability(
    $geolocation: GeolocationInput,
    $addressChanged: Boolean,
    $availableShippingMethods: [ShippingSpeedOptionInput],
    $skus: [String],
    $cartState: CartStateInput,
    $cartPayment: CartPaymentInput,
    $orderId: String
  ) {
    sameDayDelivery {
      courierAvailability(
        geolocation: $geolocation,
        addressChanged: $addressChanged,
        availableShippingMethods: $availableShippingMethods,
        skus: $skus,
        cartState: $cartState,
        cartPayment: $cartPayment,
        orderId: $orderId
      ) {
        cartID
        courierEligible
        storeNo
        shipDate
        orderByTime
        timeZone
        reason
        hasOption
        postalCode
        isValid
        shouldBeSelected
        sddAvailabilityMessage
        eligibilityConfirmed
        ineligibilityConfirmed
        usingExpressLanePayment
        displayChangeLink
        geolocation {
          country
          countryAbbr
          latlng
          postalCode
          postalTown
          state
          stateAbbr
        }
      }
    }
  }
`;
