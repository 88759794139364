import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  gql, useQuery, useMutation, useLazyQuery,
} from '@apollo/client';
import { ErrorMessage, Button } from 'anf-core-react';
import TextField from '../../TextField/TextField';
import isValidEmail from '../../../tools/isValidEmail';
import {
  ERROR_MESSAGE as errorMessage,
  LOADING_MESSAGE as loadingMessage,
} from '../../Common/Messages/Messages';
import useLog from '../../useLog/useLog';

const recognizeCustomerQuery = gql`
  {
    textFor {
      # formTitle: pair(pairKey: "recognizeCustomerTitle") {...values}
      email: textField(fieldKey: "email") {
        label {...values}
        error {...values}
      }
      buttonSubmit: pair (pairKey: "submit") {...values}
      buttonProcessing: pair (pairKey: "processing") {...values}
      buttonError: pair (pairKey: "error") {...values}
      buttonSuccess: pair (pairKey: "success") {...values}
      formError: pair(pairKey: "formError") {...values}
    }
  }

  fragment values on TextPair {
    key
    value
  }
`;

const userExistsApi = gql`
  query userExists($email: String!) {
    userExists: userExists(email: $email)
  }
`;

const userLoginApi = gql`
  mutation login($email: String!, $password: String!) {
    login: login(logonId: $email, password: $password)
  }
`;

export default function RecognizeCustomerForm({ unmount }) {
  const [emailValue, setEmailValue] = useState('');
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [passwordValue, setPasswordValue] = useState('');
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [isStepOne, setStepOne] = useState(true);
  const [formErrorText, setFormErrorText] = useState('');
  const [displayFormError, setDisplayFormError] = useState(false);
  const logger = useLog('checkout.recognizeCustomerForm');

  // Button Hooks
  const [submitButtonLabel, setSubmitButtonLabel] = useState('');
  const [submitButtonDisabledStyle, setSubmitButtonDisabledStyle] = useState(false);
  const [submitButtonLeftIcon, setSubmitButtonLeftIcon] = useState(undefined);

  const updateSubmitButtonState = (labelReference, disabledStyle = false, leftIcon = undefined) => {
    setSubmitButtonLabel(labelReference.value);
    setSubmitButtonDisabledStyle(disabledStyle);
    setSubmitButtonLeftIcon(leftIcon);
  };

  const [userExistsQuery] = useLazyQuery(userExistsApi, {
    ssr: false,
    onCompleted: ({ userExists }) => {
      if (userExists) {
        logger.debug('userExistsQuery RESULT', userExists);
        // if email exists, then continue on to next step
        setStepOne(false);
        setTimeout(() => updateSubmitButtonState('submit'), 1000);
      } else {
        // email does not exist,
        // figure out a way to take this component out from view
        unmount();
        // also dispatch an event for subscribers
        const event = new Event('RecognizeCustomer:EmailNotFound');
        window.dispatchEvent(event);
      }
    },
  });

  const [userLoginMutation] = useMutation(userLoginApi, {
    onCompleted: ({ login }) => {
      if (login) {
        logger.debug('userLoginMutation RESULT', login);
        // user successfully logged in,
        // figure out a way to take this component out from view
        unmount();
        // also dispatch an event for subscribers
        const event = new Event('RecognizeCustomer:UserLoggedIn');
        window.dispatchEvent(event);
      } else {
        setPasswordInvalid(true);
        setDisplayFormError(true);
        setFormErrorText('Wrong password. Please try again.');
      }
      // set button to its default state again
      updateSubmitButtonState('submit');
    },
  });

  const { loading, error: queryError, data: queryData } = useQuery(recognizeCustomerQuery);
  if (loading) return loadingMessage;
  if (queryError) return errorMessage;

  const {
    // formTitle,
    email,
    buttonSubmit,
    buttonProcessing,
    buttonError,
    formError,
  } = queryData.textFor;

  const validateEmail = () => {
    const isValid = isValidEmail(emailValue);
    setEmailInvalid(!isValid);
    return isValid;
  };

  const findEmail = () => {
    userExistsQuery({
      variables: {
        email: emailValue,
      },
    });
  };

  const loginUser = () => {
    userLoginMutation({
      variables: {
        email: emailValue,
        password: passwordValue,
      },
    });
  };

  const formSubmission = (e) => {
    e.preventDefault();
    if (submitButtonDisabledStyle) return;
    updateSubmitButtonState(buttonProcessing, true, 'spinner');
    setDisplayFormError(false);
    setFormErrorText(formError.value);

    const emailIsValid = validateEmail();

    if (!emailIsValid) {
      setDisplayFormError(true);
      updateSubmitButtonState(buttonError, true);
      setTimeout(() => updateSubmitButtonState(buttonSubmit), 1000);
      return;
    }
    if (!isStepOne) {
      // try to login the user with email and password
      loginUser();
      return;
    }
    findEmail();
  };

  return (
    <>
      <h1 className="checkout-heading" data-testid="checkout-heading">Checkout</h1>
      <div className="inner-wrapper">
        {
          isStepOne ? (
            <div className="header">
              <h2 className="h2">Hi There!</h2>
              <p>Enter your email address to continue checking out.</p>
            </div>
          ) : (
            <div className="header">
              <h2 className="h2">Welcome Back!</h2>
              <p>Sign in with your password and get all your rewards!</p>
            </div>
          )
        }
        <form onSubmit={formSubmission} name="recognize-customer-form">
          <div className="login-info">
            <TextField
              label={email.label.value}
              labelProp={email.label.key}
              id="recognizeCustomerForm_email"
              value={emailValue}
              onChange={(e) => setEmailValue(e.target.value)}
              invalid={emailInvalid}
              errorMessage={email.error.value}
              errorMessageProp={email.error.key}
            />
            {
              !isStepOne ? (
                <TextField
                  type="password"
                  label="Password"
                  labelProp="Password"
                  id="recognizeCustomerForm_password"
                  value={passwordValue}
                  onChange={(e) => setPasswordValue(e.target.value)}
                  invalid={passwordInvalid}
                  errorMessage={email.error.value}
                  errorMessageProp={email.error.key}
                />
              ) : null
            }
            <div hidden={!displayFormError}>
              <ErrorMessage id="recognizeCustomerForm__errorMessage">
                {formErrorText}
              </ErrorMessage>
            </div>
          </div>
          <Button
            disabledStyle={submitButtonDisabledStyle}
            leftIcon={submitButtonLeftIcon}
            variant="secondary"
            type="submit"
            fullWidth
          >
            <span>{submitButtonLabel || buttonSubmit.value}</span>
          </Button>
        </form>
      </div>
    </>
  );
}

RecognizeCustomerForm.propTypes = {
  unmount: PropTypes.func.isRequired,
};
