import React from 'react';
import { PaymentIcon, PaymentBlock } from 'anf-core-react';
import PropTypes from 'prop-types';

export default function CreditCardPaymentInfo({
  iconCode, paymentLabel, maskedCardNumber, cardExpiryMonth, cardExpiryYear, cardType, textFor,
}) {
  return ( // Credit / Debit Card
    <div className="data-card" data-payment-code={iconCode} data-payment-type={paymentLabel} data-type-name="Credit / Debit Card" data-testid="card-payment">
      <div className="data-card-header" />
      <div className="data-card-content">

        <PaymentBlock paymentIcon={<PaymentIcon iconName={iconCode} labelText={cardType} />}>
          <p>
            <span className="screen-reader-text">
              {`${textFor?.cardNumberEndingTMNT?.value} ${maskedCardNumber}`}
            </span>
            <span aria-hidden="true">
              {maskedCardNumber}
            </span>
          </p>
          <p>
            <span className="screen-reader-text">
              {`${textFor?.expiresTextTMNT?.value}: ${cardExpiryMonth} ${cardExpiryYear}`}
            </span>
            <span aria-hidden="true">
              {textFor?.expTextTMNT?.value}
              :
              {' '}
            </span>
            <span aria-hidden="true">{`${cardExpiryMonth}/${cardExpiryYear}`}</span>
          </p>
        </PaymentBlock>
      </div>
    </div>
  );
}

CreditCardPaymentInfo.defaultProps = {
  iconCode: '',
  paymentLabel: '',
  maskedCardNumber: '',
  cardExpiryMonth: '',
  cardExpiryYear: '',
  cardType: '',
  textFor: {},
};

CreditCardPaymentInfo.propTypes = {
  iconCode: PropTypes.string,
  paymentLabel: PropTypes.string,
  maskedCardNumber: PropTypes.string,
  cardExpiryMonth: PropTypes.string,
  cardExpiryYear: PropTypes.string,
  cardType: PropTypes.string,
  textFor: PropTypes.instanceOf(Object),
};
