import React, { useState, useEffect } from 'react';
import { ErrorMessage } from 'anf-core-react';

export default function PageHeaderError() {
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const handleErrorEvent = (event) => {
      setErrorMessage(event.detail);
    };

    const handleErrorClearEvent = () => {
      setErrorMessage('');
    };

    window.addEventListener('mfe:applepay:error', handleErrorEvent);
    window.addEventListener('mfe:applepay:errorClear', handleErrorClearEvent);

    return () => {
      window.removeEventListener('mfe:applepay:error', handleErrorEvent);
      window.removeEventListener('mfe:applepay:errorClear', handleErrorClearEvent);
    };
  }, []);

  return (
    <div className="error-message-banner-container" data-testid="error-message-banner">
      {errorMessage && (
        <ErrorMessage id="express-payment-error">
          {errorMessage}
        </ErrorMessage>
      )}
    </div>
  );
}
