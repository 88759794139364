import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'anf-core-react';
import Tmnt from '../../../Tmnt/Tmnt';
import CardDetails from '../CardDetails';

export default function SavedPaymentCard({
  paymentId,
  iconCode,
  cardNumber,
  expiryMonth,
  expiryYear,
  expTmnt,
  expiresTmnt,
  buttonLabelTmnt,
  onSelect,
}) {
  return (
    <div className="saved-payment-card">
      <CardDetails
        iconCode={iconCode}
        cardNumber={cardNumber}
        expiryMonth={expiryMonth}
        expiryYear={expiryYear}
        expTmnt={expTmnt}
        expiresTmnt={expiresTmnt}
      />
      <Button
        variant="tertiary-dark"
        isFullWidth
        onClick={() => onSelect(paymentId)}
      >
        <Tmnt tmnt={buttonLabelTmnt} />
      </Button>
    </div>
  );
}

SavedPaymentCard.defaultProps = {
  expTmnt: {
    key: 'REFUNDMETHOD_EXP',
    value: 'Exp:',
  },
  expiresTmnt: {
    key: 'CHK_EXPIRATION_DATE',
    value: 'Expires:',
  },
  buttonLabelTmnt: {
    key: 'USE_THIS_PAYMENT_METHOD',
    value: 'Use This Payment Method',
  },
};

SavedPaymentCard.propTypes = {
  paymentId: PropTypes.string.isRequired,
  iconCode: PropTypes.string.isRequired,
  cardNumber: PropTypes.string.isRequired,
  expiryMonth: PropTypes.string.isRequired,
  expiryYear: PropTypes.string.isRequired,
  expTmnt: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  expiresTmnt: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  buttonLabelTmnt: PropTypes.shape({
    key: PropTypes.string,
    value: PropTypes.string,
  }),
  onSelect: PropTypes.func.isRequired,
};
