/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {
  useCallback, useRef, useContext,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button, ProductCard, Badge, ProductImage, ProductDetail,
  ProductName, ProductPrice, Link, IconButton,
} from 'anf-core-react';
import ProductSecondaryContent from './ProductSecondaryContent/ProductSecondaryContent';
import ProductFooter from './ProductFooter/ProductFooter';
import SocialProofingMessage from '../SocialProofingMessage/SocialProofingMessage';
import decodeHTMLEntities from '../../../tools/decodeHtml';
import { useIsXS } from '../../../hooks/useBreakPoint';
import SwitchTestContext from '../../../context/SwitchTestContext';

function ProductImageComp({
  variant,
  bagItem,
  productDataProps,
  handleEditBtn,
  isGiftCard,
  hasGiftBox,
}) {
  const isXS = useIsXS();
  let imageSize = 'l';
  if (isXS) {
    imageSize = 'm';
  } else if (variant === 'mini' || variant === 'rightRail') {
    imageSize = 's';
  }

  return (
    <>
      <ProductImage
        alt={bagItem?.image?.altText}
        data-itemid={productDataProps.orderItemId}
        size={imageSize}
        src={bagItem?.image?.imageSrc}
        onClick={handleEditBtn}
        classList={!isGiftCard ? 'js-pc-edit-button' : ''}
      />
      {
      hasGiftBox
        ? (
          <div data-testid="gift-icon" className={`gift-overlay ${bagItem?.headers?.shouldDisplayAsbadge ? 'gift-overlay-badge' : ''}`}>
            <span className="gift-box-icon" data-icon="gift" aria-hidden="true" />
          </div>
        )
        : null
      }
    </>
  );
}

ProductImageComp.defaultProps = {
  variant: '',
  bagItem: {},
  productDataProps: {},
  isGiftCard: false,
  hasGiftBox: false,
};

ProductImageComp.propTypes = {
  variant: PropTypes.string,
  bagItem: PropTypes.instanceOf(Object),
  productDataProps: PropTypes.instanceOf(Object),
  handleEditBtn: PropTypes.func.isRequired,
  isGiftCard: PropTypes.bool,
  hasGiftBox: PropTypes.bool,
};

export default function ProductTemplate({
  isSaveForLaterEnabled,
  variant,
  bagItem,
  hasGiftBox,
  hasBogo,
  bogoMessage,
  showUpdateOrderMenu,
  removeBagItem,
  tmntData,
  showShopSimilarItems,
  moveToSFL,
  currentIndex,
  shippingInfo,
  hasPreOrder,
}) {
  const divRef = useRef();
  const { digitalData } = useContext(SwitchTestContext);

  const isPDPQuickViewEnabled = (digitalData && digitalData['pdp-trigger-quickview-on-load']) || false;
  const isDisplayBadgeInMini = (digitalData && digitalData['chk-display-badge-mini-bag']) || false;

  const {
    editButton,
    returnRefundButton,
    exchangeButton,
    sendTo, sendOn,
    finalSaleTMNTValue,
    sflButton,
  } = tmntData;

  const isGiftCard = ['GIFTCARD', 'EGIFTCARD'].indexOf(bagItem?.productContent?.collectionId) !== -1;
  const handleSaveForLater = useCallback((event, orderItemId, sku, index) => {
    event.preventDefault();
    moveToSFL(orderItemId, sku, index);
  }, [moveToSFL]);

  const handleRemoveBtn = () => {
    const data = divRef?.current;
    removeBagItem(data);
  };

  const handleEditBtn = () => {
    // using a custom event for now to pass data and communicate with parent MiniBag component
    // will look at context api in future to handle this
    if (bagItem?.productContent?.hasEdit) {
      if (isPDPQuickViewEnabled) {
        window.location.assign(`${bagItem?.imageOverlay?.productUrl}&editQuickView=true&orderItemId=${bagItem?.productContent?.orderItemId}&sku=${bagItem?.productContent?.shortSku}`);
      } else {
        const event = new CustomEvent('miniBag-toaster:close', {
          detail: {
            pdpDivElement: divRef?.current,
          },
        });

        window.dispatchEvent(event);
      }
    } else if (isGiftCard) { // if the product is GiftCard or EGiftCard
      window.location.replace(bagItem?.imageOverlay?.productUrl);
    }
  };

  const productDataProps = {
    collectionId: bagItem?.productContent?.collectionId,
    freeGift: bagItem?.productContent?.freeGift,
    imageId: bagItem?.image?.imageId,
    orderItemId: bagItem?.productContent?.orderItemId,
    faceout: bagItem?.productContent?.faceOut,
    faceoutImage: bagItem?.productContent?.faceoutImage,
    productId: bagItem?.productContent?.productId,
    seq: bagItem?.productContent?.seq,
    sku: bagItem?.productContent?.shortSku,
    status: bagItem?.headers?.badgeStatusMessage.value,
    longSku: bagItem?.productContent?.longSku,
    inventoryAvailability: bagItem?.headers?.badgeStatusMessage.value,
    brand: bagItem?.productContent?.brand,
    name: bagItem?.productContent?.name,
    listPrice: bagItem?.productContent?.productPrice?.original,
    listPriceUSD: bagItem?.productContent?.productPrice?.originalUSD,
    priceFlag: bagItem?.productContent?.productPrice?.priceFlag,
    offerPrice: bagItem?.productContent?.productPrice?.discount,
    offerPriceUSD: bagItem?.productContent?.productPrice?.discountUSD,
    cartBrands: Array.isArray(bagItem?.productContent?.brand) ? bagItem?.productContent?.brand.join(',') : '',
    kicIds: Array.isArray(bagItem?.productContent?.kicId) ? bagItem?.productContent?.kicId.join(',') : '',
  };

  const productCardCTAs = [];
  if (variant !== 'mini') {
    if (isSaveForLaterEnabled && !isGiftCard) {
      productCardCTAs.push(
        <li key="sfl">
          <Button classList="js-pc-sfl-button" data-itemid={productDataProps.orderItemId} variant="borderless" onClick={(evt) => handleSaveForLater(evt, productDataProps.orderItemId, productDataProps.sku, currentIndex)} isProcessing={bagItem?.isProcessing} isDisabled={bagItem?.isProcessing || bagItem?.isRemoving}>{sflButton?.value || 'Save for Later'}</Button>
        </li>,
      );
    }

    if (bagItem?.productContent?.hasEdit && variant !== 'rightRail') {
      productCardCTAs.push(
        <li key="edit">
          <Link classList="edit-button js-pc-edit-button" data-itemid={productDataProps.orderItemId} variant="tertiary-light" onClick={handleEditBtn}>{`${editButton?.value}`}</Link>
        </li>,
      );
    }
  }

  const secondaryButtonGroup = {
    children: [
      <Button classList="" variant="tertiary-light">{returnRefundButton?.value}</Button>,
      <Button classList="" variant="tertiary-light">{exchangeButton?.value}</Button>,
    ],
  };

  const discountTxtVal = bagItem?.productContent?.productPrice?.discountText;
  const discountTxt = tmntData?.[discountTxtVal]?.value
    ? tmntData?.[discountTxtVal]?.value : discountTxtVal;

  let productFooter = null;
  if (hasBogo || !productDataProps.status) {
    productFooter = (
      <ProductFooter
        hasBogo={hasBogo}
        bogoMessage={bogoMessage}
        productId={productDataProps.productId}
        tmntData={tmntData}
        productStatus={productDataProps.status}
        showShopSimilarItems={showShopSimilarItems}
      />
    );
  }
  // bogo: buy one get one
  const originalPrice = bagItem?.productContent?.productPrice?.originalPrice ? decodeHTMLEntities(bagItem?.productContent?.productPrice?.originalPrice) : '';
  const discountPrice = bagItem?.productContent?.productPrice?.discountPrice ? decodeHTMLEntities(bagItem?.productContent?.productPrice?.discountPrice) : '';

  const renderShippingInfo = () => {
    if (!hasPreOrder) {
      return null;
    }

    if (
      shippingInfo?.estimatedDeliveryDateDisplay
      && bagItem?.headers?.badgeStatusMessage.key !== 'BADGE_STATUS_MESSAGE_PRE_ORDER'
      && tmntData?.inStockExpectedDeliveryDate
    ) {
      return (
        <div className="instock-order-delivery-date">
          {tmntData?.inStockExpectedDeliveryDate?.value.replace('{0}', shippingInfo.estimatedDeliveryDateDisplay)}
        </div>
      );
    }

    if (bagItem?.headers?.estimatedShipDate) {
      return (
        <div className="pre-order-shipment-date">
          {bagItem?.headers?.estimatedShipDate}
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className="product-template shopping-bag-product-template bag-list"
      ref={divRef}
      data-collection={productDataProps.collectionId}
      data-free-gift={productDataProps.freeGift}
      data-image-id={productDataProps.imageId}
      data-orderitemid={productDataProps.orderItemId}
      data-primary-faceout={productDataProps.faceout}
      data-primary-faceout-image={productDataProps.faceoutImage}
      data-productid={productDataProps.productId}
      data-seq={productDataProps.seq}
      data-sku={productDataProps.sku}
      data-status={productDataProps.status}
      data-longsku={productDataProps.longSku}
      data-inventoryavailability={productDataProps.inventoryAvailability}
      data-isedit={bagItem?.productContent?.hasEdit}
      data-kicid={bagItem?.productContent?.kicId}
      data-brand={productDataProps.brand}
      data-name={productDataProps.name}
      data-listprice={productDataProps.listPrice}
      data-listpriceusd={productDataProps.listPriceUSD}
      data-priceflag={productDataProps.priceFlag}
      data-offerprice={productDataProps.offerPrice}
      data-offerpriceusd={productDataProps.offerPriceUSD}
      data-kicids={productDataProps.kicIds}
      data-cartbrands={productDataProps.cartBrands}
      data-index={currentIndex}
      data-testid="product-template"
    >
      <ProductCard
        badge={(
          (
            (
              variant === 'rightRail' && bagItem?.headers?.badgeStatusMessage.key === 'BADGE_STATUS_MESSAGE_PRE_ORDER'
            ) || (
              bagItem?.headers?.shouldDisplayAsbadge && (variant === 'mini' ? isDisplayBadgeInMini : variant !== 'rightRail')
            )
          )
        ) ? (
          <Badge isAttached status={bagItem?.headers?.variant}>
            {bagItem?.headers?.badgeStatusMessage.value}
          </Badge>
          ) : null}
        header={<ProductDetail>{bagItem?.productContent?.gender}</ProductDetail>}
        productImage={(
          <ProductImageComp
            variant={variant}
            bagItem={bagItem}
            productDataProps={productDataProps}
            handleEditBtn={handleEditBtn}
            isGiftCard={isGiftCard}
            hasGiftBox={hasGiftBox}
          />
        )}
        productName={(
          <ProductName>
            <h2>{decodeHTMLEntities(bagItem?.productContent?.name || '')}</h2>
          </ProductName>
      )}
        closeButton={variant === 'rightRail' ? null : <IconButton isDisabled={bagItem?.isProcessing || bagItem?.isRemoving} icon="close" labelText="close" onClick={handleRemoveBtn} theme="inverse" variant="square" />}
        productPrice={(
          <ProductPrice
            brand={bagItem?.productContent?.brand}
            description={bagItem?.productContent?.productPrice?.description}
            originalPrice={originalPrice}
            discountPrice={discountPrice}
            discountText={discountTxt}
            variant={bagItem?.productContent?.productPrice?.variant}
          />
        )}
        variant="bordered"
        footer={productFooter}
      >
        <div className="product-detail-wrapper">
          <ProductDetail variant={variant === 'mini' ? 'small' : null}>
            {isGiftCard ? null
              : (
                <p className="gender">
                  {bagItem?.productContent?.gender && variant !== 'mini' ? `${bagItem?.productContent?.gender}: ` : bagItem?.productContent?.gender}
                  {!bagItem?.productContent?.gender && `${tmntData?.skuText?.value}: `}
                  {variant !== 'mini' && bagItem?.productContent?.shortSku && bagItem?.productContent?.shortSku}
                </p>
              )}
            <p className="size-color">
              {`${bagItem?.productContent?.size}${bagItem?.productContent?.size && bagItem?.productContent?.color ? ', ' : ''} ${bagItem?.productContent.color}`}
            </p>
            {bagItem?.productContent?.toEmail && (
            <p>
              {`${sendTo?.value}: ${bagItem?.productContent?.toEmail}`}
            </p>
            )}
            {bagItem?.productContent.deliveryDate && (
            <p>
              {`${sendOn?.value}: ${bagItem?.productContent?.deliveryDate}`}
            </p>
            )}
            <div className="product-price-section">
              <ProductPrice
                brand={bagItem?.productContent?.brand}
                description={bagItem?.productContent?.productPrice?.description}
                originalPrice={originalPrice}
                discountPrice={discountPrice}
                discountText={discountTxt}
                variant={bagItem?.productContent?.productPrice?.variant}
              />
            </div>
            {!!bagItem?.socialProof?.message && variant !== 'mini' && (
            <div data-testid="product-social-proof-section" className="product-social-proof-section">
              <SocialProofingMessage
                brand={bagItem?.productContent?.brand}
                message={bagItem?.socialProof?.message}
              />
            </div>
            )}
            {bagItem?.productContent?.isFinalSale && (
            <p className="final-sale-messaging" data-property="GLB_FINAL_SALE_MESSAGING">
              {finalSaleTMNTValue?.value}
            </p>
            )}
            {bagItem?.productContent?.promotions?.map((promo) => (
              <div className="product-promotions-container" key={promo?.promotionType}>
                <div className="icon-block legacy-icon-block " data-icon-size="15" data-center-vertically="true">
                  <span className="icon-block-icon" data-icon="check" aria-hidden="true" />
                  {promo?.shortDesc}
                </div>
              </div>
            ))}
            {renderShippingInfo()}
          </ProductDetail>
        </div>
        {variant !== 'mini' && (
          <div className="product-card-ctas">
            <ul>
              {productCardCTAs}
            </ul>
          </div>
        )}
      </ProductCard>

      {showUpdateOrderMenu && secondaryButtonGroup?.children
      && secondaryButtonGroup.children.length > 0 && (
        <ProductSecondaryContent buttonGroup={secondaryButtonGroup?.children} />
      )}

    </div>
  );
}

ProductTemplate.defaultProps = {
  isSaveForLaterEnabled: false,
  variant: null,
  bagItem: {
    headers: {
      badgeStatusMessage: {
        key: '',
        value: '',
      },
      headerMessage: '',
      estimatedShipDate: '',
      shouldDisplayAsbadge: false,
      variant: '',
    },
    image: {
      altText: '',
      imageSrc: '',
      imageId: '',
    },
    imageOverlay: {
      productUrl: '',
      zoomIn: '',
    },
    productContent: {
      gender: '',
      name: '',
      size: '',
      brand: '',
      shortSku: '',
      longSku: '',
      orderItemId: '',
      productId: '',
      collectionId: '',
      freeGift: false,
      seq: '',
      faceOut: false,
      faceoutImage: '',
      color: '',
      hasEdit: false,
      hasRemove: false,
      promotions: [],
      productPrice: {
        description: '',
        originalPrice: '',
        discountPrice: '',
        discountText: '',
        variant: '',
        priceFlag: '',
        original: '',
        discount: '',
        originalUSD: '',
        discountUSD: '',
      },
      deliveryDate: '',
      toEmail: '',
      kicId: '',
      isFinalSale: false,
    },
    socialProof: {
      message: '',
    },
    isProcessing: false,
    isRemoving: false,
  },
  hasGiftBox: false,
  hasBogo: false,
  bogoMessage: '',
  showUpdateOrderMenu: false,
  removeBagItem: () => {},
  moveToSFL: () => {},
  tmntData: {},
  showShopSimilarItems: false,
  currentIndex: 0,
  shippingInfo: {},
  hasPreOrder: false,
};

ProductTemplate.propTypes = {
  isSaveForLaterEnabled: PropTypes.bool,
  variant: PropTypes.string,
  bagItem: PropTypes.shape({
    headers: PropTypes.shape({
      badgeStatusMessage: PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string,
      }),
      headerMessage: PropTypes.string,
      estimatedShipDate: PropTypes.string,
      shouldDisplayAsbadge: PropTypes.bool,
      variant: PropTypes.string,
    }),
    image: PropTypes.shape({
      altText: PropTypes.string,
      imageSrc: PropTypes.string,
      imageId: PropTypes.string,
    }),
    imageOverlay: PropTypes.shape({
      productUrl: PropTypes.string,
      zoomIn: PropTypes.string,
    }),
    productContent: PropTypes.shape({
      gender: PropTypes.string,
      name: PropTypes.string,
      size: PropTypes.string,
      brand: PropTypes.string,
      shortSku: PropTypes.string,
      orderItemId: PropTypes.string,
      productId: PropTypes.string,
      collectionId: PropTypes.string,
      freeGift: PropTypes.bool,
      seq: PropTypes.string,
      faceOut: PropTypes.bool,
      faceoutImage: PropTypes.string,
      color: PropTypes.string,
      hasEdit: PropTypes.bool,
      hasRemove: PropTypes.bool,
      longSku: PropTypes.string,
      promotions: PropTypes.instanceOf(Array),
      productPrice: PropTypes.shape({
        description: PropTypes.string,
        originalPrice: PropTypes.string,
        discountPrice: PropTypes.string,
        discountText: PropTypes.string,
        variant: PropTypes.string,
        priceFlag: PropTypes.string,
        original: PropTypes.number,
        discount: PropTypes.number,
        originalUSD: PropTypes.number,
        discountUSD: PropTypes.number,
      }),
      deliveryDate: PropTypes.string,
      toEmail: PropTypes.string,
      kicId: PropTypes.string,
      isFinalSale: PropTypes.bool,
    }),
    socialProof: PropTypes.shape({
      message: PropTypes.string,
    }),
    isProcessing: PropTypes.bool,
    isRemoving: PropTypes.bool,
  }),
  hasGiftBox: PropTypes.bool,
  hasBogo: PropTypes.bool,
  bogoMessage: PropTypes.string,
  showUpdateOrderMenu: PropTypes.bool,
  removeBagItem: PropTypes.func,
  moveToSFL: PropTypes.func,
  tmntData: PropTypes.instanceOf(Object),
  showShopSimilarItems: PropTypes.bool,
  currentIndex: PropTypes.number,
  shippingInfo: PropTypes.instanceOf(Object),
  hasPreOrder: PropTypes.bool,
};
