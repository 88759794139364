import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { RadioButton } from 'anf-core-react';
import clsx from 'clsx';
import shippingSpeedShape from './shippingSpeedShape';
import useShippingSpeedFieldName from './ShippingSpeedHelper';
import decodeHTMLEntities from '../../../tools/decodeHtml';

export default function ShippingSpeedOption({
  shippingSpeed, onChangeShippingSpeed, children, radioButtonProps, className, name, key,
}) {
  const handleChangeShippingSpeed = useCallback(async () => {
    if (typeof onChangeShippingSpeed === 'function') {
      return onChangeShippingSpeed(shippingSpeed);
    }
    return undefined;
  }, [shippingSpeed, onChangeShippingSpeed]);

  const radioName = useShippingSpeedFieldName(name);

  const label = useMemo(
    () => {
      if (typeof shippingSpeed === 'object' && shippingSpeed !== null) {
        return decodeHTMLEntities(`${shippingSpeed.description} - ${shippingSpeed.amountDisplay}`);
      }
      return '';
    },
    [shippingSpeed],
  );

  if (!shippingSpeed) {
    return (
      <pre>shippingSpeed missing</pre>
    );
  }

  return (
    <div data-testid="shipping-speed-option" className={clsx('shipping-speed-option', className)} key={key}>
      <RadioButton
        key={shippingSpeed.shipModeId}
        id={shippingSpeed.shipModeId}
        name={radioName}
        value={shippingSpeed.shipModeId}
        label={<span>{label}</span>}
        valueDescription=""
        isChecked={shippingSpeed.isSelected}
        isDisabled={!shippingSpeed.isValid}
        onChange={handleChangeShippingSpeed}
        labelDescription={children}
        {...radioButtonProps}
      />
    </div>
  );
}

ShippingSpeedOption.defaultProps = {
  shippingSpeed: undefined,
  onChangeShippingSpeed: undefined,
  children: undefined,
  radioButtonProps: {},
  className: '',
  name: undefined,
  key: undefined,
};

ShippingSpeedOption.propTypes = {
  shippingSpeed: shippingSpeedShape,
  onChangeShippingSpeed: PropTypes.func,
  radioButtonProps: PropTypes.shape({ disabled: PropTypes.bool }),
  children: PropTypes.element,
  className: PropTypes.string,
  name: PropTypes.string,
  key: PropTypes.string,
};
