import React from 'react';
import SwitchTestProvider from '../../SwitchTestProvider/SwitchTestProvider';
import OrderConfirmationPage from '../OrderConfirmationPage';
import { OCN_PAGE_SWITCHES } from '../../../tools/constants';

export default function OrderConfirmationWrapper() {
  return (
    <SwitchTestProvider keys={OCN_PAGE_SWITCHES}>
      <OrderConfirmationPage />
    </SwitchTestProvider>
  );
}
