/* eslint-disable react/no-array-index-key */
import React, {
  useState, useEffect, useCallback, useContext,
} from 'react';
import {
  useLazyQuery,
} from '@apollo/client';
import {
  Modal, RadioButtonGroup, RadioButton, Button, ButtonGroup,
} from 'anf-core-react';
import {
  ERROR_MESSAGE as errorMessage,
} from '../../Common/Messages/Messages';
import useLog from '../../useLog/useLog';
import Loader from '../../Common/Loader/Loader';
import ADDRESS_VERIFICATION_QUERY from '../../../gql/addressVerification.gql';
import SwitchTestContext from '../../../context/SwitchTestContext';
import trackAction from '../../../tools/analytics';

const modalId = 'verifyAddress-modal';
const selectedKey = {
  suggestedAddress: 'suggestedAddress',
  enteredAddress: 'enteredAddress',
};

export default function AddressVerification() {
  const logger = useLog('checkout.addressVerification');
  const [heading, setHeading] = useState('');
  const [contentHeading, setContentHeading] = useState('');
  const [suggestedAddress, setSuggestedAddress] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [enteredAddress, setEnteredAddress] = useState();
  const [requestID, setRequestID] = useState('');
  const [selectedAddressKey, setSelectedAddressKey] = useState('');
  const [triggerVerification, setTriggerVerification] = useState(false);
  const [pendingEvent, setPendingEvent] = useState(null);
  const [changedAddress, setChangedAddress] = useState([]);
  const {
    digitalData,
  } = useContext(SwitchTestContext);
  const isAddressVerificationEnabled = digitalData && digitalData['chk-addr-verify-enabled'];
  const acceptThreshold = digitalData && digitalData['chk-addr-verify-accept-threshold'];

  const setAddressVerificationHeadingDetails = (summary, tmntData) => {
    let verificationTitle = tmntData.confirmAddress.value;
    let verificationContentHeading = tmntData.addressVerification.value;
    if (summary?.promptUser && !summary?.hasSuggestion) {
      verificationTitle = tmntData.verifyAddress.value;
      verificationContentHeading = tmntData.addressUnableVerify.value;
    }
    setOpenModal(!!summary?.promptUser);

    setHeading(verificationTitle);
    setContentHeading(verificationContentHeading);
  };
  const [loadAddressVerificationQuery, {
    loading, error: queryError, data: queryData,
  }] = useLazyQuery(ADDRESS_VERIFICATION_QUERY, {
    fetchPolicy: 'no-cache',
    context: { batch: true },
    ssr: false,
  });

  const dispatchEvent = React.useCallback((obj = {}) => {
    const { data, error, requestID: originalRequestID } = obj;
    if (!originalRequestID) throw new Error('requestID MISSING');
    const verifyAddressResponse = new CustomEvent(
      `addressVerification:response:${originalRequestID}`,
      { detail: { data, error } },
    );
    setPendingEvent(verifyAddressResponse);
    logger.debug('addressVerification:response', ' \n Data-', obj);
  }, [logger]);

  // This will set the requestID and address from the event
  // details into state state variables. It will then set
  // the trigger verification flag. The trigger verification flag
  // is monitored by an effect handler. The effect handler
  // will verify the trigger verification flag is set to true.
  // After checking the flag, the flag is then set to flase. Finally,
  // the address verification graphql query is executed and handled.
  const addressVerificationRequest = React.useCallback((event) => {
    event.stopPropagation();
    setEnteredAddress(event.detail?.address);
    setRequestID(event?.detail?.requestID);
    setTriggerVerification(true);
  }, []);

  useEffect(() => {
    if (!triggerVerification) {
      return;
    }
    setTriggerVerification(false);
    loadAddressVerificationQuery({
      variables: {
        enteredAddress: {
          address1: enteredAddress?.address1,
          address2: enteredAddress?.address2,
          city: enteredAddress?.city,
          state: enteredAddress?.state,
          province: enteredAddress?.province,
          country: enteredAddress?.country,
          postalCode: enteredAddress?.postalCode,
        },
        acceptThreshold: `${acceptThreshold}`,
      },
    }).then((response) => {
      if ((response && response.data)) {
        const { verifyAddress, textFor } = response.data;
        const { summary } = verifyAddress || {};
        // no need to show modal, as entered address is perfect
        // crs will do order submission without prompt address suggestion popup
        if (summary?.useEnteredAddress) {
          dispatchEvent({
            data: {
              // if useSuggestedAddress is true
              // that means user entered an excellent address
              // making useEnteredAddress as true
              // so that crs can be continue with entered
              // please confirm
              useEnteredAddress: true,
              useSuggestedAddress: false,
              suggestedAddress: null,
              editAddress: false,
              reason: 'use entered address',
            },
            requestID,
          });
        } else if (summary?.promptUser) {
          setAddressVerificationHeadingDetails(summary, textFor);
          setSuggestedAddress(verifyAddress?.addressSuggestions);
          setChangedAddress(verifyAddress?.changedAddress);
        } else {
          dispatchEvent({
            error: response?.error,
            data: {
              useEnteredAddress: true,
              useSuggestedAddress: false,
              suggestedAddress: null,
              editAddress: false,
              error: response?.error,
              reason: 'due to no response from api use entered address',
            },
            requestID,
          });
        }
        // dispatchEvent once addressSuggestions has BR tags
        if (summary.hasTags) {
          trackAction('checkout_address_verification_click', {
            data_action: 'has tags',
            event_type: 'click',
          });
        }
      } else {
        logger.warn('default handler');
        // dispatchEvent as  We need a way to respond back to Phoenix that
        // the user can continue to place the order
        // even though the address verification service response had an error
        dispatchEvent({
          error: response?.error,
          data: {
            useEnteredAddress: true,
            useSuggestedAddress: false,
            suggestedAddress: null,
            editAddress: false,
            error: response?.error,
            reason: 'due to error on api use entered address',
          },
          requestID,
        });
      }
    }).catch((error) => {
      logger.debug('addressVerificationRequest catch error', error);
      dispatchEvent({
        error,
        data: {
          useEnteredAddress: true,
          useSuggestedAddress: false,
          suggestedAddress: null,
          editAddress: false,
          error,
          reason: 'due to error on api use entered address',
        },
        requestID,
      });
    });
  }, [
    logger,
    triggerVerification,
    requestID,
    enteredAddress,
    loadAddressVerificationQuery,
    dispatchEvent,
    acceptThreshold,
  ]);

  // after the dispatchEvent() places a "pendingEvent" onto
  // the state this, effect will take that event brodcast
  // it, and then dispatch the event.
  useEffect(() => {
    if (pendingEvent) {
      setPendingEvent(false);
      window.dispatchEvent(pendingEvent);
    }
  }, [pendingEvent]);

  useEffect(() => {
    window.addEventListener('addressVerification:request', addressVerificationRequest);
    return () => {
      window.removeEventListener('addressVerification:request', addressVerificationRequest);
    };
  }, [addressVerificationRequest]);

  useEffect(() => {
    const handleSyn = (event) => {
      window.dispatchEvent(new CustomEvent(`addressVerification:ack:${event?.detail?.code}`));
    };
    window.addEventListener('addressVerification:syn', handleSyn);
    return () => {
      window.removeEventListener('addressVerification:syn', handleSyn);
    };
  }, []);

  // onSaveAndSubmitOrder, here wants to be continue with either enteredAddress or suggestedAddress
  // if flag useEnteredAddress is true, then crs will do the order submission with enteredAddress
  // if flag useEnteredAddress is false, then app will do the order submission with suggestedAddress
  const onSaveAndSubmitOrder = useCallback(() => {
    const useEnteredAddress = selectedAddressKey === selectedKey.enteredAddress;
    const obj = {
      data: {
        useEnteredAddress,
        useSuggestedAddress: !useEnteredAddress,
        suggestedAddress: selectedAddressKey === selectedKey.enteredAddress ? null
          : queryData?.verifyAddress?.addressSuggestions[0]?.address,
        editAddress: false,
        reason: useEnteredAddress ? 'entered address selected' : 'suggested address selected',
      },
      requestID,
    };
    setOpenModal(false);
    dispatchEvent(obj);
    trackAction('checkout_address_verification_click', {
      data_action: 'save and continue',
      event_type: 'click',
    });
    setSelectedAddressKey('');
  }, [requestID, queryData, selectedAddressKey, dispatchEvent]);

  // onEditAddress here user want to update/edit the entered address
  // om the base of flag editAddress true, crs will land user to edit address option
  const onEditAddress = useCallback(() => {
    const obj = {
      data: {
        useEnteredAddress: false,
        useSuggestedAddress: false,
        suggestedAddress: null,
        editAddress: true,
        reason: 'user want to edit the address',
      },
      requestID,
    };
    setOpenModal(false);
    dispatchEvent(obj);
    trackAction('checkout_address_verification_click', {
      data_action: 'edit address',
      event_type: 'click',
    });
  }, [requestID, dispatchEvent]);

  // onUseThisAddress, here user want to be continue with entered address
  // if flag useEnteredAddress is true then
  // crs app will be continue with order submission
  const onUseThisAddress = useCallback(() => {
    const obj = {
      data: {
        useEnteredAddress: true,
        useSuggestedAddress: false,
        suggestedAddress: null,
        editAddress: false,
        reason: 'user want to continue with the entered address',
      },
      requestID,
    };
    setOpenModal(false);
    dispatchEvent(obj);
    trackAction('checkout_address_verification_click', {
      data_action: 'use this address',
      event_type: 'click',
    });
  }, [dispatchEvent, requestID]);

  if (!isAddressVerificationEnabled) {
    return null;
  }
  if (loading) return <Loader classList="loader-verification-screen" />;
  if (queryError) return errorMessage;
  if (!queryData) return null;
  //  returning true, so that user can't close it forcefully;
  const onVerificationClose = () => true;

  const hasSuggestion = queryData.verifyAddress.summary.promptUser
   && queryData.verifyAddress.summary.hasSuggestion;
  const { useSuggestedAddress } = queryData.verifyAddress.summary;

  const buttonGroup = hasSuggestion
    ? (
      <Button
        variant="primary"
        isFullWidth
        onClick={onSaveAndSubmitOrder}
        labelText={queryData.textFor.saveAndSubmitOrder.value}
        isDisabled={selectedAddressKey === ''}
      >
        {queryData.textFor.saveAndSubmitOrder.value}
      </Button>
    ) : (
      <ButtonGroup variant="vertical-spaced" marginBottom="xs">
        <Button
          variant="secondary"
          onClick={onEditAddress}
          labelText={queryData.textFor.editAddress.value}
        >
          {queryData.textFor.editAddress.value}
        </Button>
        <Button
          variant="tertiary-dark"
          onClick={onUseThisAddress}
          labelText={queryData.textFor.useThisAddress.value}
        >
          {queryData.textFor.useThisAddress.value}
        </Button>
      </ButtonGroup>
    );

  const onChangeHandler = (anEvent) => {
    setSelectedAddressKey(anEvent.currentTarget.value);
    trackAction('checkout_address_verification_click', {
      data_action: `select ${anEvent.currentTarget.value}`,
      event_type: 'click',
    });
  };

  const updateChangedAddress = (address, isSecondLine) => {
    const parts = address.split('*');
    const replaceChar = isSecondLine ? ',' : ' ';
    const trimmedParts = parts.map((string) => string.trim());
    for (let j = 0; j < changedAddress.length; j += 1) {
      const changedValue = suggestedAddress[0]?.address[changedAddress[j]];
      const index = trimmedParts.indexOf(changedValue);
      if (index !== -1) {
        parts[index] = (
          <span className="highlight" data-testid="highlight-address-container" key={index}>
            {index < parts.length - 1 ? `${parts[index]}${replaceChar} ` : `${parts[index]}`}
          </span>
        );
      }
    }
    return <div>{parts.map((item, idx) => (typeof item === 'string' && idx < parts.length - 1 ? `${item}${replaceChar} ` : item))}</div>;
  };

  const suggestedAddressList = suggestedAddress.map((item, idx) => {
    const { address } = item;
    const { country } = address;
    const isNotUSorCA = country !== 'US' && country !== 'CA';

    const address1And2 = [
      address?.address1,
      address?.address2,
    ]
      .filter((v) => !!v)
      // dedup
      .reduce((prev, curr) => (prev.indexOf(curr) < 0 ? [...prev, curr] : prev), [])
      .join('*');// joined with * so that we can split the data with unique symbol

    // FUL-382: we want to put the country before the postal code for non US / CA
    const cityStatePostalCodeAndMore = [
      address?.city,
      address?.state,
      address?.province,
      isNotUSorCA ? country : address?.postalCode,
      isNotUSorCA ? address?.postalCode : country,
    ]
      .filter((v) => !!v)
      // dedup
      .reduce((prev, curr) => (prev.indexOf(curr) < 0 ? [...prev, curr] : prev), [])
      .join('*'); // joined with * so that we can split the data with unique symbol

    // If it is changed to Address 1 and Address 2 then no need to return <br />
    const dividerTag = () => {
      if (changedAddress.indexOf('address1') !== -1 || changedAddress.indexOf('address2') !== -1) {
        return <br />;
      }
      return null;
    };

    return (
      <RadioButton
        id={`suggested-address-${idx}`}
        key={`suggested-address-${idx}`}
        label={queryData?.textFor?.weSuggest?.value}
        labelDescription={(
          <div className="suggested-address">
            {updateChangedAddress(address1And2, false)}
            {dividerTag}
            { updateChangedAddress(cityStatePostalCodeAndMore, true)}
          </div>
        )}
        name="avChoice"
        onChange={onChangeHandler}
        value={selectedKey.suggestedAddress}
        isChecked={selectedAddressKey === selectedKey.suggestedAddress}
      />
    );
  });

  const address1And2 = [
    enteredAddress?.address1,
    enteredAddress?.address2,
  ]
    .filter((v) => !!v)
    // dedup
    .reduce((prev, curr) => (prev.indexOf(curr) < 0 ? [...prev, curr] : prev), [])
    .join(' ');

  // FUL-382: we want to put the country before the postal code for non US / CA
  const { country } = enteredAddress;
  const isNotUSorCA = country !== 'US' && country !== 'CA';

  const cityStatePostalCodeAndMore = [
    enteredAddress?.city,
    enteredAddress?.state,
    enteredAddress?.province,
    isNotUSorCA ? country : enteredAddress?.postalCode,
    isNotUSorCA ? enteredAddress?.postalCode : country,
  ]
    .filter((v) => !!v)
    // dedup
    .reduce((prev, curr) => (prev.indexOf(curr) < 0 ? [...prev, curr] : prev), [])
    .join(', ');

  return (
    <Modal
      onOpen={() => {
        trackAction('checkout_address_verification_click', {
          data_action: hasSuggestion ? 'suggested address modal open' : 'poor address modal open',
          event_type: 'click',
        });
      }}
      onClose={onVerificationClose}
      isOpen={openModal
        && !useSuggestedAddress}
      id={modalId}
      heading={heading}
      closeButtonLabel="Close"
    >
      <div>{contentHeading}</div>
      <RadioButtonGroup legend="">
        {!queryData.verifyAddress.summary.hasSuggestion
          ? (
            <div className="entered-poor-address">
              <strong>{queryData?.textFor?.youEntered?.value}</strong>
              <br />
              {address1And2}
              <br />
              {cityStatePostalCodeAndMore}
              <br />
            </div>
          )
          : (
            <RadioButton
              id="entered-address"
              name="avChoice"
              label={queryData?.textFor?.youEntered?.value}
              value={selectedKey.enteredAddress}
              labelDescription={(
                <div>
                  {address1And2}
                  <br />
                  {cityStatePostalCodeAndMore}
                </div>
              )}
              isChecked={selectedAddressKey === selectedKey.enteredAddress}
              onChange={onChangeHandler}
            />
          )}
        {hasSuggestion && suggestedAddressList}
      </RadioButtonGroup>
      {buttonGroup}
    </Modal>
  );
}
