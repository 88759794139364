import React, { useContext } from 'react';
import {
  HeadingBanner, CountrySelector, FlagBlock, Link,
} from 'anf-core-react';
import PropTypes from 'prop-types';
import SwitchTestContext from '../../../context/SwitchTestContext';
import CheckoutPageContext from '../../../context/CheckoutPageContext';

export default function ShipOrPickup({ textFor, shipOrPickup }) {
  const { digitalData } = useContext(SwitchTestContext);
  const { checkoutPageState } = useContext(CheckoutPageContext);
  const isPaypalExpressParametersInUrl = digitalData && digitalData['check-pay-pal-express-parameters-in-url'];
  const isDeliveryTitleOn = digitalData && digitalData['ful-delivery-options-title'];
  if (!textFor || !shipOrPickup || !isDeliveryTitleOn) {
    return null;
  }
  const isPayPalOrderProcessCommand = /.*PayPalOrderProcess.*/.test(window.location.href);
  const searchParams = new URLSearchParams(window.location.search);

  const tokenPresent = searchParams.get('token');
  const payerIdPresent = searchParams.get('PayerID');
  const isExpressPayLane = shipOrPickup.isExpressLane
  && !isPayPalOrderProcessCommand && isPaypalExpressParametersInUrl
  && tokenPresent && payerIdPresent;
  const onChange = () => {
    const shippingCountryEvent = new CustomEvent(
      'mfe:open:changeShippingCountryController',
    );
    window.dispatchEvent(shippingCountryEvent);
  };
  return (
    <div className="ship-or-pickup-header-title" data-testid="shipOrPickup">
      <HeadingBanner variant="boxed">
        <h2 className="h2" data-property={shipOrPickup?.key}>
          {shipOrPickup?.title}
        </h2>

        { !isExpressPayLane && checkoutPageState?.shipMethod?.isHomeDelivery && (
          <>
            {/* TODO:: we need to remove the hidden filed,
            and below name of field is hardcoded as it will only visible once it is homeDelivery */}
            <input id="shipping-country" name="addresses.homeDelivery.country" type="hidden" value={shipOrPickup?.country} />
            <div className="country-section">
              <CountrySelector
                flagBlock={(
                  <FlagBlock countryCode={shipOrPickup?.country} countryName={`${textFor?.shipTo?.value} ${shipOrPickup?.country}`} />
          )}
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                link={<Link onClick={onChange}>{textFor?.change?.value}</Link>}
              />
            </div>
          </>
        )}
      </HeadingBanner>

    </div>
  );
}

ShipOrPickup.propTypes = {
  textFor: PropTypes.shape({
    shipTo: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    change: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
  shipOrPickup: PropTypes.shape({
    country: PropTypes.string,
    title: PropTypes.string,
    key: PropTypes.string,
    isHomeDelivery: PropTypes.bool,
    isExpressLane: PropTypes.bool,
  }),

};

ShipOrPickup.defaultProps = {
  textFor: {
    shipTo: {
      key: 'CHK_SHIPPING_TO',
      value: 'Shipping To',
    },
    change: {
      key: 'GLB_CHANGE',
      value: 'Change',
    },
  },
  shipOrPickup: {
    country: '',
    title: '',
    key: '',
    isHomeDelivery: false,
    isExpressLane: false,
  },

};
