import PropTypes, { node } from 'prop-types';
import React, { useEffect, useState } from 'react';
import SwitchTestContext from '../../context/SwitchTestContext';
import { getDigitalDataSubscriptionPromise } from '../../tools/getDigitalDataSubscriptionPromise';

export default function SwitchTestProvider({ children, keys }) {
  const [digitalData, setDigitalData] = useState({});

  useEffect(() => {
    if (window.digitalData) {
      keys.forEach((key) => {
        getDigitalDataSubscriptionPromise(key, 2000).then((value) => {
          setDigitalData((currentState) => ({
            ...currentState,
            [key]: value,
          }));
        }).catch(() => {
          // noop
        });
      });
    }
  }, [keys]);

  return (
    <SwitchTestContext.Provider value={{ digitalData }}>
      {children}
    </SwitchTestContext.Provider>
  );
}

SwitchTestProvider.defaultProps = {
  keys: [],
};

SwitchTestProvider.propTypes = {
  children: node.isRequired,
  keys: PropTypes.arrayOf(PropTypes.string),
};
