import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from 'anf-core-react';

export default function OrderInStoreSuccess({
  oisData,
  isSendToRegister,
  startNewSession,
}) {
  return (
    <>
      <p className="h2" data-property={oisData.thankYouLabel.key}>
        {oisData.thankYouLabel.value}
      </p>
      { // display success message for send to register flow
          isSendToRegister
          && (
          <p
            className="send-to-register-success"
            data-property={oisData.registerSuccessLabel.key}
            data-testid="ois-success-message"
          >
            {oisData.registerSuccessLabel.value}
          </p>
          )
        }
      { // display success message for send to customer flow
          !isSendToRegister
          && (
          <p
            className="send-to-customer-success"
            data-property={oisData.customerSuccessLabel.key}
            data-testid="ois-success-message"
          >
            {oisData.customerSuccessLabel.value}
          </p>
          )
        }
      <Button
        isFullWidth
        type="submit"
        variant="tertiary-light"
        classList="start-new-session-button"
        onClick={() => startNewSession()}
      >
        {oisData?.startNewSessionLabel?.value}
      </Button>
    </>
  );
}

OrderInStoreSuccess.defaultProps = {
  oisData: {
    thankYouLabel: {
      key: 'CHK_THANKYOU',
      value: 'Thank you!',
    },
    registerSuccessLabel: {
      key: 'CHK_SNDCUSTOMERINSTD',
      value: 'Your order has been sent to the register.',
    },
    customerSuccessLabel: {
      key: 'CHK_SNDCUSTOMER',
      value: 'Your order has been sent to the customer.',
    },
    startNewSessionLabel: {
      key: 'CHK_STARTNEWSESSION',
      value: 'Start a new session',
    },
  },
};

OrderInStoreSuccess.propTypes = {
  oisData: PropTypes.shape({
    thankYouLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    registerSuccessLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    customerSuccessLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    startNewSessionLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
  isSendToRegister: PropTypes.bool.isRequired,
  startNewSession: PropTypes.func.isRequired,
};
