import { Button, Icon } from 'anf-core-react';
import ReactDOM from 'react-dom';
import React, {
  useEffect,
  useContext,
  useMemo,
  useState,
} from 'react';
import SwitchTestContext from '../../../context/SwitchTestContext';
import { useIsM } from '../../../hooks/useBreakPoint';

export default function MiniBagWithIcon() {
  const {
    digitalData,
  } = useContext(SwitchTestContext);
  const [mounted, setMounted] = useState(false);
  const miniBagMobileEnabled = useMemo(() => (digitalData ? digitalData['chk-mfe-miniBag-mobile'] : false), [digitalData]);

  const isNotLargeScreen = useIsM();
  useEffect(() => {
    setMounted(true);
    Object.keys(localStorage)
      .filter((key) => key.startsWith('miniBagData_'))
      .forEach((key) => localStorage.removeItem(key));
  }, []);
  if (!mounted) {
    return null;
  }
  const handleMiniBagClick = () => {
    window.location.href = '/shop/OrderItemDisplayView?';
  };

  const miniBagIconClass = 'rs-nav__item-count--badge js-rs-nav__item-count--bag rs-nav__item-count rs-nav__item-count--bag';

  const miniBagContent = () => (
    <div className="minibag-icon-wrapper">
      <button
        className="rs-nav-icon rs-nav-icon--shopping-bag rs-nav-cat-menu-item minibag-nav-mfe"
        data-aui="shopping-bag-nav-link"
        aria-describedby="rs-nav__item-count mini-bag__a11y-desc"
        aria-expanded="false"
        data-testid="navigation-button"
        onClick={handleMiniBagClick}
      >
        <Icon
          icon="bag-outline"
          labelText="MiniBag"
          size="m"
        />
        <span data-testid="bag-item-count-b-test" id="rs-nav__item-count-largescreen" className={miniBagIconClass} aria-hidden="true">0</span>
      </button>
    </div>
  );

  const mobileMiniBagPortalContent = () => {
    const portalElement = typeof document !== 'undefined'
      ? document.getElementById('checkout-mfe-mobile-minibag-portal')
      : null;

    return ReactDOM.createPortal(
      <div data-testid="mobile-navigation-button-static">
        <Button
          classList="link-as--button rs-nav-icon rs-nav-icon--shopping-bag-alt rs-nav-icon--r hide-lg mobile-mini-bag"
          variant="borderless"
          icon="bag-outline"
          onClick={handleMiniBagClick}
        >
          <span
            data-testid="bag-item-count-mobile"
            id="rs-nav__item-count-smallscreen"
            className={miniBagIconClass}
            aria-hidden="true"
          >
            0
          </span>
        </Button>
      </div>,
      portalElement,
    );
  };

  return (
    <div data-testid="minibag-static-icon-comp" className="minibag-static-icon-comp">
      {isNotLargeScreen ? (miniBagMobileEnabled && mobileMiniBagPortalContent()) : miniBagContent()}
    </div>
  );
}
