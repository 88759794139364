/* eslint-disable no-undefined */
import React from 'react';
import PropTypes from 'prop-types';

// TODO: 'step' isn't supported at the moment
export default function IconHeading({
  ariaLabel,
  children,
  icon,
  image,
  layout,
  variant,
}) {
  return (
    <div className="icon-heading" data-variant={variant} data-layout={layout}>
      <div className="icon-heading-icon" data-icon={image ? undefined : icon} aria-label={ariaLabel} aria-hidden="true">
        {
          image ? (
            <img src={image} alt={ariaLabel} />
          ) : null
        }
      </div>
      <div className="icon-heading-text">{children}</div>
    </div>
  );
}

IconHeading.defaultProps = {
  ariaLabel: undefined,
  children: undefined,
  icon: undefined,
  image: undefined,
  layout: 'horizontal',
  variant: 'small',
};

IconHeading.propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.string,
  image: PropTypes.string,
  layout: PropTypes.oneOf(['horizontal', 'vertical']),
  variant: PropTypes.oneOf(['small', 'medium', 'large']),
};
