export function dispatchPaymentTypeChangedEvent(paymentOption) {
  const event = new Event('mfe:paymentType:changed');
  event.detail = {
    currentTarget: {
      value: paymentOption?.id,
      dataset: {
        paymentCode: paymentOption?.code,
        paymentType: paymentOption?.type,
        typeName: paymentOption?.name,
      },
    },
  };
  window.dispatchEvent(event);
}

export function handleClickPayOverTimeLearnMore(target) {
  // grab the last class name which is the target (klarna / paypal)
  window.dispatchEvent(new CustomEvent('payOverTime:learnMore', {
    detail: {
      target,
    },
  }));
}
