import React, { useContext, useMemo } from 'react';
import MarketingPreferences from './MarketingPreferences';
import CheckoutPageContext from '../../../context/CheckoutPageContext';
import Loader from '../../Common/Loader/Loader';
import Tmnt from '../../Tmnt/Tmnt';
import SwitchTestContext from '../../../context/SwitchTestContext';

export default function MarketingPreferencesWrapper() {
  const {
    checkoutPageState, loading,
  } = useContext(CheckoutPageContext);
  const {
    digitalData,
  } = useContext(SwitchTestContext);
  const isStDecommEnabled = digitalData['chk-hco-only-marketing-checkboxes'];
  const stGhInstances = useMemo(() => ['st', 'gh'], []);

  const loadEmailConsent = checkoutPageState?.config?.showEmailLegalConsent
    && !checkoutPageState?.marketingPreferences?.hideMarketingPreferences;
  if (loading || !checkoutPageState.marketingPreferences) {
    return (
      <div className="marketing-preferences-wrapper" data-testid="marketing-preferences-loader">
        <Loader classList="loader-marketing-preferences" />
      </div>
    );
  }
  let marketingPrefData;

  if (isStDecommEnabled) {
    const renderedBrands = checkoutPageState.marketingPreferences.brands;
    const marketingPrefBrands = renderedBrands?.filter((brand) => !stGhInstances
      .includes(brand?.id)) || [];
    marketingPrefData = { ...checkoutPageState.marketingPreferences, brands: marketingPrefBrands };
  } else {
    marketingPrefData = { ...checkoutPageState.marketingPreferences };
  }

  return (
    <div className="marketing-preferences-wrapper" data-testid="marketing-preferences">
      <MarketingPreferences
        marketingPreferencesData={marketingPrefData}
      />
      {loadEmailConsent
      && (
      <div className="email-subscribe-legal-consent" data-testid="email-subscribe-legal-consent">
        <Tmnt tmnt={checkoutPageState.textFor.subscribeToEmailLegalConsent} />
      </div>
      )}
    </div>
  );
}
