import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Button } from 'anf-core-react';
import ShippingSpeedOption from './ShippingSpeedOption';
import shippingSpeedShape from './shippingSpeedShape';

export default function SameDayDeliveryShippingSpeedOption({
  shippingSpeed, onChangeShippingSpeed, textFor, switches, ...props
}) {
  const [state, setState] = useState({
    shippingSpeed,
    postalCode: null,
    orderByTime: null,
    shipDate: null,
    sameDayDeliveryNoAvailabilityConfirmed: false,
    canDisplaySameDayDeliveryChangeLink: false,
    usingSameDayDeliveryMFE: switches?.usingSameDayDeliveryMFE,
  });

  // NOTE: this function will be reduced soon since it is similar to the one below
  const handleClickCheckAvailability = useCallback((event) => {
    event.preventDefault();
    if (state.usingSameDayDeliveryMFE) {
      window.dispatchEvent(
        new CustomEvent('sameDayDelivery:open'),
      );
    } else {
      window.dispatchEvent(
        new CustomEvent('sameDayDelivery:checkAvailability', { detail: { fromMFE: true } }),
      );
    }
  }, [state.usingSameDayDeliveryMFE]);

  // NOTE: this function will be reduced soon since it is similar to the one above
  const handleClickChangePostalCode = useCallback((event) => {
    event.preventDefault();
    if (state.usingSameDayDeliveryMFE) {
      window.dispatchEvent(
        new CustomEvent('sameDayDelivery:open'),
      );
    } else {
      window.dispatchEvent(
        new CustomEvent('sameDayDelivery:changePostalCode', { detail: { fromMFE: true } }),
      );
    }
  }, [state.usingSameDayDeliveryMFE]);

  const changeShippingSpeed = useCallback(async (shippingSpeedOption) => {
    const request = {
      ...shippingSpeedOption,
      availability: {
        ...shippingSpeedOption.availability,
        ...state,
      },
    };
    try {
      await onChangeShippingSpeed(request);
    } finally {
      window.dispatchEvent(new CustomEvent('sameDayDelivery:selected', { detail: request }));
    }
  }, [onChangeShippingSpeed, state]);

  // use this method to merge state and current option together
  const handleChangeShippingSpeed = useCallback((shippingSpeedOption) => {
    if (state.eligibilityConfirmed) {
      changeShippingSpeed(shippingSpeedOption);
    } else {
      setState((previousState) => ({
        ...previousState,
        requestPostalCode: true,
      }));
      window.dispatchEvent(new CustomEvent('sameDayDelivery:open'));
    }
  }, [changeShippingSpeed, state]);

  useEffect(() => {
    function handleSameDayDeliveryAvailability(event) {
      event.stopPropagation();
      setState((currentState) => ({
        ...currentState,
        ...event.detail.availability,
        usingSameDayDeliveryMFE: (
          typeof event.detail.usingSameDayDeliveryMFE !== 'undefined'
            ? !!event.detail.usingSameDayDeliveryMFE
            : currentState.usingSameDayDeliveryMFE
        ),
        requestPostalCode: false,
        // NOTE: if we had requested a postal code and now we have availability we can
        // select the shipping speed option in the next rendering effect
        selectShippingSpeed: (
          currentState.requestPostalCode
          && event.detail.availability?.eligibilityConfirmed
        ),
        shippingSpeed: {
          ...currentState.shippingSpeed,
          availability: {
            ...currentState.shippingSpeed?.availability,
            ...event.detail.availability,
          },
        },
      }));
    }

    window.addEventListener('sameDayDelivery:availability', handleSameDayDeliveryAvailability);

    window.dispatchEvent(new CustomEvent('sameDayDelivery:availabilityRequest'));

    return function removeListener() {
      window.removeEventListener('sameDayDelivery:availability', handleSameDayDeliveryAvailability);
    };
  }, []);

  useEffect(() => {
    setState((currentState) => ({
      ...currentState,
      shippingSpeed,
      ...shippingSpeed?.availability,
    }));
  }, [shippingSpeed]);

  useEffect(() => {
    setState((currentState) => ({ ...currentState, ...switches }));
  }, [switches]);

  useEffect(() => {
    if (state.selectShippingSpeed) {
      setState((currentState) => ({
        ...currentState,
        selectShippingSpeed: false,
      }));
      changeShippingSpeed(state.shippingSpeed);
    }
  }, [state.shippingSpeed, state.selectShippingSpeed, changeShippingSpeed]);

  const {
    sameDayDeliveryChangeZipTMNTValue,
    sameDayDeliveryCheckAvailabilityTMNTValue,
    sameDayDeliveryOrderByTMNTValue,
    sameDayDeliveryUnavailableTMNTValue,
  } = textFor;

  const canDisplayChangeLink = (
    state.displayChangeLink
    || state.canDisplaySameDayDeliveryChangeLink
  );

  const ineligibilityConfirmed = (
    state.ineligibilityConfirmed
    || state.sameDayDeliveryNoAvailabilityConfirmed
  );

  const eligibilityConfirmed = (
    state.eligibilityConfirmed
    || !state.sameDayDeliveryNoAvailabilityConfirmed
  );

  return (
    <ShippingSpeedOption
      shippingSpeed={state.shippingSpeed}
      onChangeShippingSpeed={handleChangeShippingSpeed}
      radioButtonProps={{
        isDisabled: ineligibilityConfirmed,
      }}
      className="sdd"
      {...props}
    >
      <span className="sdd-info">
        <span>
          { state.postalCode && (
            <>
              { state.postalCode && <span>{state.postalCode}</span> }
              { ineligibilityConfirmed && <span>{` | ${sameDayDeliveryUnavailableTMNTValue.value}`}</span> }
              { canDisplayChangeLink && (
                <span className="change-zip">
                  <span>{' | '}</span>
                  <Button
                    classList="link-button"
                    onClick={handleClickChangePostalCode}
                  >
                    {sameDayDeliveryChangeZipTMNTValue.value}
                  </Button>
                </span>
              )}
            </>
          )}
          { !state.postalCode && (
            <Button
              classList="link-button"
              onClick={handleClickCheckAvailability}
            >
              {sameDayDeliveryCheckAvailabilityTMNTValue.value}
            </Button>
          )}
        </span>
        {eligibilityConfirmed && state.orderByTime && (
          <span className="order-by-time">
            {`${sameDayDeliveryOrderByTMNTValue.value} ${state.orderByTime} ${state.shipDate}`}
          </span>
        )}
      </span>
    </ShippingSpeedOption>
  );
}

SameDayDeliveryShippingSpeedOption.defaultProps = {
  shippingSpeed: undefined,
  onChangeShippingSpeed: undefined,
  textFor: {
    sameDayDeliveryChangeZipTMNTValue: {
      key: 'SDD_CHANGE_ZIP',
      value: 'Change Zip',
    },
    sameDayDeliveryCheckAvailabilityTMNTValue: {
      key: 'SDD_CHANGE_ZIP',
      value: 'Check Availability',
    },
    sameDayDeliveryOrderByTMNTValue: {
      key: 'SDD_CHANGE_ZIP',
      value: 'Order By',
    },
    sameDayDeliveryUnavailableTMNTValue: {
      key: 'SDD_CHANGE_ZIP',
      value: 'Unavailable',
    },
  },
  switches: {
    usingSameDayDeliveryMFE: false,
  },
};

SameDayDeliveryShippingSpeedOption.propTypes = {
  shippingSpeed: shippingSpeedShape,
  onChangeShippingSpeed: PropTypes.func,
  textFor: PropTypes.shape({
    sameDayDeliveryChangeZipTMNTValue: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    sameDayDeliveryCheckAvailabilityTMNTValue: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    sameDayDeliveryOrderByTMNTValue: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    sameDayDeliveryUnavailableTMNTValue: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
  switches: PropTypes.shape({
    usingSameDayDeliveryMFE: PropTypes.bool,
  }),
};
