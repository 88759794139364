import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'anf-core-react';
import Tmnt from '../../Tmnt/Tmnt';

export default function Disclosure({ tmntData }) {
  const disclosureTMNTValue = tmntData?.disclosureTMNTValue?.value;
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion
      headingLevel={2}
      title={disclosureTMNTValue}
      id="disclosure"
      variant="bordered"
      isExpanded={expanded}
      onClick={() => { setExpanded(!expanded); }}
    >
      <Tmnt tmnt={tmntData?.disclosureInfoTMNTValue} isHtml />
    </Accordion>
  );
}

Disclosure.defaultProps = {
  tmntData: {},
};

Disclosure.propTypes = {
  tmntData: PropTypes.instanceOf(Object),
};
