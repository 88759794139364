import React from 'react';
import SwitchTestProvider from '../../SwitchTestProvider/SwitchTestProvider';
import ShoppingBag from '../../ShoppingBag/ShoppingBag';
import { BAG_SWITCHES } from '../../../tools/constants';

export default function BagWrapper() {
  return (
    <SwitchTestProvider keys={BAG_SWITCHES}>
      <ShoppingBag />
    </SwitchTestProvider>
  );
}
