import React, { useContext } from 'react';
import OrderConfirmationContext from '../../../context/OrderConfirmationContext';
import CreditCardPaymentInfo from './CreditCardPaymentInfo';
import PaymentInfo from './PaymentInfo';
import ShipmentInfo from './ShipmentInfo';
import PudosPickUpInfo from './PudosPickUpInfo/PudosPickUpInfo';

export default function OrderReview() {
  const { orderConfirmationData } = useContext(OrderConfirmationContext);
  const {
    textFor, orderInfo, shippingAddress, eSpots, shipMethod, orderContact, bagItems,
  } = orderConfirmationData;

  const isAltPickUp = shipMethod?.shipModeType === 'Pudos' && shippingAddress?.country === 'US';
  const getPaymentSpecificContent = (paymentDetail) => {
    const {
      cardType,
      cardExpiryMonth,
      cardExpiryYear,
      maskedCardNumber,
      isCreditCard,
      iconCode,
      paymentLabel,
      payPalPaymentEmail,
    } = paymentDetail;
    return (
      <div className="payment-details" key={iconCode}>
        {!isCreditCard ? (
        // All other payment methods
          <PaymentInfo
            iconCode={iconCode}
            paymentLabel={paymentLabel}
            payPalPaymentEmail={payPalPaymentEmail}
          />
        ) : ( // Credit / Debit Card
          <CreditCardPaymentInfo
            iconCode={iconCode}
            paymentLabel={paymentLabel}
            maskedCardNumber={maskedCardNumber}
            cardExpiryMonth={cardExpiryMonth}
            cardExpiryYear={cardExpiryYear}
            cardType={cardType}
            textFor={textFor}
          />
        ) }
      </div>
    );
  };

  const getShipmentSpecificContent = () => (shipMethod?.shipModeType === 'Pudos' ? <PudosPickUpInfo /> : (
    <ShipmentInfo
      shippingAddress={shippingAddress}
      shipMethod={shipMethod}
      orderContact={orderContact}
      eSpots={eSpots}
      textFor={textFor}
    />
  ));

  return (
    <div className={`order-review-mfe ${isAltPickUp ? 'alt-pickup' : ''}`} data-testid="order-confirmation-review">
      <div className="buyer-info-section" data-testid="buyer-info">
        <h2 className="h1 order-review-heading" data-property={textFor?.reviewYourOrderTMNT?.key}>{textFor?.reviewYourOrderTMNT?.value}</h2>
        {/* Shipment info should show if the order does not have Egift cards only */}
        {!bagItems?.hasOnlyEGiftCard ? getShipmentSpecificContent() : null}
        <section id="payment" className="payment-section" data-testid="payment-section">
          <div className="payment-info">
            {orderInfo?.paymentDetails?.length > 0
            && orderInfo?.paymentDetails.map(
              (paymentDetail) => getPaymentSpecificContent(paymentDetail),
            )}
          </div>
        </section>
      </div>
    </div>
  );
}
