import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { RecommendationsContext, useUserConsent } from '@xp-utilities/web';

const propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  bagItems: PropTypes.arrayOf(PropTypes.shape({})),
  hasItemsInBag: PropTypes.bool.isRequired,
};

const defaultProps = {
  bagItems: undefined,
};

export const generateRecommendationsCallEventName = (id) => `recommendations-${id}`;

export default function ShoppingBagRecommendationsContextProvider({
  id, bagItems, hasItemsInBag, children,
}) {
  const { getUserConsent } = useUserConsent();
  const [shoppingBagItems, setShoppingBagItems] = useState(null);

  const ctx = useMemo(
    () => ({
      id,
      isEventDriven: true,
      variables: {
        hasUserConsent: getUserConsent(),
        hideSwatches: false,
        products: shoppingBagItems || [],
      },
    }),
    [id, getUserConsent, shoppingBagItems],
  );

  useEffect(() => {
    const shoppingBagItemsKICs = bagItems?.map((item) => ({ kic: item.item.image?.imageId }));
    setShoppingBagItems(shoppingBagItemsKICs);
  }, [bagItems]);

  useEffect(() => {
    if (!shoppingBagItems && hasItemsInBag) return;
    window?.digitalData?.trigger(generateRecommendationsCallEventName(id));
  }, [shoppingBagItems, id, hasItemsInBag]);

  return (
    <RecommendationsContext.Provider value={ctx}>
      { children }
    </RecommendationsContext.Provider>
  );
}

ShoppingBagRecommendationsContextProvider.propTypes = propTypes;
ShoppingBagRecommendationsContextProvider.defaultProps = defaultProps;
