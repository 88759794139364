import { useEffect, useRef } from 'react';
import Logger from './Logger/Logger';
import ConsoleTransport from './Logger/Transporter/Transport/ConsoleTransport';
import QuantumMetricTransport from './Logger/Transporter/Transport/QuantumMetricTransport';
import $window from '../../tools/window';

export const rootLogger = new Logger();

rootLogger.manager.transporter.addTransports(
  new ConsoleTransport(),
  new QuantumMetricTransport(),
);

export const clientSideLogger = rootLogger.controls;

$window.clientSideLogger = clientSideLogger;

export default function useLog(name) {
  const logger = useRef(rootLogger.logger(name));

  useEffect(() => {
    const { current } = logger;
    return () => {
      current.destroy();
    };
  }, []);

  return logger.current;
}
