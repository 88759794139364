import React, {
  useState, useEffect, useContext,
} from 'react';
import { Button, ErrorMessage } from 'anf-core-react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import ORDER_SUBMIT_MUTATION from '../../../gql/orderSubmit.gql';
import SwitchTestContext from '../../../context/SwitchTestContext';
import CheckoutPageContext from '../../../context/CheckoutPageContext';
import handleSubmit from './handleSubmit';
import prepareFormData from './prepareFormData';

function SubmitButton({ submitButtonData, paymentInfo, isKlarnaReady }) {
  const {
    digitalData,
  } = useContext(SwitchTestContext);
  const hasCheckoutSubmitOverlay = digitalData && digitalData['chk-submit-overlay'];
  const hasApplePayPXP = digitalData && digitalData['chk-has-apple-pay-pxp'];
  const { checkoutPageState } = useContext(CheckoutPageContext);
  const {
    label, tryAgainLabel, processingLabel, successLabel, unavailableMessage,
  } = submitButtonData;
  const [displaySubmitButton, setDisplaySubmitButton] = useState(false);
  const [errorMessageText, setErrorMessageText] = useState('');
  const [buttonState, setButtonState] = useState('initial');
  const [currentPaymentType, setCurrentPaymentType] = useState('');
  const [isUsingExpressPayment, setIsUsingExpressPayment] = useState(false);

  const triggerError = (errorMessage) => {
    if (isUsingExpressPayment) {
      const newEvent = new Event('mfe:applepay:error');
      newEvent.detail = errorMessage;
      window.dispatchEvent(newEvent);
    } else {
      setErrorMessageText(errorMessage);
    }
  };

  const clearErrors = () => {
    setErrorMessageText('');
    const newEvent = new Event('mfe:applepay:errorClear');
    window.dispatchEvent(newEvent);
  };

  useEffect(() => {
    if (hasCheckoutSubmitOverlay) {
      const body = document.querySelector('body');
      if (buttonState === 'processing') {
        body.setAttribute('aria-hidden', 'true');
        body.setAttribute('aria-live', 'polite');
      } else {
        body.setAttribute('aria-hidden', 'false');
        body.setAttribute('aria-live', 'off');
      }
    }
  }, [buttonState, hasCheckoutSubmitOverlay]);

  const [orderSubmit] = useMutation(ORDER_SUBMIT_MUTATION, {
    onCompleted: (data) => {
      clearErrors();

      if (data?.orderSubmit?.success) {
        setButtonState('success');
        // trigger an event for CRS to listen to and submit the form
        // this evenet will pass the data that was returned from CART
        // currently required for proper redirect to order confirmation page
        const event = new CustomEvent('mfe:submit:successful', {
          detail: data?.orderSubmit,
        });
        window.dispatchEvent(event);
      } else {
        // TODO: trigger an event for CRS to listen to and
        // highlight the fields that have errors
        setButtonState('retry');

        const errorMessage = data?.orderSubmit?.statusMessages?.[0]?.message ?? 'Something went wrong. Please try again.';
        triggerError(errorMessage);
      }
    },
    onError: (error) => {
      setButtonState('retry');

      const errorMessage = error?.message ?? 'Something went wrong. Please try again.';
      triggerError(errorMessage);
    },
  });

  const handlePaymentSubmit = () => {
    const isPaymentReady = currentPaymentType === 'klarna' ? isKlarnaReady : true;

    handleSubmit({
      currentPaymentType,
      isPaymentReady,
      setErrorMessageText,
      paymentUnavailableMessage: unavailableMessage?.value,
    });
  };

  // listen for the event that CRS will trigger when it's ready to submit
  useEffect(() => {
    function handleCheckoutSubmitEvent(event) {
      const { success, errorMessage, formData } = event.detail;
      const submitData = prepareFormData(formData, checkoutPageState);
      const currentPaymentInfo = {
        ...paymentInfo,
        paymentType: currentPaymentType,
      };

      if (success) {
        setErrorMessageText('');
        setButtonState('processing');

        orderSubmit({
          variables: {
            orderSubmitInput: submitData,
            paymentInfo: currentPaymentInfo,
          },
        });
      } else {
        setErrorMessageText(errorMessage);
        setButtonState('initial');
      }
    }
    window.addEventListener('mfe:checkout:submit', handleCheckoutSubmitEvent);
    return () => {
      window.removeEventListener('mfe:checkout:submit', handleCheckoutSubmitEvent);
    };
  }, [paymentInfo, orderSubmit, checkoutPageState, currentPaymentType]);

  // listen for the CRS payment selector payment method change event
  // and if it's klarna or apple pay (PXP flow only), show the MFE submit button
  useEffect(() => {
    function handlePaymentMethodChangeEvent(event) {
      const { paymentType } = event.detail;
      if (paymentType === 'klarna' || (hasApplePayPXP && paymentType === 'applepay')) {
        setDisplaySubmitButton(true);
        setCurrentPaymentType(paymentType);
      } else {
        setDisplaySubmitButton(false);
        setCurrentPaymentType('');
      }
    }
    window.addEventListener('paymentSection:paymentUpdated', handlePaymentMethodChangeEvent);
    return () => {
      window.removeEventListener('paymentSection:paymentUpdated', handlePaymentMethodChangeEvent);
    };
  }, [hasApplePayPXP]);

  // on MFE payment selector payment method change
  // and if it's klarna or apple pay (PXP flow only), show the MFE submit button
  useEffect(() => {
    const paymentType = checkoutPageState?.selectedPaymentType;
    if (paymentType === 'klarna' || (hasApplePayPXP && paymentType === 'applepay')) {
      setDisplaySubmitButton(true);
      setCurrentPaymentType(paymentType);
    } else {
      setDisplaySubmitButton(false);
      setCurrentPaymentType('');
    }
  }, [checkoutPageState?.selectedPaymentType, hasApplePayPXP]);

  // listen to event that will let us know if it's express apple pay or not
  // depending on that we will show an error on the header or above submit button
  useEffect(() => {
    function handleApplePayExpressEvent(event) {
      const { isApplePayExpress } = event.detail;
      setIsUsingExpressPayment(isApplePayExpress);
      clearErrors();
    }
    window.addEventListener('mfe:checkout:applePayOpen', handleApplePayExpressEvent);
    return () => {
      window.removeEventListener('mfe:checkout:applePayOpen', handleApplePayExpressEvent);
    };
  }, [setIsUsingExpressPayment]);

  // we don't want to show the MFE submit button until Klarna or Apple Pay is selected
  // until more payments implemented
  if (!displaySubmitButton) {
    return null;
  }

  const getButtonLabel = (currentState) => {
    switch (currentState) {
      case 'initial':
        return label.value;
      case 'processing':
        return processingLabel.value;
      case 'retry':
        return tryAgainLabel.value;
      case 'success':
        return successLabel.value;
      default:
        return label.value;
    }
  };

  return (
    <div className="submit-button-wrapper-mfe" data-testid="submit-button-wrapper-mfe">
      {(buttonState === 'processing' && hasCheckoutSubmitOverlay) && <div className="submit-overlay" />}
      {errorMessageText
        && (
        <ErrorMessage id="summary-error-message">
          {errorMessageText}
        </ErrorMessage>
        )}
      <Button
        classList="submit-button"
        variant="primary"
        onClick={handlePaymentSubmit}
        isProcessing={buttonState === 'processing'}
        isDisabled={buttonState === 'processing'}
      >
        {getButtonLabel(buttonState)}
      </Button>
    </div>
  );
}

SubmitButton.defaultProps = {
  submitButtonData: {
    label: {
      key: 'Submit',
      value: 'Submit Order',
    },
    tryAgainLabel: {
      key: 'Try_Again',
      value: 'Try Placing Order Again',
    },
    processingLabel: {
      key: 'Processing',
      value: 'Processing',
    },
    successLabel: {
      key: 'Success',
      value: 'Success',
    },
  },
  isKlarnaReady: false,
};

SubmitButton.propTypes = {
  submitButtonData: PropTypes.shape({
    label: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    tryAgainLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    processingLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    successLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    unavailableMessage: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
  paymentInfo: PropTypes.shape({
    paymentType: PropTypes.string,
    authorizationToken: PropTypes.string,
  }).isRequired,
  isKlarnaReady: PropTypes.bool,
};

export default SubmitButton;
