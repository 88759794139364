import React from 'react';
import PropTypes from 'prop-types';

export default function GiftCardBalance({ giftCardBalance, tmntData }) {
  return (
    <div className="gift-card-balance-wrapper">
      <p className="gift-card-balance" data-property={tmntData?.giftCardBalanceRemaining?.key}>
        {`${giftCardBalance} ${tmntData?.giftCardBalanceRemaining?.value}`}
      </p>
    </div>
  );
}

GiftCardBalance.defaultProps = {
  tmntData: {},
};

GiftCardBalance.propTypes = {
  giftCardBalance: PropTypes.string.isRequired,
  tmntData: PropTypes.instanceOf(Object),
};
