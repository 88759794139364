import { ButtonGroup } from 'anf-core-react';
import React from 'react';
import PropTypes from 'prop-types';

export default function ProductSecondaryContent({ buttonGroup }) {
  return (
    <div className="product-secondary-content">
      <ButtonGroup variant="grid">{buttonGroup}</ButtonGroup>
    </div>
  );
}

ProductSecondaryContent.defaultProps = {
  buttonGroup: [],
};

ProductSecondaryContent.propTypes = {
  buttonGroup: PropTypes.arrayOf(PropTypes.shape({})),
};
