import PropTypes from 'prop-types';

const shippingSpeedShape = PropTypes.shape({
  shipModeId: PropTypes.string,
  shipModeType: PropTypes.string,
  description: PropTypes.string,
  amountDisplay: PropTypes.string,
  estsimatedDeliveryDateDisplay: PropTypes.string,
  selected: PropTypes.bool,
  isValid: PropTypes.bool,
  isEnabled: PropTypes.bool,
  availability: PropTypes.shape({}),
});

export default shippingSpeedShape;
