import { rootLogger } from '../components/useLog/useLog';
import $window from './window';

// I was on a call with QM today and we created this code specific for Address Verification
// We are required to use QM tracking so I am adding this logic here. In the future the
// Analytics team will be able to support other teams. When that comes we can remove the
// QM code and just let digital data / tealium handle the rest.

const QM_AV_CODE = 509;
const QM_SFL_CODE = 514;

const trackAction = (event, data = {}) => {
  if (event === 'checkout_address_verification_click') {
    $window?.QuantumMetricAPI?.sendEvent(QM_AV_CODE, 0, data.data_action);
  }
  if (event === 'bag_save_for_later_click') {
    $window?.QuantumMetricAPI?.sendEvent(QM_SFL_CODE, 0, data.data_action);
  }
  if ($window?.digitalData?.trigger) {
    // this is handle analytics triggers to listened by analytics teams
    $window.digitalData.trigger(event, data);
  } else {
    rootLogger.logger('trackAction').warn(`UNABLE TO TRACK ${event === 'digitalData'}`, event).destroy();
  }
};

export default trackAction;
