import { getShippingAddress, getBillingAddress, cleanUpAddressValues } from '../../../tools/address';

export default function prepareFormData(formData, checkoutPageState = {}) {
  const { shippingSpeed, shippingAddress, orderContact } = checkoutPageState;
  return {
    billingAddressSameAsShipping: formData?.billingAddressSameAsShipping ?? false,
    shippingAddressSameAsBilling: formData?.shippingAddressSameAsBilling ?? false,
    estimatedDeliveryDate: formData?.estimatedDeliveryDate ?? '',
    estimatedShipDate: formData?.estimatedShipDate ?? '',
    items: formData?.items ?? [],
    payment: formData?.payment ?? {},
    shippingAddress: cleanUpAddressValues(
      formData?.shippingAddress ?? getShippingAddress(
        formData,
        shippingSpeed,
        shippingAddress,
        orderContact,
      ),
    ),
    billingAddress: cleanUpAddressValues(
      formData?.billingAddress ?? getBillingAddress(formData, shippingSpeed),
    ),
    shipModeId: formData?.shipModeId ?? '',
    signifydSessionId: formData?.signifydSessionId ?? '',
    smsOptIn: formData?.smsOptIn ?? false,
    valueTierFlag: formData?.valueTierFlag ?? '',
    marketingInfo: formData?.marketingInfo ?? {},
  };
}
