import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from 'anf-core-react';
import CreditCard from '../../CreditCard/CreditCard';
import SelectedSavedPayment from '../SavedPayments/SelectedSavedPayment';
import ModalButtonWithTMNTQuery from '../../../Common/ModalButtonWithTMNTQuery/ModalButtonWithTMNTQuery';

const INVALID_CREDIT_CARD_FIELDS = {
  card: false,
  cardNumber: false,
  cardExpiry: false,
  cardCvv: false,
};

export default function CreditCardFields({
  isLoggedIn,
  creditCardConfig,
  payment,
  savedPaymentDetails,
  tmntData,
  onChange,
  onClear,
}) {
  const [cardDetails, setCardDetails] = useState('');
  const [isCheckedSave, setIsCheckedSave] = useState(true);
  const [hasCcValidationError, setHasCcValidationError] = useState(INVALID_CREDIT_CARD_FIELDS);
  const index = 0;

  useEffect(() => {
    setHasCcValidationError(INVALID_CREDIT_CARD_FIELDS);
  }, [savedPaymentDetails?.savedPaymentId]);

  useEffect(() => {
    const submitErrorHandler = (event) => {
      const { errors } = event.detail;
      if (errors?.invalidFields && errors?.invalidFields?.length > 0) {
        let invalidCardFields = { ...INVALID_CREDIT_CARD_FIELDS };
        errors?.invalidFields?.forEach((invalidField) => {
          if (invalidField?.type === `creditCard[${index}]`) {
            invalidCardFields.card = true;
          } else if (invalidField?.type === `creditCard[${index}].cardNumber`) {
            invalidCardFields.cardNumber = true;
          } else if (invalidField?.type === `creditCard[${index}].cardExpiryFmt`) {
            invalidCardFields.cardExpiry = true;
          } else if (invalidField?.type === `creditCard[${index}].cvv`) {
            invalidCardFields.cardCvv = true;
          }
        });
        if (invalidCardFields.card) {
          invalidCardFields = {
            ...INVALID_CREDIT_CARD_FIELDS,
            card: true,
          };
        }
        setHasCcValidationError(invalidCardFields);
      }
    };

    window.addEventListener('crs:submit:error', submitErrorHandler);

    return (() => {
      window.addEventListener('crs:submit:error', submitErrorHandler);
    });
  }, [index]);

  const onChangeCardDetails = (ccDetails) => {
    setCardDetails(ccDetails);
  };

  const handleCheckedSave = useCallback(() => {
    setIsCheckedSave((isCheckedPrev) => !isCheckedPrev);
  }, []);

  return (
    <div className="credit-card-fields-wrap">
      {savedPaymentDetails
        ? (
          <SelectedSavedPayment
            index={index}
            creditCardConfig={creditCardConfig}
            savedPaymentDetails={savedPaymentDetails}
            expTmnt={tmntData?.exp}
            expiresTmnt={tmntData?.expires}
            changeTmt={tmntData?.change}
            provideSecurityCodeTmnt={tmntData?.provideSecurityCode}
            securityCodeTmnt={tmntData?.securityCode}
            clearTmnt={tmntData?.clear}
            onChange={onChange}
            onClear={onClear}
            hasValidationError={hasCcValidationError}
          />
        )
        : (
          <>
            <CreditCard
              index={index}
              creditCardConfig={creditCardConfig}
              onChangeCreditCard={onChangeCardDetails}
              hasValidationError={hasCcValidationError}
            />
            {isLoggedIn && payment?.savable && (
              <>
                <div className="save-card-for-future">
                  <Checkbox
                    description={tmntData?.saveCardForFutureUse?.value}
                    id="save-card-for-future-use-0"
                    isChecked={isCheckedSave}
                    name={`creditCard[${index}].savePayment`}
                    value="1"
                    onChange={handleCheckedSave}
                  />
                </div>
                <ModalButtonWithTMNTQuery
                  tmntPairKey="legalPrivacyPolicy"
                  buttonLabelTmnt={tmntData?.privacyPolicy}
                  modalHeadingTmnt={tmntData?.privacyPolicy}
                />
              </>
            )}
            <input
              name={`creditCard[${index}].paymentCode`}
              value={cardDetails?.code || '021'}
              type="hidden"
              data-testid="cc-payment-code"
            />
          </>
        )}
    </div>
  );
}

CreditCardFields.defaultProps = {
  isLoggedIn: false,
  payment: {},
  savedPaymentDetails: null,
  creditCardConfig: {},
  tmntData: {},
  onChange: () => {},
  onClear: () => {},
};

CreditCardFields.propTypes = {
  isLoggedIn: PropTypes.bool,
  payment: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    code: PropTypes.string,
    description: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    message: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    icon: PropTypes.string,
    disabled: PropTypes.bool,
    savable: PropTypes.bool,
    default: PropTypes.bool,
  }),
  creditCardConfig: PropTypes.instanceOf(Object),
  savedPaymentDetails: PropTypes.shape({
    iconCode: PropTypes.string,
    maskedCardNumber: PropTypes.string,
    cardExpiryMonth: PropTypes.string,
    cardExpiryYear: PropTypes.string,
    cardType: PropTypes.string,
    requiresCvv: PropTypes.bool,
    savedPaymentId: PropTypes.string,
    default: PropTypes.bool,
  }),
  tmntData: PropTypes.instanceOf(Object),
  onChange: PropTypes.func,
  onClear: PropTypes.func,
};
