// Currently will trigger an event for CRS
function handleValidation(currentPaymentType, setErrorMessageText) {
  setErrorMessageText('');
  // trigger an event for CRS to listen to and run through normal logic
  // Once CRS does all the validations and is ready to submit, it will trigger another event
  // that we can listen to and then submit the form
  const event = new Event('crs:checkout:validate');
  event.details = { paymentType: currentPaymentType };
  window.dispatchEvent(event);
}

export default function handleSubmit({
  currentPaymentType,
  isPaymentReady,
  setErrorMessageText,
  paymentUnavailableMessage,
}) {
  if (isPaymentReady) {
    handleValidation(currentPaymentType, setErrorMessageText);
  } else {
    setErrorMessageText(paymentUnavailableMessage ?? '');
  }
}
