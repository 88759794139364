const removeTypeNameFromObject = (object) => (
  JSON.parse(
    JSON.stringify(object, (key, value) => {
      if (key === '__typename') return undefined;
      return value;
    }),
  )
);

export default removeTypeNameFromObject;
