import React from 'react';
import PropTypes from 'prop-types';
import ErrorMessage from 'anf-core-react/components/ErrorMessage';

export default function TextField({
  id, name, label, labelProp, type, isInvalid,
  onChange, errorMessage, errorMessageProp, isRequired,
  pattern, maxLength, autoComplete, onBlur, inputRef,
}) {
  return (
    <div className="input-field">
      <input
        ref={inputRef}
        type={type}
        pattern={pattern}
        id={id}
        name={name}
        placeholder={label}
        aria-label={label}
        aria-invalid={isInvalid}
        aria-required={isRequired}
        onChange={onChange}
        maxLength={maxLength}
        aria-describedby={(isInvalid && `${id}-error-message`) || undefined}
        autoComplete={autoComplete}
        onBlur={onBlur}
      />
      <label
        htmlFor={id}
        className="float-label"
        aria-hidden="true"
        data-property={labelProp}
      >
        {label}
      </label>
      {isInvalid && (
        <ErrorMessage
          id={`${id}-error-message`}
          data-property={errorMessageProp}
        >
          {errorMessage}
        </ErrorMessage>
      )}
    </div>
  );
}

TextField.defaultProps = {
  inputRef: undefined,
  name: undefined,
  type: 'text',
  onChange: undefined,
  labelProp: undefined,
  errorMessageProp: undefined,
  isInvalid: false,
  isRequired: false,
  pattern: undefined,
  maxLength: undefined,
  autoComplete: undefined,
  onBlur: undefined,
};

TextField.propTypes = {
  // the thing that is passed is an object
  // eslint-disable-next-line react/forbid-prop-types
  inputRef: PropTypes.object,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  labelProp: PropTypes.string,
  errorMessage: PropTypes.node.isRequired,
  errorMessageProp: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  isInvalid: PropTypes.bool,
  isRequired: PropTypes.bool,
  pattern: PropTypes.string,
  maxLength: PropTypes.number,
  autoComplete: PropTypes.string,
  onBlur: PropTypes.func,
};
