import React, { useMemo } from 'react';
import SwitchTestProvider from '../../SwitchTestProvider/SwitchTestProvider';
import MiniBagWithIcon from '../MiniBagWithIcon/MiniBagWithIcon';
import MiniBagIconStatic from '../MiniBagIconStatic/MiniBagIconStatic';
import $window from '../../../tools/window';
import { MINI_BAG_SWITCHES } from '../../../tools/constants';

export default function MiniBagWrapper() {
  const isOCNPage = useMemo(() => $window.location.href?.includes('/OrderConfirmationDisplayView'), []);

  return (
    <SwitchTestProvider keys={MINI_BAG_SWITCHES}>
      {isOCNPage ? <MiniBagIconStatic /> : <MiniBagWithIcon />}
    </SwitchTestProvider>
  );
}
