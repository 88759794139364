import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

export default function ProgressBar({
  classList,
  prelabel,
  postlabel,
  progressMinValue,
  progressValue,
  progressMaxValue,
}) {
  const [value, setValue] = useState(0);
  const animation = useRef({ currentValue: 0, animationInterval: null });
  animation.current.currentValue = value;

  const animateProgressBar = useCallback(() => {
    animation.current.animationInterval = setInterval(() => {
      if (animation.current.currentValue < progressValue) {
        setValue((oldValue) => oldValue + 1);
      }
      if (animation.current.currentValue === progressValue) {
        clearInterval(animation.current.animationInterval);
      }
    }, 1);
  }, [progressValue]);

  useEffect(() => {
    animateProgressBar();
    return () => {
      const { current } = animation;
      clearInterval(current.animationInterval);
    };
  }, [animateProgressBar]);

  useEffect(() => {
    setValue(0); // Reset the value when progressValue changes
  }, [progressValue]);

  return (
    <div className={clsx('progress-bar js-progress-bar', classList)}>
      {(progressMinValue || progressMinValue === 0) && (
        <span
          aria-hidden="true"
          className="progress-bar-prelabel"
          data-testid="progress-bar-prelabel"
        >
          {prelabel}
        </span>
      )}
      <progress
        className="js-progress-element"
        data-testid="progress-bar"
        aria-hidden="true"
        data-value={value}
        value={value}
        max={progressMaxValue}
      />
      {progressMaxValue && (
        <span
          aria-hidden="true"
          className="progress-bar-postlabel"
          data-testid="progress-bar-postlabel"
        >
          {postlabel}
        </span>
      )}
    </div>
  );
}

ProgressBar.defaultProps = {
  classList: '',
  postlabel: '',
  prelabel: '',
  progressValue: 0,
  progressMinValue: 0,
  progressMaxValue: 100,
};

ProgressBar.propTypes = {
  classList: PropTypes.string,
  progressMinValue: PropTypes.number,
  postlabel: PropTypes.string,
  prelabel: PropTypes.string,
  progressValue: PropTypes.number,
  progressMaxValue: PropTypes.number,
};
