import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import PayOverTime from './PayOverTime';

export default function PayOverTimeWidget({
  purchaseAmount, page, width, inline, querySelector,
}) {
  const [state, setState] = useState(null);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const $purchaseAmount = parseFloat(purchaseAmount || searchParams.get('purchaseAmount')) || 0;
    const $page = page || searchParams.get('page') || 'bag';
    let $inline = (inline || searchParams.get('inline'));
    $inline = (typeof $inline === 'undefined' || $inline === null) ? false : $inline === 'true';
    const $querySelector = (querySelector || searchParams.get('querySelector') || '.pay-over-time-portal');
    setState({
      purchaseAmount: $purchaseAmount,
      page: $page,
      inline: $inline,
      querySelector: $querySelector,
    });
  }, [purchaseAmount, page, inline, querySelector]);

  if (!state) {
    return null;
  }

  return (
    <div
      className="pay-over-time-widget"
      style={{ width }}
    >
      <PayOverTime
        purchaseAmount={state.purchaseAmount}
        page={state.page}
        inline={state.inline}
        querySelector={state.querySelector}
      />
    </div>
  );
}

PayOverTimeWidget.defaultProps = {
  page: undefined,
  purchaseAmount: undefined,
  width: undefined,
  inline: undefined,
  querySelector: undefined,
};

PayOverTimeWidget.propTypes = {
  page: PropTypes.string,
  purchaseAmount: PropTypes.string,
  width: PropTypes.string,
  inline: PropTypes.string,
  querySelector: PropTypes.string,
};
