import React from 'react';
import PropTypes from 'prop-types';

export default function MaskedGiftCard({ giftcard }) {
  return (
    <div className="gift-card-content">
      {giftcard?.maskedNumber?.replace(/X/g, '\u2022').replace(/-/g, ' ') || ''}
    </div>
  );
}

MaskedGiftCard.defaultProps = {
  giftcard: {},
};

MaskedGiftCard.propTypes = {
  giftcard: PropTypes.instanceOf(Object),
};
