import { Button } from 'anf-core-react';
import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import usePaypalExpress from '../../../tools/paypal';
import trackAction from '../../../tools/analytics';

export default function PaypaylExpressButton({
  paypalExpressURL,
  paypalExpressImage,
  payPalMerchantId,
  payPalEnvironment,
  PayPalInContextScriptSrc,
  classList,
  tmntData,
  isDisabled,
}) {
  const {
    loadPaypalJS,
    paypalSetup,
    openPaypalPopupWindow,
  } = usePaypalExpress(
    payPalEnvironment,
    payPalMerchantId,
    paypalExpressURL,
    PayPalInContextScriptSrc,
  );

  useEffect(() => {
    loadPaypalJS();
    paypalSetup(payPalMerchantId, payPalEnvironment);
  }, [loadPaypalJS, paypalSetup, payPalEnvironment, payPalMerchantId]);

  const handleClickPaypalExpress = useCallback(
    () => {
      if (isDisabled) return;
      trackAction('bag_paypal_click', {
        data_text: 'paypal',
        data_action: 'open',
        event_type: 'click',
      });
      openPaypalPopupWindow();
    },
    [openPaypalPopupWindow, isDisabled],
  );

  const paypalExpressBtnTMNTValue = tmntData?.paypalButtonTMNTValue?.value;

  return (
    <Button
      isFullWidth
      classList={clsx(classList, 'paypal-express-button')}
      onClick={handleClickPaypalExpress}
      isDisabled={isDisabled}
    >
      <img
        data-testid="paypal-image"
        src={paypalExpressImage}
        alt={paypalExpressBtnTMNTValue}
      />
    </Button>
  );
}

PaypaylExpressButton.defaultProps = {
  paypalExpressURL: '',
  tmntData: {},
  paypalExpressImage: '',
  payPalMerchantId: '',
  payPalEnvironment: '',
  PayPalInContextScriptSrc: '',
  classList: '',
  isDisabled: false,
};

PaypaylExpressButton.propTypes = {
  paypalExpressURL: PropTypes.string,
  tmntData: PropTypes.instanceOf(Object),
  paypalExpressImage: PropTypes.string,
  payPalMerchantId: PropTypes.string,
  payPalEnvironment: PropTypes.string,
  PayPalInContextScriptSrc: PropTypes.string,
  classList: PropTypes.string,
  isDisabled: PropTypes.bool,
};
