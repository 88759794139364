import React from 'react';
import PropTypes from 'prop-types';

export default function TileInput({
  isChecked,
  labelText,
  id,
  name,
  type,
  value,
  variant,
  tileText,
  onChange,
  state,
}) {
  return (
    <div className="tile-input" data-state={state}>
      <input
        checked={isChecked}
        aria-label={labelText}
        id={id}
        name={name}
        type={type}
        value={value}
        onChange={onChange}
      />

      <label data-variant={variant} className="tile-content" htmlFor={id}>
        <span className="tile-text">{tileText || value}</span>
      </label>
    </div>
  );
}

TileInput.defaultProps = {
  isChecked: false,
  variant: 'default',
  labelText: undefined,
  tileText: undefined,
  state: undefined,
  onChange: undefined,
};

TileInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  isChecked: PropTypes.bool,
  labelText: PropTypes.string,
  variant: PropTypes.string,
  tileText: PropTypes.string,
  state: PropTypes.string,
};
