import React, { useState } from 'react';
import {
  Modal,
} from 'anf-core-react';
import PropTypes from 'prop-types';
import OrderInStoreSuccess from './OrderInStoreSuccess';
import OrderInStoreForm from './OrderInStoreForm';

export default function OrderInStoreModal({
  openModal,
  closeModal,
  modalType,
  oisData,
  startNewSession,
}) {
  const [submisssionSuccess, setSubmissionSuccess] = useState(false);
  const isSendToRegister = modalType === 'sendToRegister';
  const [
    isSendToRegisterFormSubmitted,
    setIsSendToRegisterFormSubmitted,
  ] = useState(isSendToRegister);

  const modalHeading = isSendToRegister
    ? oisData?.sendToRegisterLabel?.value : oisData?.sendToCustomerLabel?.value;

  return (
    <Modal
      id="ois-modal"
      isOpen={openModal}
      onClose={!submisssionSuccess ? closeModal : startNewSession}
      heading={modalHeading}
      closeButtonLabel="Close" // screen reader text
    >
      <div className="ois-modal-form scope-1892">
        {
          // display OiS Form Initially
          !submisssionSuccess
          && (
          <OrderInStoreForm
            oisData={oisData}
            isSendToRegister={isSendToRegister}
            setSubmissionSuccess={setSubmissionSuccess}
            setIsSendToRegisterFormSubmitted={setIsSendToRegisterFormSubmitted}
          />
          )
        }
        {
          // display the success message and don't display the form
          submisssionSuccess
          && (
          <OrderInStoreSuccess
            oisData={oisData}
            isSendToRegister={isSendToRegisterFormSubmitted}
            startNewSession={startNewSession}
          />
          )
        }
      </div>

    </Modal>
  );
}

OrderInStoreModal.defaultProps = {
  oisData: {
    sendToRegisterLabel: {
      key: 'CHK_OISSENDTOREGISTER',
      value: 'Send to Register',
    },
    sendToCustomerLabel: {
      key: 'CHK_OISSENDTOCUSTOMER',
      value: 'Send to Customer',
    },
  },
};

OrderInStoreModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  modalType: PropTypes.string.isRequired,
  oisData: PropTypes.shape({
    sendToRegisterLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
    sendToCustomerLabel: PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  }),
  startNewSession: PropTypes.func.isRequired,
};
