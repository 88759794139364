import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'anf-core-react';
import Tmnt from '../../Tmnt/Tmnt';
import SavedPaymentsModal from './SavedPayments/SavedPaymentsModal';

export default function PaymentOptionsHeader({
  isLoggedIn,
  isModalOpen,
  savedPayments,
  tmntData,
  onButtonClick,
  onModalClose,
  onSelect,
}) {
  return (
    <div className="payment-options-header">
      <h3 className="payment-options-heading">
        <Tmnt tmnt={tmntData?.paymentOptions} />
      </h3>
      {isLoggedIn && (
        <>
          <Button
            value="saved-payments"
            variant="borderless"
            classList="button ds-override"
            labelText={tmntData?.savedPayments?.value}
            onClick={onButtonClick}
          >
            <Tmnt tmnt={tmntData?.savedPayments} />
          </Button>
          <SavedPaymentsModal
            isOpen={isModalOpen}
            onClose={onModalClose}
            savedPayments={savedPayments}
            tmntData={tmntData}
            onSelect={onSelect}
          />
        </>
      )}
    </div>
  );
}

PaymentOptionsHeader.defaultProps = {
  isLoggedIn: false,
  isModalOpen: false,
  savedPayments: [],
  tmntData: {},
};

PaymentOptionsHeader.propTypes = {
  isLoggedIn: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  savedPayments: PropTypes.arrayOf(PropTypes.shape({
    iconCode: PropTypes.string,
    maskedCardNumber: PropTypes.string,
    cardExpiryMonth: PropTypes.string,
    cardExpiryYear: PropTypes.string,
    cardType: PropTypes.string,
    requiresCvv: PropTypes.bool,
    savedPaymentId: PropTypes.string,
    default: PropTypes.bool,
  })),
  tmntData: PropTypes.instanceOf(Object),
  onButtonClick: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};
