import React, {
  useEffect, useContext, useState,
} from 'react';
import UserSectionDetails from '../Common/UserSectionDetails/UserSectionDetails';
import CheckoutPageContext from '../../context/CheckoutPageContext';

export default function CheckoutLeftRail() {
  const {
    checkoutPageState,
    setCheckoutPageState,
    setShowDiscount,
    showNotification,
    setShowNotification,
  } = useContext(CheckoutPageContext);
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) { return null; }

  return (
    <>
      {/* promos-loyalty-wrapper start */}
      {checkoutPageState?.switches?.showCheckoutSignInModule && (
        <UserSectionDetails
          purchaseContext={checkoutPageState}
          setPurchaseContext={setCheckoutPageState}
          setShowDiscount={setShowDiscount}
          showNotification={showNotification}
          setShowNotification={setShowNotification}
          page="checkout"
        />
      )}
      {/* promos-loyalty-wrapper end  */}
    </>
  );
}
